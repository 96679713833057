import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import AddCategory from "./ProductCategory/AddCategory";
import AddCategoryForm from "./ProductCategory/AddCategoryForm";
import AddCategoryFormEdit from "./ProductCategory/AddCategoryFormEdit";
import CategoryTable from './ProductCategory/CategoryTable';
import SearchFilterCategoryByGroup from "./ProductCategory/Filter/SearchFilterCategoryByGroup";
import { ChangeCategory, countPage, DeleteCategory, GetCategoryListByGroup, HideItem, ShowItemFlex, SubmitCategory } from "./productHelper";
import Slider from "./Slider";

const ProductCategoryByGroup = () => {
  useTitle("Pao Kitchen | Category by Group")
  const { groupCategoryId } = useParams()

  const [categorylists, setCategorylist] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)


  useEffect(() => {
    getCategory(groupCategoryId, page, "");

    return () => {
    };
  }, [groupCategoryId, page]);
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const getCategory = (groupCategoryId, page, search) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setCategorylist([])
    setPageCount(0)
    GetCategoryListByGroup(groupCategoryId, page, search).then((res) => {
      setIsEnabled(false)
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;
      setPageCount(countPage(total, limit))
      let toPush = []
      Object.keys(paginatedResults).forEach(key => {
        const { CreatedAt, Description, Name, GroupCategory, IsActive, _id } = paginatedResults[key];
        toPush.push({
          id: _id,
          groupCategory: GroupCategory,
          isActive: IsActive,
          categoryName: {
            EN: Name.EN,
            VN: Name.VN,
            CN: Name.CN,
            TH: Name.TH
          },
          categoryDescription: {
            EN: Description.EN,
            VN: Description.EN,
            CN: Description.EN,
            TH: Description.EN
          },
          createdAt: CreatedAt
        })
      })
      setCategorylist(toPush)
      Swal.close()
    }).catch(function (error) {
      setPageCount(0)
      if (error?.response?.status === 422) {
        setIsEnabled(true)
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "An error occured while doing your request",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      } else if (error?.response?.status === 404) {
        setIsEnabled(false)
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Category(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "An error occured while doing your request",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
        setIsEnabled(true)
      }
    });
  }
  const submitCategoryForm = (category, e) => {
    e.preventDefault();
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitCategory(category).then(res => {
      getCategory(groupCategoryId, page, "");
      HideItem("#categoryAddForm")
      Swal.close()
      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Category was added`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }
    ).catch(function (error) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    });
  }
  const deleteCategory = (id, e) => {
    e.preventDefault();
    DeleteCategory(id).then(res => {
      getCategory(groupCategoryId, 1, "");
    })
  }
  const [categoryValue, setCategoryValue] = useState({
    id: "",
    groupCategory: "",
    categoryname: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    categorydescription: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    isActive: true
  })
  const showCategoryEdit = (e, categoryValue) => {
    e.preventDefault()
    const { groupCategory, categoryName, categoryDescription, isActive, id } = categoryValue
    console.log(categoryValue);
    setCategoryValue({
      id: id,
      groupCategory: groupCategory._id,
      categoryname: categoryName,
      categorydescription: categoryDescription,
      isActive
    })
    ShowItemFlex("#categoryAddFormEdit")
  }
  const updateCategory = (e, categoryValue) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const { id, groupCategory, categoryname, categorydescription, isActive } = categoryValue
    let data = {
      Name: categoryname,
      Description: categorydescription,
      IsActive: isActive,
      GroupCategory: groupCategory
    }
    ChangeCategory(id, data)
      .then(
        res => {
          HideItem("#categoryAddFormEdit")
          getCategory(groupCategoryId, page, "");
          Swal.close()
          Swal.fire({
            toast: true,
            icon: 'success',
            title: `${id} Updated`,
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              Swal.hideLoading();
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
        }
      )
      .catch(
        err => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! 😔",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.showLoading();
            }
          })
        }
      )
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getCategory(groupCategoryId, 1, "");
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getCategory(groupCategoryId, 1, search);
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        product management / product categories
      </section>
      <SearchFilterCategoryByGroup {...{ clearFilter, productFilter }} />
      <CategoryTable {...{ categorylists, deleteCategory, showCategoryEdit }} />
      {
        isEnabled ? (<></>) : (<AddCategory />)
      }

      <Slider tableId={"productCategoryTable"} />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <AddCategoryForm {...{ submitCategoryForm, groupCategoryId }} />
      <AddCategoryFormEdit {...{ categoryValue, updateCategory }} />
    </main>
  );
};

export default ProductCategoryByGroup;
