export const defaultEmployeeMeal = {
  Card: {
    totalOrders: 0,
    totalUserSpent: 0,
    TotalCompanyAllowance: 0,
    totalOnline: 0,
    totalDineIn: 0,
    totalKTV: 0
  },
  // SalesPerPaymentMethod: [],
  EmployeeLists: [],
  paginatedResults: []
}