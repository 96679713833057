import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { GetCategoryList, HideItem } from "../productHelper";

const AddSubCategoryForm = ({ categoryId, node, submitSubCategoryForm }) => {
  const closeAddCategoryForm = () => {
    HideItem("#subcategoryAdd")
  }
  const [subCategory, setSubCategory] = useState({
    category: categoryId ?? "",
    categoryname: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    categorydescription: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    isActive: true
  });
  const [categories, setCategory] = useState([]);
  const [language, setLanguage] = useState({ Language: "VN", LangDesc: "Vietnam" });
  const { Language, LangDesc } = language;
  const { category, categoryname, categorydescription, isActive } = subCategory;
  useEffect(() => {
    GetCategoryList("", "").then(res => {
      const { paginatedResults } = res.data;
      setCategory(paginatedResults);
    }).catch(function (error) {
      console.log(error);
    });
    return () => {

    };
  }, []);

  const changeLang = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    value === "EN" ? (setLanguage({ ...language, [name]: value, LangDesc: "English" }))
      : value === "CN" ? (setLanguage({ ...language, [name]: value, LangDesc: "Chinese" }))
        : value === "TH" ? (setLanguage({ ...language, [name]: value, LangDesc: "Thailand" }))
          : (setLanguage({ ...language, [name]: value, LangDesc: "Vietnam" }))


    let buttons = document.querySelectorAll(".langSCA");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("lang-active")
    }
    e.target.classList.add('lang-active')
  }
  const changeCategoryName = (e) => {
    const { name, value } = e.target;
    setSubCategory({ ...subCategory, categoryname: { ...categoryname, [name]: value } })
  }
  const changeCategoryDescription = (e) => {
    const { name, value } = e.target;
    setSubCategory({ ...subCategory, categorydescription: { ...categorydescription, [name]: value } })
  }

  const changeStatus = (e) => {
    const { name, value } = e.target;
    setSubCategory({ ...subCategory, [name]: value })
  }

  return (
    <div className="modal-add-group" id="subcategoryAdd" onClick={() => { closeAddCategoryForm() }}>
      <div className="modal-add-group-form" onClick={(e) => {
        e.stopPropagation();
      }}>
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            add new sub category
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={closeAddCategoryForm}
            />
          </div>
        </div>
        <form className="modal-add-group-form-main">

          <div className="modal-add-group-form-main-language">
            <div className="language-changer">
              <label htmlFor="language">FIELDS FOR</label>
              <button onClick={(e) => changeLang(e)} className="langSCA lang-active" name="Language" value="VN">VN</button>
              <button onClick={(e) => changeLang(e)} className="langSCA " name="Language" value="EN">EN</button>
              <button onClick={(e) => changeLang(e)} className="langSCA " name="Language" value="CN">CN</button>
              <button onClick={(e) => changeLang(e)} className="langSCA " name="Language" value="TH">TH</button>
            </div>
          </div>
          <div className="modal-add-group-form-main-group">
            <label htmlFor="categorygroup">Category</label>
            <select disabled={categoryId ?? false} name="category" id="categorygroup" value={category} onChange={changeStatus}>
              <option value="">- Please select category -</option>
              {
                categories ? (
                  Object.keys(categories).map((key, index) => {
                    const { _id, Name } = categories[key];
                    return (
                      <option key={index} value={_id}>{Name.EN} ( {Name.VN} )</option>
                    )
                  })
                ) : "No Other Category found"
              }
            </select>
          </div>
          <div className="modal-add-group-form-main-name">
            <label htmlFor="categoryname">SubCategory Name ( {LangDesc} )</label>
            <input type="text" id="categoryname" name={Language}
              value={
                Language === "EN" ? categoryname.EN
                  : Language === "CN" ? categoryname.CN
                    : Language === "TH" ? categoryname.TH
                      : categoryname.VN
              }
              onChange={changeCategoryName}
            />
          </div>
          <div className="modal-add-group-form-main-description">
            <label htmlFor="categorydescription">Description ( {LangDesc} )</label>
            <textarea type="text" id="categorydescription" name={Language}
              value={
                Language === "EN" ? categorydescription.EN
                  : Language === "CN" ? categorydescription.CN
                    : Language === "TH" ? categorydescription.TH
                      : categorydescription.VN
              }
              onChange={changeCategoryDescription}
            />
          </div>
          <div className="modal-add-group-form-main-status">
            <label htmlFor="categorystatus">status</label>
            <select name="isActive" id="categorystatus" value={isActive} onChange={changeStatus}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </form>

        <div className="content-main-add-user-footer">
          <button
            className="content-main-add-user-footer-cancel"
            onClick={closeAddCategoryForm}
          >
            cancel
          </button>
          <button
            className="content-main-add-user-footer-create"
            onClick={(e) => submitSubCategoryForm(subCategory, e)}
          >
            create
          </button>
        </div>
      </div>
    </div>
  )
};

export default AddSubCategoryForm;
