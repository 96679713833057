import { useState, useEffect } from "react"
import { VietQRCode, GcashQRCode, ConvertTOVND, getQRCodeByStatus } from "../../EcommerceHelper"
import QRCode from "react-qr-code"
import "./GenerateQRCode.scss"
const branchId = localStorage.getItem( 'branchId' )

const GenerateQRCode = ({order})=>{
  const [payDetails, setPayDetails] = useState("")
    const [qrcodedetails, setQRCodeDetails] = useState([])
    const [gcashqrcodedetails, setGcashQRCodeDetails] = useState([])
    const [amountFinal , setAmountFinal] = useState(0)
    const [convertVND, setConvertVND] = useState([])
   
    const [rate, setRate] = useState("")

    //listdetails
    const [accountNumberForBank, setaccountNumberForBank] = useState("")
    const [accountBankForBank, setaccountBankForBank] = useState("")
    const [accountNameForBank, setaccountNameForBank] = useState("")

    const [accountNumberForGcash, setaccountNumberForGcash] = useState("")
    const [accountBankForGcash, setaccountBankForGcash] = useState("")
    const [accountNameForGcash, setaccountNameForGcash] = useState("")
    useEffect(() => { 
      paymentDetails()
      VietQR()
      GcashQR()
    }, [order])

    
    const VietQR = async() => {
      const GetQRCodeByStatus = await getQRCodeByStatus()
       let accountNumberForBank = ''
       let accountBankForBank = ''
       let accountNumberForGcash = ''
       let accountBankForGcash = ''
       let accountNameForGcash = ''
       let accountNameForBank = ''
       let bankId =''
    //  console.log('this',GetQRCodeByStatus.data[1].BankId)
      for(let i = 0 ; i < GetQRCodeByStatus.data.length ; i++){
       // console.log('this',GetQRCodeByStatus.data[0].BankId)
        if(GetQRCodeByStatus.data[i].BankId != null){
          bankId = GetQRCodeByStatus.data[i].BankId
          accountNumberForBank = GetQRCodeByStatus.data[i].Number
          setaccountBankForBank(GetQRCodeByStatus.data[i].BankName)
          setaccountNameForBank(GetQRCodeByStatus.data[i].Account)
          setaccountNumberForBank(GetQRCodeByStatus.data[i].Number)
        }
        else if(GetQRCodeByStatus.data[i].BankId == null){
          setaccountNumberForGcash(GetQRCodeByStatus.data[i].Number)
          setaccountBankForGcash(GetQRCodeByStatus.data[i].BankName)
          setaccountNameForGcash(GetQRCodeByStatus.data[i].Account)
        }
      }
      const amount = parseFloat(order.TotalAmount).toFixed(2);
       const filterForVND = {
          params:{
             SelectedBranch:branchId,
             status: true,
             page: 1
          }
        }
      const resultVND = await ConvertTOVND(filterForVND)
      let total = resultVND.data.paginatedResults[0].rate * amount
        //  console.log(Math.round(total))
         const filter =  {
              params:{
              accountNo : accountNumberForBank,
              amount : Math.round(total),
              info: order.OrderRef,
              bankId : bankId
            }
        }
        console.log(filter)
       const result = await VietQRCode(filter)
       
       setConvertVND( Math.round(total))
       setQRCodeDetails(result.data)
       setRate(resultVND.data.paginatedResults[0].rate)
    }
    
    const GcashQR = async() => {
      const amountgcash = parseFloat(order.TotalAmount).toFixed(2);
      const filter =  {
           params:{
           amount :amountgcash,
           }
     }       
    const result = await GcashQRCode(filter)
    setGcashQRCodeDetails(result.data)
    setAmountFinal(amountgcash)
    
  }

  const paymentDetails = async()=>{
    const GetQRCodeByStatus = await getQRCodeByStatus()
    //console.log(GetQRCodeByStatus.data[0].Account)
    setPayDetails(GetQRCodeByStatus.data)
  }
    return (   
      <div className="parent">
        <div className="parent-text">
          Choose QR Code
        </div>
        <div className='flexbox-container'>
          <div className="flexbox-item flexbox-item-1">
             <div>
              GCASH
             </div>
             <div className="flexbox-item-qr">
                <QRCode
                 size={256}
                 style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                 value={gcashqrcodedetails.toString()}
                 viewBox={`0 0 256 256`}
                   />
             </div>
             <div className="wallet-value">
            Amount: ₱{amountFinal.toLocaleString("en-US")}
             </div>
             <div className="wallet-value">     
              Name: {accountNameForGcash}    
             </div>
             <div className="wallet-value">       
              Number: {accountNumberForGcash}
             </div>
          </div>   

          <div className="flexbox-item flexbox-item-2">
             <div>
              BANK TRANSFER
             </div>
             <div className="flexbox-item-qr">
             <QRCode
               size={256}
               style={{ height: "auto", maxWidth: "100%", width: "100%" }}
               value={qrcodedetails.toString()}
               viewBox={`0 0 256 256`}
                />
             </div>       
             <div className="wallet-value">
             Amount: {convertVND.toLocaleString("en-US")} VND
             {/* <span className="wallet-value">Amount: {convertVND.toLocaleString("en-US")} VND</span> */}
             </div>
             <div className="wallet-value">
              Rate: {rate.toLocaleString("en-US")}   
             </div>
             <div className="wallet-value">
              Bank: {accountBankForBank}
             </div>
             <div className="wallet-value">      
              Name:{accountNameForBank}
             </div>
             <div className="wallet-value">  
              Account No: {accountNumberForBank}
             </div>
          </div>
        </div>
        <div className="flexbox-container2">
        
        </div>
     </div>
      )
}

export default GenerateQRCode