import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { GetOrderList } from "../../pages/Ecommerce/EcommerceHelper";
import music from './../../assets/order.wav';
import { setTab } from "../../redux/actions/tab";
import { setToken } from "../../redux/actions/token";
import BranchSelect from "./content/BranchSelect";
import Content from "./content/Content";
import Menu from "./menu/Menu";
import {setCmsConfigPusher} from "../../redux/actions/CmsConfigPusher";
import {getListEventCMSPusher} from "../../helpers/pusherHelper";
import Pusher from "pusher-js";
import {setOrderTotalPending} from "../../redux/actions/Order";

const CMS = () => {
  const jwtSecretKey = process.env.REACT_APP_JWT_SECRET_KEY;
  const locationIcon = { fontSize: "60px", color: "#50311b" };
  const jwt = require("jsonwebtoken");
  const dispatch = useDispatch();
  const {token: tokenState, order: orderState} = useSelector((state) => state);
  const { branchId, token } = tokenState?.token;
  const user = jwt.decode(token, null);
  const DEFAULT_SEARCH_VALUE = {
    Status: 0,
    PaymentMethod: "",
    SendOption: "",
    Paid: "",
    Locale: "",
    HasOrderPriority: "",
    DateOrderFrom: "",
    DateOrderTo: "",
    DateCompletedFrom: "",
    DateCompletedTo: ""
  }

  useEffect(() => {
    getOrderList()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const pusherEventRegister = []

    if(branchId) {
      const audio = new Audio(music);

      getListEventCMSPusher().then((events = []) => {
        dispatch(setCmsConfigPusher(events))
        events.forEach(event => {
          if (event?.appKey) {
            const eventNames = event.events
            // console.log("CMS PUSH: ", eventNames)
            eventNames.forEach(eventName => {
              if (eventName === "CMS_PUSHER_NEW_ORDER") {
                const pusher = new Pusher(event?.appKey, {
                  cluster: event?.cluster,
                  encrypted: true
                });
                const channel = pusher.subscribe(event?.channel);
                channel.bind(eventName, () => {
                  getOrderList()
                  audio.play();
                  Swal.fire({
                    toast: true,
                    icon: 'info',
                    title: 'New Order Arrived',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                });
                pusherEventRegister.push(pusher)
              }
            })
          }
        })
      })
    }
    return () => {
      pusherEventRegister.forEach(pusher => {
        if (pusher instanceof Pusher) {
          pusher.unbind_all()
          pusher.disconnect()
        }
      })
    }
    // eslint-disable-next-line
  }, [branchId])

  const getOrderList = (page = 1, search = "", searchValue = DEFAULT_SEARCH_VALUE) => {
    GetOrderList(page, search, searchValue)
      .then(res => {
        const { metadata } = res?.data
        const { total } = metadata;
        dispatch(setOrderTotalPending(total ?? 0))
      })
      .catch(
        err => console.log(err.response)
      )
  }
  const showSubmenu = (menuType) => {
    if (branchId) {
      switch (menuType) {
        case "dashboard":
          dispatch(setTab({ currentTab: 0 }));
          break;
        case "product":
          dispatch(setTab({ currentTab: 1 }));
          break;
        case "eCommerce":
          dispatch(setTab({ currentTab: 2 }));
          break;
        case "pos":
          dispatch(setTab({ currentTab: 3 }));
          break;
        case "discount":
          dispatch(setTab({ currentTab: 4 }));
          break;
        case "customer":
          dispatch(setTab({ currentTab: 5 }));
          break;
        case "user":
          dispatch(setTab({ currentTab: 6 }));
          break;
        case "analytics":
          dispatch(setTab({ currentTab: 7 }));
          break;
        case "currency-conversion":
          dispatch(setTab({ currentTab: 8 }));
          break;
        case "public":
          dispatch(setTab({ currentTab: 9 }));
          break;
        case "setting_site":
          dispatch(setTab({ currentTab: 10 }));
          break;

        default:
          dispatch(setTab({ currentTab: 0 }));
          break;
      }
      document.getElementsByClassName("cms-submenu")[0].style.display = "flex";
      document.getElementsByClassName("cms-submenu")[0].style.animation =
        "submenuAnimationOpen 0.5s ease";
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops... Store branch is missing!",
        text: "Please select a store branch to continue",
        footer: "Pao Kitchen 2023",
      });
    }
  };

  const branchSelect = (id) => {
    localStorage.setItem("branchId", id);
    document.querySelector(".branch-selection").style.display = "none";
    dispatch(setToken({ branchId: id }));
    const newToken = jwt.sign({ ...user, SelectedBranch: id }, jwtSecretKey);
    localStorage.setItem("cmsToken", newToken);
    dispatch(setToken({ token: newToken }));
  };

  return (
    <div className="cms">
      <Menu showSubmenu={showSubmenu} countPending={orderState?.totalPending ?? 0} />
      <Content />
      <BranchSelect {...{ user, branchSelect, locationIcon }} />

    </div >
  );
};

export default CMS;
