import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GroupIcon from "@mui/icons-material/Group";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CalculateIcon from '@mui/icons-material/Calculate';
import HorizontalDivider from "./../../reusable/HorizontalDivider";
import IconLogo from "./../../reusable/IconLogo";
import SettingsIcon from '@mui/icons-material/Settings';
import {getCMSUser, USER_ROLES} from "../../../helpers/Authentication";
import {getObjectKey} from "../../../pages/Ecommerce/Order/OderHelper";

const Menu = ({ showSubmenu, countPending }) => {
    const user = getCMSUser()
  return (
    <div className="menu">
      <IconLogo />
      <HorizontalDivider />
      {/* <button
        className="menu-item"
        onClick={() => {
          showSubmenu("dashboard");
        }}
      >
        <DashboardIcon />
        <p>Dash Board</p>
      </button> */}
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("product");
        }}
      >
        <ViewInArIcon />
        <p>Product Manager</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("eCommerce");

        }}
      >
        <ShoppingCartIcon />
        <p>E-Commerce Manager </p>
        {
          Number(countPending) <= 0 ? (
            <></>
          ) : <span className="menu-item-count"><PriorityHighIcon sx={{
            fontSize: "14px"
          }} /></span>
        }
      </button>
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("pos");
        }}
      >
        <SplitscreenIcon />
        <p>Branch Manager</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("discount");
        }}
      >
        <LocalOfferIcon />
        <p>Discount Manager</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          if (user?.Role === getObjectKey(USER_ROLES, USER_ROLES.Admin)) {
            showSubmenu("customer");
          }else {
            console.log("you have no rights to access this page")
          }
        }}
      >
        <GroupIcon />
        <p>Customers Manager</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          if (user?.Role === getObjectKey(USER_ROLES, USER_ROLES.Admin)) {
            showSubmenu("user");
          }else {
            console.log("you have no rights to access this page")
          }
        }}
      >
        <ManageAccountsIcon />
        <p>Users Manager</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("analytics");
        }}
      >
        <AutoGraphIcon />
        <p>Analytics</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          showSubmenu("currency-conversion");
        }}
      >
        <CalculateIcon />
        <p>Currency Conversion</p>
      </button>
      <button
        className="menu-item"
        onClick={() => {
          if (user?.Role === getObjectKey(USER_ROLES, USER_ROLES.Admin)) {
            showSubmenu("public");
          } else {
            console.log("you have no rights to access this page") 
          }
        }}
      >
        <FileCopyIcon />
        <p>Public Manager</p>
      </button>
        {
            user?.Role === getObjectKey(USER_ROLES, USER_ROLES.Admin) ?
                <button
                    className="menu-item"
                    onClick={() => {
                        showSubmenu("setting_site");
                    }}
                >
                    <SettingsIcon />
                    <p>Settings site</p>
                </button>
            : null
        }
    </div>
  );
};

export default Menu;
