import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {USER_ROLES} from "../../helpers/Authentication";
const AddUserForm = ({ dataState, getUser, token, baseUrl }) => {
  const axios = require("axios");
  const addUserNode = useRef(null);
  const closeStyle = {
    fontSize: "18px",
    "&:hover": {
      fontWeight: "bold",
      color: "#50311b",
      cursor: "pointer",
    },
  };
  const [userData, setUserData] = useState(dataState);
  const { page, isActive, search, username, role, userid } = userData;

  useEffect(() => {
    const handleClick = (e) => {
      if (addUserNode.current.contains(e.target)) {
        return;
      }
      document.querySelector(".content-main-add-user").style.display = "none";
    };

    document.querySelector(".content-main-add-user").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const closeAddUserForm = () => {
    document.querySelector(".content-main-add-user").style.display = "none";
  };
  const submitUserForm = (e) => {
    e.preventDefault();
    document.querySelector(
      "#createUser"
    ).innerHTML = `<div class="loader"></div>`;
    let data = JSON.stringify({
      UserId: userData.userid,
      UserName: userData.username,
      Role: userData.role,
    });

    let config = {
      method: "post",
      url: `${baseUrl}/cms/users/add`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === "success") {
          document.querySelector("#createUser").innerHTML = `create`;
          setUserData({ ...userData, username: "", role: "", userid: "" });
          getUser(page, search, isActive);
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: `Password :  ${response.data.data.password}`,
            footer: "&#169; Pao Kitchen 2023",
          });
          closeAddUserForm();
        } else {
          document.querySelector("#createUser").innerHTML = `create`;
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "User was not added!",
            footer: "&#169; Pao Kitchen 2023",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
        let message = "",
          divider = "";
        const { data } = error.response.data;
        for (let i = 0; i < data.length; i++) {
          if (i < data.length - 1) {
            divider = ", ";
          } else {
            divider = "";
          }
          if (data[i].UserId) {
            message += data[i].UserId + divider;
          } else if (data[i].UserName) {
            message += data[i].UserName + divider;
          } else if (data[i].Role) {
            message += data[i].Role + divider;
          }
        }
        document.querySelector("#createUser").innerHTML = `create`;

        errorAlert(e, message);
      });
  };
  const errorAlert = (e, message) => {
    e.preventDefault();
    Swal.fire({
      icon: "error",
      title: "Failed",
      text: message,
      footer: "&#169; Pao Kitchen 2023",
    });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const renderOptionRoles = () => {
    const roles = []
    for (const value in USER_ROLES) {
      roles.push(<option key={`user_role_${value}`} value={value}>{USER_ROLES[value]}</option>)
    }

    return roles
  }

  return (
    <div ref={addUserNode} className="content-main-add-user">
      <div className="content-main-add-user-header">
        <div className="content-main-add-user-header-title">add new user</div>
        <div className="content-main-add-user-header-close">
          <CloseIcon sx={closeStyle} onClick={closeAddUserForm} />
        </div>
      </div>
      <div>
        <form className="content-main-add-user-form">
          <div className="content-main-add-user-form-item">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              value={username}
              onChange={handleOnChange}
            />
          </div>
          <div className="content-main-add-user-form-item">
            <label htmlFor="userid">User ID</label>
            <input
              type="text"
              name="userid"
              id="userid"
              value={userid}
              onChange={handleOnChange}
            />
          </div>
          <div className="content-main-add-user-form-item">
            <label htmlFor="role">Role</label>
            <select
              name="role"
              id="role"
              value={role}
              onChange={handleOnChange}
            >
              <option value="">Select Role</option>
              {renderOptionRoles()}
            </select>
          </div>
        </form>
      </div>
      <div className="content-main-add-user-footer">
        <button
          className="content-main-add-user-footer-cancel"
          onClick={closeAddUserForm}
        >
          cancel
        </button>
        <button
          className="content-main-add-user-footer-create"
          onClick={submitUserForm}
          id="createUser"
        >
          create
        </button>
      </div>
    </div>
  );
};

export default AddUserForm;
