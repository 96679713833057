import {EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_STATUS} from "../constant";
import ProductDetail from "./ProductDetail";
import {useCallback, useState} from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {random} from "lodash/number";
import KitchenPrintOrder from "./KitchenPrintOrder";
import {getObjectKey} from "../../OderHelper";
import {post} from "../../../../../helpers/axiosHelper";
import Swal from "sweetalert2";
import Proptypes from "prop-types";
import moment from "moment-timezone";

const ExtraOrderItem = ({order, index, mainOrder, refreshOrder}) => {
    const id = order?._id ?? 0
    const createdAt = order?.createdAt ?? null
    const status = order?.status ?? 0
    const detail = order?.detail ?? []
    const recordId = order?._id ?? ""
    const sendOption = order?.SendOption ?? "DELIVERY"
    const [collapsed, setCollapsed] = useState(true)
    const key = `extra-order-item--${index}`

    const renderListProduct = useCallback((products) => {
        const rand = random(1000, 10000, false)
        if(collapsed) {
            return null
        }

        const output = []
        output.push(<div key={`extra-product-detail-head-0-${rand}`} className="order-container-left-products-title">
            <span className="product-title">PRODUCT</span>
            <div className="product-number">
                <span className="fix-width">PRICE</span>
                <span>QTY</span>
                <span>TOTAL</span>
            </div>
        </div>)

        products.forEach((product, index) => {
            output.push(<ProductDetail key={`extra-product-detail-${index}-${rand}`} product={product} sendOption={sendOption} />)
        })

        return output
    }, [sendOption, collapsed])

    const getStatus = () => {
        let statusName

        switch (parseInt(status)) {
            case EXTRA_ORDER_STATUS.doing:
                statusName = "Doing"
                break
            case EXTRA_ORDER_STATUS.done:
                statusName = "Done"
                break
            case EXTRA_ORDER_STATUS.cancel:
                statusName = "Canceled"
                break
            default:
                statusName = "Pending"
        }

        return statusName
    }

    const toggleListProduct = () => {
        setCollapsed(!collapsed)
    }

    const [isOpenPrint, setIsOpenPrint] = useState(false)
    const onCancelPrint = () => {
        setIsOpenPrint(false)
    }
    const onSuccessPrint = () => {
        setIsOpenPrint(false)
    }
    const openPrint = () => {
        const needConfirm = status === EXTRA_ORDER_STATUS.pending
        if(needConfirm) {
            Swal.fire({
                icon: "question",
                title: "Are you sure?",
                text: "Order status will be updated to Doing.",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel"
            })
                .then(result => {
                    if(result.isConfirmed) {
                        const doing = EXTRA_ORDER_STATUS.doing
                        const onSuccess = () => {
                            setIsOpenPrint(true)
                            refreshOrder()
                        }

                        handleUpdateStatus(id, doing, onSuccess)
                       .catch(e => {
                            throw e
                        })
                    }
                })
        }
        else {
            setIsOpenPrint(true)
        }
    }

    const showDialogError = () => {
        Swal.fire({
            icon: "error",
            title: "Ops!",
            text: "Something went wrong. Please try again!",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok"
        })
    }

    const handleUpdateStatus = async (id, status, onSuccess = () => {}) => {
        try {
            Swal.showLoading()
            const res = await post("/cms/order/order-extra/update/status", {id, status})
            const newRecord = res.data?.data ?? {}
            const newStatus = newRecord?.status ?? null

            if(!newStatus) {
                showDialogError()
            }

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Order has bean updated",
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok"
            })
            .then(result => {
                if(result.isConfirmed) {
                    onSuccess()
                }
            })
        }
        catch (e) {
            Swal.close()
            console.log(e)
            showDialogError()
        }
    }

    const onClickDone = async () => {
        const STT_DONE = EXTRA_ORDER_STATUS.done
        const onSuccess = () => {
            refreshOrder()
        }
        await handleUpdateStatus(recordId, STT_DONE, onSuccess)
    }

    const onClickCancel = async () => {
        const STT_CANCEL = EXTRA_ORDER_STATUS.cancel
        const onSuccess = () => {
            refreshOrder()
        }
        await handleUpdateStatus(recordId, STT_CANCEL, onSuccess)
    }

    return (
        <div key={key} className="width-100 order-container-left-products extra-order-item">
            <div className="gap-10 margin-bottom-10 extra-order-head">
                <p className="extra-order-title">
                    Extra #{index + 1} : {createdAt ? moment.utc(createdAt).tz("Asia/Manila").format("HH:mm:ss DD-MM-YYYY") : ""} - <span className={`extra-order-title-highlight ${getStatus()}`}>{getStatus()}</span>
                </p>
                <div className="extra-order-actions">
                    <button className='btn-print' onClick={openPrint}>Print</button>
                    <button className='btn-done' disabled={status === EXTRA_ORDER_STATUS.cancel || EXTRA_ORDER_STATUS.done === status} onClick={onClickDone}>Done</button>
                    <button className='btn-cancel' disabled={status === EXTRA_ORDER_STATUS.cancel || EXTRA_ORDER_STATUS.done === status} onClick={onClickCancel}>Cancel</button>
                    <button className='btn-collapsed' onClick={toggleListProduct}>
                        {
                            collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </button>
                </div>
            </div>
            {renderListProduct(detail)}
            <KitchenPrintOrder isOpen={isOpenPrint} onCancel={onCancelPrint} type={getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyExtra)} order={mainOrder} index={index} onSuccess={onSuccessPrint} />
        </div>
    )
}

ExtraOrderItem.propTypes = {
    order: Proptypes.object.isRequired,
    index: Proptypes.number,
    mainOrder: Proptypes.object.isRequired,
    refreshOrder: Proptypes.func
}

export default ExtraOrderItem