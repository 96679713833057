import _ from 'lodash';
import { statuses } from '../OrderUtils';
import StatusC from './StatusC';

const StatusContainer = ({ Status, setOrder, order, currentStatus, currentPaymentStatus }) => {

  return (
    <div className="order-container-right-container-content">
      {
        _.map(statuses, (value, indx) => {
          return (
            <StatusC
              key={indx}
              Status={Status}
              setOrder={setOrder}
              value={Number(indx)}
              order={order}
              name="Status"
              id={"" + value}
              text={value}
              currentStatus={currentStatus}
              currentPaymentStatus={currentPaymentStatus}
            />
          )
        })
      }

      {/* <StatusC
        Status={Status}
        setOrder={setOrder}
        value={8}
        order={order}
        name="Status"
        id="confirmed"
        text="Confirmed"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={5}
        order={order}
        name="Status"
        id="prepared"
        text="Prepared"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={3}
        order={order}
        name="Status"
        id="processing"
        text="Processing"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={2}
        order={order}
        name="Status"
        id="shipping"
        text="Shipping"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={9}
        order={order}
        name="Status"
        id="delivered"
        text="Delivered"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={1}
        order={order}
        name="Status"
        id="completed"
        text="Completed"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={4}
        order={order}
        name="Status"
        id="cancelled"
        text="Cancelled"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={6}
        order={order}
        name="Status"
        id="returned"
        text="Returned"
      />
      <StatusC
        Status={Status}
        setOrder={setOrder}
        value={7}
        order={order}
        name="Status"
        id="declined"
        text="Declined"
      /> */}
    </div>
  );
};

export default StatusContainer;

