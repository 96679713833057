import _ from "lodash"; 
import CurrencyFormat from "react-currency-format";
import ScrollContainer from "react-indiana-drag-scroll";

const TableProduct = ({ tableContents }) => (
  
  <ScrollContainer className="content-product-table">
    <div className="content-product-table-head">
      <div className="content-product-table-head-item normal center item-name">EMPLOYEES</div>
      <div className="content-product-table-head-item normal center item-name">CONTACT</div>
      <div className="content-product-table-head-item normal center item-name">ORDERS</div>
      <div className="content-product-table-head-item normal center item-name">TOTAL</div>
    </div>
    { 
      !_.isEmpty(tableContents) ? (
        Object.keys(tableContents).map((key, index) => {
          const { _id, Orders, Total, Customer } = tableContents[key]
          const { ContactNum, FirstName, LastName } = Customer;
          const FullName = FirstName + " " + LastName;
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal center item-name">
                {FullName || "N/A"}
              </div>
              <div className="content-product-table-data-item normal center item-name">
                {ContactNum || "N/A"}
              </div>
              <div className="content-product-table-data-item normal center item-name">
                {Orders || "N/A"}
              </div> 
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Total}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal center item-name"></div> 
          <div className="content-product-table-data-item normal center item-name">
            No Available Reports Found!
          </div> 
          <div className="content-product-table-data-item normal center item-name"></div> 
        </div>
      )}
  </ScrollContainer>
);

TableProduct.defaultProps = {
  tableContents: []
}

export default TableProduct;
