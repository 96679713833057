import FileDownloadIcon from "@mui/icons-material/FileDownload";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ExportProductList, HideItem, IdSelector, ShowItem } from "./../productHelper";

const ExportProductButton = () => {
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { branchId, token } = tokens.token;
  const user = jwt.decode(token);
  const showTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#exportproduct");
  };
  const hideTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#exportproduct");
    }, 500);
  };
  return (
    <div className="export-product">
      <div id="exportproduct">Export</div>
      <div
        className="icon"
        onMouseEnter={showTextExport}
        onMouseLeave={hideTextExport}
        onClick={(e) => {

          e.preventDefault()
          Swal.fire({
            title: "In progress!",
            text: "Please wait while fetching data",
            allowOutsideClick: false,
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.showLoading();
            },
          });
          ExportProductList().then(
            res => {
              let FileSaver = require('file-saver');
              let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
              let branchName = _.find(user.Branches, (value, key) => {
                return value.BranchId === branchId
              })?.Name ?? ""
              FileSaver.saveAs(blob, `Annam-Products (${branchName}) List`);
              Swal.close();
            }
          )
            .catch(
              err => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Failed!",
                  text: "An error occured while doing your request",
                  footer: "Pao Kitchen 2023",
                });
              }
            )
        }}
      >
        <FileDownloadIcon sx={{ fontSize: "18px" }} />
      </div>
    </div>
  );
};
export default ExportProductButton;
