import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { HideItem } from "../productHelper";

const ShowBranchList = ({ node, user, selectAssignBranch }) => {
  const closeShowBranchList = () => {
    HideItem(".modal-add-group")
  }
  let selectedBranch = localStorage.getItem("branchId");
  const [branchList, setBranchList] = useState([]);
  const [assignBranch, setAssignBranch] = useState("");
  useEffect(() => {
    setBranchList(user.Branches)
    return () => {

    };
  }, [user.Branches, selectedBranch]);

  const changeBranch = (e) => {
    const { value } = e.target;
    setAssignBranch(value)
  }

  return (
    <div className="modal-add-group">
      <div className="modal-add-group-form" ref={node}>
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            Select Branch To Assign
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={closeShowBranchList}
            />
          </div>
        </div>
        <form className="modal-add-group-form-main">
          <div className="modal-add-group-form-main-description">
            <label htmlFor="branchSelect">Choose Branch to assign</label>
            <select value={assignBranch} onChange={changeBranch} name="assignBranch" id="branchSelect">
              <option value="">Select Branch</option>
              {
                !_.isEmpty(branchList) ? (
                  _.map(branchList, (value, key) => {
                    const { BranchId, Name } = value
                    return (
                      <option value={BranchId} key={key}>{Name}</option>
                    )
                  })
                ) : (<></>)
              }
            </select>
          </div>

        </form>

        <div className="content-main-add-user-footer">
          <button
            className="content-main-add-user-footer-cancel"
            onClick={closeShowBranchList}
          >
            cancel
          </button>
          <button
            className="content-main-add-user-footer-create"
            onClick={(e) => selectAssignBranch(assignBranch, e)}
          >
            Assign
          </button>
        </div>
      </div>
    </div>
  )
};

export default ShowBranchList;
