import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
const CategoryTable = ({ categorylists, deleteCategory, showCategoryEdit }) => {
  const [categorylist, setCategorylist] = useState();
  const container = useRef(null);
  useEffect(() => {
    container.current.getElement().id = "productCategoryTable";
    setCategorylist(categorylists);
    return () => {};
  }, [categorylists]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <ScrollContainer
      className="scroll-container content-product-table"
      ref={container}
    >
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start item-name">
          Category
        </div>
        <div className="content-product-table-head-item normal-start item-name">
          Description
        </div>
        <div className="content-product-table-head-item item-name">
          Group Category
        </div>
        <div className="content-product-table-head-item normal">Status</div>
        <div className="content-product-table-head-item normal-start">
          Date Created
        </div>
        <div className="content-product-table-head-item center">Actions</div>
      </div>
      {!_.isEmpty(categorylist) ? (
        Object.keys(categorylist).map((key, index) => {
          const { id, groupCategory, isActive, categoryName, createdAt } =
            categorylist[key];
          const { Name } = groupCategory;
          let now = new Date(createdAt);
          let dateCreated = moment(now).format("YYYY-MM-DD HH:MM:SS");
          return (
            <div
              className="content-product-table-data group-category"
              key={index}
              onClick={(e) => {
                openInNewTab(`/admin/cms/${id}/product-subcategories`);
              }}
            >
              <div className="content-product-table-data-item normal-start item-name">
                <div className="product-name">
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{categoryName.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{categoryName.VN}</small>
                    </div>
                    <div>
                      <small>{categoryName.CN}</small>
                    </div>
                    <div>
                      <small>{categoryName.TH}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                <div className="product-name">
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>-</b>
                      </span>
                    </div>
                    <div>
                      <small>-</small>
                    </div>
                    <div>
                      <small>-</small>
                    </div>
                    <div>
                      <small>-</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item item-name">
                <div className="product-name">
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Name.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Name.VN}</small>
                    </div>
                    <div>
                      <small>{Name.CN}</small>
                    </div>
                    <div>
                      <small>{Name.TH}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal">
                {isActive ? (
                  <span>
                    <CircleIcon sx={{ color: "green", fontSize: "12px" }} />{" "}
                    Active
                  </span>
                ) : (
                  <span>
                    <CircleIcon sx={{ color: "red", fontSize: "12px" }} />{" "}
                    Inactive
                  </span>
                )}
              </div>
              <div className="content-product-table-data-item normal-start">
                {dateCreated}
              </div>
              <div className="content-product-table-data-item center">
                <EditIcon
                  data-tip
                  data-for="editGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showCategoryEdit(e, categorylist[key]);
                  }}
                />

                <DeleteIcon
                  data-tip
                  data-for="deleteGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCategory(id, e);
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-head-item normal-start item-name"></div>
          <div className="content-product-table-head-item normal-start item-name"></div>
          <div className="content-product-table-head-item normal-center item-name">
            No Data Found!
          </div>
          <div className="content-product-table-head-item normal"></div>
          <div className="content-product-table-head-item normal-start"></div>
          <div className="content-product-table-head-item center"></div>
        </div>
      )}
    </ScrollContainer>
  );
};

export default CategoryTable;
