import { useTitle } from 'react-use'
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlusOneIcon from '@mui/icons-material/PlusOne';
// import { Pagination } from "@mui/material";
import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router";

import SearchFilter from './Filter/SearchFilter'
import './styles.scss'
import PlatformList from './PlatformList'
import { GetPlatformList } from '../EcommerceHelper'
// import Swal from "sweetalert2";
import { ShowItemFlex } from '../../ProductManager/productHelper';
import AddPlatform from "./AddPlatform";
const Platform = () => {

  // Title Setting
  useTitle("Pao Kitchen | Platforms")

  // Variables
  const [platformData, setPlatformData] = useState(null)
  const [statusActiveFilter, setStatusActiveFilter] = useState(false)
  const [refresh, refresher] = useState(true)
  const [rawPlatformData, setRawPlatformData] = useState([])

  const getPlatforms = async () => {

    const platforms = await GetPlatformList()
    if (platforms) setRawPlatformData( platforms.map( platform => {
      
      return {
        id: platform._id,
        name: platform.PlatformName,
        percentage: platform.PricePercentage,
        status: platform.Status ? 'active' : 'inactive',
        created: platform.DateCreated,
        updated: platform.DateUpdated,
      }

    }))
    refresher(!refresh)

  }

  const refreshPlatforms = async () => { 
    setPlatformData(rawPlatformData.map(x => {   
      // return (<PlatformList id={x.id} props={{ ...x, refresher, refresh, rawPlatformData, setRawPlatformData }} />) 
      return(<PlatformList platformdata={x} prefresher={refresher} prefresh={refresh} prawPlatformData={rawPlatformData} psetRawPlatformData={setRawPlatformData}/> )
    }))
  }

  const filter = ( name = null , status = null ) => {
    
    if (status === 'all') status = null
    if (name) name = name.toLowerCase()
    const filtered = rawPlatformData.filter(x => {

      // If either to be filtered was given
      if (name || status) {

        const filteredNameLower = x.name.toLowerCase()
        const filteredStatusLower = x.status.toLowerCase()
        
        // If both name and status was given
        if (name && status) if (filteredNameLower.includes(name) && filteredStatusLower === status) return x

        // If only status was given
        if (filteredStatusLower === status) return x
          
        // If only name was given
        if (filteredNameLower.includes(name)) return x
  
      } else {

        // No filter or Reset
        return x

      }

    })
    
    // setPlatformData(filtered.map(x => <PlatformList id={x.id} props={{...x, refresher, refresh, rawPlatformData, setRawPlatformData }} />))
    setPlatformData(filtered.map(x => <PlatformList prefresher={refresher} prefresh={refresh} prawPlatformData={rawPlatformData} psetRawPlatformData={setRawPlatformData}/>))
  }

  const sortByStatus = () => {
    
    const sortedArr = rawPlatformData.sort( ( a, b ) => a.status.localeCompare( b.status ) )
    if ( statusActiveFilter ) setRawPlatformData(sortedArr)
    else setRawPlatformData(sortedArr.reverse())
    refresher(!refresh)

  }

  useEffect(() => {
    
    getPlatforms()
    
  }, [])
  
  
  useEffect(() => {
    
    refreshPlatforms()
    
  }, [refresh, rawPlatformData])
  
  const refreshTable = (e) => {
    e.preventDefault()  
    getPlatforms()
  }

  // console.log("rawPlatformData:", rawPlatformData)
  return (
    <main className='content-product'>
      
      {/* Breadcrumbs */}
      <section className="content-main-breadcrumbs">
        e-commerce / platform
      </section>

      {/* Search Filter Section */}
      {/* <SearchFilter props={{filter, refreshPlatforms}} /> */}
      <SearchFilter pfilter={filter} prefreshPlatforms={refreshPlatforms} />
      
      {/* Platform table */}
      <div className={`platform-table-head`}>
          
        <div className="item-size-big item-start">
          Platform Name
        </div>  
        <div className="item-size-big">
          Price Percentage
        </div>  
        <div className="item-size-big">
          Date Created
        </div>  
        <div className="item-size-medium">
          {statusActiveFilter ? 
            <img
            src="https://img.icons8.com/material-rounded/24/FFFFFF/give-way.png" className='filter-status'
            height={15} width={15} 
            onClick={e => {
              e.preventDefault()
              setStatusActiveFilter(!statusActiveFilter)
              sortByStatus()
            }}
            /> :
            <img
            src="https://img.icons8.com/material-rounded/24/FFFFFF/give-way.png" className='filter-status-reversed'
            height={15} width={15} 
            onClick={e => {
              e.preventDefault()
              setStatusActiveFilter(!statusActiveFilter)
              sortByStatus()
            }}
            /> }
          <span>Status</span>
        </div>  
        <div className="item-size-small">
          Action
        </div>  

      </div>

      {/* Platform List */}
      {platformData ? 
        platformData
        : <div className="platform-table-data">
          No Data found
        </div>}
      
        {/* <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
          <div className="table-slider-left-button">
            <ChevronLeftIcon />
          </div>
        </div>
        <div
          className="table-slider-right"
          onMouseEnter={tableScrollRight}
          onMouseLeave={stopScrolling}
        >
          <div className="table-slider-right-button">
            <ChevronRightIcon />
          </div>
        </div> */}
        
      <div className='btn-open-modal'>
        <button onClick={(e) => {
          ShowItemFlex("#addPlatform")
        }}><PlusOneIcon sx={{ fontSize: "16px" }} /></button>
      </div>
      <AddPlatform {...{ refreshTable }} />
    </main>
  )
}

export default Platform;