import i18next from "i18next";
import React from "react";
import { initReactI18next } from 'react-i18next';
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import LoadingSpinner from './routes/LoadingSpinner';
import MainRoute from "./routes/MainRoute";
import "./styles/app.scss";
import translation from "./translation/translation.json";

const App = () => {

  const tokens = useSelector((state) => state.token);
  const { lang } = tokens.token;
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: translation,
      lng: lang, // if you're using a language detector, do not define the lng option
      fallbackLng: "EN",
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    })

  return (
    <>
      <React.Suspense fallback={<LoadingSpinner loaderOpen={true} />}>
        <MainRoute />
      </React.Suspense>
      <Outlet />
    </>
  );
};

export default App;
