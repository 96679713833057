import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/RestartAlt';

const SearchFilter = (props) => {

  // const { filter, refreshPlatforms } = props.props
  const { pfilter, prefreshPlatforms} = props
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('all')

  const reset = (event) => {
    event.preventDefault()
    setStatus('all')
    setSearch('')
    prefreshPlatforms()
  }

  return (
    <section className="content-main-search">
      <div className="flex-column-start width-100 gap-5 content-main-search-filter">
        <div className="flex-row-start gap-10">

          <form onSubmit={e => {
            
          }} className="content-main-search-item">
            
            <div className='flex-row-start gap-20'>
              
              {/* Search Name */}
              <div className='flex-column-start gap-5'>
                <label htmlFor="search">Search name</label>
                <input autoComplete="off" type="text" name="search" value={search} onChange={e => setSearch(e.target.value)}/>
              </div>

              {/* Filter By Status */}
              <div className='flex-column-start gap-5'>
                <label htmlFor="search">Filter by Status</label>
                <select name="status" id="status" value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="all">- All -</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              {/* Search Icon */}
              <button className="flex-row-center-center gap-5" onClick={e => {
                e.preventDefault()
                if (search) pfilter(search, status)
                else pfilter(undefined, status)
              }}>
                <SearchIcon />
              </button>
              
              {/* Reset Filter Icon */}
              <button className="flex-row-center-center gap-5" onClick={e => reset(e)}>
                <ResetIcon />
              </button>

            </div>
            
          </form>

        </div>
      </div>

    </section>
  )
}

export default SearchFilter