import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState, useRef } from "react";
import Compressor from "compressorjs";
import _ from "lodash";
import Swal from "sweetalert2";
import { HideItem, GetBase64 } from "../../ProductManager/productHelper";

const AddContactForm = ({ submitContactForm }) => {
  const closeAddContactForm = () => {
    HideItem("#contactAddForm");
  };

  const [contact, setContact] = useState({
    Link: "",
    Type: "Messenger",
    IsActive: true,
  });

  useEffect(() => {
    return () => {
      
    };
  }, []);

  const changeContactLink = (e) => {
    const { name, value } = e.target;
    setContact({...contact, [name]: value})

  };

  const changeStatus = (e) => {
    const { name, value } = e.target;
    const statusVal = value === "true" ? true : false;
    setContact({ ...contact, [name]: statusVal });
  };

  const changeType = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  return (
    <div className="modal-add-group" id="contactAddForm">
    <div className="content-main-add-contact">
      <div className="content-main-add-user-header">
        <div className="content-main-add-user-header-title">add new contact info</div>
        <div className="content-main-add-user-header-close">
          <CloseIcon
            sx={{
              fontSize: "18px",
              "&:hover": {
                fontWeight: "bold",
                color: "#50311b",
                cursor: "pointer",
              },
            }}
            onClick={closeAddContactForm}
          />
        </div>
      </div>
      <div>
        <form className="modal-add-group-form-main">
          <div className="modal-add-group-form-main-status">
          <label htmlFor="link">Link </label>
            <input
              type="text"
              id="link"
              name="Link"
              onChange={(e) => changeContactLink(e)}
            />
            <label htmlFor="categorystatus">status</label>
            <select name="IsActive" id="categorystatus" onChange={changeStatus}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            <label htmlFor="type">status</label>
            <select name="Type" id="type" onChange={changeType}>
              <option value="Messenger">Messenger</option>
              <option value="Telegram">Telegram</option>
              <option value="Zalo">Zalo</option>
            </select>
          </div>
        </form>
      </div>
      <div className="content-main-add-user-footer">
        <button
          className="content-main-add-user-footer-cancel"
          onClick={closeAddContactForm}
        >
          cancel
        </button>
        <button
          className="content-main-add-user-footer-create"
          onClick={(e) => {
            submitContactForm(contact, e)
            setContact({...contact, Link: ""})
          }}
        >
          create
        </button>
      </div>
    </div>
    </div>
  );
};

export default AddContactForm;
