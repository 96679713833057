import {useTitle} from 'react-use';
import CustomerListTable from './CustomerListTable';
import CustomerListFilter from './Filter/CustomerListFilter';
import React, {useEffect, useState} from "react";
import {Pagination} from "@mui/material";
import {countPage} from "../../ProductManager/productHelper";
import {exportListCustomer, getListCustomer, newTabURL, changeEmployee} from "../customerHelper";
import Swal from "sweetalert2";
const FileSaver = require('file-saver');

const CustomerList = () => {
    useTitle("Pao Kitchen | Customer list")
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10,
        Email: "",
        ContactNum: "",
        IsActive: "",
        sortDesc: "1",
        sortBy: "spend"
    })

    const [customers, setCustomers] = useState({total: 0, data: [], limit: 10})

    const onChangePage = (e, page) => {
        e.preventDefault()
        setFilters({
            ...filters,
            page: page
        })
    }

    const onSearch = (newFilters = filters) => {
        Swal.showLoading();

        getListCustomer(newFilters)
            .then(response => {
                const customers = response.data
                const metadata = customers?.metadata ?? {}
                const paginatedResults = customers?.paginatedResults ?? []

                setCustomers({
                    limit: metadata?.limit ?? 10,
                    total: metadata?.total ?? 0,
                    data: paginatedResults
                })

                Swal.close()
            })
            .catch(e => {
                console.log(e.message)
                setCustomers({
                    limit: 10,
                    total: 0,
                    data: []
                })
                Swal.close()

            })
    }

    // useEffect(() => {
    //     onSearch()
    //     // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        onSearch(filters)
        // eslint-disable-next-line
    }, [filters.page])

    const onFilter = (newFilters = {}) => {
        const _filters = {
            ...filters,
            ...newFilters
        }
        setFilters(_filters)
        onSearch(_filters)
    }

    const onExport = (filters) => {
        Swal.showLoading();
        exportListCustomer(filters)
            .then(response => {
                let blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                // console.log(blob)
                FileSaver.saveAs(blob, `Pao Kitchen customers list`);
                // var csvURL = window.URL.createObjectURL(blob);
                // let tempLink = document.createElement('a');
                // tempLink.href = csvURL;
                // tempLink.setAttribute('download', 'filename.xlsx');
                // tempLink.click();

                Swal.close()
            })
            .catch(e => {
                console.log(e.message)
                Swal.close()
            })
    }

    const changeStatus = (i, id) => (event) => {  
        setCustomers((customer) => { 
            customer.data[i].IsEmployee = !customer.data[i].IsEmployee;
            const { IsEmployee } = customer.data[i] 
            console.log("IsEmployee: ", IsEmployee)
            changeEmployee(id, IsEmployee).then(res => {
            })
            return {
                ...customer,
                [i]: customer[i],
            };
        });
      };

    return (
        <main className="content-product">
            <section className="content-product-breadcrumbs">
                customer management / customer list
            </section>
            <CustomerListFilter onSearch={onFilter} onExport={onExport}/>
            <CustomerListTable customers={customers.data} newTabURL={newTabURL} changeStatus={changeStatus}/>
            {
                customers.total > 0 ? <Pagination count={countPage(customers.total, customers.limit)} onChange={onChangePage} page={filters.page} shape="rounded" /> : null
            }
        </main>
    )
}

export default CustomerList