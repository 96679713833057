import Proptypes from "prop-types";
import css from "../../OrderDineIn.module.scss"
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
const SearchProduct = (props) => {
    const clearSearch = (e) => {
        e.preventDefault()
        props.onChange("")
    }
    const {t} = useTranslation()

    return (
        <div className={css.searchProductContainer}>
            <input
                onChange={(e) => props.onChange(e.target.value)}
                value={props.searchValue}
                className={css.searchInput}
                placeholder={t("searchProdPlaceholder")}
            />
            <button type="button" className={css.buttonClearSearch} onClick={clearSearch} disabled={!props.searchValue} title="Clear">
                <ClearIcon className={css.iconClearSearch} />
            </button>
            <button type="button" className={css.buttonSearch} title="Search">
                <SearchIcon className={css.SearchIcon} />
            </button>
        </div>
    )
}

SearchProduct.propTypes = {
    onChange: Proptypes.func,
    searchValue: Proptypes.string
}

export default SearchProduct
