import React, { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import fb from "./../../assets/social-link/fb.svg";

const SocialLogin = ({
  GOOGLE_CLIENT_ID,
  responseGoogle,
  FACEBOOK_APP_ID,
  responseFacebook,
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    let fbDiag = document.querySelectorAll(".kep-login-facebook")
    for (let i = 0; i < fbDiag.length; i++) {
      fbDiag[i].classList.add("small")
      fbDiag[i].classList.remove("metro")
      fbDiag[i].innerHTML = `<img src="${fb}" width='21' height='21' alt='icon' /> <span class="text-login">${t('login')}</span>`
    }

    return () => {

    }
  }, [t])

  return (
    <div className="login-dialog-container-register-item1">
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText={t('login')}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        isSignedIn={false}
      />
      <FacebookLogin appId={FACEBOOK_APP_ID} callback={responseFacebook} version="v13.0" />

    </div>
  );
};

export default SocialLogin;
