import { useEffect, useState } from 'react';
import _ from "lodash"
import "../listTable.scss"; 
import SearchIcon from '@mui/icons-material/Search'; 
import CurrencyFormat from 'react-currency-format';
import { t } from 'i18next';
import CardList from './CustomerStatistic/CardList'
import Charts from './CustomerStatistic/Charts'

import {GetSalesReport} from "../../customerHelper";

const MealAllowance = ({ customerid, Allowance, orderHistoryList, moment, newTabURL, getOtherOrderList, Swal }) => {
    const defaultSales = {
        Card: { 
          totalOrders: 0,
          totalUserSpent: 0,
          TotalCompanyAllowance: 0, 
          totalDineIn: 0,
          totalOnline: 0,
          totalKTV: 0,
        },
        SalesPerPaymentMethod: [],
        paginatedResults: []
    }

    const [salesReport, setSalesReport] = useState(defaultSales)
    const [chartControl, setChartControl] = useState([])
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({
        dateStart: "",
        dateEnd: ""
      })
    const { dateStart, dateEnd } = filter
    const searchSubmit = (e) => {
        e.preventDefault()
        getSalesReport(page, {
          DateFrom: moment(dateStart || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
          DateTo: moment(dateEnd || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
        }) 
    }
    const handleChange = (e) => {
        const { value, name } = e.target
        setFilter({ ...filter, [name]: value })
    }

    const ITEM_PER_PAGE = 10

    useEffect(() => {
      getSalesReport(page, {})
      return () => { 
      } 
    }, [page])

    const getSalesReport = (page, searchValue) => {
        Swal.fire({
          title: "In progress!",
          text: "Please wait while fetching data",
          allowOutsideClick: false,
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setSalesReport(defaultSales)
        // setPageCount(0)
        console.log("searchValue: ", searchValue)
        GetSalesReport(page, customerid, {...searchValue, limit: ITEM_PER_PAGE})
          .then(
            res => {
              Swal.close()
              const { paginatedResults, metadata } = res?.data
              const { total, limit } = metadata;
            //   setPageCount(countPage(total, limit))
              setChartControl(paginatedResults)
              console.log("resuls: ", res?.data)
              setSalesReport(res?.data);
              console.log("SALES REPORT", res?.data);
            }
          )
          .catch(
            err => {
              Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "No sales report(s) found!",
                footer: "&#169; Pao Kitchen 2023",
                didOpen: () => {
                  Swal.hideLoading();
                }
              });
              setSalesReport(defaultSales)
            }
          )
      }
    
    const { Card } = salesReport
    return (
        <div className="customer-container-right-content">
            <div className="customer-container-right-content-title">
                <img src={Allowance} width="16" height="16" alt="icon" />
                <span>Meal Allowance</span>
                <div>
                    <form onSubmit={(e) => { searchSubmit(e); }}> 
                        <span> From: </span>
                        <input onChange={(e) => handleChange(e)} type="date" name="dateStart" value={dateStart} placeholder="Date" />
                        <span> To: </span>
                        <input onChange={(e) => handleChange(e)} type="date" name="dateEnd" value={dateEnd} placeholder="Date" />
                        <button type="submit">
                            <SearchIcon />
                        </button>
                    </form>
                </div> 
            </div> 
            <div className="flex-column-start">
                <CardList  {...Card}/>
                <Charts chartData={chartControl} />  
            </div>
        </div>
    )
};

export default MealAllowance;