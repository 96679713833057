import {SET_CONFIG_CMS_PUSHER} from "../types/CmsConfigPusher";

const initialState = {
  configs: [],
};
const CmsConfigPusher = (state = initialState, action = {}) => {
  // console.log("action_payload: ", action.payload)
  // console.log("action_typexxx: ", action.type)
  switch (action.type) {
    case SET_CONFIG_CMS_PUSHER:
      return {
        ...state,
        configs: action.payload,
      };
    default:
      return state;
  }
};

export default CmsConfigPusher;
