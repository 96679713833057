import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useRef } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';

const ProductStockTable = ({ product, showUpdateStock }) => {
  const container = useRef(null);
  useEffect(() => {
    container.current.getElement().id = "productStock";
    return () => {
    }
  }, [])

  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item big-item item-name">Product</div>
        <div className="content-product-table-head-item normal-start item-name">SKU & Barcode</div>
        <div className="content-product-table-head-item normal">Stock</div>
        <div className="content-product-table-head-item normal">Date Created</div>
        <div className="content-product-table-head-item normal">Actions</div>
      </div>
      {!_.isEmpty(product) ? (
        Object.keys(product).map((key, index) => {
          const {
            Product,
            Stocks,
            CreatedAt
          } = product[key];
          const { Barcode, Images, Name, SKU, _id } = Product;
          let now = new Date(CreatedAt);
          let dateCreated = moment(now).format("YYYY-MM-DD HH:MM:SS");
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item big-item item-name">
                <div className="product-name">
                  <img
                    srcSet={Images.DineIn}
                    alt={Name.EN}
                    width="75px"
                    className="product-name-image"
                  />
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Name.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Name.VN}</small>
                    </div>
                    <div>
                      <small>{Name.CN}</small>
                    </div>
                    <div>
                      <small>{Name.TH}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                <div className="product-sku">
                  <div className="product-sku-item">
                    <small>SKU:</small>
                    <span>{SKU}</span>
                  </div>
                  <div className="product-sku-item">
                    <small>BARCODE:</small>
                    <span>{Barcode}</span>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal">
                {Stocks}
              </div>


              <div className="content-product-table-data-item normal">{dateCreated}</div>
              <div className="content-product-table-data-item normal">
                <Tooltip title="update stock of this product" placement="bottom-start" arrow>
                  <AutoAwesomeMotionIcon sx={{
                    color: "#50311b", cursor: "pointer", "&:hover": {
                      color: "#503110", fontSize: "22px"
                    }
                  }} onClick={(e) => { showUpdateStock(_id, e) }} />
                </Tooltip>
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item big-item item-name"></div>
          <div className="content-product-table-data-item normal-start item-name"></div>
          <div className="content-product-table-data-item normal">No Data Found!</div>
          <div className="content-product-table-data-item normal"></div>
          <div className="content-product-table-data-item normal"></div>
        </div>
      )}

    </ScrollContainer>
  )
};

export default ProductStockTable;
