import React, { useCallback, useState } from "react"; 
import _ from 'lodash';  
import SearchIcon from '@mui/icons-material/Search';

const Filter = ({ rateFilter }) => {
    let ItemFields = {
        Status: ""
    }

    const statlist = {
        true: "Active",
        false: "Inactive"        
    }

    const [filters, setFilters] = useState(ItemFields)

    const handleChange = useCallback((e) => {
        const { name, value } = e.target
        setFilters(oldValue => {
          return {
            ...oldValue,
            [name]: value
          }
        })
      }, [])
    return (
        <section className="content-main-search">
            <div className="flex-column-start width-100 gap-5 content-main-search-filter">
                <div className="flex-row-start gap-10">
                    <div className="content-main-search-item">
                        <div className='flex-row-start gap-20'>

                            {/* Filter By Status */}
                            <div className='flex-column-start gap-5'>
                                <label htmlFor="search">Filter by Status</label>  
                                <select name="Status" id="Status" value={filters.Status} onChange={(e) => { handleChange(e) }}>
                                    <option value="">All</option>
                                    {
                                    _.map(statlist, (value, key) => {
                                        return <option key={key} value={key}>{value}</option>
                                    })
                                    }
                                </select>
                            </div> 

                            {/* Filter Button */}
                            <button className="flex-row-center-center gap-5" onClick={e => { 
                                rateFilter(e, filters)
                            }}>
                                <SearchIcon />
                            </button>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default Filter