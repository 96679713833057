import {useEffect,  useState} from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import css from "./ShippingOption.module.scss" 
import { GetLalamoveOrderDetails } from '../../EcommerceHelper'
import { shippingDet } from '../OrderUtils';
// import {Link} from "react-router-dom";

const ShiippingOption = ({getData, order, currentPlaceOrderId, currentStatus, openModalShippingOption, openModalShippingPlaceOrder}) => {
  // console.log("Order Details", order)
  // console.log("Courier", order?.Courier.Code)
  const CourierName = getData(order?.Courier.Code) ?? "";
  const Courier = getData(order?.Courier) ?? "";
  const PlaceOrderID = currentPlaceOrderId.PlaceOrderID ?? ""
  // console.log("PlaceOrderID: ",PlaceOrderID)
  const [shippingInfo, setShippingInfo] = useState(shippingDet)
  useEffect(() => {
    if(currentPlaceOrderId.PlaceOrderID){
      getLalamoveOrder()
    }
  }, [currentPlaceOrderId])

  const getLalamoveOrder = () => {
    GetLalamoveOrderDetails(PlaceOrderID).then(res => {   
      setShippingInfo(res?.data)
    }).catch(err => {
      setShippingInfo(shippingDet)
      console.log(err.response);
    })
  }
  // console.log("currentStatus: ", currentStatus)
  // console.log("CourierName: ", CourierName) 
  const WalletBalance = getData(shippingInfo.walletBalance) ?? ""
  const ShareLink = getData(shippingInfo.shareLink) ?? ""

  return (
    <div className="width-100 order-container-right-container">
      <div className="order-container-right-container-content">
        <span>Shipping Option</span> 
        
      </div>  
      <div className="order-container-right-container-content">
        <LocalShippingIcon /> {CourierName} 
        {
          currentStatus !== 6 ?
          (<>
            <button className='time-edit' onClick={() => openModalShippingOption()}><span className={css.buttonedit}>Change Courier</span></button> 
          </>) : ""
        }
        { 
          currentStatus === 3 ?
            CourierName == "LALAMOVE" ?
              !PlaceOrderID ?
                (<>
                |  
                <button className='time-edit' onClick={() => openModalShippingPlaceOrder()}><span className={css.buttonedit}>Request Order Shipping</span></button>
                </>) : 
                  (shippingInfo.status == "EXPIRED" || shippingInfo.status == "CANCELED" || shippingInfo.status == "REJECTED") ? 
                    (<>
                      |  
                      <button className='time-edit' onClick={() => openModalShippingPlaceOrder()}><span className={css.buttonedit}>Request Order Shipping</span></button>
                    </>)
                  : ""
            : ""
          : ""
        }
        
      </div>
      {
        (PlaceOrderID) ? 
        <div>
          <div className={css.row}>
            <div className={css.column} >
              Status: <b>{shippingInfo.status}</b>
            </div> 
            <div className={css.column} >
              Fee: <b>₱{shippingInfo.priceBreakdown.total}</b>
            </div> 
            <div className={css.column} > 
              Rider: {shippingInfo.driverId.driverId ? shippingInfo.driverId.name + "|" + shippingInfo.driverId.plateNumber : "--" }
            </div>     
            <div className={css.column} >
              Lalamove Order ID: <b>{PlaceOrderID}</b>
            </div>    
          </div>
          <br></br>
          <div className={css.row}>
            <div className={css.column} >
              Wallet Balance: <b>₱{WalletBalance}</b>
            </div>
            <div className={css.column} >
              <span>Share Link:</span>   
              <a href={`${ShareLink}`} target="_blank" rel="noreferrer"><ShareLocationIcon/></a>
            </div>
          </div>
        </div>
        : "" 
      } 
    </div>
  );
}

export default ShiippingOption