import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { useEffect, useState } from 'react';
const AddFee = ({ closeModalEdit, submitFee, order }) => {
  const [addedFee, setAddedFee] = useState([])
  useEffect(() => {
    setAddedFee(order.AddFee)

    return () => {

    }
  }, [order])

  const addField = () => {
    setAddedFee([...addedFee, {
      Name: "",
      Type: "fixed",
      FeeValue: 0
    }])
  }
  const handleChange = (e, i) => {
    const { value, name } = e.target
    setAddedFee(oldValue => _.map(oldValue, (result, key) => {
      return {
        ...result,
        [name]: key === i ? value : result[name]
      }
    }))
  }
  const removeItem = (i) => {
    setAddedFee(oldValue => _.filter(oldValue, (result, key) => {
      return key !== i
    }))
  }
  return (
    <div className='order-modal-container'>
      <h3>Additional Fee(s)</h3>
      <div className="container-row-wrap-600 gap-10" >
        <div className='flex-row-end width-100 margin-bottom-10'><button className='btn-submit' onClick={(e) => {
          addField()
        }}>Add Fee</button></div>
        <span className='text-grey'>* Click on "Add Fee" button to add field to proceed with additional fee.</span>
        <div className='flex-column-start width-100 gap-10'>
          {
            _.map(addedFee, (value, key) => {
              const { Name, Type, FeeValue } = value
              return (
                <div key={key} className='flex-row-start-center width-100 gap-5'>
                  <div className='input'>
                    <label htmlFor={"feename" + key}>Fee Name</label>
                    <input className='width-80' type="text" onChange={(e) => {
                      handleChange(e, key)
                    }} name="Name" value={Name} id={"feename" + key} />
                  </div>

                  <div className='input'>
                    <label htmlFor={"type" + key} >Type</label>
                    <select
                      className='width-80'
                      onChange={(e) => { handleChange(e, key) }}
                      name="Type"
                      value={Type}
                      id={"type" + key} >

                      <option value="fixed">fixed</option>
                      <option value="percent">Percent</option>

                    </select>
                  </div>

                  <div className='input'>
                    <label htmlFor={"feevalue" + key}>Fee Value</label>
                    <input
                      onChange={(e) => {
                        handleChange(e, key)
                      }} className='width-80'
                      name="FeeValue"
                      type="number"
                      min={0}
                      value={FeeValue}
                      id={"feevalue" + key} />
                  </div>

                  <button className='btn-delete'>
                    <DeleteIcon
                      onClick={() => {
                        removeItem(key)
                      }}
                      sx={{
                        "cursor": "pointer",
                        "&:hover": {
                          color: "#f08d19"
                        }
                      }} />
                  </button>
                </div>
              )
            })
          }
        </div>

      </div>
      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitFee(e, addedFee)
        }}>Save</button>
      </div>

    </div >
  )
}

export default AddFee