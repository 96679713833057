import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../components/reusable/Logo';
const PrintWOPrice = ({ closeModalEdit, order, paymentMethod }) => {
  const componentRef = useRef(null);
  const handleAfterPrint = useCallback(() => {
    closeModalEdit()
  }, [closeModalEdit]);

  const handleOnBeforeGetContent = useCallback(() => {


  }, []);
  const handleBeforePrint = useCallback(() => {

  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${order.OrderRef}`,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onBeforeGetContent: handleOnBeforeGetContent,
  });
  useEffect(() => {
    handlePrint()
    return () => {
      handlePrint()
    }
    //eslint-disable-next-line
  }, [handlePrint])
  const { OrderDetails, CreatedAt, ContactDetails, OrderRef, DeliveryDetails, PreferredDeliveryDate, OrderNote } = order

  return (
    <div className='forPrint' ref={componentRef}>
      <div className='flex-row-center-center'>
        <div className='logo'>
          <Logo swidth={100} sheight={100} />
        </div>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-30'>
        <span><b>Date:</b> {moment(CreatedAt).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
        <span><b>Order:</b> {OrderRef}</span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-30'>
        <span className='name'><b>Customer Name:</b> {ContactDetails?.FullName}</span>
        <span><b>Phone:</b> {ContactDetails?.MobileNum}</span>
        <span><b>Address:</b> {DeliveryDetails?.Address}</span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Shipping Time:</b> {moment(PreferredDeliveryDate).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
        <span><b>Payment Via:</b> {paymentMethod ?? ""}</span>
        <span><b>Note:</b> {OrderNote}</span>
      </div>
      <div className='table margin-bottom-15'>
        <div className="flex-row-start table-header">
          <div className='name'>Name</div>
          <div className='quantity'>Quantity</div>
        </div>
        <div className='flex-column-start width-100'>
          {
            _.isEmpty(OrderDetails) ? (
              <div className="flex-row-center-center table-body">
                <span>No Products Found</span>
              </div>
            ) :
              (<>
                {
                  _.map(OrderDetails, (value, key) => {
                    const { Quantity, ProductName } = value
                    return (
                      <div key={key} className="flex-row-start table-body">
                        <div className="flex-column-start width-100">
                          <div className='name'>{ProductName.VN || ProductName.EN || ProductName.CN || ProductName.TH}</div>
                          <small className='name-small'>{ProductName.EN || ProductName.CN || ProductName.TH || ProductName.VN}</small>
                        </div>
                        <div className='quantity'>{Quantity || 0}</div>

                      </div>
                    )
                  })
                }
              </>)
          }
        </div>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Received By:</b></span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Issued By:</b></span>
      </div>
      <span>Thank you for shopping with us!</span>
    </div >
  )
}

export default PrintWOPrice