import Proptypes from "prop-types";
import {getCurrentLang, getPrice} from "../../../helper";
import React, {useRef, useState} from "react";
import placeholderImage from "../../../../../../assets/placeholderImage.jpg";
import css from "./Product.module.scss"
import {useTranslation} from "react-i18next";
import "./swiper.scss"
import QuantityInput from "../QuantityInput";

const Product = (props) => {
    const {product, sendOption} = props
    const currentLang = getCurrentLang()

    const {Product, Price} = product ?? {}
    const {Name, Images} = Product
    const displayPrice = getPrice(Price, sendOption)
    const displayName = Name[currentLang] || Name["EN"]
    const {DineIn: ImageDineIn, Delivery: ImageDelivery} = Images
    const displayImage = ImageDineIn ? ImageDineIn : ImageDelivery
    const image = useRef(null)

    const [activeQuantity, setActiveQuantity] = useState(false)
    const [quantity, setQuantity] = useState(1)

    const getDisplayName = () => {
        if(!displayName){
            for (const outputElement in Name) {
                if(Name[outputElement]){
                    return Name[outputElement]
                }
            }
        }
        return displayName
        // return displayName.length > 50 ? `${displayName.length > 50 ? displayName.substr(0, 50) : displayName}...` : displayName
    }

    const imgError = (e) => {
        e.preventDefault()
        image.current.src = placeholderImage
        return true;
    }
    const {t} = useTranslation()

    const onConfirm = () => {
        setActiveQuantity(false)
        props.onSelect({
            quantity: quantity,
            product: product
        })
    }

    const onCancel = () => {
        setActiveQuantity(false)
    }

    const onSelect = (e) => {
        e.preventDefault()

        setQuantity(1)
        setActiveQuantity(true)
    }

    const activeQuantityClassName = activeQuantity ?  `${css.quantityWrap} ${css.active}` : css.quantityWrap

    return (
        <div className={css.productContainer}>
            <img
                ref={image}
                src={displayImage || placeholderImage}
                onError={(e) => imgError(e)}
                className={css.image}
                alt={getDisplayName()}
            />
           <div className={css.groupAction}>
               <div className={css.infoWraper}>
                   <p className={css.productName}>{getDisplayName()}</p>
                   <p className={css.productPrice}>₱{displayPrice}</p>
               </div>
               <button className={css.addToCart} disabled={!props.hasOrderSelected} onClick={onSelect}>{t("select")}</button>

               <div className={activeQuantityClassName}>
                   <div className={css.quantity}>
                       <QuantityInput quantity={quantity} setQuantity={setQuantity} />
                   </div>
                   <div className={css.groupBtn}>
                       <button onClick={onCancel} className={css.cancel}>{t("cancel")}</button>
                       <button onClick={onConfirm} className={css.confirm}>{t("confirm")}</button>
                   </div>
               </div>
           </div>
        </div>
    )
}

Product.propTypes = {
    product: Proptypes.object,
    sendOption: Proptypes.oneOf(["DINEIN", "KTV", "REGULAR"]),
    onSelect: Proptypes.func.isRequired,
    hasOrderSelected: Proptypes.bool
}

export default Product