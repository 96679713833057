import _ from 'lodash'; 
import {useState} from 'react'; 
import { GetVoucher } from '../../EcommerceHelper'
import Swal from 'sweetalert2';
import SearchIcon from '@mui/icons-material/Search';
import UpdateIcon from '@mui/icons-material/Update';
import CurrencyFormat from 'react-currency-format'; 

const ApplyVoucher = ({  closeModalEdit, order, submitUpdateVoucher }) => {
  
  const [voucherE, setVoucherE] = useState("")
  const [voucher, setVoucher] = useState("")
  const [isVoucherApplied, setIsVoucherApplied] = useState({
    isApplied: false,
    payment: 0
  })
  const [couponDetails, setCouponDetails] = useState({})
  
  const voucherInputChange = (e) => {
    const { value } = e.target
    setVoucher(value)
  }
 
  const submitGetVoucherForm = (e) => {
    e.preventDefault()
    let orderDetail = _.map(order.OrderDetails, value => {
      return { ProductId: value.ProductId, Price: value.TotalPrice }
    });
    let Prices = 0;  
    _.forEach(order.OrderDetails, (value, key) => {
      Prices = Prices + value.TotalPrice
    })
    console.log("order: ", order) 
    console.log("BranchId: ", localStorage.getItem("branchId")) 
    console.log("CouponCode: ", voucher) 
    console.log("CustomerId: ", order.CustomerId ?? "") 
    console.log("orderDetail: ", orderDetail) 
    console.log("TotalPrice: ", Prices) 

    GetVoucher({
      "BranchId": localStorage.getItem("branchId"),
      "CouponCode": voucher,
      "CustomerId": order.CustomerId ?? "",
      "OrderDetails": orderDetail,
      "TotalPrice": Prices
    }).then(res => {   
      const { IfReachedMax, Payment } = res?.data
      if (IfReachedMax) {
        console.log("IfReachedMax A: ", IfReachedMax)
        console.log("Payment A: ", Payment)
        setVoucherE("Voucher not applied, Reached max limit")
        // setOrder({
        //   ...order,
        //   CouponCode: ""
        // })
        setIsVoucherApplied({
          isApplied: false,
          payment: 0
        })
        setCouponDetails({})
      }else{
        console.log("IfReachedMax B: ", IfReachedMax)
        console.log("Payment B: ", Payment)
        // setOrder({
        //   ...order,
        //   CouponCode: voucher
        // })
        setIsVoucherApplied({
          isApplied: true,
          payment: Payment
        })
        setVoucherE("")
        setCouponDetails(res?.data)
      }
    })
    .catch(err => {
      let errorResult = !_.isEmpty(err.response) ? err.response.data.mess : ""
      console.log(errorResult);
      console.log("If coupon error here >>> ")
      setVoucherE(errorResult)
      setIsVoucherApplied({
        isApplied: false,
        payment: 0
      })
    })
  }

  const updateOrderVoucher = (e) => { 
    console.log("Orderssss: ", order)
    submitUpdateVoucher(e, couponDetails)
  }
 
  return (
    <div className="order-modal-container"><h3>VOUCHER</h3>
      <div className='flex-row-start gap-10'>
        <form className='input' method='post'>
          <label htmlFor="applyVoucherEdit">Voucher Code</label>
          <input
            type="text"
            id="applyVoucherEdit"
            placeholder='Enter Voucher Code'
            value={voucher}
            onChange={(e) => {
              voucherInputChange(e)
            }} />
        </form> 
        <button className='btn-cancel' onClick={(e) => {submitGetVoucherForm(e)}}>
          <SearchIcon sx={{ fontSize: "16px" }} />
        </button>       
      </div>
      <div className='widthpx-500 flex-row-start-center'>
        <small className='border-bot error-message'>{voucherE}</small>
      </div>
      <div className="order-container-left-products-total margin-top-20">
        {(isVoucherApplied.isApplied === true) ? (<>
          <div className="order-container-left-products-total-number">
            <span>NEW DISCOUNT:</span>
            <span className="price">
              <CurrencyFormat
                value={isVoucherApplied.payment}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₱ '}
              />
            </span>
          </div>
          <div className="order-container-left-products-total-number">
            <span>DISCOUNTED AMOUNT:</span>
            <span className="price">
              <CurrencyFormat
                value={(order.OrderAmount - isVoucherApplied.payment)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₱ '}
              />
            </span>
          </div>
          <div className="order-container-left-products-total-number">
            <span>NEW TOTAL AMOUNT:</span>
            <span className="price">
              <CurrencyFormat
                value={((order.OrderAmount + order.ShippingAmount) - isVoucherApplied.payment)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₱ '}
              />
            </span>
          </div>
          <button className='btn-submit' onClick={(e) => {updateOrderVoucher(e)}}>
            <div className='flex-row-start gap-5'>
              <UpdateIcon sx={{ fontSize: "16px" }} /> <span>Update Discount</span>
            </div> 
          </button>
        </>) : (<></>)
        }
      </div>
    </div> 
  )
}

export default ApplyVoucher