import _ from 'lodash';
import moment from 'moment-timezone';
import { useState } from 'react';
const PreparationTime = ({ PreferredDeliveryDate, closeModalEdit, submitNewPreparationTime, orderE }) => {
  const [preparationDateTime, setPreparationDateTime] = useState(moment(PreferredDeliveryDate).tz("Asia/Manila").format('yyyy-MM-DDTHH:mm'))
  const changeData = (e) => {
    const { value } = e.target
    setPreparationDateTime(moment(value).tz("Asia/Manila").format('yyyy-MM-DDTHH:mm'))

  }
  return (
    <div className='order-modal-container'>
      <h3>Preparation time</h3>
      <div className='input'>
        <label htmlFor="preparatimeTimeEdit">Preparation Time</label>
        <input onChange={(e) => { changeData(e) }} type="datetime-local" value={preparationDateTime} min={moment(new Date()).tz("Asia/Manila").format('yyyy-MM-DDTHH:mm')} id="preparatimeTimeEdit" />
      </div>
      {
        _.isEmpty(orderE?.PreferredDeliveryDate) ? (<></>) : (<small className='error-message'>{orderE?.PreferredDeliveryDate}</small>)
      }
      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitNewPreparationTime(e, preparationDateTime)
        }}>Save</button>
      </div>

    </div>
  )
}

export default PreparationTime