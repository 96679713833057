import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { countPage, GetGroupCategoryList } from "../../productHelper";

const SearchFilterCategory = ({ productFilter, clearFilter }) => {
  const [searchItem, setSearchItem] = useState({
    search: "",
    GroupCategory: ""
  })
  const clearSearch = (e) => {
    e.preventDefault()
    setSearchItem({
      search: "",
      GroupCategory: ""
    })
    clearFilter(e)
  }
  const [dropdowns, setDropDowns] = useState({
    groupCategory: [],
  })
  useEffect(() => {
    setDropDowns({
      groupCategory: [],
    })
    getGroupCategory(1)
    return () => {
      setDropDowns({
        groupCategory: [],
      })
    }
    // eslint-disable-next-line
  }, [])
  const getGroupCategory = (pages) => {
    GetGroupCategoryList(pages, "")
      .then(
        res => {
          const { paginatedResults, metadata } = res.data
          const { page, total, limit } = metadata
          let nextPage = countPage(total, limit)
          setDropDowns(dropdown => {
            return {
              ...dropdown,
              groupCategory: [...dropdown.groupCategory, ...paginatedResults]
            }
          })
          if (nextPage !== page && nextPage !== 0) {
            let newPage = page + 1
            getGroupCategory(newPage)
          }
        }
      )
  }
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])
  const { groupCategory } = dropdowns
  return (
    <section className="content-main-search">
      <form onSubmit={(e) => {
        productFilter(e, searchItem)
      }} className="content-main-search-item">
        <label htmlFor="search">CATEGORY</label>
        <input
          autoComplete="off"
          type="text"
          name="search"
          value={searchItem.search}
          onChange={(e) => {
            handleChange(e)
          }}
        />
      </form>
      <div className="content-main-search-item">
        <label htmlFor="GroupCategory">Group Category</label>
        <select name="GroupCategory" id="GroupCategory" value={searchItem.GroupCategory} onChange={(e) => { handleChange(e) }}>
          <option value="">All</option>
          {
            _.map(groupCategory, (value, key) => {
              const { _id, Name } = value
              let name = Name?.EN || Name?.VN || Name?.CN || Name?.TH
              return (
                <option key={key} value={_id} > {name}</option>
              )
            })
          }
        </select>
      </div>
      <Tooltip title="Submit Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => {
            productFilter(e, searchItem)
          }}
        >
          <SearchIcon />
        </button>
      </Tooltip>
      <Tooltip title="Clear Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => { clearSearch(e) }}
        >
          <AutorenewIcon />
        </button>
      </Tooltip>
    </section>
  )
};

export default SearchFilterCategory;
