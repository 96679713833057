import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {USER_ROLES} from "../../helpers/Authentication";

const UpdateUser = ({
  node,
  closeUpdateUserForm,
  handleOnChangeUpdate,
  userUpdate,
  token,
  getUser,
  dataState,
}) => {
  const closeStyle = {
    fontSize: "18px",
    "&:hover": {
      fontWeight: "bold",
      color: "#50311b",
      cursor: "pointer",
    },
  };
  const axios = require("axios");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { usernameUpdate, passwordUpdate, _id, roleUpdate, isActiveUpdate } =
    userUpdate;
  const { page, search, isActive } = dataState;
  const submitUpdateUser = (id) => {
    let data = JSON.stringify({
      Role: roleUpdate,
      IsActive: isActiveUpdate,
      Password: passwordUpdate,
    });

    let config = {
      method: "put",
      url: `${baseUrl}/cms/users/update/${id}`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data);
        getUser(page, search, isActive);
        closeUpdateUserForm();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderOptionRoles = () => {
    const roles = []
    for (const value in USER_ROLES) {
      roles.push(<option key={`user_role_${value}`} value={value}>{USER_ROLES[value]}</option>)
    }

    return roles
  }

  return (
    <div ref={node} className="content-main-update-user">
      <div className="content-main-update-user-header">
        <div className="content-main-update-user-header-title">Update User</div>
        <div className="content-main-update-user-header-close">
          <CloseIcon sx={closeStyle} onClick={closeUpdateUserForm} />
        </div>
      </div>
      <div>
        <form className="content-main-update-user-form">
          <div className="content-main-update-user-form-item">
            <label htmlFor="usernameUpdate">Username</label>
            <input
              type="text"
              name="usernameUpdate"
              id="usernameUpdate"
              value={usernameUpdate}
              onChange={handleOnChangeUpdate}
              disabled
            />
          </div>
          <div className="content-main-update-user-form-item">
            <label htmlFor="passwordUpdate">Password</label>
            <input
              type="password"
              name="passwordUpdate"
              id="passwordUpdate"
              value={passwordUpdate}
              onChange={handleOnChangeUpdate}
            />
          </div>
          <div className="content-main-update-user-form-item">
            <label htmlFor="roleUpdate">Role</label>
            <select
              name="roleUpdate"
              id="roleUpdate"
              value={roleUpdate}
              onChange={handleOnChangeUpdate}
            >
              {renderOptionRoles()}
            </select>
          </div>
          <div className="content-main-update-user-form-item">
            <label htmlFor="isActiveUpdate">Status</label>
            <select
              name="isActiveUpdate"
              id="isActiveUpdate"
              value={isActiveUpdate}
              onChange={handleOnChangeUpdate}
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
        </form>
      </div>
      <div className="content-main-update-user-footer">
        <button
          className="content-main-update-user-footer-cancel"
          onClick={closeUpdateUserForm}
        >
          cancel
        </button>
        <button
          className="content-main-update-user-footer-update"
          onClick={() => {
            submitUpdateUser(_id);
          }}
        >
          update
        </button>
      </div>
    </div>
  );
};

export default UpdateUser;
