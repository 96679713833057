import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { GetShippingAreaOnlyCMS, IshippeeGet } from '../../../../components/public/PublicHelper';

const CustomerDetail = ({ closeModalEdit, order, submitCustomerDetail }) => {
  const { ContactDetails, DeliveryDetails } = order
  const [customerDetails, setCustomerDetails] = useState({
    Address: "",
    Area: "",
    Email: "",
    FullName: "",
    MobileNum: "",
    Place: null,
    PlaceId: null,
  })
  const [areaList, setArealist] = useState([])
  useEffect(() => {
    setCustomerDetails({
      Address: DeliveryDetails.Address ?? "",
      Area: DeliveryDetails?.Area ?? "",
      Email: ContactDetails.Email ?? "",
      FullName: ContactDetails.FullName ?? "",
      MobileNum: ContactDetails.MobileNum ?? "",
      Place: DeliveryDetails.Place ?? "",
      PlaceId: DeliveryDetails.PlaceId ?? "",
    })
    GetShippingAreaOnlyCMS().then(res => {
      setArealist(res.data)
    }).catch(err => {
    })
    return () => {

    }
    //eslint-disable-next-line
  }, [ContactDetails, DeliveryDetails])
  const [ishipeeSearch, setIshipeeSearch] = useState([])
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "Address") {
      if (_.isEmpty(value)) {
        setIshipeeSearch([])
      } else {
        IshippeeGet(customerDetails?.Area + value)
          .then(
            res => {
              setIshipeeSearch([...res.predictions])
            }
          )
      }
    }
    setCustomerDetails({
      ...customerDetails,
      [name]: value
    })

  }
  const addressFillup = (e, ishipee) => {
    e.preventDefault()
    setCustomerDetails({ ...customerDetails, Address: ishipee.description, PlaceId: ishipee.place_id })
    setIshipeeSearch([])
  }
  return (
    <div className='order-modal-container'>
      <h3>Customer Contact Details</h3>
      <div className='input'>
        <label htmlFor="fullNameEdit">Full Name</label>
        <input onChange={(e) => handleChange(e)} name="FullName" value={customerDetails?.FullName} type="text" id="fullNameEdit" disabled />
      </div>
      <div className='input'>
        <label htmlFor="contactNumberEdit">Contact Number</label>
        <input onChange={(e) => handleChange(e)} name="MobileNum" value={customerDetails?.MobileNum} type="text" id="contactNumberEdit" />
      </div>
      <div className='input'>
        <label htmlFor="emailEdit">Email</label>
        <input onChange={(e) => handleChange(e)} name="Email" value={customerDetails?.Email} type="Email" id="emailEdit" />
      </div>
      <div className='input'>
        <label htmlFor="areaEdit">Area</label>
        <select onChange={(e) => handleChange(e)} name="Area" value={customerDetails?.Area} type="text" id="areaEdit" >
          <option value="" > SELECT YOUR AREA </option>
          {
            Object.keys(areaList).map((key, index) => {
              const { Area } = areaList[key]
              return (
                <option key={index} value={Area}> {Area} </option>
              )
            })
          }
        </select>
      </div>
      <div className='input'>
        <label htmlFor="addressEdit">Address</label>
        <input type="text" autoComplete='none' onChange={(e) => handleChange(e)} name="Address" value={customerDetails?.Address} id="addressEdit" />
        {
          !_.isEmpty(ishipeeSearch) ? (
            <div className='ishipee-container'>
              {
                Object.keys(ishipeeSearch).map((key, index) => {
                  return (
                    <button type="button" onClick={(e) => addressFillup(e, ishipeeSearch[key])} key={index}>{ishipeeSearch[key].description}</button>
                  )
                })
              }
            </div>
          ) : (
            <div> </div>
          )

        }
      </div>

      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitCustomerDetail(e, customerDetails)
        }}>Save</button>
      </div>

    </div>
  )
}

export default CustomerDetail