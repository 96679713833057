import { Modal, Box } from '@mui/material';
import Proptypes from "prop-types";
import {EXTRA_ORDER_PRINT_TYPES} from "../constant";
import {useEffect, useMemo, useState} from "react";
import {getObjectKey} from "../../OderHelper";
import moment from "moment-timezone";
const ModalConfirmKitchenPrintOrder = ({isOpen, onClose, toggle, order, onConfirm}) => {
    const INDEX_IS_ALL = "-1"
    const typeIsAll = useMemo(() => {
        return getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.all)
    }, [])

    const [type, setType] = useState(typeIsAll)
    const [orderIndex, setOrderIndex] = useState(INDEX_IS_ALL)

    useEffect(() => {
        setType(typeIsAll)
        setOrderIndex(INDEX_IS_ALL)
    }, [isOpen, typeIsAll, INDEX_IS_ALL])

    const renderOptionTypes = () => {
        const output = []
        const ordersPending = order?.OrdersPending ?? []

        for (const key in EXTRA_ORDER_PRINT_TYPES) {
            if(EXTRA_ORDER_PRINT_TYPES[key] === EXTRA_ORDER_PRINT_TYPES.onlyExtra && ordersPending.length === 0) {
                continue;
            }

            output.push(<option key={`option_print_type_${key}`} value={key}>{EXTRA_ORDER_PRINT_TYPES[key]}</option>)
        }

        return output
    }

    const renderOptionIndex = () => {
        const output = [<option key="option_print_index_all" value="-1">All</option>]
        const ordersPending = order?.OrdersPending ?? []
        for (let i = 0; i < ordersPending.length; i++) {
            const orderPending = ordersPending[i]
            const createdAt = orderPending?.createdAt ?? null
            output.push(<option key={`option_print_index_${i}`} value={`${i}`}>Extra #{i + 1} : {createdAt ? moment.utc(createdAt).tz("Asia/Manila").format("HH:mm:ss DD-MM-YYYY") : ""}</option>)
        }

        return output
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        padding: "10px 15px",
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            closeAfterTransition>
            <Box sx={style}>
                <div className="modal-kitchen-print-group">
                    <label className="modal-kitchen-print-label">Order type</label>
                    <select className="modal-kitchen-print-input" value={type} onChange={(e) => setType(e.target.value)}>
                        {renderOptionTypes()}
                    </select>
                </div>
                {
                    type !== getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyExtra) ? <></> : (<div className="modal-kitchen-print-group">
                        <label className="modal-kitchen-print-label">Index</label>
                        <select className="modal-kitchen-print-input" value={orderIndex} onChange={(e) => setOrderIndex(e.target.value)}>
                            {renderOptionIndex()}
                        </select>
                    </div>)
                }
                <div className="footer-modal-kitchen-print">
                    <button className="btn-cancel" onClick={toggle}>Cancel</button>
                    <button className="btn-print" onClick={() => {
                        toggle()

                        onConfirm({
                            type,
                            index: orderIndex
                        })
                    }}>Print</button>
                </div>
            </Box>
        </Modal>
    )
}

ModalConfirmKitchenPrintOrder.propTypes = {
    isOpen: Proptypes.bool.isRequired,
    onClose: Proptypes.func,
    toggle: Proptypes.func.isRequired,
    order: Proptypes.object.isRequired,
    onConfirm: Proptypes.func.isRequired
}

export default ModalConfirmKitchenPrintOrder