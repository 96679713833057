const jwt = require("jsonwebtoken");
const CMS_TOKEN = "cmsToken"
const CMS_BRANCH = "branchId"
const PUBLIC_BRANCH = "publicBranch"

const getCMSToken = () =>  {
    return localStorage.getItem(CMS_TOKEN) ?? null
}

const setCMSToken = (token) => {
    return localStorage.setItem(CMS_TOKEN, token)
}

const removeCMSToken = () => {
    return localStorage.removeItem(CMS_TOKEN)
}

const getCMSBranch = () => {
    return localStorage.getItem(CMS_BRANCH) ?? null
}

const setCMSBranch = (branchId) => {
    return localStorage.setItem(CMS_BRANCH, branchId)
}

const getPublicBranch = () => {
    return localStorage.getItem(PUBLIC_BRANCH) ?? null
}

const setPublicToken = (branchId) => {
    return localStorage.setItem(PUBLIC_BRANCH, branchId)
}

const getCMSUser = () => {
    const token = getCMSToken()
    if(token) {
        return jwt.decode(token, {})
    }

    return null
}

const USER_ROLES = {
    Admin: "Admin",
    Cashier: "Cashier",
    Finance: "Finance",
    Kitchen: "Kitchen",
    CS: "CS",
    Waiter: "Waiter"
}

module.exports = {
    getCMSToken,
    setCMSToken,
    getCMSBranch,
    setCMSBranch,
    getPublicBranch,
    setPublicToken,
    removeCMSToken,
    getCMSUser,
    USER_ROLES
}