import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GetPublicPaymentMethod, logout } from "../PublicHelper";
import Swal from 'sweetalert2';
const LoggedIn = ({
  customerInformation,
  setCustomerInformation,
  setBankList,
  setBanktoBlank,
  getAreaShippingFee,
  isShippingEmpty,
  maxPreparationTime,
  checkOutError,
  userLoggedIn,
  showEditLocation,
  setSpecificShippingArea,
  getCustomerInformation,
  customer,
  changeMealStatus,
  isMealAllowance
}) => {
  const { t } = useTranslation()
  const history = useNavigate();
  const getMinimumDate = () => {
    return moment().tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD")
  };
  const getMinimumTime = (addedMin) => {
    return moment().tz("Asia/Manila").add(addedMin, "minutes").format("HH:MM")
  };
  let adjustedDate = moment().tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD");
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState({
    deliveryDate: adjustedDate,
    deliveryTime: getMinimumTime(maxPreparationTime),
  });
  
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [minOrderDate, setMinOrderDate] = useState();
  
  const [showMealOption, setShowMealOption] = useState(false)
  let publicToken = localStorage.getItem("publicToken") 
  // const [customerDefault, setCustomerDefault] = useState({
  //   DContactNum: "",
  //   DFirstName: "",
  //   DLastName: ""
  // })
  // console.log("New Date: ", adjustedDate)

  // console.log("preferredDeliveryDate: ", preferredDeliveryDate)
  // console.log("publicToken ON login: ", publicToken)

  useEffect(() => {

    getPaymentMethod();
    createTimePicker()
    setMinOrderDate(getMinimumDate()); 
    getInitialShippingTime(); 
    return () => { };
    //eslint-disable-next-line
  }, [maxPreparationTime]);

  const getInitialShippingTime = () => {
    let InitialDate = moment(new Date()).tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD");
    let DelDate = moment(`${InitialDate} ${preferredDeliveryDate.deliveryTime}`).tz("Asia/Manila").format().toString()
    console.log("Initial Date: ", DelDate)
    setCustomerInformation({
      ...customerInformation, 
      PreferredDeliveryDate: DelDate
    });
  }
 
  const [isDisabled, setIsDisable] = useState(_.isEmpty(customerInformation.ShippingAddress))
  const getPaymentMethod = () => {
    GetPublicPaymentMethod()
      .then((res) => {
        const { paginatedResults } = res.data;
        setPaymentMethod(paginatedResults);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const [timePicker, setTimePicker] = useState([])
  const createTimePicker = (date) => { 
    let newTime = []
    let today = moment(_.isEmpty(date) ? (new Date()) : date).tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD")
    let time = moment(today).isAfter(getMinimumDate()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
    for (let hour = parseInt(time); hour < 24; hour++) {
      for (let minutes = 0; minutes < 60;) {
        let newMinutes = minutes < 10 ? "00" : minutes
        let newHours = hour < 10 ? `0${hour}` : hour
        newTime.push(`${newHours}:${newMinutes}`)
        minutes += 15;
      }
    }
    setTimePicker(newTime)
    setPreferredDeliveryDate({
      deliveryTime: `${time}:00`,
      deliveryDate: `${today}`,
    });
    setCustomerInformation({
      ...customerInformation,
      PreferredDeliveryDate: moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString(),
    });
  }
   
  const forceLogout = () => {   
    Swal.fire({
      title: "Session Ended / Already Logout",
      text: "Please login again to continue",
      icon: 'error',
      footer: "&#169; Pao Kitchen 2023",
      showCancelButton: false,
      confirmButtonColor: '#50311b',
      confirmButtonText: 'Okay',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("publicToken");
        window.location.reload();
      }
    })
    
  }

  const handleChange = (e) => {
    const { value, name } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);

    if(_.isEmpty(publicToken)){
      forceLogout() 
    }else{
      if (name === "deliveryDate" || name === "deliveryTime") {
        if (name === "deliveryDate") {
          createTimePicker(value)
          setPreferredDeliveryDate({
            ...preferredDeliveryDate,
            deliveryDate: value,
          });
          setCustomerInformation({
            ...customerInformation,
            PreferredDeliveryDate: moment(`${value} ${deliveryTime}`).tz("Asia/Manila").format().toString(),
          });
          getCustomerInformation({
            ...customerInformation,
            PreferredDeliveryDate: moment(`${value} ${deliveryTime}`).tz("Asia/Manila").format().toString(),
          });
        }
        if (name === "deliveryTime") {
          console.log(value);
          setPreferredDeliveryDate({
            ...preferredDeliveryDate,
            deliveryTime: value,
          });
          setCustomerInformation({
            ...customerInformation,
            PreferredDeliveryDate: moment(`${deliveryDate} ${value}`).tz("Asia/Manila").format().toString(),
          });
          getCustomerInformation({
            ...customerInformation,
            PreferredDeliveryDate: moment(`${deliveryDate} ${value}`).tz("Asia/Manila").format().toString(),
          });
        }
      }
      if (name === "ShippingAddress") { 
        if (!_.isEmpty(value)) {
          let contactInformation = _.find(ShippingAddress, (shippingAddress) => {
            return shippingAddress._id === value;
          });
          const { Address, Area, Email, FullName, MobileNumber, PlaceId } = contactInformation;
          setSpecificShippingArea({
            _id: value,
            FullName: FullName,
            Email: Email,
            MobileNumber: MobileNumber,
            Area: Area,
            Address: Address
          })
          let DelDate = moment(`${preferredDeliveryDate.deliveryDate} ${preferredDeliveryDate.deliveryTime}`).tz("Asia/Manila").format().toString()
          setCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              Email: Email,
              ContactNum: MobileNumber.slice(3),
              FullName: FullName,
            },
            DeliveryDetails: {
              Area: Area,
              Address: Address,
              PlaceId: PlaceId,
              Place: Address,
            },
            PreferredDeliveryDate:DelDate
          });
          
          getCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              Email: Email,
              ContactNum: MobileNumber.slice(3),
              FullName: FullName,
            },
            DeliveryDetails: {
              Area: Area,
              Address: Address,
              PlaceId: PlaceId,
              Place: Address,
            },
            PreferredDeliveryDate:DelDate
          });
          // console.log("preferredDeliveryDate: ", DelDate);
          // console.log("get here >>>")
          //moment(`${value} ${deliveryTime}`).tz("Asia/Manila").format().toString(),
          // getAreaShippingFee(e, Area, {
          //   Area: Area,
          //   Address: Address,
          //   PlaceId: PlaceId,
          //   Place: Address,
          // });
          getAreaShippingFee(e,  
            {
              Area: Area,
              Address: Address,
              PlaceId: PlaceId,
              Place: Address,
            },
            {
              Email: Email,
              ContactNum: MobileNumber.slice(3),
              FullName: FullName,
            },
            preferredDeliveryDate);
        } else {
  
          setCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              Email: "",
              ContactNum: "",
              FullName: "",
            },
            DeliveryDetails: {
              Area: "",
              Address: "",
              PlaceId: "",
              Place: "",
            },
          });
  
          getCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              Email: "",
              ContactNum: "",
              FullName: "",
            },
            DeliveryDetails: {
              Area: "",
              Address: "",
              PlaceId: "",
              Place: "",
            },
          });
        }
        setIsDisable(_.isEmpty(value))
      }
      if (name === "FullName") {
        setCustomerInformation({
          ...customerInformation,
          ContactDetails: {
            ...customerInformation.ContactDetails,
            FullName: value,
          },
        });
  
        getCustomerInformation({
          ...customerInformation,
          ContactDetails: {
            ...customerInformation.ContactDetails,
            FullName: value,
          },
        });
      }
      if (name === "Email") {
        setCustomerInformation({
          ...customerInformation,
          ContactDetails: {
            ...customerInformation.ContactDetails,
            Email: value,
          },
        });
  
        getCustomerInformation({
          ...customerInformation,
          ContactDetails: {
            ...customerInformation.ContactDetails,
            Email: value,
          },
        });
      }
      if (name === "ContactNum") {
        const re = /^[0-9\b]+$/;
        if (_.isEmpty(value) || re.test(value)) {
          setCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              ...customerInformation.ContactDetails,
              ContactNum: value,
            },
          });
  
          getCustomerInformation({
            ...customerInformation,
            ContactDetails: {
              ...customerInformation.ContactDetails,
              ContactNum: value,
            },
          });
        }
      }
      if (name === "OrderNote") {
        setCustomerInformation({
          ...customerInformation,
          OrderNote: value,
        });
  
        getCustomerInformation({
          ...customerInformation,
          OrderNote: value,
        });
      }
      if (name === "SendOption") {
        setCustomerInformation({
          ...customerInformation,
          SendOption: value,
        });
  
        getCustomerInformation({
          ...customerInformation,
          OrderNote: value,
        });
        userLoggedIn(value)
        
        if(value === 'DINEIN' || value === 'PICKUP'){
          if(customer.IsEmployee === true){
            //console.log("Show check button meal", customer);
            setShowMealOption(true)
          }
        }else{
          setShowMealOption(false)
        }
      }
      if (name === "PaymentMode") {
        setBanktoBlank();
        setCustomerInformation({
          ...customerInformation,
          PaymentMode: value,
        });
  
        getCustomerInformation({
          ...customerInformation,
          OrderNote: value,
        });
        setBankList(
          _.find(paymentMethod, (methodPayment) => {
            return methodPayment.Code === value;
          }).Banks
        );
      }
    }
 
  };
  // console.log("XXXcustomerInformationXXX: ",customerInformation);
  const { ShippingAddress, ContactDetails, OrderNote, SendOption } =
    customerInformation;
  const { deliveryDate, deliveryTime } = preferredDeliveryDate;
  const { Email, ContactNum, FullName } = ContactDetails; 

  return (
    <div className="checkout-container-left">
      <div className="checkout-container-left-content padding-top-100">
        <form
          autoComplete="off"
          id="register-public"
          className="login-dialog-container-register active-register"
        >
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('shippingtime')}</label>
              <div className="checkout-input-div-2">
                <input
                  type="date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  min={minOrderDate}
                  name="deliveryDate"
                  value={preferredDeliveryDate.deliveryDate}
                  
                />
                {/* <input
                  type="time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  min={moment().tz("Asia/Manila").add(maxPreparationTime, "minutes").format("HH:MM")}

                  name="deliveryTime"
                /> */}
                <select name="deliveryTime" 
                  value={"" || preferredDeliveryDate.deliveryTime}                   
                  onChange={(e) => {
                    handleChange(e);
                }}> 
                  <option value=""> Select Time </option>
                  {
                    _.map(timePicker, (value, index) => {
                      return <option key={index} value={value}>{value}</option>
                    })
                  }
                </select>
              </div>
              <small className="text-grey">
                {t('maxpreparationtime')} : {_.isNaN(maxPreparationTime) ? "0" : maxPreparationTime} {t('minutes')}
              </small>
              <small className='text-grey'>{t('estimatedtime')} : {moment().tz("Asia/Manila").add(_.isNaN(maxPreparationTime) ? "0" : maxPreparationTime, "minutes").format("YYYY-MM-DD hh:mm:ss a")}</small>
              {
                !_.isEmpty(checkOutError.PreferredDeliveryDate) ? (<small className='error-message'>{checkOutError.PreferredDeliveryDate}</small>) : (<></>)
              }
            </div>
          </div>
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('contactdetails')}</label>
              <div className={!_.isEmpty(checkOutError.ShippingFee) ? "checkout-input-div-select input-warning" : "checkout-input-div-select"} >
                <select
                  disabled={isShippingEmpty}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type="text"
                  name="ShippingAddress"
                  value={!_.isEmpty(checkOutError.ShippingFee) ? "" : ShippingAddress._id}
                  id="shppinAddress" 
                >
                  <option value=""> {t('selectaddress')} </option>
                  {_.map(ShippingAddress, (key, index) => {
                    const { Address, _id } = key;
                    return (
                      <option key={index} value={_id}>
                        {Address}
                      </option>
                    );
                  })}
                </select>

                <button disabled={isDisabled} onClick={(e) => { showEditLocation(e) }}>{t('edit')}</button>
              </div>

              {
                !_.isEmpty(checkOutError.ShippingFee) ? (<small className='error-message'>{checkOutError.ShippingFee}</small>) : (<></>)
              }
              <div className="checkout-input-div-2">
                <input
                  disabled={isShippingEmpty}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={FullName}
                  name="FullName"
                  type="text"
                  placeholder={t('fullname')}
                  className={!_.isEmpty(checkOutError.FullName) ? "input-warning" : ""}
                />
              </div>
              <div className="checkout-input-div-2" autoComplete="off">
                <input
                  disabled={isShippingEmpty}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={Email}
                  name="Email"
                  type="email"
                  placeholder={t('email')}
                  className={!_.isEmpty(checkOutError.Email) ? "input-warning" : ""}
                />
                <div className={!_.isEmpty(checkOutError.MobileNum) ? "input-warning" : ""}>
                  <span className="mobile-number">+63</span>
                  <input
                    disabled={isShippingEmpty}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={ContactNum}
                    maxLength="10"
                    name="ContactNum"
                    type="text"
                    placeholder={t('mobilenumber')}
                  />
                </div>
              </div>

              {
                !_.isEmpty(checkOutError.FullName) ? (<small className='error-message'>{t('fullname')}: {checkOutError.FullName}</small>) : (<></>)
              }
              {
                !_.isEmpty(checkOutError.MobileNum) ? (<small className='error-message'>{t('mobilenumber')}: {checkOutError.MobileNum}</small>) : (<></>)
              }
              {
                !_.isEmpty(checkOutError.Email) ? (<small className='error-message'>{t('email')}: {checkOutError.Email}</small>) : (<></>)
              }
              {isShippingEmpty ? (
                <small className="error-message margin-top-10">
                  {t('pleaseadarea')}{" "}
                  <button
                    onClick={(e) => {
                      history("/myaccount/shippingaddresses");
                    }}
                    className="button-pager"
                  >
                    {" "}
                    {t('myaccount')}{" "}
                  </button>
                </small>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <div className="checkout-input-div-4">
                <label>
                  <input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={SendOption === "DELIVERY"}
                    value="DELIVERY"
                    type="radio"
                    name="SendOption"
                  />
                  <span>{t('delivery')}</span>
                </label>
                <label>
                  <input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={SendOption === "PICKUP"}
                    value="PICKUP"
                    type="radio"
                    name="SendOption"
                  />
                  <span>{t('pickup')}</span>
                </label>
                {/* <label>
                  <input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={SendOption === "DINEIN"}
                    value="DINEIN"
                    type="radio"
                    name="SendOption"
                  />
                  <span>{t('dinein')}</span>
                </label> */}
                {showMealOption ? 
                  (<label>
                    <input 
                      value={isMealAllowance}
                      type="checkbox"
                      name="IsMealAllowance" 
                      onChange={changeMealStatus()}
                    />
                    <span>{t('isMealAllowance')}</span>
                  </label>)
                  : (<></>)                  
                }

              </div>
              <div className="checkout-input-div-2">
                <input
                  value={OrderNote}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={t('note')}
                  name="OrderNote"
                />
              </div>
            </div>
          </div>
          <div className="login-dialog-container-register-item">
            <div className="checkout-input" >
              <label>{t('paymentdetails')}</label>
              <div className={!_.isEmpty(checkOutError.PaymentMode) ? "checkout-input-div-2 input-warning" : "checkout-input-div-2"}>
                {_.isEmpty(paymentMethod) ? (
                  <></>
                ) : (
                  Object.keys(paymentMethod).map((key, index) => {
                    const { Code, Name } = paymentMethod[key];
                    return (
                      <label key={index} className="payment-option-input">
                        <input
                          className="payment-option-input-radio"
                          type="radio"
                          value={Code}
                          name="PaymentMode"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          checked={customerInformation.PaymentMode === Code}
                        />
                        <span>{Name}</span>
                      </label>
                    );
                  })
                )}
                <label className="payment-option-input disabled-input">
                  <input
                    className="payment-option-input-radio"
                    type="radio"
                    name="paymentoption"
                    disabled
                  />
                  <span>Vmart Wallet</span> <small>coming soon</small>
                </label>
              </div>

              {
                !_.isEmpty(checkOutError.PaymentMode) ? (<small className='error-message'>{checkOutError.PaymentMode}</small>) : (<></>)
              }

            </div>

          </div>
        </form>
      </div>
    </div>
  );
};

export default LoggedIn;
