
import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useDispatch } from "react-redux";
import { setToken } from '../../../redux/actions/token';
import { GetPublicPaymentMethod, GetShippingAreaOnly, IshippeeGet, SubmitLogin, SubmitLoginFacebook, SubmitLoginGoogle } from '../PublicHelper';
import Member from './../../../assets/member.svg';
import fb from "./../../../assets/social-link/fb.svg";
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const NotLoggedIn = ({
  changeActive,
  getAreaShippingFee,
  getCustomerInformation,
  setShippingArea, checkOutError, setBankList, setBanktoBlank, maxPreparationTime,
  userNotLoggedIn
}) => {
  const [areaList, setArealist] = useState([])
  let dispatch = useDispatch()
  let cart = JSON.parse(localStorage.getItem("publicCart") || "[]")
  const [customerInformation, setCustomerInformation] = useState({
    OrderDetails: cart,
    OrderNote: "",
    PaymentMode: "",
    PreferredDeliveryDate: "",
    SendOption: "DELIVERY",
    ContactDetails: {
      Email: "",
      ContactNum: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: ""
    },
    DeliveryDetails: {
      Area: "Grab - Lalamove - LBC - J and T",
      Address: "",
      PlaceId: "",
      Place: ""
    }
  })
  const getMinimumDate = () => {
    return moment().tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD")
  };
  const getMinimumTime = (addedMin) => {
    return moment().tz("Asia/Manila").add(addedMin, "minutes").format("HH:MM")
  };
  let adjustedDate = moment().tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD");
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState({
    deliveryDate: adjustedDate,
    deliveryTime: getMinimumTime(_.isNaN(maxPreparationTime) ? 0 : maxPreparationTime),
  });
  const [paymentMethod, setPaymentMethod] = useState([])
  const [minOrderDate, setMinOrderDate] = useState();

  useEffect(() => {
    createTimePicker()
    GetShippingAreaOnly().then(res => {
      setArealist(res.data)
    }).catch(err => {
      console.log(err.response);
    })


    // let today = moment(new Date()).tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD")
    // let time = moment(today).isAfter(getMinimumDate()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
    // setCustomerInformation({
    //   ...customerInformation,
    //   PreferredDeliveryDate: moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString(),
    // });
    // getCustomerInformation({
    //   ...customerInformation,
    //   PreferredDeliveryDate: moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString()
    // })
    getPaymentMethod()
    document.querySelector("#register-public").classList.add("active-register")
    let fbDiag = document.querySelectorAll(".kep-login-facebook")
    for (let i = 0; i < fbDiag.length; i++) {
      fbDiag[i].classList.add("small")
      fbDiag[i].classList.remove("metro")
      fbDiag[i].innerHTML = `<img src="${fb}" width='21' height='21' alt='icon' /> ${t('login')}`
    }
    setMinOrderDate(getMinimumDate());
    getInitialShippingTime(); 
    return () => { }
    //eslint-disable-next-line
  }, [maxPreparationTime])

  const getInitialShippingTime = () => {
    let InitialDate = moment(new Date()).tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD");
    let DelDate = moment(`${InitialDate} ${preferredDeliveryDate.deliveryTime}`).tz("Asia/Manila").format().toString()
    console.log("Initial Date: ", DelDate)
    setCustomerInformation({
      ...customerInformation, 
      PreferredDeliveryDate: DelDate
    });
  }

  const [login, setLogin] = useState({
    Username: "",
    Password: ""
  })
  const [timePicker, setTimePicker] = useState([])
  const createTimePicker = (date) => {
    let newTime = []
    let today = moment(_.isEmpty(date) ? (new Date()) : date).tz("Asia/Manila").add(maxPreparationTime, "minutes").format("YYYY-MM-DD")
    let time = moment(today).isAfter(getMinimumDate()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
    for (let hour = parseInt(time); hour < 24; hour++) {
      for (let minutes = 0; minutes < 60;) {
        let newMinutes = minutes < 10 ? "00" : minutes
        let newHours = hour < 10 ? `0${hour}` : hour
        newTime.push(`${newHours}:${newMinutes}`)
        minutes += 15;
      }
    }
    setTimePicker(newTime)
    setPreferredDeliveryDate({
      deliveryTime: `${time}:00`,
      deliveryDate: `${today}`,
    });
    setCustomerInformation({
      ...customerInformation,
      PreferredDeliveryDate: moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString(),
    });
  }
  const submitLogin = (e) => {
    e.preventDefault()
    console.log(login);
    SubmitLogin(login).then(res => {
      localStorage.setItem("publicToken", res.data);
      dispatch(setToken({ publicToken: res.data }));
    }).catch(err => console.log(err))
  }
  const handleInputLogin = (e) => {
    const { name, value } = e.target
    setLogin({
      ...login,
      [name]: value
    })
  }
  const responseGoogle = (response) => {
    let data = {
      IdToken: response?.tokenId
    }
    SubmitLoginGoogle(data).then(res => {
      console.log(res);
      localStorage.setItem("publicToken", res?.data);
      dispatch(setToken({ publicToken: res?.data }));

    }).catch(err => console.log(err))
  };
  const responseFacebook = (fbRes) => {

    let data = {
      access_token: fbRes?.accessToken
    }
    SubmitLoginFacebook(data).then(res => {
      localStorage.setItem("publicToken", res?.data);
      dispatch(setToken({ publicToken: res?.data }));

    }).catch(err => console.log(err?.response))
  }
  const getPaymentMethod = (params) => {
    GetPublicPaymentMethod()
      .then(res => {
        const { paginatedResults } = res?.data
        setPaymentMethod(paginatedResults)
      })
      .catch(err => { console.log(err?.response) })
  }

  const [ishipeeSearch, setIshipeeSearch] = useState([])

  //pass data outside
  const handleNoteChange = (e) => {
    const { name, value } = e.target
    setCustomerInformation({
      ...customerInformation,
      [name]: value
    })
    getCustomerInformation({
      ...customerInformation,
      [name]: value
    })
  }
  const handleChange = (e) => {
    const { value, name } = e.target
    console.log("test 1 >>>")
    if (name === "Address") {
      
      getCustomerInformation({
        ...customerInformation,
        DeliveryDetails: {
          ...customerInformation.DeliveryDetails,
          [name]: value,
          PlaceId: "",
          Place: ""
        }
      })
      if (_.isEmpty(value)) {
        setIshipeeSearch([])
      } else {
        // const { Area } = customerInformation.DeliveryDetails
        IshippeeGet(value)
          .then(
            res => {
              console.log(res.predictions)
              setIshipeeSearch([...res.predictions])

            }
          )
      }

    } else if (name === "Area") {
      console.log("test 3 >>>", e, " == ", value)
      // getAreaShippingFee(e, value)
    }
    if (name === "Address") {
      setCustomerInformation({
        ...customerInformation,
        DeliveryDetails: {
          ...customerInformation.DeliveryDetails,
          [name]: value,
          PlaceId: "",
          Place: ""
        }
      })
      getCustomerInformation({
        ...customerInformation,
        DeliveryDetails: {
          ...customerInformation.DeliveryDetails,
          [name]: value,
          PlaceId: "",
          Place: ""
        }
      })
    } else {
      setCustomerInformation({
        ...customerInformation,
        DeliveryDetails: {
          ...customerInformation.DeliveryDetails,
          [name]: value
        }
      })
      // let myArea = _.filter(areaList, { _id: value });
      getCustomerInformation({
        ...customerInformation,
        DeliveryDetails: {
          ...customerInformation.DeliveryDetails,
          [name]: value,
        }
      })

      console.log("test 4 >>>", e, " == ", value)
    }
  }
  const addressFillup = (e, value) => {
    e.preventDefault()
    setCustomerInformation({
      ...customerInformation,
      DeliveryDetails: {
        ...customerInformation.DeliveryDetails,
        Address: value.description,
        PlaceId: value.place_id,
        Place: value.place_id
      }
    })
    setIshipeeSearch([])
    getCustomerInformation({
      ...customerInformation,
      DeliveryDetails: {
        ...customerInformation.DeliveryDetails,
        Address: value.description,
        PlaceId: value.place_id,
        Place: value.place_id
      }
    })
    getAreaShippingFee(e, value, '', preferredDeliveryDate)
    console.log("Select Address >>>", value)
    // console.log("preferredDeliveryDate >>>", preferredDeliveryDate)
  }
  const onChangePayment = (e) => {
    const { value } = e.target
    setBanktoBlank()
    setCustomerInformation({
      ...customerInformation,
      PaymentMode: value
    });
    getCustomerInformation({
      ...customerInformation,
      PaymentMode: value
    })
    setBankList(_.find(paymentMethod, (methodPayment) => {
      return methodPayment.Code === value;
    }).Banks);

  }
  const changeValueDeliveryOption = (e) => {
    const { value } = e.target

    userNotLoggedIn(value)
    if (value === "DELIVERY") {

      setCustomerInformation({
        ...customerInformation,
        SendOption: value
      });
      getCustomerInformation({
        ...customerInformation,
        SendOption: value
      })
    } else {
      setCustomerInformation({
        ...customerInformation,
        SendOption: value,
        DeliveryDetails: {
          Area: "",
          Address: "",
          PlaceId: "",
          Place: ""
        }
      });
      getCustomerInformation({
        ...customerInformation,
        SendOption: value,
        DeliveryDetails: {
          Area: "",
          Address: "",
          PlaceId: "",
          Place: ""
        }
      })
      setShippingArea({
        Area: "",
        AreaNote: { EN: '', VN: '', TH: '', CN: '' },
        BranchId: "",
        CreatedAt: "",
        IsActive: true,
        MinOrderAmount: 0,
        ShippingFee: 0,
        TotalOrderAmount: 0,
      })
      setIshipeeSearch([])
    }

  }
  const handleDeliveryInputChange = (e) => {
    const { value, name } = e.target
    setPreferredDeliveryDate({
      ...preferredDeliveryDate,
      [name]: value
    })

    if (name === "deliveryDate") {
      createTimePicker(value)

      console.log("Shipping Date Convert: ", value + " " + preferredDeliveryDate.deliveryTime)

      setCustomerInformation({
        ...customerInformation,
        PreferredDeliveryDate: moment(value + " " + preferredDeliveryDate.deliveryTime).tz("Asia/Manila").format().toString()
      })
      getCustomerInformation({
        ...customerInformation,
        PreferredDeliveryDate: moment(value + " " + preferredDeliveryDate.deliveryTime).tz("Asia/Manila").format().toString()
      })
    } else if (name === "deliveryTime") {

      console.log("Shipping Time Convert: ", preferredDeliveryDate.deliveryDate + " " + value)

      setCustomerInformation({
        ...customerInformation,
        PreferredDeliveryDate: moment(preferredDeliveryDate.deliveryDate + " " + value).tz("Asia/Manila").format().toString()
      })
      getCustomerInformation({
        ...customerInformation,
        PreferredDeliveryDate: moment(preferredDeliveryDate.deliveryDate + " " + value).tz("Asia/Manila").format().toString()
      })

    }

  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCustomerInformation({
      ...customerInformation,
      ContactDetails: {
        ...customerInformation.ContactDetails,
        [name]: value
      }
    })
    getCustomerInformation({
      ...customerInformation,
      ContactDetails: {
        ...customerInformation.ContactDetails,
        [name]: value
      }
    })
  }
  const handleChangeNum = (e) => {
    const { value, name } = e.target
    const re = /^[0-9\b]+$/;
    if (_.isEmpty(value) || re.test(value)) {
      setCustomerInformation({
        ...customerInformation,
        ContactDetails: {
          ...customerInformation.ContactDetails,
          [name]: value
        }
      })
      getCustomerInformation({
        ...customerInformation,
        ContactDetails: {
          ...customerInformation.ContactDetails,
          [name]: value
        }
      })
    }

  }
  const { Area, Address } = customerInformation.DeliveryDetails
  const { OrderNote, SendOption } = customerInformation
  const { FirstName, LastName, Email, ContactNum } = customerInformation.ContactDetails

  return (
    <div className="checkout-container-left">
      <div className="checkout-container-left-content">
        <div className="login-dialog-container-header">
          <div className="login-dialog-container-header-buttons">
            <button
              onClick={(e) => changeActive(e, 1)}
              className="login-dialog-container-header-buttons-button active">
              {t('newuser')}
            </button>
            <button
              onClick={(e) => changeActive(e, 2)}
              className="login-dialog-container-header-buttons-button">
              <img src={Member} width="16" height="16" alt="icon" /> {t('asmember')}
            </button>
          </div>
        </div>
        <form autoComplete='off' id="register-public" className="login-dialog-container-register">
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('shippingtime')}</label>
              <div className="checkout-input-div-2">
                <input className={!_.isEmpty(checkOutError.PreferredDeliveryDate) ? "input-warning" : ""} type="date" value={preferredDeliveryDate.deliveryDate} min={minOrderDate} name="deliveryDate" onChange={(e) => handleDeliveryInputChange(e)} />
                {/* <input className={!_.isEmpty(checkOutError.PreferredDeliveryDate) ? "input-warning" : ""} type="time" min={getMinimumTime(maxPreparationTime)} name="deliveryTime" onChange={(e) => handleDeliveryInputChange(e)} /> */}
                <select name="deliveryTime" onChange={(e) => handleDeliveryInputChange(e)} >
                  {
                    _.map(timePicker, (value, index) => {
                      return <option key={index} value={value}>{value}</option>
                    })
                  }
                </select>
              </div>
              {/* REQUIRED: first name, lastname, password * regex*/}
              {
                !_.isEmpty(checkOutError.PreferredDeliveryDate) ? (<small className='error-message'>{checkOutError.PreferredDeliveryDate}</small>) : (<></>)
              }
              <small className='text-grey'>{t('maxpreparationtime')} : {_.isNaN(maxPreparationTime) ? "0" : maxPreparationTime} {t('minutes')}</small>
              <small className='text-grey'>{t('estimatedtime')} : {moment().tz("Asia/Manila").add(_.isNaN(maxPreparationTime) ? "0" : maxPreparationTime, "minutes").format("YYYY-MM-DD hh:mm:ss a")}</small>

            </div>
          </div>
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('contactdetails')}</label>
              <div className="checkout-input-div-2">
                <input
                  className={!_.isEmpty(checkOutError.FullName) ? "input-warning" : ""}
                  onChange={(e) => { handleInputChange(e) }} value={FirstName} name="FirstName" type="text" placeholder={t('firstname')} />
                <input
                  className={!_.isEmpty(checkOutError.FullName) ? "input-warning" : ""}
                  onChange={(e) => { handleInputChange(e) }} value={LastName} name="LastName" type="text" placeholder={t('lastname')} />

              </div>
              <div className="checkout-input-div-2" autoComplete='off'>
                <input
                  className={!_.isEmpty(checkOutError.Email) ? "input-warning" : ""}
                  onChange={(e) => { handleInputChange(e) }} value={Email} name="Email" type="email" placeholder={t('email')} />
                <div
                  // className={!_.isEmpty(loginError.ContactNum) ? "input-warning" : ""}
                  className={!_.isEmpty(checkOutError.MobileNum) ? "input-warning" : ""}
                >
                  <span className="mobile-number">+63</span>
                  <input maxLength="10" onChange={(e) => { handleChangeNum(e) }} value={ContactNum} name="ContactNum"
                    type="text"
                    placeholder={t('mobilenumber')}
                  />
                </div>
              </div>
              {
                !_.isEmpty(checkOutError.MobileNum) ? (<small className='error-message'>{t('mobilenumber')} :{checkOutError.MobileNum}</small>) : (<></>)
              }
              {
                !_.isEmpty(checkOutError.FullName) ? (<small className='error-message'>{t('firstname')}: {checkOutError.FullName}</small>) : (<></>)
              }
              {
                // !_.isEmpty(loginError.LastName) ? (<small className='error-message'>{loginError.LastName}</small>) : (<></>)
              }
              {
                !_.isEmpty(checkOutError.Email) ? (<small className='error-message'>{t('email')}: {checkOutError.Email}</small>) : (<></>)
              }
              {
                // !_.isEmpty(loginError.ContactNum) ? (<small className='error-message'>{loginError.ContactNum}</small>) : (<></>)
              }

            </div>
          </div>
          {/* <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('password')}</label>
              <div className="checkout-input-div-2">
                <input className={!_.isEmpty(loginError.Password) ? "input-warning" : ""} type="password" onChange={(e) => { handleInputChange(e) }} value={Password} name="Password" placeholder={t('password')} />
                <input className={!_.isEmpty(loginError.ConfirmPassword) ? "input-warning" : ""} type="password" onChange={(e) => { handleInputChange(e) }} value={ConfirmPassword} name="ConfirmPassword" placeholder={t('confirmpassword')} />
              </div>

              {
                !_.isEmpty(loginError.Password) ? (<small className='error-message'>{loginError.Password}</small>) : (<></>)
              }
              {
                !_.isEmpty(loginError.ConfirmPassword) ? (<small className='error-message'>{loginError.ConfirmPassword}</small>) : (<></>)
              }
            </div>
          </div> */}
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <div className="checkout-input-div-4">
                <label>
                  <input onChange={(e) => changeValueDeliveryOption(e)} checked={SendOption === "DELIVERY"} value="DELIVERY" type="radio" name="deliveryOption" />
                  <span>{t('delivery')}</span>
                </label>
                <label>
                  <input onChange={(e) => changeValueDeliveryOption(e)} checked={SendOption === "PICKUP"} value="PICKUP" type="radio" name="deliveryOption" />
                  <span>{t('pickup')}</span>
                </label>
                {/* <label>
                  <input onChange={(e) => changeValueDeliveryOption(e)} checked={SendOption === "DINEIN"} value="DINEIN" type="radio" name="deliveryOption" />
                  <span>{t('dinein')}</span>
                </label> */}
              </div>
              {
                customerInformation.SendOption === "DELIVERY" ? (
                  <>
                    <div className={!_.isEmpty(checkOutError.ShippingFee) ? "checkout-input-div-0 input-warning" : "checkout-input-div-0"}>
                      {/* <select onChange={(e) => { handleChange(e) }} type="text" name="Area" value={!_.isEmpty(checkOutError.ShippingFee) ? "" : Area} id="area" > */}
                      <select onChange={(e) => { handleChange(e) }} type="text" name="Area" value={!_.isEmpty(checkOutError.ShippingFee) ? "" : Area} id="area" >
                        {/* <option value=""> {t('selectarea')} </option> */}
                        {
                          Object.keys(areaList).map((key, index) => {
                            const { Area } = areaList[key] 
                              return (
                                <option key={index} value={Area} > {Area} </option>
                              )                          
                          })
                        }
                      </select>
                    </div>
                    {
                      !_.isEmpty(checkOutError.ShippingFee) ? (<small className='error-message'>{checkOutError.ShippingFee}</small>) : (<></>)
                    }
                    <div className="checkout-input-div-2">
                      <input
                        // className={!_.isEmpty(loginError.Address) ? "input-warning" : ""}
                        autoComplete="none" type="text" placeholder={t('address')} onChange={(e) => { handleChange(e) }} name="Address" value={Address} id="addressto" />
                      {
                        !_.isEmpty(ishipeeSearch) ? (
                          <div className='ishipee-container'>
                            {
                              Object.keys(ishipeeSearch).map((key, index) => {
                                return (
                                  <button type="button" onClick={(e) => addressFillup(e, ishipeeSearch[key])} key={index}>{ishipeeSearch[key].description}</button>
                                )
                              })
                            }
                          </div>
                        ) : (
                          <></>
                        )

                      }
                    </div>
                  </>

                ) : (
                  <></>
                )
              }

              <div className="checkout-input-div-2">
                <input type="text" placeholder={t('note')} onChange={(e) => { handleNoteChange(e) }} value={OrderNote} name="OrderNote" />
              </div>

              {
                // !_.isEmpty(loginError.Address) ? (<small className='error-message'>{loginError.Address}</small>) : (<></>)
              }
            </div>

          </div>
          <div className="login-dialog-container-register-item">
            <div className="checkout-input">
              <label>{t('paymentdetails')}</label>

              <div className={!_.isEmpty(checkOutError.PaymentMode) ? "checkout-input-div-2 input-warning" : "checkout-input-div-2"} >
                {
                  _.isEmpty(paymentMethod) ? (
                    <></>
                  ) : (
                    Object.keys(paymentMethod).map((key, index) => {
                      const { Code, Name } = paymentMethod[key]
                      return (
                        <label key={index} className="payment-option-input">
                          <input
                            className="payment-option-input-radio"
                            type="radio"
                            value={Code}
                            name="paymentMethod"
                            onChange={(e) => { onChangePayment(e) }}
                            checked={customerInformation.PaymentMode === Code}
                          />
                          <span>{Name}</span>
                        </label>
                      )
                    })
                  )
                }
                <label className="payment-option-input disabled-input">
                  <input
                    className="payment-option-input-radio"
                    type="radio"
                    name="paymentoption"
                    disabled
                  />
                  <span>Vmart Wallet</span> <small>coming soon</small>
                </label>
              </div>
              {
                !_.isEmpty(checkOutError.PaymentMode) ? (<small className='error-message'>{checkOutError.PaymentMode}</small>) : (<></>)
              }
            </div>
          </div>
        </form>

        <div id="login-public" className="login-dialog-container-register ">
          <div className="big-padding-container">
            <div className="login-dialog-container-register-item">
              <h3>{t('welcomeback')}</h3>
            </div>
            <div className="login-dialog-container-register-item">
              <input
                onChange={(e) => {
                  handleInputLogin(e);
                }}
                type="text"
                name="Username"
                value={login.Username}
                placeholder={t('mobilenumber') + ", " + t('email')}
              />
            </div>
            <div className="login-dialog-container-register-item">
              <input
                onChange={(e) => {
                  handleInputLogin(e);
                }}
                type="password"
                name="Password"
                value={login.Password}
                placeholder={t('password')}
              />
            </div>
            <div className="login-dialog-container-register-item1 margin-bottom-5">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText={t('login')}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
              />
              <FacebookLogin
                appId={FACEBOOK_APP_ID}
                callback={responseFacebook}
              />
            </div>
            <div className="login-dialog-container-register-item">
              <button
                onClick={(e) => {
                  submitLogin(e);
                }}>
                {t('signin')}
              </button>
            </div>
            <a className='forgot-password-link' href='/forgot-password' target="_blank"> {t('forgotpassword')}</a>

          </div>
        </div>
      </div >
    </div >
  );
};

export default NotLoggedIn;


