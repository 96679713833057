import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";
import { HideItem, IdSelector, ShowItemFlex } from "../../ProductManager/productHelper";

const ExportOrderButton = ({ openExportModal }) => {
  const showTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItemFlex("#exportproduct");
  };
  const hideTextExport = () => {
    IdSelector("exportproduct").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#exportproduct");
    }, 500);
  };
  return (
    <div className="export-product">
      <div id="exportproduct">Export</div>
      <div
        className="icon"
        onMouseEnter={showTextExport}
        onMouseLeave={hideTextExport}
        onClick={
          (e) => {
            openExportModal(e)
          }
        }
      >
        <FileDownloadIcon sx={{ fontSize: "18px" }} />
      </div>
    </div>
  );
};
export default ExportOrderButton;
