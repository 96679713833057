import _ from 'lodash'; 
import AddIcon from "@mui/icons-material/Add"; 
import RemoveIcon from '@mui/icons-material/Remove';
import '../discount.scss'
import { useEffect } from "react";

const AddAmountFormula = ({ setRateDetails, rateDetails, addNewRows, deleteRows, coupon }) => {
  // console.log("rateDetails: ", rateDetails)
  // console.log("Coupons: ", coupon)
  useEffect(() => {
    if(!_.isEmpty(coupon.Discount.Rate)){ 

      setRateDetails((oldValue) => {
        return {
          ...oldValue,
          Rate: coupon.Discount.Rate
        };
      });
    } 
  }, [coupon]);

  const handleChange = (e, index) => {
    const { name, value } = e.target; 
    const values = [...rateDetails.Rate];
    if(name === "Condition"){
      values[index].Condition = value
    }
    else if(name === "Amount"){
      values[index].Amount = value
    }
    else{
      values[index].Rate = value
    } 
    setRateDetails({
      ...rateDetails,
      Rate: values  
    }); 
  };

  return (
    <div>  
      {
        _.map(rateDetails.Rate, (value, indx) => {
          let Condition = `Condition-${indx}`,
              Amount = `Amount-${indx}`,
              Rate = `Rate-${indx}`;
          return (
            <div className="coupon-table-data" key={indx}>
              <div className="input-coupon">
                <label htmlFor="Discount">Condition</label>
                <select 
                  data-id={indx}
                  name="Condition"
                  id={Condition} 
                  value={value.Condition || ""}
                  onChange={(e) => { 
                    handleChange(e,indx) 
                  }}
                >
                  <option value="<">{"<"}</option>
                  <option value="<=">{"<="}</option>
                  <option value=">">{">"}</option>
                  <option value=">=">{">="}</option>
                </select>                                 
              </div>
              <div className="input-coupon">
                <label htmlFor="Amount">Amount</label>
                <input 
                  data-id={indx}
                  type="text"
                  id={Amount}
                  name="Amount" 
                  value={value.Amount || ""}
                  onChange={(e) => { 
                    handleChange(e,indx) 
                  }}
                />
              </div>
              <div className="input-coupon">
                <label htmlFor="Amount">Fix/Percentage</label>
                <input 
                  data-id={indx}
                  type="text"
                  id={Rate}
                  name="Rate" 
                  value={value.Rate || ""}
                  onChange={(e) => { 
                    handleChange(e,indx) 
                  }}
                />
              </div>
              <div className="input-coupon">
                {indx === 0 ? (
                  <button 
                    type="button"
                    className='btn-add' 
                    onClick={(e) => addNewRows(e)}
                  >
                    <AddIcon sx={{ fontSize: "16px" }} />
                  </button> 
                ) : (
                  <button 
                    type="button"
                    className='btn-remove'  
                    onClick={(e) => deleteRows(e, value._id)}
                  >
                    <RemoveIcon sx={{ fontSize: "16px" }} />
                  </button> 
                )}
                
              </div>
            </div>
          ) 
        })
      }  
    </div>
  );
}; 

export default AddAmountFormula;
