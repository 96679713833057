import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Badge, createTheme } from "@mui/material";
import { withStyles } from '@mui/styles';
import i18n from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {HideItem, openPublicCart, ShowItemFlex} from "../../../pages/ProductManager/productHelper";
import { setToken } from "../../../redux/actions/token";
import cn from "./../../../assets/lang/cn.svg";
import en from "./../../../assets/lang/en.svg";
import th from "./../../../assets/lang/th.svg";
import vn from "./../../../assets/lang/vn.svg";
import SearchBar from "./SearchBar";
const TopNav = ({ cartCount, submitSearch, submitSearchNav, searchbar, dontAffect }) => {
  const history = useNavigate();
  const theme = createTheme();
  const { t } = useTranslation();
  const styles = () => ({
    margin: {
      margin: theme.spacing(1.2),
    },
    customBadge: {
      backgroundColor: (props) => props.color,
      color: "white",
      fontSize: "10px",
      width: "20px",
      height: "20px",
      top: "6px",
      right: "3px",
    },
  });
  function SimpleBadge(props) {
    const { classes } = props;
    return (
      <div style={{ cursor: "pointer" }} onClick={(e) => { openCart(e) }}>
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.margin}
          badgeContent={cartCount}
        >
          <ShoppingCartOutlinedIcon sx={{ color: "#fff" }} />
        </Badge>
        <span>{t('cart')}</span>
      </div>
    );
  }
  const openCart = (e) => {
    e.preventDefault()
    // dontAffect(e)
    e.stopPropagation();

    openPublicCart()
  }
  const StyledBadge = withStyles(styles)(SimpleBadge);
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { publicToken, lang } = tokens.token;
  let user;
  const [language, setLanguage] = useState("");
  const [flag, setFLag] = useState(null);
  let toggle = false;
  const node = useRef(null); //this is used for targetting clicking outside the language option
  const nod1 = useRef(null); //this is used for targetting clicking outside the language option
  const languageOption = document.getElementById("language-options");
  if (publicToken) {
    user = jwt.decode(publicToken)
  }
  const dispatch = useDispatch();
  //USEEFFECT INITIAL STATE
  useEffect(() => {
    languageIdentify()
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }

      if (nod1.current.contains(e.target)) {
        return;
      }
      document.getElementById("language-options").style.display = "none";
      document.getElementById("user-option").style.display = "none";
    };
    document.getElementById("language-options").style.display = "none";
    document.getElementById("user-option").style.display = "none";
    document.addEventListener("mousedown", handleClick);
    return () => {
      languageIdentify()
      document.removeEventListener("mousedown", handleClick);
    };
  }, [setLanguage, publicToken, lang]);
  //show login form
  const showLogin = (e) => {
    e.preventDefault()
    if (publicToken) {
      const userOption = document.querySelector(".topnav-user-option");

      const display = window.getComputedStyle(userOption).display;
      if (display === "flex") {
        HideItem(".topnav-user-option")
      } else {
        ShowItemFlex(".topnav-user-option")
      }

    } else {
      ShowItemFlex(".login-dialog")
    }

  }

  //initialize language
  const languageIdentify = () => {
    let lang = localStorage.getItem("language") ?? "EN"
    setLanguage(lang);
    i18n.changeLanguage(lang)
    lang === "EN"
      ? setFLag(en)
      : lang === "VN"
        ? setFLag(vn)
        : lang === "CN"
          ? setFLag(cn)
          : setFLag(th);
  }

  //TOGGLE LANGUAGE BUTTON
  const toggleLanguage = () => {
    toggle = !toggle;
    toggle
      ? (languageOption.style.display = "flex")
      : (languageOption.style.display = "none");
  };

  //CHOOSE LANGUAGE IN OPTION

  const changeLanguage = (lang) => {

    i18n.changeLanguage(language)
    dispatch(setToken({ lang: lang }));
    localStorage.setItem("language", lang)
    toggle = !toggle;
    setLanguage(lang);
    lang === "EN"
      ? setFLag(en)
      : lang === "VN"
        ? setFLag(vn)
        : lang === "CN"
          ? setFLag(cn)
          : setFLag(th);
    toggle
      ? (languageOption.style.display = "flex")
      : (languageOption.style.display = "none");
  };
  const logout = (e) => {
    e.preventDefault()
    localStorage.removeItem("publicToken");
    dispatch(setToken({ publicToken: "" }));
  }
  const gotoAccount = (e) => {
    e.preventDefault()
    history(`/myaccount/`)
  }
  //const [showSearchBar, setShowSearchbar] = useState(searchbar ?? true)
  let showSearchBar = searchbar ?? true
  return (
    <div className="topnav">
      {
        showSearchBar ? <SearchBar submitSearch={submitSearchNav} /> : <></>
      }

      <div className="topnav-item cart">
        <StyledBadge color="#eaa460" />
      </div>
      <div className="topnav-item user-account" style={{ cursor: "pointer" }} onClick={(e) => {
        showLogin(e)
      }}>
        <AccountCircleOutlinedIcon fontSize="medium" />
        <span>{user ? user.FirstName : t('signin/signup')}</span>
      </div>
      <div className="topnav-item">
        <button onClick={toggleLanguage}>
          {language}
          <img src={flag} width="20" alt="language" />
        </button>
      </div>

      <div ref={nod1} className="language-option" id="language-options">
        <div
          className="language-option-item"
          onClick={() => changeLanguage("EN")}
        >
          EN <img src={en} width="20" alt="language" />
        </div>

        <div
          className="language-option-item"
          onClick={() => changeLanguage("VN")}
        >
          VN <img src={vn} width="20" alt="language" />
        </div>

        {/* <div
          className="language-option-item"
          onClick={() => changeLanguage("CN")}
        >
          CN <img src={cn} width="20" alt="language" />
        </div>

        <div
          className="language-option-item"
          onClick={() => changeLanguage("TH")}
        >
          TH <img src={th} width="20" alt="language" />
        </div> */}
      </div>

      <div ref={node} id="user-option" className="topnav-user-option">
        <button className="button" onClick={(e) => {
          gotoAccount(e)
        }
        }>{t('myaccount')}</button>
        <button className="button" onClick={(e) => logout(e)}>{t('logout')}</button>
      </div>
    </div>
  );
};

export default TopNav;
