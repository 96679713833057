import RoomIcon from "@mui/icons-material/Room";
import React, { useEffect, useState } from "react";
import { GetPublicBranches } from "../PublicHelper";
const BranchList = () => {
  let branchid = localStorage.getItem("publicBranch");
  const [branches, setBranches] = useState("")
  useEffect(() => {

    GetPublicBranches()
      .then(res => {
        const { paginatedResults } = res.data
        let index = Object.keys(paginatedResults).filter(key => {
          const { _id } = paginatedResults[key]
          return _id === branchid
        })
        if (branchid) {
          setBranches(paginatedResults[index].CityAddress)
        } else {
          setBranches("")
        }
      })
      .catch(err => err.response);

    return () => {

    }
  }, [branchid])

  return (
    <div className="branch-list text-sky-500" onClick={
      () => {
        document.querySelector(".branch-dialog-selection").style.display = "flex"
      }}>
      <RoomIcon sx={{ color: "#50311b" }} />
      {branches}
    </div>
  );
};

export default BranchList;
