import RemoveIcon from '@mui/icons-material/Remove';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {closePublicCart} from '../../pages/ProductManager/productHelper';
import CartItem from './CartItem';
import { DeleteCart } from './PublicHelper';
import moment from 'moment';
const Cart = ({ cartList, publicToken, checkOutCart, UpdateCartCustomer, newList, cartId, removeAllItemsOnCart, DeleteOnCART, setCartCount }) => {
  const [productList, setProductList] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const { t } = useTranslation()
  useEffect(() => {
    setProductList(cartList)
    totalAmountComputer()
    return () => {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartList, publicToken, productList])

  const quantitySubtract = (e, id) => {
    e.preventDefault()
    let index = Object.keys(productList).filter(key => {
      return productList[key].ProductId === id
    })
    const { Quantity, Price, SaleDateFrom, SaleDateTo, SalePrice } = productList[index[0]]
    if (Quantity === 1) {
      // do nothing
    } else if (Quantity > 1) {
      let realPrice = showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price)
      let newQuantity = Quantity - 1
      let totalPrice = newQuantity * realPrice
      totalAmountComputer()
      setProductList(newList => {
        newList[index].Quantity = newQuantity
        newList[index].TotalPrice = totalPrice
        return {
          ...productList,
          [index]: newList[index],
        }
      }
      )
    }
    if (_.isEmpty(publicToken)) {
      localStorage.setItem("publicCart", JSON.stringify(productList));
    } else {
      UpdateCartCustomer(productList)
    }
  }

  const showPrice = (SaleDateFrom, SaleDateTo, SalePrice, Price) => {
    const isBetween = moment().tz( 'Asia/Manila' ).isBetween( SaleDateFrom, SaleDateTo )
    if (isBetween && SalePrice !== 0) {
      return SalePrice;
    } else {
      return Price?.Regular;
    }
  }
  const quantityAdd = (e, id) => {
    e.preventDefault()
    let index = Object.keys(productList).filter(key => {
      return productList[key].ProductId === id
    })
    const { Quantity, Price, SaleDateFrom, SaleDateTo, SalePrice } = productList[index[0]]
    let realPrice = showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price)
    let newQuantity = Quantity + 1
    let totalPrice = newQuantity * realPrice
    totalAmountComputer()
    setProductList(newList => {
      newList[index].Quantity = newQuantity
      newList[index].TotalPrice = totalPrice
      return {
        ...productList,
        [index]: newList[index],
      }
    }
    )
    if (_.isEmpty(publicToken)) {
      localStorage.setItem("publicCart", JSON.stringify(productList));
    } else {
      UpdateCartCustomer(productList)
    }
  }
  const totalAmountComputer = () => {
    let total = 0;
    Object.keys(productList).forEach(key => {
      const { TotalPrice } = productList[key]
      total += TotalPrice
    })
    setTotalAmount(total)

  }
  const checkOutProduct = (e) => {
    e.preventDefault()
    checkOutCart(e, productList, totalAmount)
  }
  const removeAllItemOnCart = (e) => {
    e.preventDefault()
    console.log(cartId);
    removeAllItemsOnCart(e)
    if (publicToken) {
      DeleteCart(cartId).then(
        res => {
          console.log(res)
        }
      ).catch(err => {
        console.log(err.response);
      })
    }
  }
  const removeItemOnCart = (e, productId, index) => {
    e.preventDefault()
    let newProduct = []
    newProduct = productList;
    newProduct = _.filter(newProduct, (value) => {
      return value.ProductId !== productId
    });
    if (productList.length <= 1) {
      DeleteOnCART()
      // DeleteCart(cartId).then(
      //   res => {
      //     setCartCount(0)
      //   }
      // ).catch(err => {
      //   console.log(err.response);
      // })
    } else {
      UpdateCartCustomer(newProduct)
    }
    totalAmountComputer()
    setProductList(newProduct)
    newList(newProduct)
  }
  return (
    <div className="public-cart" onClick={(e) => { e.stopPropagation(); }}>
      <div className="public-cart-navigation">
        <button
          onClick={() => { closePublicCart() }}>
          <RemoveIcon />
        </button>
        <button onClick={(e) => {
          removeAllItemOnCart(e)
        }}>{t('removeall')}</button>
      </div>
      <div className="public-cart-list-container">
        <div className="public-cart-list">
          {
            productList.length ? (
                Object.keys(productList).map((key, index) => {
                  let ProductItem = productList[key]
                  return (
                      <CartItem key={index} {...{ ProductItem, quantitySubtract, quantityAdd, removeItemOnCart, index }} />
                  )
                })
            ) : <div className='flex-row-center-center padding-top-30'>{t('noitemoncart')}</div>
          }
        </div>
      </div>
      <div className="public-cart-footer">
        <div className="public-cart-footer-left">
          <span className="subtotal">{t('subtotal')}: </span>
          <span className="price"> ₱{totalAmount}</span>
        </div>
        <div className="public-cart-footer-right">
          <button onClick={(e) => {
            checkOutProduct(e)
          }}>{t('checkout')}</button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
