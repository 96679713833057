import style from "../../OrderDineIn.module.scss"
import {Button} from "react-bootstrap"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom"
import React, {useEffect, useState} from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {useTranslation} from "react-i18next";
import {getCurrentBranch, getCurrentUser} from "../../helper";
import Language from "./Language";

const Navigation = () => {
    const navigate = useNavigate();

    const [Branch, setBranch] = useState({
        City: "",
        Name: ""
    })

    const NavigateToMainCMS = () => {
        navigate("/admin/cms")
    }

    const {t} = useTranslation()

    const onClickBack = () => {
        Swal.fire({
            title: t("areYouSure"),
            text: t("redirectToAdminPage"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.isConfirmed) {
                NavigateToMainCMS()
            }
        })
    }

    const handleBranch = ({Branches, SelectedBranch}, branchId) => {
        const currentBranch = Branches.filter((branch) => branch.BranchId === branchId);
        if (!currentBranch || !SelectedBranch) {
            Swal.fire({
                title: t("branchIsRequired"),
                text: t("selectBranchMessage"),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.isConfirmed) {
                    NavigateToMainCMS()
                }
            })
        } else {
            setBranch({City: currentBranch[0].City, Name: currentBranch[0].Name});
        }
    }

    useEffect(() => {
        const branchId = getCurrentBranch()
        const user = getCurrentUser();
        handleBranch(user, branchId)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={style.navigation}>
            <Button onClick={onClickBack} size="sm" className={style.back}>
                <ArrowBackIcon/>
            </Button>
            <div className={style.branch}>
                <FmdGoodIcon/>
                <span className={style.branchName}>{Branch.Name} ~ {Branch.City}</span>
                <Language />
            </div>
        </div>
    )
}

export default Navigation