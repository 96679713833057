import { Pagination } from "@mui/material"
import _ from "lodash"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { useTitle } from "react-use"
import Swal from "sweetalert2"
import { countPage } from "../../ProductManager/productHelper"
import AnalyticsFilter from "../AnalyticsFilter"
import { GetSalesReport } from '../analyticsHelper'
import ExportButton from "../ExportButton"
import ExportModalSales from "../Exports/ExportModalSales"
import AnalyticsCardList from './AnalyticsCardList.js'
import AnalyticsCharts from './AnalyticsCharts'
import AnalyticsPie from "./AnalyticsPie"
import AnalyticsTableProduct from "./AnalyticsTableProduct"
import SalesModalContent from './SalesModalContent'
import SalesPerPaymentMethods from "./SalesPerPaymentMethod"
import { defaultSales } from './salesutils'
const SalesReport = () => {
  useTitle("Pao Kitchen | Sales Report")
  const [overView, setOverView] = useState({
    from: "",
    to: ""
  })
  const [salesReport, setSalesReport] = useState(defaultSales)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [salesModal, setSalesModal] = useState(false)
  const [exportDates, setExportDates] = useState({
    DateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    DateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
  })
  const ITEM_PER_PAGE = 10

  useEffect(() => {
    getSalesReport(page, {})
    return () => {

    }
    //eslint-disable-next-line
  }, [page])
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getSalesReport(p, exportDates)
  }

  const getSalesReport = (page, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setSalesReport(defaultSales)
    setPageCount(0)
    GetSalesReport(page, {...searchValue, limit: ITEM_PER_PAGE})
      .then(
        res => {
          Swal.close()
          const { paginatedResults, metadata } = res?.data
          const { total, limit } = metadata;
          setPageCount(countPage(total, limit))
          setChartControl(paginatedResults)
          setSalesReport(res?.data);
          console.log("SALES REPORT", res?.data);
        }
      )
      .catch(
        err => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No sales report(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
          setSalesReport(defaultSales)
        }
      )
  }

  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { DateFrom, DateTo } = searchValue
    if (_.isEmpty(DateFrom) || _.isEmpty(DateTo)) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please fill up all required inputs",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    } else {
      if (moment(DateFrom).tz("Asia/Manila").isAfter(DateTo)) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Date From field must be earlier than Date to field",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      } else {
        setOverView({
          from: moment(DateFrom || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
          to: moment(DateTo || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
        })
        setExportDates(
          {
            DateFrom: moment(DateFrom || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
            DateTo: moment(DateTo || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
          }
        )
        getSalesReport(1, searchValue)
      }
    }
  }
  const [chartControl, setChartControl] = useState([])

  const clearFilter = (e) => {
    e.preventDefault()
    getSalesReport(1, {})
    setExportDates({
      DateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
      DateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    })
  }
  //eslint-disable-next-line
  const { Card, SalesPerPaymentMethod, paginatedResults } = salesReport
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        analytics / sales report
      </section>
      <section className="content-product-subtitle ">
        OVERVIEW : {_.isEmpty(overView.from) || _.isEmpty(overView.to) ? moment().tz("Asia/Manila").format("MMM DD, YYYY (dddd)") : `${overView.from} ~ ${overView.to}`}
      </section>
      <AnalyticsFilter {...{ productFilter, clearFilter }} />
      <div className="flex-column-start gap-30 width-100">
        <AnalyticsCardList {...Card} />
        {
          _.isEmpty(paginatedResults) ? <></> :
            <>
              <AnalyticsCharts chartData={chartControl} />
              <AnalyticsTableProduct tableContents={paginatedResults} />
              {
                pageCount === 0 ? <></> :
                  <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
              }

            </>
        }
        <AnalyticsPie chartData={SalesPerPaymentMethod} />
        <SalesPerPaymentMethods chartData={SalesPerPaymentMethod} />

        <ExportButton tooltip="Export Sales Button" openExportModal={(e) => {
          e.preventDefault()
          setSalesModal(true)
        }} />
        <ExportModalSales closeExportModalEdit={() => {
          setSalesModal(false)
        }} exportModalEdit={salesModal}
          modalContent={<SalesModalContent dateFrom={exportDates.DateFrom} dateTo={exportDates.DateTo} />}
        />
      </div>



    </main>
  )
}
export default SalesReport