import _ from "lodash";
import { useEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const BranchesTable = ({ tableContents }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "branchTable"

    return () => {

    }
  }, [])
  return (
    <ScrollContainer className="content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal normal-start item-name">BRANCH</div>
        <div className="content-product-table-head-item big-item  item-name">FULL ADDR.</div>
        <div className="content-product-table-head-item normal normal-start item-name">CONTACT NUMBERS</div>
        <div className="content-product-table-head-item normal normal-start item-name">COURIERS</div>
        <div className="content-product-table-head-item normal normal-start item-name">OTHER INFOS</div>
        <div className="content-product-table-head-item center item-name">ACTIONS</div>
      </div>
      {
        !_.isEmpty(tableContents) ? (
          Object.keys(tableContents).map((key, index) => {

            return (
              <div className="content-product-table-data" key={index}>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item big-item  item-name"></div>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item center item-name"></div>
              </div>
            )
          })
        ) : (
          <div className="content-product-table-data">
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item big-item  item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name">No Branche(s) Found!</div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item center item-name"></div>
          </div>
        )}
    </ScrollContainer >
  )
};

BranchesTable.defaultProps = {
  tableContents: []
}

export default BranchesTable;
