import React, {useState} from "react";
import css from "./OrderDineIn.module.scss"
import Header from "./components/Header/index";
import Body from "./components/Body";

const OrderDineIn = () => {
    const [orderSelected, setOrderSelected] = useState(null)
    const onChangeOrder = (order = null) => {
        setOrderSelected(order)
    }

    return (
        <main className={css.main}>
            <Header onChangeOrder={onChangeOrder} orderSelected={orderSelected} />
            <Body orderSelected={orderSelected} updateOrder={onChangeOrder} />
        </main>
    )
}

export default OrderDineIn