import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Tooltip } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { countPage, GetCategoryList, GetGroupCategoryList, GetSubCategoryList } from '../../productHelper';
const SearchFilterProduct = ({ productFilter, clearFilter }) => {
  const [searchItem, setSearchItem] = useState({
    search: "",
    GroupCategory: "",
    Category: "",
    Subcategory: "",
    type: "",
    stocks: "",
    sort: "",
    sortValue: "",
    IfFeatured: "",
    IsAvailable: "",
  })
  useEffect(() => {
    setDropDowns({
      groupCategory: [],
      category: [],
      subCategory: []
    })
    getGroupCategory(1)
    getCategory(1)
    getSubCategory(1)
    return () => {
      setDropDowns({
        groupCategory: [],
        category: [],
        subCategory: []
      })
    }
    // eslint-disable-next-line
  }, [])
  const [filters, setFilters] = useState({})
  const [showContainer, setShowContainer] = useState(false)
  const [dropdowns, setDropDowns] = useState({
    groupCategory: [],
    category: [],
    subCategory: []
  })
  const getGroupCategory = (pages) => {
    GetGroupCategoryList(pages, "")
      .then(
        res => {
          const { paginatedResults, metadata } = res.data
          const { page, total, limit } = metadata
          let nextPage = countPage(total, limit)
          setDropDowns(dropdown => {
            return {
              ...dropdown,
              groupCategory: [...dropdown.groupCategory, ...paginatedResults]
            }
          })
          if (nextPage !== page && nextPage !== 0) {
            let newPage = page + 1
            getGroupCategory(newPage)
          }
        }
      )
  }
  const getCategory = (pages) => {
    GetCategoryList(pages, "")
      .then(
        res => {
          const { paginatedResults, metadata } = res.data
          const { page, total, limit } = metadata
          let nextPage = countPage(total, limit)
          setDropDowns(dropdown => {
            return {
              ...dropdown,
              category: [...dropdown.category, ...paginatedResults]
            }
          })
          if (nextPage !== page && nextPage !== 0) {
            let newPage = page + 1
            getCategory(newPage)
          }
        }
      )
  }

  const getSubCategory = (pages) => {
    GetSubCategoryList(pages, "")
      .then(
        res => {
          const { paginatedResults, metadata } = res.data
          const { page, total, limit } = metadata
          let nextPage = countPage(total, limit)
          setDropDowns(dropdown => {
            return {
              ...dropdown,
              subCategory: [...dropdown.subCategory, ...paginatedResults]
            }
          })
          if (nextPage !== page && nextPage !== 0) {
            let newPage = page + 1
            getSubCategory(newPage)
          }
        }
      )
  }

  const fillFilter = (searchItem) => {
    setFilters({})
    _.forEach(searchItem, (value, key) => {
      //const { search, GroupCategory, Category, Subcategory, type, stocks, sort, sortValue, IfFeatured, IsAvailable } = value
      if (!_.isEmpty(value)) {
        setFilters(filter => {
          return {
            ...filter,
            [key]: value
          }
        })
      }
    })
  }
  const removeFilter = (e, keyPass) => {
    e.preventDefault()
    let newItem = {
      ...searchItem,
      [keyPass]: ""
    }
    fillFilter(newItem)
    setSearchItem(newItem)
    productFilter(e, newItem)

  }
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])
  const clearItem = (e) => {
    let newItem = {
      search: "",
      GroupCategory: "",
      Category: "",
      Subcategory: "",
      type: "",
      stocks: "",
      sort: "",
      sortValue: "",
      IfFeatured: "",
      IsAvailable: "",
    }
    setSearchItem(newItem)
    fillFilter(newItem)
    productFilter(e, newItem)
  }
  const { groupCategory, category, subCategory } = dropdowns
  return (
    <section className="content-main-search">
      <div className="flex-column-start width-100 gap-5 content-main-search-filter">
        <div className="flex-row-start gap-10">
          <form onSubmit={(e) => {
            fillFilter(searchItem)
            productFilter(e, searchItem)
          }} className="content-main-search-item">
            <label htmlFor="search">Keyword / Barcode / SKU</label>
            <input autoComplete='off' type="text" name="search" value={searchItem.search} onChange={(e) => { handleChange(e) }} />
          </form>

          <button className="flex-row-center-center gap-5" onClick={() => {
            setShowContainer(!showContainer)
          }}>
            <FilterAltIcon /> Filters
          </button>
        </div>
        <Collapse sx={{ width: "100%" }} in={showContainer}>
          <div className="filter-container border-top-dashed padding-top-10 " id='productListFilter'>
            <div className='flex-row-start-start flex-wrap width-100 gap-10'>
              <div className="content-main-search-item">
                <label htmlFor="GroupCategory">Group Category</label>
                <select name="GroupCategory" id="GroupCategory" value={searchItem.GroupCategory} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  {
                    _.map(groupCategory, (value, key) => {
                      const { _id, Name } = value
                      let name = Name?.EN || Name?.VN || Name?.CN || Name?.TH
                      return (
                        <option key={key} value={_id} > {name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="Category">Category</label>
                <select name="Category" id="Category" value={searchItem.Category} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  {
                    _.map(category, (value, key) => {
                      const { _id, Name } = value
                      let name = Name?.EN || Name?.VN || Name?.CN || Name?.TH
                      return (
                        <option key={key} value={_id} > {name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="Subcategory">Subcategory</label>
                <select name="Subcategory" id="Subcategory" value={searchItem.Subcategory} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  {
                    _.map(subCategory, (value, key) => {
                      const { _id, Name } = value
                      let name = Name?.EN || Name?.VN || Name?.CN || Name?.TH
                      return (
                        <option key={key} value={_id} > {name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="type">Type</label>
                <select name="type" id="type" value={searchItem.type}
                  onChange={(e) => {
                    handleChange(e)
                  }}>
                  <option value="">All</option>
                  <option value="Single">Single</option>
                  <option value="Group">Group</option>
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="stocks">Stocks</label>
                <select name="stocks" id="stocks" value={searchItem.stocks} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value={true}>In stock</option>
                  <option value={false}>Out of stock</option>
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="sort">Sort by</label>
                <select name="sort" id="sort" value={searchItem.sort} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value="Price">Price</option>
                  <option value="Alphabet">Alphabet</option>
                  <option value="Stocks">Stocks</option>
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="sortValue">Sort Order</label>
                <select name="sortValue" id="sortValue" value={searchItem.sortValue} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="IfFeatured">Featured</label>
                <select name="IfFeatured" id="IfFeatured" value={searchItem.IfFeatured} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <div className="content-main-search-item">
                <label htmlFor="IsAvailable">Status</label>
                <select name="IsAvailable" id="IsAvailable" value={searchItem.IsAvailable} onChange={(e) => { handleChange(e) }}>
                  <option value="">All</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>

            </div>
            <div className="flex-row-end gap-10 margin-top-20">
              <Tooltip title="Hide or Cancel filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5 skeleton-button"
                  onClick={(e) => {
                    setShowContainer(false)
                  }}
                >
                  Hide / Cancel
                </button>
              </Tooltip>
              <Tooltip title="Apply Filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5"
                  onClick={(e) => {
                    fillFilter(searchItem)
                    productFilter(e, searchItem)
                  }}
                >
                  Apply Filters
                </button>
              </Tooltip>
            </div>

          </div>
        </Collapse>
        {
          _.isEmpty(filters) ? <></> : (
            <div className="filter-list">
              Active filters: {
                _.map(filters, (value, key) => {
                  return (
                    //<div key={key}></div>
                    <span key={key}>{key === "search" ? value : key}
                      <button onClick={(e) => { removeFilter(e, key) }}>
                        <CloseIcon sx={{ fontSize: "16px", color: "#7e4e2b", "&:hover": { color: "red" } }} />
                      </button>
                    </span>
                  )
                })
              }
              <button className="flex-row-center-center gap-5 skeleton-button"
                onClick={(e) => {
                  clearItem(e)
                }}
              >
                clear all
              </button>
            </div>
          )
        }

      </div >

    </section >
  )
};

export default SearchFilterProduct;
