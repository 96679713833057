import React from "react";
import BranchList from "./BranchList";
import TopNav from "./TopNav";

const TopBar = ({ cartCount, submitSearchNav, searchbar, dontAffect }) => {
  return (
    <div className="topbar">
      <BranchList />
      <TopNav {...{ cartCount, submitSearchNav, searchbar, dontAffect }} />
    </div>
  );
};

export default TopBar;
