import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { HideItem, IdSelector, ShowItem } from "./../productHelper";
const AddProductButton = () => {
  const showTextAdd = () => {
    IdSelector("addproduct").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#addproduct");
  };
  const hideTextAdd = () => {
    IdSelector("addproduct").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#addproduct");
    }, 500);
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className="add-product">
      <div id="addproduct">Add Product</div>
      <div
        className="icon"
        onMouseEnter={showTextAdd}
        onMouseLeave={hideTextAdd}
        onClick={() => openInNewTab("/admin/cms/product-list/new")}
      >
        <AddIcon sx={{ fontSize: "18px" }} />
      </div>
    </div>
  );
};

export default AddProductButton;
