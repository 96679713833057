import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Logo from '../../reusable/Logo';
import messenger from './../../../assets/footer/messenger.svg';
import zalo from './../../../assets/footer/zalo.svg';
import fb_messenger from './../../../assets/footer/fb-messenger.svg';
import { GetContactListWeb } from '../PublicHelper';
import _ from 'lodash';

const Footer = () => {
  const { t } = useTranslation()
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    getContactList(true)
    return () => {}
  }, [])
  

  const openInNewTab = (e, url) => {
    e.preventDefault()
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const getContactList = (search) => {
    GetContactListWeb(search).then((res) => {
      console.log("footer:", res?.data)
      const { paginatedResults } = res?.data;

      let toPush = [];

      Object.keys(paginatedResults).forEach((key) => {
        const { _id, Link, Type, IsActive } = paginatedResults[key];
        toPush.push({
          id: _id,
          link: Link,
          type: Type,
          isActive: IsActive,
        });
      });
      setContactList(toPush)
    }).catch((error) => {
      setContactList([])
    })
  }
  return (
    <footer>
      <div className="footer">
        <div className='flex-row-center-center width-30 footer-logo' >
          <Logo />
        </div>
        <div className="footer-container">
          <div className="footer-container-item">
            <h2>{t('introduction')}</h2>
            <div className="footer-text">
              <span>
                {t('introbody')}
              </span>
            </div>
          </div>
          <div className="footer-container-item">
            <h2>{t('contact')}</h2>
            <div className="footer-text">
              <span className='cursor-pointer' onClick={(e) => openInNewTab(e, "https://goo.gl/maps/qFhdbqRnFfBfcxT37")}>Pao Cafe, G/F, Ags Building, 174 Yakal, Makati, 1203 Metro Manila</span>
              <a href="tel:09998286888">0999 828 6888</a>
              <a href="tel:09602472525">0960 247 2525</a>
              <a href="mailto:business@paokitchen.ph">business@paokitchen.ph</a>
              <div className="imagelist">
              {!_.isEmpty(contactList) ? (
                Object.keys(contactList).map((key, index) => {
                  const { id, link, type, isActive } = contactList[key]
                  if (type === "Telegram") {
                    return (
                      <img className='cursor-pointer' src={messenger} alt="messenger" width={41} height={41} onClick={(e) => openInNewTab(e, link)} />
                    )
                  } else if (type === "Messenger") {
                     return (
                      <img className='cursor-pointer' src={fb_messenger} alt="messenger" width={41} height={41} onClick={(e) => openInNewTab(e, link)} />
                     )
                  }else {
                    return (
                      <img className='cursor-pointer' src={zalo} alt="zalo" width={39} height={39} onClick={(e) => openInNewTab(e, link)} />
                    )
                  }
                })
                ) : (
                  <div></div>
                )
              }
                {/* <img className='cursor-pointer' src={zalo} alt="zalo" width={41} height={41} />
                <img className='cursor-pointer' src={messenger} alt="messenger" width={49} height={49} onClick={(e) => openInNewTab(e, "https://t.me/pao_kitchen")} /> */}
                <h3>@pao_kitchen</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
