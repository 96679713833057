import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { GetCategoryListWithSubcategory, HideItem, ShowItemFlex } from "../productHelper";
const Categorization = ({ groupCategoryChoice, groupCategoryLists, closeForm, categoryChoice, subcategoryChoice, subCategoryListTemp }) => {

  const [groupCategory, setGroupCategory] = useState()
  const [checkedGroupCategory, setCheckedGroupCategory] = useState()

  const [subcategoryList, SetSubcategoryList] = useState([])
  let toPush = []
  let categoryArray = []
  let subcategoryArray = []
  useEffect(() => {
    setGroupCategory(groupCategoryLists)

    return () => {

    }
  }, [groupCategoryLists])
  const addOrRemoveGroupCategory = (i) => (event) => {
    let letThrough = true;
    toPush = [] //group category list

    Object.keys(groupCategory.groupCategoryList).forEach(key => {
      const { isCheck, id } = groupCategory.groupCategoryList[key]
      if (isCheck) {
        toPush.push(id)
      }
    })
    setGroupCategory((state) => {
      state.groupCategoryList[i].isCheck = !state.groupCategoryList[i].isCheck;
      if (state.groupCategoryList[i].isCheck) {
        for (let x = 0; x < toPush.length; x++) {
          if (state.groupCategoryList[i].id === toPush[x]) {
            letThrough = false;
            break;
          }
          letThrough = true;
        }
        if (letThrough === true) {
          toPush.push(state.groupCategoryList[i].id)
        }
      } else {
        Object.keys(state.groupCategoryList[i].categories).forEach(
          keys => {
            const { _id } = state.groupCategoryList[i].categories[keys]
            Object.keys(categoryArray).forEach(
              key => {
                if (categoryArray[key] === _id) {
                  const ind = categoryArray.indexOf(categoryArray[key]);
                  if (index > -1) {
                    categoryArray.splice(ind, 1); // 2nd parameter means remove one item only
                  }
                }
              }
            )
          }
        )
        const index = toPush.indexOf(state.groupCategoryList[i].id);
        if (index > -1) {
          toPush.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      console.log({
        groupCategoryList: state.groupCategoryList,
      });
      return {
        groupCategoryList: state.groupCategoryList,
      };
    });
  };
  const addOrRemoveCategory = (x, y) => (event) => {
    let letThrough = true;
    categoryArray = [] // category list
    Object.keys(checkedGroupCategory).forEach(key => {
      const { categories } = checkedGroupCategory[key]
      Object.keys(categories).forEach(keys => {
        const { _id, isCheck } = categories[keys]
        if (isCheck) {
          categoryArray.push(_id)
        }
      })
    })

    setCheckedGroupCategory((state) => {
      state[x].categories[y].isCheck = !state[x].categories[y].isCheck;
      if (state[x].categories[y].isCheck) {
        for (let z = 0; z < categoryArray.length; z++) {
          if (state[x].categories[y]._id === categoryArray[x]) {
            letThrough = false;
            break;
          }
          letThrough = true;
        }
        if (letThrough === true) {
          categoryArray.push(state[x].categories[y]._id)
        }
      } else {

        const index = categoryArray.indexOf(state[x].categories[y]._id);
        if (index > -1) {
          categoryArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      return {
        ...state,
        [x]: state[x],
      };
    });

  }
  const addOrRemoveSubcategory = (x, y) => (event) => {
    let letThrough = true;
    subcategoryArray = [] // sub category list
    Object.keys(subcategoryList).forEach(key => {
      const { subcategories } = subcategoryList[key]
      Object.keys(subcategories).forEach(keys => {
        const { sid, sIsCheck } = subcategories[keys]
        if (sIsCheck) {
          subcategoryArray.push(sid)
        }
      })
    })
    SetSubcategoryList((state) => {
      state[x].subcategories[y].sIsCheck = !state[x].subcategories[y].sIsCheck;
      if (state[x].subcategories[y].sIsCheck) {
        for (let z = 0; z < subcategoryArray.length; z++) {
          if (state[x].subcategories[y].sid === subcategoryArray[x]) {
            letThrough = false;
            break;
          }
          letThrough = true;
        }
        if (letThrough === true) {
          subcategoryArray.push(state[x].subcategories[y].sid)
        }
      } else {

        const index = subcategoryArray.indexOf(state[x].subcategories[y].sid);
        if (index > -1) {
          subcategoryArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }

      return {
        ...state,
        [x]: state[x],
      };
    });

  }
  const proceedGroup = (e) => {
    toPush = []
    Object.keys(groupCategory.groupCategoryList).forEach(key => {
      const { isCheck, id } = groupCategory.groupCategoryList[key]
      if (isCheck) {
        toPush.push(id)
      }
    })

    groupCategoryChoice(toPush, e)
    let checkedGroup = []
    Object.keys(toPush).forEach((keyss) => {
      let arrayIndex = Object.keys(groupCategoryLists.groupCategoryList).filter((key) => {
        const { id } = groupCategoryLists.groupCategoryList[key]
        return id === toPush[keyss];
      });

      checkedGroup.push(groupCategoryLists.groupCategoryList[arrayIndex[0]])
    })
    setCheckedGroupCategory(checkedGroup)

    if (checkedGroup.length) {
      HideItem("#groupcategorycardlist")
      ShowItemFlex("#categorycardlist");
    } else {
      //if empty list
    }
  }
  const backtogroup = (e) => {
    e.preventDefault()
    ShowItemFlex("#groupcategorycardlist")
    HideItem("#categorycardlist");
  }
  const backtocategory = (e) => {
    e.preventDefault()
    ShowItemFlex("#categorycardlist")
    HideItem("#subcategorycardlist");
    SetSubcategoryList([])
  }


  const proceedCategory = (e) => {
    e.preventDefault()
    categoryArray = [] // category list
    Object.keys(checkedGroupCategory).forEach(key => {
      const { categories } = checkedGroupCategory[key]
      Object.keys(categories).forEach(keys => {
        const { _id, isCheck } = categories[keys]
        if (isCheck) {
          categoryArray.push(_id)
        }
      })
    })
    SetSubcategoryList([])
    GetCategoryListWithSubcategory()
      .then(res => {
        const { paginatedResults } = res.data
        Object.keys(paginatedResults).forEach(key => {
          const { _id, Name, SubCategories } = paginatedResults[key]
          let arrayIndex = Object.keys(categoryArray).filter((keys) => {
            return categoryArray[keys] === _id;
          });
          if (categoryArray[arrayIndex] === _id) {
            let subCategoryPush = []
            let checkSubcategory = false;
            Object.keys(SubCategories).forEach(x => {
              const { Name, _id } = SubCategories[x]
              let arrayIndex = Object.keys(subCategoryListTemp).filter((key, index) => {
                return subCategoryListTemp[key] === _id;
              });
              try {
                if (subCategoryListTemp[arrayIndex] === _id) {
                  checkSubcategory = true;
                } else {
                  checkSubcategory = false;
                }
              } catch (error) {
                checkSubcategory = false;
              }
              subCategoryPush.push({ sen: Name.EN, svn: Name.VN, sid: _id, sIsCheck: checkSubcategory })
            })
            SetSubcategoryList(array => [...array, { id: _id, vn: Name.VN, en: Name.EN, subcategories: subCategoryPush }])
          }
        })

      })
      .catch(err => console.log(err))
    ShowItemFlex("#subcategorycardlist")
    HideItem("#categorycardlist");
    HideItem("#groupcategorycardlist");
    categoryChoice(categoryArray, e)

  }
  const proceedSubCategory = (e) => {
    e.preventDefault();
    subcategoryArray = [] // sub category list
    Object.keys(subcategoryList).forEach(key => {
      const { subcategories } = subcategoryList[key]
      Object.keys(subcategories).forEach(keys => {
        const { sid, sIsCheck } = subcategories[keys]
        if (sIsCheck) {
          subcategoryArray.push(sid)
        }
      })
    })
    subcategoryChoice(subcategoryArray, e)
    HideItem("#categoryModal")
  }
  return (
    <div id="categoryModal" className="modal-categories">
      <div className="modal-categories-form">
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">Categorization</div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                backtogroup(e)
                setGroupCategory()
                SetSubcategoryList([])
                closeForm(e)

              }}
            />
          </div>
        </div>
        <form className="modal-categories-form-main" id="groupcategorycardlist">
          <div className="modal-type-form-main-note">
            <span> Please select <b>group categories</b> from the list below:
            </span>
            <span>* Take note that you cannot select the group category that has no assigned category.
            </span>
          </div>
          <div className="container-row-wrap">
            {
              groupCategory ? (
                Object.keys(groupCategory.groupCategoryList).map((key, index) => {
                  const { icon, id, en, vn, isCheck, categories } = groupCategory.groupCategoryList[key]

                  return categories.length ? (
                    <div key={index} className="card-column-group" onClick={addOrRemoveGroupCategory(key)} >
                      <label htmlFor={id}>
                        <input
                          type="checkbox"
                          name=""
                          id={id}
                          value={id}
                          onChange={addOrRemoveGroupCategory(key)}
                          checked={isCheck}
                        />
                        <div className="fix-column">
                          <img src={icon} alt="icon" width="50px" />
                          <span className="name-en">{en}</span>
                          <span className="name-vn">{vn}</span>
                        </div>
                      </label>
                    </div>
                  ) : (
                    <div key={index} className="card-column-group">
                      <label>
                        <div className="fix-column">
                          <img src={icon} alt="icon" width="50px" />
                          <span className="name-en">{en}</span>
                          <span className="name-vn">{vn}</span>
                        </div>
                      </label>
                    </div>
                  )
                })
              ) : (<div style={{ width: "100%", textAlign: "center" }}> <span>No Group Category Found</span></div>)
            }
          </div>
          <div className="button-container">
            <button className="proceed" onClick={(e) => { proceedGroup(e) }}>PROCEED</button>
          </div>
        </form>
        <form className="modal-categories-form-main" id="categorycardlist">
          <div className="modal-type-form-main-note">
            <span> Please select <b>categories</b> per group:
            </span>

          </div>
          <div className="container-main">
            {
              checkedGroupCategory ? (
                Object.keys(checkedGroupCategory).map((key, index) => {
                  const { categories, en, vn } = checkedGroupCategory[key]
                  return (
                    <div className="container-column" key={index}>
                      <div className="fix-column groupcategory-category">
                        <span className="name-en">{en}</span>
                        <span className="name-vn">({vn})</span>
                      </div>
                      <div className="container-row-wrap-start">
                        {
                          categories.length ? (Object.keys(categories).map((keys, index) => {
                            const { _id, EN, VN, isCheck } = categories[keys]
                            return (
                              <div key={index} className="card-column-category" onClick={addOrRemoveCategory(key, keys)}>
                                <label htmlFor={_id}>
                                  <input
                                    type="checkbox"
                                    name=""
                                    id={_id}
                                    value={_id}
                                    onChange={addOrRemoveCategory(key, keys)}
                                    checked={isCheck}
                                  />
                                  <div className="fix-column">
                                    <span className="name-en">{EN}</span>
                                    <span className="name-vn">{VN}</span>
                                  </div>
                                </label>
                              </div>
                            )
                          }))
                            : (
                              <div style={{ width: "100%", textAlign: "center" }}>
                                no category found
                              </div>
                            )
                        }
                      </div>

                    </div>
                  )
                })
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>no category</div>
              )
            }
          </div>
          <div className="button-container">
            <button className="back" onClick={(e) => backtogroup(e)}><ArrowBackIosIcon sx={{ fontSize: "12px" }} /> back</button>
            <button className="proceed" onClick={(e) => { proceedCategory(e) }}>PROCEED</button>
          </div>
        </form>
        <form className="modal-categories-form-main" id="subcategorycardlist">
          <div className="modal-type-form-main-note">
            <span> Please select <b>sub categories</b> per category:
            </span>

          </div>
          <div className="container-main">
            {
              subcategoryList ? (
                Object.keys(subcategoryList).map((key, index) => {
                  const { subcategories, en, vn } = subcategoryList[key]
                  return (
                    <div className="container-column" key={index}>
                      <div className="fix-column groupcategory-category">
                        <span className="name-en">{en}</span>
                        <span className="name-vn">({vn})</span>
                      </div>
                      <div className="container-row-wrap-start">
                        {
                          subcategories.length ? (Object.keys(subcategories).map((keys, index) => {
                            const { id, sen, svn, sIsCheck } = subcategories[keys]
                            return (
                              <div key={index} className="card-column-category" onClick={addOrRemoveSubcategory(key, keys)}>
                                <label htmlFor={id}>
                                  <input
                                    type="checkbox"
                                    name=""
                                    id={id}
                                    value={id}
                                    onChange={addOrRemoveSubcategory(key, keys)}
                                    checked={sIsCheck}
                                  />
                                  <div className="fix-column">
                                    <span className="name-en">{sen}</span>
                                    <span className="name-vn">{svn}</span>
                                  </div>
                                </label>
                              </div>
                            )
                          }))
                            : (
                              <div style={{ width: "100%", textAlign: "center" }}>
                                no subcategory found
                              </div>
                            )
                        }
                      </div>

                    </div>
                  )
                })
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>no subcategory found</div>
              )
            }
          </div>
          <div className="button-container">
            <button className="back" onClick={(e) => backtocategory(e)}><ArrowBackIosIcon sx={{ fontSize: "12px" }} /> back</button>
            <button className="proceed" onClick={(e) => { proceedSubCategory(e) }}>PROCEED</button>
          </div>
        </form>
      </div >
    </div >
  )
};

export default Categorization;
