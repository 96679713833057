import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { setToken } from '../../../redux/actions/token';
import { DeleteShippingAddress, GetShippingAreaOnly, IshippeeGet, logout, UpdateShippingArea } from '../PublicHelper';
import Location from './../../../assets/myaccount/location.svg';
import MobilePhone from './../../../assets/myaccount/mobilephone.svg';
import Toship from './../../../assets/myaccount/toship.svg';

const EditLocation = ({ node, specificShippingArea }) => {
  const { t } = useTranslation()
  const [areaList, setArealist] = useState([])
  const dispatch = useDispatch()
  const history = useNavigate()
  const [location, setLocation] = useState({
    ShippingId: "",
    FullName: "",
    Email: "",
    MobileNumber: "",
    Area: "",
    Address: "",
    PlaceId: ""
  })
  const [locationError, setLocationError] = useState({
    ShippingId: "",
    FullName: "",
    Email: "",
    MobileNumber: "",
    Area: "",
    Address: "",
    PlaceId: ""
  })
  const [ishipeeSearch, setIshipeeSearch] = useState([])

  useEffect(() => {
    GetShippingAreaOnly().then(res => {
      setArealist(res.data)
    }).catch(err => {
      console.log(err.response);
    })
    let oldNum = specificShippingArea.MobileNumber;

    let newNumber = oldNum.slice(3);

    setLocation({
      ShippingId: specificShippingArea._id,
      FullName: specificShippingArea.FullName,
      Email: specificShippingArea.Email,
      MobileNumber: newNumber,
      Area: specificShippingArea.Area,
      Address: specificShippingArea.Address,
      PlaceId: specificShippingArea.PlaceId,
    })

    return () => {

    };
  }, [specificShippingArea])

  const handleChange = (e) => {
    const { value, name } = e.target
    setLocation({ ...location, [name]: value })
    if (name === "Address") {
      if (_.isEmpty(value)) {
        setIshipeeSearch([])
      } else {
        IshippeeGet(value)
          .then(
            res => {
              setIshipeeSearch([...res.predictions])
            }
          )
      }

    }
  }
  const submitLocationUpdate = (e, ShippingId) => {
    e.preventDefault()
    console.log(ShippingId)
    let payload;
    if (_.isEmpty(location.MobileNumber)) {
      payload = ""
    } else {
      payload = "+63" + location.MobileNumber
    }
    UpdateShippingArea({
      ShippingId: ShippingId,
      FullName: location.FullName,
      Email: location.Email,
      MobileNumber: payload,
      Area: location.Area,
      Address: location.Address,
      PlaceId: location.PlaceId
    }).then(res => {
      localStorage.setItem("publicToken", res.data);
      dispatch(setToken({ publicToken: res.data }));
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Shipping Address successfully Updated!',
        showConfirmButton: false,
        timer: 1000,
        footer: "&#169; Pao Kitchen 2023",
      })
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }).catch(err => {
      if (err.response) {
        if (_.isEmpty(err.response.data.data)) {
          Swal.fire({
            icon: 'error',
            title: err.response.data.mess,
            text: "Status : " + err.response.status,
            footer: "&#169; Pao Kitchen 2023"
          })
        } else {
          setLocationError({ ...err.response.data.data })
        }
      }
      const { response } = err
      logout(response, history)
    })
  }
  const handleChangeNum = (e) => {
    const { value, name } = e.target
    const re = /^[0-9\b]+$/;
    if (_.isEmpty(value) || re.test(value)) {
      setLocation({ ...location, [name]: value })
    }

  }
  const deleteLocation = (e, id) => {
    e.preventDefault()
    console.log(id)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteShippingAddress(id)
          .then(
            res => {
              console.log(res.data)
              localStorage.setItem("publicToken", res.data);
              dispatch(setToken({ publicToken: res.data }));
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Shipping Address successfully deleted!',
                showConfirmButton: false,
                timer: 1000,
                footer: "&#169; Pao Kitchen 2023",
              })
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          ).catch(err => {
            console.log(err.response);
            const { response } = err
            logout(response, history)
          })
      }
    })


  }
  const addressFillup = (e, ishipee) => {
    e.preventDefault()
    setLocation({ ...location, Address: ishipee.description, PlaceId: ishipee.place_id })
    setIshipeeSearch([])
  }
  const { ShippingId, FullName, Email, MobileNumber, Area, Address } = location
  return (
    <div className="modal-container" id="editLocationModal">
      <form onSubmit={(e) => { submitLocationUpdate(e, ShippingId) }} action="">
        <div ref={node} className="modal-container-item">

          <div className='modal-container-item-title'><img src={Location} width="20" height="20" alt="icon" /> {t('editlocation')}</div>
          <div className='modal-container-item-subtitle'><img src={MobilePhone} width="16" height="16" alt="icon" /> {t('contactdetails')}</div>

          <div className="input">
            <input onChange={(e) => { handleChange(e) }} type="text" name="FullName" value={FullName} id="fullNameE" />
            <label htmlFor="fullNameE">{t('fullname')}</label>
          </div>
          <span className='error-message'>{locationError.FullName}</span>
          <div className='group-input'>
            <div className="input">
              <input onChange={(e) => { handleChange(e) }} type="text" name="Email" value={Email} id="emailAddressE" />
              <label htmlFor="emailAddressE">{t('emailaddress')}</label>
            </div>
            <div className="input">
              <span>+63</span><input type="text"
                className='number-location'
                maxLength="10" name="MobileNumber" value={MobileNumber} id="contactnumE" onChange={(e) => { handleChangeNum(e) }} />
              <label htmlFor="contactnumE">{t('mobilenumber')}</label>
            </div>
          </div>
          <span className='error-message'>{locationError.Email}</span>
          <span className='error-message'>{locationError.MobileNumber}</span>
          <div className='mb'></div>
          <div className='modal-container-item-subtitle'><img src={Toship} width="16" height="16" alt="icon" /> Shipping Address</div>
          <div className='group-input'>
            <div className="input">
              <select onChange={(e) => { handleChange(e) }} type="text" name="Area" value={Area} id="areaE" >
                <option value="" > SELECT YOUR AREA </option>
                {
                  Object.keys(areaList).map((key, index) => {
                    const { Area } = areaList[key]
                    let newArea = Area.substring(0, 20)
                    let ellipse = Area.length > 20 ? "..." : ""
                    return (
                      <option key={index} value={Area}> {newArea + (_.isEmpty(ellipse) ? "" : ellipse)} </option>
                    )
                  })
                }
              </select>
              <label htmlFor="areaE">{t('area')}</label>
            </div>
            <div className="input">
              <input onChange={(e) => { handleChange(e) }} type="text" name="Address" value={Address} id="addressE" autoComplete='none' />
              <label htmlFor="addressE">{t('address')}</label>
              {
                !_.isEmpty(ishipeeSearch) ? (
                  <div className='ishipee-container'>
                    {
                      Object.keys(ishipeeSearch).map((key, index) => {
                        return (
                          <button type="button" onClick={(e) => addressFillup(e, ishipeeSearch[key])} key={index}>{ishipeeSearch[key].description}</button>
                        )
                      })
                    }
                  </div>
                ) : (
                  <> </>
                )

              }
            </div>
          </div>
          <span className='error-message'>{locationError.Area}</span>
          <span className='error-message'>{locationError.Address}</span>
          <small>{t('freein')} Makati.</small>
          <div className='modal-container-item-buttons'>
            <button className='modal-container-item-buttons-cancel' onClick={(e) => { deleteLocation(e, ShippingId) }}>{t('delete')}</button>
            <button type='submit' className='modal-container-item-buttons-save'>{t('save')}</button>
          </div>
        </div>

      </form>
    </div>
  )

}

export default EditLocation;
