import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";

const AddBranchButton = ({ addBranch, tooltip }) => {

  return (
    <Tooltip title={tooltip} placement="bottom-start" arrow>

      <div className="export-product">
        <div>Export</div>
        <div
          className="icon"
          onClick={
            (e) => {
              addBranch(e)
            }
          }
        >
          <AddIcon sx={{ fontSize: "18px" }} />
        </div>
      </div>
    </Tooltip>
  );
};
AddBranchButton.defaultProps = {
  tooltip: "",
  addBranch: () => { }
}
export default AddBranchButton;
