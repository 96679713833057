import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from "moment";
import { useEffect, useRef } from "react";
import CurrencyFormat from 'react-currency-format';
import ScrollContainer from 'react-indiana-drag-scroll';

import Swal from 'sweetalert2';
import {Link} from "react-router-dom";
const ProductTable = ({ orderList }) => {

  const container = useRef(null)
  const IS_REJECTED = 2
  useEffect(() => {
    container.current.getElement().id = "orderTable"

    return () => {

    }
  }, [])

  const copyText = (e, value) => {
    e.preventDefault()

    navigator.clipboard.writeText(value);
    Swal.fire({
      toast: true,
      icon: 'success',
      title: `Order reference (${value}) copied`,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }
  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start">Reference #</div>
        <div className="content-product-table-head-item normal-start">Status</div>
        <div className="content-product-table-head-item normal-start">Platform</div>
        <div className="content-product-table-head-item center">Pref. Delivery Time</div>
        <div className="content-product-table-head-item big-item ml-3">Billing</div>
        <div className="content-product-table-head-item center">Contact Details</div>
        <div className="content-product-table-head-item small-item center">Total</div>
        <div className="content-product-table-head-item small-item center">Paid</div>
        <div className="content-product-table-head-item ">Update At</div>

      </div>
      {orderList.length ? (
        Object.keys(orderList).map((key, index) => {
          const { OrderRef, Status, PreferredDeliveryDate, Paid, ContactDetails, DeliveryDetails, PaymentMode, TotalAmount,UpdatedAt , CreatedAt, _id, PaymentReceipt, PlatformId, IsMealAllowance } = orderList[key]
          const Rejected = PaymentReceipt?.Status === IS_REJECTED
          let PreferredDateDeliver = moment(PreferredDeliveryDate).tz("Asia/Manila").format("YYYY-MM-DD HH:mm A");
          let updateAt = UpdatedAt ? UpdatedAt : CreatedAt

          let UpdateAtDate = moment(updateAt).tz("Asia/Manila").format("YYYY-MM-DD HH:mm A");
          let Created = new Date(CreatedAt);
          let DateCreated = moment(Created).format("YYYY-MM-DD HH:mm A");

          let itemClassName = `content-product-table-data`
          let contactStyle = `order-status blue`
          let meal_allowance_txt = ''
          // let Platform = "Grab Mart"
          if(Paid) {
            itemClassName = `${itemClassName} is-paid`
            contactStyle = `order-status white`
          }

          if(Rejected) {
            itemClassName = `${itemClassName} is-rejected`
            contactStyle = `order-status white`
          }
          if(IsMealAllowance === true){
            meal_allowance_txt = 'Meal Allowance'
          }

          return (
            <div className={itemClassName} key={index} onClick={() => {
              // paymentMethodUpdate(_id)
              //history(`/admin/cms/order-list/${_id}`)

            }}>


              <div onClick={(e) => {
                e.stopPropagation()
                copyText(e, OrderRef)
              }} className="content-product-table-data-item normal-start tocopy color-text-white">
                {OrderRef} | {meal_allowance_txt}
              </div>

              <div className="content-product-table-data-item normal-start center">
                <Link to={`/admin/cms/order-list/${_id}`} target="_blank" style={{textDecoration: 'none'}}>
                {
                  Status === 0 ? (
                    <div className='order-status orange'><PendingActionsIcon /> Pending</div>
                  ) : Status === 1 ? (
                    <div className='order-status orange'><PublishedWithChangesIcon /> Processing</div>
                  ) : Status === 2 ? (
                    <div className='order-status green'><ThumbUpIcon /> Confirmed</div>
                  ) : Status === 3 ? (
                    <div className='order-status green'><Inventory2Icon /> Prepared</div>
                  ) : Status === 4 ? (
                    <div className='order-status orange'><LocalShippingIcon /> Shipping</div>
                  ) : Status === 5 ? (
                    <div className='order-status green'><DeliveryDiningIcon /> Delivered</div>
                  ) : Status === 6 ? (
                    <div className='order-status green'><AssignmentTurnedInIcon /> Completed</div>
                  ) : Status === 7 ? (
                    <div className='order-status red'><ThumbDownAltIcon /> Declined</div>
                  ) : Status === 8 ? (
                    <div className='order-status red'><DoDisturbIcon /> Cancelled</div>
                  ) : Status === 9 ? (
                    <div className='order-status red'><AssignmentReturnIcon /> Returned</div>
                  ) : ""
                }
                </Link>
              </div>
              
              <div className="content-product-table-data-item normal-start center">
                <Link to={`/admin/cms/platform/`} target="_blank" style={{textDecoration: 'none'}}>
                  <span className='f-column-payment-mode color-text-white'>{PlatformId}</span>
                </Link>
              </div>

              <div className="content-product-table-data-item center color-text-white">
                <Link to={`/admin/cms/order-list/${_id}`} target="_blank" style={{textDecoration: 'none'}}>

                  <span className='order-status orange'>{PreferredDateDeliver}</span>
                </Link>
              </div>

              <div className="content-product-table-data-item big-item ml-3 f-column">
                <Link to={`/admin/cms/order-list/${_id}`} target="_blank" style={{textDecoration: 'none'}}>
                <span className='f-column-name'>{ContactDetails.FullName}</span>
                <span className='f-column-address color-text-white'>{DeliveryDetails.Address}</span>
                <span className='f-column-payment-mode color-text-white'>via {PaymentMode}</span>
                </Link>
              </div>
              <div className="content-product-table-data-item center color-text-white">
                {}
                <div className={contactStyle}> <PhoneAndroidIcon /> {ContactDetails.MobileNum}</div>
              </div>
              <div className="content-product-table-data-item small-item center color-text-white">
                <CurrencyFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
              </div>
              <div className="content-product-table-data-item small-item center color-text-white">
                {
                  Paid ? "YES" : "NO"
                }
              </div>
              <div className="left content-product-table-data-item f-column ">
                <span className={'f-column-address color-text-white'}> {UpdateAtDate} </span>
                <span className='f-column-payment-mode color-text-white right'>Update At</span>
                <span className={'f-column-address color-text-white'}>{DateCreated}</span>
                <span className='f-column-payment-mode color-text-white right'>Date Order</span>
              </div>



            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item center">

          </div>

          <div className="content-product-table-data-item center">

          </div>

          <div className="content-product-table-data-item big-item ml-3">
            No Order List Found
          </div>
          <div className="content-product-table-data-item center">

          </div>
          <div className="content-product-table-data-item center">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item right">

          </div>
          <div className="content-product-table-data-item center">

          </div>
        </div>
      )}
    </ScrollContainer>
  )
};

export default ProductTable;
