import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BranchSelectPublic = ({ branches, branchSelect, locationIcon }) => {
  const { t } = useTranslation()
  return (
    <div className="branch-dialog-selection">
      <div className="branch-dialog-selection-container">
        <div className="branch-dialog-selection-container-header">
          <h3 className="title">{t('pleaseselectbranch')}</h3>
        </div>
        <div className="branch-dialog-selection-container-cardlist">
          {branches
            ? Object.keys(branches).map((key, index) => {
              const { CityAddress, Name, _id } = branches[key];
              return (
                <div
                  onClick={(e) => {
                    branchSelect(e, _id, Name, CityAddress);
                  }}
                  key={index}
                  className="branch-dialog-selection-container-cardlist-card">
                  <div>
                    <LocationOnIcon sx={locationIcon} />
                  </div>
                  <div className="branch-name">
                    <span className="branch-name-city">{CityAddress}</span>
                    <span className="branch-name-name">{Name}</span>
                  </div>
                </div>
              );
            })
            : ' No active branch was found !'}
        </div>
      </div>
    </div>
  );
};

export default BranchSelectPublic;

