import React from 'react';
import {Tooltip} from "@mui/material";

const OrderCustomerDetail = ({
  getData,
  ContactDetails,
  DeliveryDetails,
  openEditCustomerDetail,
  currentStatus
}) => {
  return (
    <div className="width-100 order-container-left-customer  margin-bottom-70">
      <div className="order-container-left-customer-detail">
        <div className="order-container-left-customer-detail-title">
          <span>CUSTOMER</span>
        </div>
        <div className="order-container-left-customer-detail-content">
          <span className="customer-name">
            {getData(ContactDetails.FullName)}
          </span>
          <span className="customer-email">
            {getData(ContactDetails.Email)}
          </span>
          <span>{getData(ContactDetails.MobileNum)}</span>
        </div>
      </div>
      <div className="order-container-left-customer-billing">
        <div className="order-container-left-customer-billing-title">
          <span>BILLING ADDRESS</span>

          {
            currentStatus === 6 ?
                (<>
                  <Tooltip title="Unable to update completed order">
                    <button className='customer-edit' >Edit</button>
                  </Tooltip>
                </>) :
                (
                    <>
                      <button className='customer-edit' onClick={(e) => openEditCustomerDetail(e)}>Edit</button>
                    </>
                )
          }


        </div>

        <div className="order-container-left-customer-billing-content ">
          <span className="customer-name">
            {getData(ContactDetails.FullName)}
          </span>
          <span>{getData(DeliveryDetails.Address)}</span>
          <span>{getData(DeliveryDetails.Area)}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderCustomerDetail;
