import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
import { GetProductList } from '../../../ProductManager/productHelper';
const AddproductModal = ({
  closeModalEdit,
  selectedAddProduct,
  setSelectedAddProduct,
  order,
  submitAddProduct
}) => {
  const [search, setSearch] = useState("")
  const [products, setProduct] = useState([])
  const [productE, setProductE] = useState("")
  useEffect(() => {
    getProductList(1, search, {}) 
    //eslint-disable-next-line
  }, [])
  const getProductList = (page, search, searchValue) => {
    setProduct([])
    setProductE("")
    GetProductList(page, search, searchValue)
      .then((res) => {
        const { paginatedResults } = res?.data;
        //setPageCount(countPage(total, limit))
        setProduct(_.map(paginatedResults, value => {
          let checked = !_.isEmpty(_.find(selectedAddProduct, selectedValue => {
            return selectedValue._id === value._id
          }))
          return {
            ...value,
            isCheck: checked,
            Quantity: 0,
            OrderProdType: "REGULAR"
          }
        }))
      })
      .catch(
        (err) => {
          setProductE(err?.response?.data?.mess)
          setProduct([])
        }
      )
  }
  const handleChange = (e) => {
    const { value } = e.target
    setSearch(value)
  }
  const submitSearch = (e) => {
    e.preventDefault()
    getProductList(1, search, {})

  }
  const [selectedProduct, setSelectedProduct] = useState(selectedAddProduct)
  const addOrRemoveProduct = (productValue) => {
    //if (_.isEmpty()
    if (_.isEmpty(_.find(order.OrderDetails, (value) => {
      return value.ProductId === productValue.Product._id
    }))) {
      setProduct(oldProduct => _.map(oldProduct, value => {
        return {
          ...value,
          isCheck: value._id === productValue._id ? !value.isCheck : value.isCheck
        }
      }))
      if (productValue.isCheck === false) {
        setSelectedProduct([
          ...selectedProduct,
          {
            ...productValue,
            isCheck: true
          }
        ])
        setSelectedAddProduct([
          ...selectedProduct,
          {
            ...productValue,
            isCheck: true
          }
        ]);
      } else {
        selectedProduct.length <= 1 ? setProceed(false) : setProceed(proceed)

        setSelectedProduct(oldSelect => _.filter(oldSelect, value => {
          return value._id !== productValue._id
        }))
        setSelectedAddProduct(oldSelect => _.filter(oldSelect, value => {
          return value._id !== productValue._id
        }));

      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "Product is already ordered!",
        footer: "&#169; Pao Kitchen 2023"
      })
    }
  }

  const [proceedE, setProceedE] = useState("")
  const [proceed, setProceed] = useState(false)
  const [submitE, setSubmitE] = useState("")
  const proceedProduct = (e, selectedItem) => {
    e.preventDefault()
    setProceedE("")

    if (proceed) {
      setSubmitE("")
      //proceed
      let submitProceed = _.find(selectedItem, value => {
        return Number(value.Quantity) === 0
      })
      if (_.isEmpty(submitProceed)) {

        submitAddProduct(e, [...order.OrderDetails, ..._.map(selectedItem, value => {
          const { Quantity, OrderProdType, Product, Price } = value
          const { PreparationTime, Images, Name, SKU } = Product
          const { SendOption } = order
          let price = SendOption === "PICKUP" ?
            Price.Regular : SendOption === "DELIVERY" ?
              Price.Delivery : SendOption === "DINEIN" ?
                Price.DineIn : SendOption === "KTV" ?
                  Price.KTV : Price.Regular

          let totalPrice = Number(Quantity) * Number(price)
          return {
            IsScan: false,
            OrderProdType: OrderProdType,
            PreparationTime: PreparationTime,
            Price: Number(price),
            ProdImage: Images?.DineIn || Images?.Delivery,
            ProductId: Product?._id,
            ProductName: Name,
            Quantity: Number(Quantity),
            SKU: SKU,
            TotalPrice: Number(totalPrice)
          }
        })])


      } else {
        setSubmitE("Please fill up all quantity field or remove product from list!")
      }

    } else {
      if (_.isEmpty(selectedItem)) {
        setProceedE("Please select a product to proceed")
      } else {
        setProceed(true)
        setSelectedAddProduct(selectedItem);
        // selected item === item that's selected already 
      }
    }

  }
  const cancelProceed = () => {
    proceed ? setProceed(false) : closeModalEdit()
  }
  const handleChangeQuantity = (e, productValue) => {
    const { value } = e.target
    setSelectedProduct(oldProduct => _.map(oldProduct, result => {
      return {
        ...result,
        Quantity: result._id === productValue._id ? value : result.Quantity
      }
    }))

  }
  return (
    <div className='order-modal-container'>
      <h3>Add Product to order</h3>
      {
        proceed ? (
          <div className="container-row-wrap-600" >
            <div className='flex-row-start gap-10 border-top-dashed border-bottom-dashed padding-bottom-10 padding-top-10'>
              <div className='widthpx-230'>PRODUCT</div>
              <div className='widthpx-90'>PRICE</div>
              <div className='widthpx-90'>QUANTITY</div>
              <div className='widthpx-90'>TYPE</div>
              <div className='widthpx-30'></div>
            </div>
            {
              _.isEmpty(selectedProduct) ? (<div className='flex-row-center-center padding-top-10'>
                No Products Added
              </div>) : (<>
                {
                  _.map(selectedProduct, (value, key) => {
                    const { SendOption } = order
                    const { Price, Product, Stocks, Quantity } = value
                    const { Images, Name, SKU } = Product
                    let price = SendOption === "PICKUP" ?
                      Price.Regular : SendOption === "DELIVERY" ?
                        Price.Delivery : SendOption === "DINEIN" ?
                          Price.DineIn : SendOption === "KTV" ?
                            Price.KTV : Price.Regular
                    return (
                      <div key={key}>
                        <div className='flex-row-start gap-10 border-bottom-dashed padding-bottom-10 padding-top-10' >
                          <div className='widthpx-230'>
                            <div className='flex-row-start-center gap-10'>
                              <img src={Images.DineIn ?? Images.Delivery} alt="icon" width="75" />
                              <div className='flex-column-start'>
                                <span className="name-en">{Name?.EN || Name?.VN || Name?.CN || Name?.TH}</span>
                                <span className="name-vn">{Name?.VN || Name?.EN || Name?.CN || Name?.TH}</span>
                                <span className="name-vn">{Name?.CN || Name?.EN || Name?.VN || Name?.TH}</span>
                                <span className="name-vn">{Name?.TH || Name?.EN || Name?.VN || Name?.CN}</span>
                                <small className="text-grey">{SKU}</small>
                              </div>
                            </div>
                          </div>
                          <div className='widthpx-90 flex-row-start-center'>
                            <CurrencyFormat
                              value={price}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₱ '}
                            />
                          </div>
                          <div className='widthpx-90 flex-row-start-center relative-div'>
                            <div className='input'>
                              <input onChange={(e) => handleChangeQuantity(e, value)} value={Quantity} className='width-60' type="number" placeholder='quantity' />
                            </div>
                            <small className={Number(Stocks) <= 0 ? "error-message small-absolute text-grey" : "small-absolute text-grey"}>{Number(Stocks) <= 0 ? "*Out of stock" : `*Stock: ${Stocks}`}</small>

                          </div>
                          <div className='widthpx-90 input flex-row-start-center'>
                            <select className='width-60' disabled={true}>
                              <option value="REGULAR">REGULAR</option>
                              <option value="FREE">FREE</option>
                            </select>
                          </div>
                          <div className='widthpx-30 flex-row-center-center btn-edit-skeleton'>
                            <Tooltip title="Remove this item" placement="bottom-start" arrow>
                              <ClearIcon
                                onClick={() => addOrRemoveProduct(value)}
                                sx={{
                                  '&:hover': {
                                    color: "#f08d19",
                                    cursor: "pointer"
                                  }
                                }} />
                            </Tooltip>
                          </div>
                        </div>
                        {
                          Quantity <= 0 ? <small className='flex-row-center-center error-message'>Please Add Quantity To This Product</small> : <></>
                        }
                      </div>
                    )
                  })
                }
              </>)
            }
            {
              _.isEmpty(submitE) ? <></> : <small className='error-message'>{submitE}</small>
            }

          </div >
        ) : (
          <>
            <span className='text-grey'>* The product list are limited to optimize the speed. Please use the search box if the item you want to add is not listed below.</span>
            <div className='flex-row-start gap-10'>
              <form onSubmit={(e) => {
                submitSearch(e)
              }} className='input' method='post'>
                <label htmlFor="addProductEdit">Product Name / SKU</label>
                <input
                  type="text"
                  id="addProductEdit"
                  placeholder='Enter Product Name Or SKU'
                  value={search}
                  onChange={(e) => {
                    handleChange(e)
                  }} />
              </form>
              <button className='btn-cancel' onClick={(e) => {
                submitSearch(e)
              }}><SearchIcon sx={{ fontSize: "16px" }} /></button>
            </div>
            <div className={_.isEmpty(products) ? " flex-row-center-center container-row-wrap" : "container-row-wrap"}>
              {
                _.isEmpty(products) && _.isEmpty(productE) ? <div className='loader'></div> : (
                  <>
                    {
                      _.map(products, (value, key) => {
                        const { _id, Product, Stocks, IsAvailable, isCheck } = value
                        const { Images, Name, SKU } = Product
                        return (
                          <div key={key} className="card-column-group"  >
                            <label htmlFor={_id}>
                              <input
                                type="checkbox"
                                name=""
                                id={_id}
                                value={_id}
                                onChange={() => addOrRemoveProduct(value)}
                                checked={isCheck}
                                disabled={!IsAvailable || (Number(Stocks) <= 0)}
                              />
                              <div className="fix-column">
                                <img src={Images?.DineIn} alt="icon" width="50px" />
                                <small className="text-grey margin-bottom-5">{SKU}</small>
                                <span className="name-en">{Name?.EN || Name?.VN || Name?.CN || Name?.TH}</span>
                                <span className="name-vn">{Name?.VN || Name?.EN || Name?.CN || Name?.TH}</span>
                                <small className={Number(Stocks) <= 0 ? "error-message" : ""}>{Number(Stocks) <= 0 ? "Out of stock" : `Stock: ${Stocks}`}</small>
                              </div>
                            </label>
                          </div>
                        )
                      })
                    }
                  </>
                )
              }
              {
                !_.isEmpty(productE) ? <small className='error-message'>{productE}</small> : <></>
              }
            </div>
            {
              _.isEmpty(proceedE) ? <></> : <small className='error-message'>{proceedE}</small>
            }
          </>
        )
      }

      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          cancelProceed()
        }}>{proceed ? "Back" : "Cancel"}</button>
        <button className='btn-submit' onClick={(e) => {
          proceedProduct(e, selectedProduct)
        }}>{proceed ? "Add products" : "Proceed"}</button>
      </div>

    </div >
  )
}

export default AddproductModal