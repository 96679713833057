import React from "react";
import { Navigate } from "react-router";
import Login from "../components/cms/login/Login";
import useAuth from "./../routes/useAuth";
const Admin = () => {
  const auth = useAuth();
  return !auth ? (
    <div className="admin">
      <Login />
    </div>
  ) : (
    <Navigate to="/admin/cms" />
  );
};

export default Admin;
