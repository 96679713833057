import { useState, useEffect } from 'react'
import { GetPlatformList } from '../EcommerceHelper'

import './styles.scss'
import Search from '@mui/icons-material/Search'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import _ from 'lodash';

const Filter = (props) => {

  // useState variables/setters and functions provided from NewOrder.js 
  const {

    searchItem, // Variable
    searchPlatform, // Variable
    pagination, // Variable
    setSearchItem, // useState setter
    setSearchPlatform, // useState setter
    setPlatformId, // useState Setter
    movePagination, // Independent function
    // filter, // Independent function
    toRenderProductList,
    getProductPlatformList,
    setCart,
    rawCart, 
    deliveryInfo,
    getSubtotal,
    setIsNext,
    isNext
  } = props.props

  // Variables
  const [platforms, setPlatforms] = useState()


  // Functions
  // -- Getting platforms
  const getPlatforms = async () => {
    
    const platforms = await GetPlatformList()
    if ( platforms ) setPlatforms( platforms.map( p => {
      let Percent = p.PricePercentage / 100;
      if(searchPlatform == Percent){
        console.log("platforms_id_select: ", p._id)
        console.log("platforms_name_select: ", p.PlatformName)
        setPlatformId( p._id )
        setSearchPlatform( Percent )
      }
      
      return <option key={p._id} id={p._id} value={p.PricePercentage / 100}>{p.PlatformName}</option>
      
    }))

  }

  // -- Filter
  const filter = () => {
    console.log("Selected Radio: ", deliveryInfo.deliveryMethod)
    
    if ( searchItem ) {getProductPlatformList( searchItem, 1 ) }
    else{ getProductPlatformList( undefined, 1) }
    
    if ( searchPlatform ) {
      setCart( rawCart.map( x => {
        return {
          ...x, 
          price: x.price + _.round((searchPlatform * x.price), 2),
          prices: {
            delivery: x.prices.delivery + _.round((searchPlatform * x.prices.delivery), 2),
            dineIn: x.prices.dineIn + _.round((searchPlatform * x.prices.dineIn), 2),
            ktv: x.prices.ktv + _.round((searchPlatform * x.prices.ktv), 2),
            regular: x.prices.regular + _.round((searchPlatform * x.prices.regular), 2)            
          }
        } 
      } ) ) 
      
    } 
  }

  // console.log("searchPlatform: ", searchPlatform)

  useEffect( () => {
    
    getPlatforms()

  }, [])

  return <>
    
    <section className="filter-box">

      <div className="filter-search-title">
        search
      </div>

      <div className="filter-platform-title">
        platform
      </div>

    </section>

    <section className="content-main-search">
      
      <div className="content-main-search-item">
        
        {/* Search bar */}
        <input type="text"
          value={searchItem}
          onChange={e => setSearchItem( e.target.value )}  
          className="input"
        />
        
        {/* Platform Selection */}
        <select
          value={searchPlatform}
          onChange={e => {
            const index = e.target.selectedIndex
            const node = e.target.childNodes[index]
            const option = node.getAttribute('id')
            setPlatformId( option )
            setSearchPlatform( e.target.value )
          }}
          className="select"
        >
          <option id="0" value="">- None -</option>
          {platforms}
        </select>
        
        {/* Search Icon Button */}
        <button onClick={e => {
          e.preventDefault()
          filter()
          toRenderProductList( searchPlatform )
          setIsNext( false )
          getSubtotal()
        }}>
          <Search />
        </button>

        {/* Pagination */}
        <ArrowBackIosIcon sx={{
          marginLeft: "100px",
          cursor: 'pointer'
        }} onClick={e => {
          e.preventDefault()
          movePagination(false)
        }}/>
        
        <input
          type="number"
          value={pagination}
          className="input"
          disabled
          />

        <ArrowForwardIosIcon sx={{
          cursor: 'pointer'
        }} onClick={e => {
          e.preventDefault()
          movePagination(true)
        }}/>

      </div>


    </section>

  </>

}

export default Filter;