import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";

const AddBannerButton = ({ addBanner, tooltip }) => {

  return (
    <Tooltip title={tooltip} placement="bottom-start" arrow>

      <div className="export-product">
        <div>Export</div>
        <div
          className="icon"
          onClick={
            (e) => {
              addBanner(e)
            }
          }
        >
          <AddIcon sx={{ fontSize: "18px" }} />
        </div>
      </div>
    </Tooltip>
  );
};
AddBannerButton.defaultProps = {
  tooltip: "",
  addBanner: () => { }
}
export default AddBannerButton;
