import Card from "./Card";
import ScrollContainer from 'react-indiana-drag-scroll';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyFormat from 'react-currency-format';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import BusinessIcon from '@mui/icons-material/Business'; 
import SetMealIcon from '@mui/icons-material/SetMeal'; 
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LiquorIcon from '@mui/icons-material/Liquor';

const CardList = ({
    totalOrders,
    totalUserSpent,
    TotalCompanyAllowance,
    totalDineIn, 
    totalOnline,
    totalKTV
}) => {
    return (
        <ScrollContainer className="customer-analytics-card-list scroll-container">
            <div className="flex-row-space-between gap-20 width-100">
                <Card 
                    showCard={true}
                    icon={<ShoppingCartIcon />}
                    number={
                      <CurrencyFormat 
                        key={0}
                        value={totalOrders}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                      />
                    }
                    text="Total Orders" />
                <Card 
                    showCard={true}
                    icon={<ShoppingCartCheckoutIcon />}
                    number={
                      <CurrencyFormat 
                        key={1}
                        value={totalUserSpent}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₱ '}
                      />
                    }
                    text="Total Meal Spent" />
                <Card 
                    showCard={true}
                    icon={<BusinessIcon />}
                    number={
                      <CurrencyFormat
                        value={TotalCompanyAllowance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₱ '}
                      />
                    }
                    text="Company Spent" /> 
            </div> 
        </ScrollContainer>
    );
}

export default CardList;