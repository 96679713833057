import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Tooltip } from "@mui/material";

const ExportButton = ({ openExportModal, tooltip }) => {

  return (
    <Tooltip title={tooltip} placement="bottom-start" arrow>

      <div className="export-product">
        <div>Export</div>
        <div
          className="icon"
          onClick={
            (e) => {
              openExportModal(e)
            }
          }
        >
          <FileDownloadIcon sx={{ fontSize: "18px" }} />
        </div>
      </div>
    </Tooltip>
  );
};
ExportButton.defaultProps = {
  tooltip: "",
  openExportModal: () => { }
}
export default ExportButton;
