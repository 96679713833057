import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import SourceIcon from "@mui/icons-material/Source";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ScrollContainer from "react-indiana-drag-scroll";
import { Popover, Table } from "element-react";
import "element-theme-default";

import { GetClaimHistory, DeleteCoupon } from "../discountHelper";

import { Link } from "react-router-dom";

const WebcouponTable = ({ tableContents, showUpdateModal, refreshTable }) => {
  const container = useRef(null);

  useEffect(() => {
    container.current.getElement().id = "discountTable";

    return () => {};
  }, []);

  const deleteCoupon = (e, id) => {
    Swal.fire({
      icon: "warning",
      title: "Warning",
      text: "Are you sure to delete this?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCoupon(id)
          .then((res) => {
            refreshTable(e);
            Swal.fire("Deleted!", "", "success");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error in deleting this coupon!",
              text: "Please try again.",
              footer: "&#169; Pao Kitchen 2023",
            });
          });
      }
    });
  };

  const tableColumns = [
    {
      label: "Order Ref. #",
      prop: "OrderRef",
      width: 160,
      fixed: "left",
    },
    {
      label: "Email Address",
      prop: "ContactDetails.Email",
      width: 220,
    },
    {
      label: "Coupon Code",
      prop: "CouponCode",
      width: 150,
    },
    {
      label: "Discount",
      prop: "Discount",
      width: 100,
    },
    {
      label: "Total Amount",
      prop: "TotalAmount",
      width: 150,
    },
  ];

  const [claimHistory, setClaimHistory] = useState([]);
  const fetchClaimHistory = (id) => {
    GetClaimHistory(id)
      .then((res) => {
        if (res?.data.length > 0) {
          setClaimHistory(res?.data);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops!",
            text: "No Claim History found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          text: "No Claim History found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      });
  };

  return (
    <ScrollContainer className="content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal normal-start item-name">
          COUPON
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          DISCOUNT
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          LIMITATION
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          PAYOUT
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          MIN. AMOUNT REQ
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          OTHER CONDITIONS
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          VALID DATE
        </div>
        <div className="content-product-table-head-item center item-name">
          ACTIONS
        </div>
      </div>
      {!_.isEmpty(tableContents) ? (
        Object.keys(tableContents).map((key, index) => {
          const {
            _id,
            CouponCode,
            Prefix,
            CouponStatus,
            CouponName,
            Limitation,
            Discount,
            MaxPayout,
            MinPayout,
            MinOrderReq,
            ExpirationDate,
            IsAllowedFreebie,
            IsTagCategory,
            LoginType,
            IsAutoGenerate,
          } = tableContents[key];
          const { DiscountType, Fix, Percentage } = Discount;
          // console.log("tableContents: ", tableContents)
          const { Description } = Limitation ?? {
            Description: { EN: "--", VN: "--" },
          };
          const { End, Start } = ExpirationDate ?? { Start: "", End: "" };
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="product-name width-100 ">
                  {CouponStatus ? (
                    <CircleIcon sx={{ color: "#1bb76d", fontSize: "12px" }} />
                  ) : (
                    <CircleIcon sx={{ color: "gray", fontSize: "12px" }} />
                  )}
                  <div className="flex-column-center-start gap-5 width-100">
                    <div className="coupon">
                      <div className="coupon-left"></div>
                      <div className="coupon-right">
                        {IsAutoGenerate ? Prefix : CouponCode}
                      </div>
                    </div>
                    <span className="span-label">
                      {CouponName?.EN ||
                        CouponName?.VN ||
                        CouponName?.CN ||
                        CouponName?.TH}
                    </span>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="product-name width-100 ">
                  <div className="product-name-title">
                    {/* <div>
                      <span>
                        <b>
                          {DiscountType === "By Fix Amount" ? (
                            <CurrencyFormat
                              value={Fix}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₱ "}
                            />
                          ) : (
                            <CurrencyFormat
                              value={Number(Percentage)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={"%"}
                            />
                          )}
                        </b>
                      </span>
                    </div> */}
                    <div>
                      <small>{DiscountType}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="product-name">
                  <div className="product-name-title">
                    <div>
                      <span>
                        <b>{Description.VN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Description.EN}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="flex-column-center-start">
                  <span>
                    MIN :
                    <CurrencyFormat
                      value={MinPayout}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />
                  </span>
                  <span>
                    MAX :{" "}
                    <CurrencyFormat
                      value={MaxPayout}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />
                  </span>
                </div>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <CurrencyFormat
                  value={MinOrderReq}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₱ "}
                />
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="flex-column-center-start gap-3">
                  <span>
                    Claim w/ Freebie:
                    <span className={IsAllowedFreebie ? "type-no" : "type-yes"}>
                      {IsAllowedFreebie ? "YES" : "NO"}
                    </span>
                  </span>
                  <span>
                    Categorized:
                    <span className={IsTagCategory ? "type-no" : "type-yes"}>
                      {IsTagCategory ? "YES" : "NO"}
                    </span>
                  </span>
                  <span
                    className={
                      LoginType === "LOGINONLY"
                        ? "login-type login-type-no"
                        : "login-type login-type-yes"
                    }
                  >
                    {LoginType === "LOGINONLY" ? (
                      <span>
                        LOGIN <span className="type-yes">REQUIRED</span>
                      </span>
                    ) : (
                      <span>ALLOWED TO ALL</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <div className="flex-column-center-end gap-3">
                  <span>
                    {moment(Start)
                      .tz("Asia/Manila")
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                  <small className="conjuction">TO</small>
                  <span>
                    {moment(End)
                      .tz("Asia/Manila")
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div className="content-product-table-data-item center item-name">
                <Tooltip
                  title="Update this Coupon"
                  placement="bottom-start"
                  arrow
                >
                  <Link
                    onClick={(e) => showUpdateModal(e, _id, IsAutoGenerate)}
                    className="action-btn"
                    to="#"
                  >
                    <EditIcon sx={{ fontSize: "20px" }} />
                  </Link>
                </Tooltip>

                <Popover
                  placement="left"
                  title="Coupons: History"
                  width="700"
                  trigger="click"
                  content={
                    <Table
                      style={{ width: "100%" }}
                      maxHeight={300}
                      stripe={true}
                      columns={tableColumns}
                      data={claimHistory}
                    />
                  }
                >
                  <Tooltip
                    title="View Claim History"
                    placement="bottom-start"
                    arrow
                  >
                    <Link
                      onClick={() => fetchClaimHistory(_id)}
                      className="action-btn"
                      to="#"
                    >
                      <SourceIcon sx={{ fontSize: "20px" }} />
                    </Link>
                  </Tooltip>
                </Popover>
                <Tooltip
                  title="Delete this coupon"
                  placement="bottom-start"
                  arrow
                >
                  <Link
                    onClick={(e) => deleteCoupon(e, _id)}
                    className="action-btn"
                    to="#"
                  >
                    <DeleteIcon sx={{ fontSize: "20px" }} />
                  </Link>
                </Tooltip>
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name">
            No Available Web coupons Found!
          </div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item center item-name"></div>
        </div>
      )}
    </ScrollContainer>
  );
};

WebcouponTable.defaultProps = {
  tableContents: [],
  showUpdateModal: () => {},
  refreshTable: () => {},
};

export default WebcouponTable;
