import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import { ChangeSubCategory, countPage, DeleteSubCategory, GetSubCategoryList, HideItem, ShowItemFlex, SubmitSubCategory } from "./productHelper";
import AddSubCategory from "./ProductSubcategory/AddSubCategory";
import AddSubCategoryForm from "./ProductSubcategory/AddSubCategoryForm";
import AddSubCategoryFormEdit from "./ProductSubcategory/AddSubCategoryFormEdit";
import SearchFilterSubCategory from "./ProductSubcategory/Filter/SearchFilterSubCategory";
import SubcategoryTable from './ProductSubcategory/SubcategoryTable';
import Slider from "./Slider";

const ProductSubCategories = () => {
  useTitle("Pao Kitchen | Sub Category")
  const [subCategorylists, setSubCategorylist] = useState([]);

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  useEffect(() => {
    getSubCategory(page, "");

    return () => {
    };
  }, [page]);
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const getSubCategory = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setSubCategorylist([])
    setPageCount(0)
    GetSubCategoryList(page, search, searchValue).then((res) => {
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;
      setPageCount(countPage(total, limit))
      let toPush = []
      Object.keys(paginatedResults).forEach(key => {
        const { CreatedAt, Description, Name, Category, IsActive, _id } = paginatedResults[key];
        toPush.push({
          id: _id,
          category: Category,
          isActive: IsActive,
          categoryName: {
            EN: Name.EN,
            VN: Name.VN,
            CN: Name.CN,
            TH: Name.TH
          },
          categoryDescription: {
            EN: Description.EN,
            VN: Description.EN,
            CN: Description.EN,
            TH: Description.EN
          },
          createdAt: CreatedAt
        })
      })
      setSubCategorylist(toPush)
      Swal.close()
    }).catch(function (error) {
      setPageCount(0)
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "No Subcategory(s) found!",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      });
    });
  }
  const submitSubCategoryForm = (subCategory, e) => {
    e.preventDefault();
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    SubmitSubCategory(subCategory).then(res => {
      getSubCategory(page, "");
      HideItem("#subcategoryAdd")
      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Subcategory successfully added`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }).catch(function (error) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    });
  }
  const deleteSubCategory = (id, e) => {
    e.preventDefault();
    DeleteSubCategory(id).then(res => {
      getSubCategory(1, "");
    })
  }
  const [subcateogryValue, setSubcategoryValue] = useState({
    id: "",
    category: "",
    categoryname: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    categorydescription: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    isActive: true
  })
  const showSubCategoryEdit = (e, subCategoryValue) => {
    e.preventDefault()

    ShowItemFlex("#subcategoryFormEdit")
    const { category, categoryDescription, categoryName, isActive, id } = subCategoryValue
    setSubcategoryValue({
      id,
      category: category._id,
      categoryname: categoryName,
      categorydescription: categoryDescription,
      isActive: isActive
    })
  }
  const submitSubcategoryEdit = (e, subcategoryValue) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    console.log(subcategoryValue);
    const { categoryname, categorydescription, isActive, category, id } = subcategoryValue
    let data = {

      "Name": categoryname,
      "Description": categorydescription,
      "IsActive": isActive,
      "CategoryId": category

    }
    ChangeSubCategory(id, data)
      .then(
        () => {
          getSubCategory(page, "");
          HideItem("#subcategoryFormEdit")
          Swal.fire({
            toast: true,
            icon: 'success',
            title: `${id} Updated`,
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              Swal.hideLoading();
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
        }
      )
      .catch(
        () => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! 😔",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getSubCategory(1, "")
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getSubCategory(1, search, searchValue)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        product management / product sub categories
      </section>
      <SearchFilterSubCategory {...{ clearFilter, productFilter }} />
      <SubcategoryTable {...{ subCategorylists, deleteSubCategory, showSubCategoryEdit }} />
      <Slider tableId={'productSubcategory'} />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <AddSubCategory />
      <AddSubCategoryForm {...{ submitSubCategoryForm }} />
      <AddSubCategoryFormEdit {...{ subcateogryValue, submitSubcategoryEdit }} />
    </main>
  );
};

export default ProductSubCategories;
