import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import i18next, { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import ProductCard from "./ProductCard";
import ProductTitle from "./ProductTitle";
import moment from "moment";
const Section = ({ mainId, id, onHover, cardData, title, addCart, showProductItem }) => {

  const addProductToCart = (e, product) => {
    e.preventDefault()
    addCart(e, product)
    console.log(product);
  }
  const [product, setProduct] = useState([])
  const container = useRef(id)
  useEffect(() => {
    container.current.id = id
    // Object.keys(cardData).forEach((key) => {
    //   const { IsAvailable } = cardData[key]
    //   if (IsAvailable !== null) {
    //     toPush.push(cardData[key])
    //   }
    // })
    let products = _.filter(cardData, (product) => {
      return product?.IsAvailable === true
    })
    setProduct(products)
    return () => {

    }
    // eslint-disable-next-line
  }, [cardData])

  const showProductDetails = (e, product) => {
    e.preventDefault()
    console.log("showProductDetails 1: ", product)
    showProductItem(e, product)
  }
  const showPrice = (dateFrom, dateTo, salePrice, regularPrice) => {
    const isBetween = moment().tz( 'Asia/Manila' ).isBetween( dateFrom, dateTo )
    if (isBetween && salePrice !== 0) {
      return salePrice;
    } else {
      return regularPrice;
    }
  }
  return (
    <section id={mainId} className="section">
      {
        id === "todayMenu" ? (<div className="main-button">
          <button
            onClick={() => {
              document.getElementById(`${id}`).scrollLeft -= 239.71;
            }}
          >
            <NavigateBeforeIcon sx={onHover} />
          </button>
          <button
            onClick={() => {
              document.getElementById(`${id}`).scrollLeft += 239.71;
            }}
          >
            <NavigateNextIcon sx={onHover} />
          </button>
        </div>) : <></>
      }


      <ProductTitle title={title} />
      <ScrollContainer className={`scroll-container ${id === "todayMenu" ? "product-list-main" : "product-list"} `} innerRef={container}>
        {
          !_.isEmpty(product) ? (
            Object.keys(product).map((key, index) => {
              const { Price, Product, SaleDateFrom, SaleDateTo, SalePrice } = product[key]
              const { Images, Name } = Product
              // console.log();
              return product[key]?.IsAvailable ? (
                <ProductCard
                  key={index}
                  title={Name[i18next.language] || Name.EN || Name.VN || Name.CN || Name.TH}
                  amount={showPrice(SaleDateFrom,SaleDateTo, SalePrice, Price.Regular)}
                  image={Images}
                  id={product[key]}
                  addProductToCart={addProductToCart}
                  showProductDetails={showProductDetails}
                />
              ) : (<></>)
            })
          ) : <>{t('noproductfound')}</>
        }
      </ScrollContainer>


    </section>
  );
};

export default Section;
