import TodayIcon from '@mui/icons-material/Today';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';

const OrderDateAndStatus = ({ getData, getStatus, CreatedAt, Status }) => {
  useEffect(() => {


    return () => {

    }
  }, [])

  return (
    <div className="width-100 order-container-left-customer margin-bottom-10">
      <div className="order-container-left-customer-detail">
        <div className="order-container-left-customer-billing-content ">
          <span className="customer-referal">
            <TodayIcon sx={{ fontSize: '18px' }} />{' '}
            {getData(moment(CreatedAt).tz("Asia/Manila").format('YYYY-MM-DD hh:mm:ss a'))}{' '}
            <span>|</span>
            <span className="customer-status">{getStatus(Status)}</span>{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderDateAndStatus;

