import axios from 'axios'
const baseUrl = process.env.REACT_APP_BASE_URL;
const axiosApi = axios.create({
    baseURL: baseUrl,
  });
  const token = localStorage.getItem( 'cmsToken' )
export async function get(url, config = {}) {
    axiosApi.defaults.headers.common["X-Auth-Token"] = token
    return axiosApi.get(url, { ...config })
                  .then(response => response)
}