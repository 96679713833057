import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import React, { useCallback, useState } from "react";

const SearchFilterPaymentMethod = ({ productFilter, clearFilter }) => {
  const [searchItem, setSearchItem] = useState({
    search: "",
    IsActive: ""
  })
  const clearSearch = (e) => {
    e.preventDefault()
    setSearchItem({
      search: "",
      IsActive: ""
    })
    clearFilter(e)
  }


  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])
  return (
    <section className="content-main-search">
      <form onSubmit={(e) => {
        productFilter(e, searchItem)
      }} className="content-main-search-item">
        <label htmlFor="search">NAME</label>
        <input
          autoComplete="off"
          type="text"
          name="search"
          value={searchItem.search}
          onChange={(e) => {
            handleChange(e)
          }}
        />
      </form>
      <div className="content-main-search-item">
        <label htmlFor="IsActive">STATUS</label>
        <select name="IsActive" id="IsActive" value={searchItem.IsActive} onChange={(e) => { handleChange(e) }}>
          <option value="">All</option>
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>

        </select>
      </div>
      <Tooltip title="Submit Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => {
            productFilter(e, searchItem)
          }}
        >
          <SearchIcon />
        </button>
      </Tooltip>
      <Tooltip title="Clear Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => { clearSearch(e) }}
        >
          <AutorenewIcon />
        </button>
      </Tooltip>
    </section>
  )
};

export default SearchFilterPaymentMethod;
