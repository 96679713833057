import moment from 'moment-timezone';
import { useEffect } from 'react';
import {Tooltip} from "@mui/material";

const PrefferedTime = ({ getData, CreatedAt, PreferredDeliveryDate, openModalPreparationTime, currentStatus }) => {

  useEffect(() => {

    // console.log("ITO YUNG FUCKING TIME", moment(PreferredDeliveryDate).tz("Asia/Manila").format('yyy-MM-DD hh:mm:ss A'));
    return () => {

    }
  }, [PreferredDeliveryDate])

  return (
    <div className="width-100 order-container-right-container">
      <div className="order-container-right-container-content">
        <span className="time-label">ORDER TIME:</span>
        <span className="time-content">
          {getData(moment(CreatedAt).tz("Asia/Manila").format('yyyy-MM-DD hh:mm:ss A'))}
        </span>
      </div>
      <div className="order-container-right-container-content">
        <span className="time-label">PREF. DELIVERY TIME:</span>
        <span className="time-content">
          {getData(
            moment(PreferredDeliveryDate).tz("Asia/Manila").format('yyy-MM-DD hh:mm:ss A'),
          )}
        </span>
          {
              currentStatus === 6 ?
                  (<>
                      <Tooltip title="Unable to update completed order">
                          <button className='time-edit'>Edit</button>
                      </Tooltip>
                  </>) :
                  (
                      <>
                          <button className='time-edit' onClick={() => openModalPreparationTime()}>Edit</button>
                      </>
                  )
          }


      </div>
    </div>
  );
};

export default PrefferedTime;
