import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from "react";
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { ChangeProductStatus, countPage, GetProductList, DeleteProduct } from "./productHelper";
import AddProductButton from "./ProductLists/AddProductButton";
import ExportProductButton from "./ProductLists/ExportProductButton";
import SearchFilterProduct from './ProductLists/Filter/SearchFilterProduct';
import ProductTable from "./ProductLists/ProductTable";
import Slider from "./Slider";
const ProductList = () => {
  useTitle("Pao Kitchen | Products")
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [productSearch, setProductSearch] = useState("")
  const [searchValues, setSearchValues] = useState({})
  useEffect(() => {
    getProductList(1, "", {})
    //eslint-disable-next-line
  }, []);
  const getProductList = (page, search, searchValue) => {
    setProduct([])
    setPageCount(0)
    setPage(page)
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }

    });
    GetProductList(page, search, searchValue).then((res) => {
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;
      setPageCount(countPage(total, limit))
      setProduct(paginatedResults);
      console.log("PRODUCT LIST", paginatedResults);
      Swal.close()
    })
      .catch(
        () => {
          Swal.close()
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No Product(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
        }
      )
  }

  const deleteProduct = (id, e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      footer: "&#169; Pao Kitchen 2023",
      
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProduct(id).then(res => {
          getProductList(1, "", {});
          Swal.fire({
            title: "Deleted",
            text: "Product has been deleted!",
            icon: "success"
          })
        })
      }
    })
  }

  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getProductList(p, productSearch, searchValues)
  }
  const changeStatus = (i, id) => (event) => {
    setProduct((product) => {
      product[i].IsAvailable = !product[i].IsAvailable;
      const { IsAvailable } = product[i]
      ChangeProductStatus(id, IsAvailable).then(res => {
      })
      return {
        ...product,
        [i]: product[i],
      };
    });
  };
  const clearFilter = (e) => {
    e.preventDefault()
    getProductList(1, "", {})
    setPage(1)
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getProductList(1, search, searchValue)
    setProductSearch(search)
    setSearchValues(searchValue)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        product management / product list
      </section>
      <SearchFilterProduct {...{ clearFilter, productFilter }} />
      <ProductTable {...{ product, changeStatus, deleteProduct }} />
      <Slider tableId={"producttable"} />

      <AddProductButton />
      <ExportProductButton />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
    </main>
  );
};

export default ProductList;
