import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import moment from "moment";
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from 'react-router';
import { GetCustomerOrderListother, logout, newTabURL } from '../PublicHelper';
import OrderHistory from './../../../assets/myaccount/orderhistory.svg';
const OrderHistoryTable = () => {
  const history = useNavigate()
  const [orderList, setOrderList] = useState([])
  useEffect(() => {
    // setOrderList([])
    getOther("", "", "", "")
    return () => {

    }
    //eslint-disable-next-line
  }, [])
  const getOther = (page, limit, orderref, datamade) => {
    GetCustomerOrderListother(page, limit, orderref, datamade)
      .then(res => {
        const { paginatedResults } = res.data
        setOrderList(paginatedResults)

      }
      )
      .catch(err => {
        const { response } = err
        logout(response, history)
      })
  }
  const searchSubmit = (e) => {
    getOther("", "", orderRef, dateCreated)
    e.preventDefault()
  }

  const [filter, setFilter] = useState({
    orderRef: "",
    dateCreated: ""
  })
  const handleChange = (e) => {
    const { value, name } = e.target
    setFilter({ ...filter, [name]: value })
  }
  const { orderRef, dateCreated } = filter
  return (
    <div className="right-content">
      <div className="right-content-item">
        <div className="right-content-item-title">
          <img src={OrderHistory} width="16" height="16" alt="icon" />
          <span>{t('orderhistory')}</span>
          <div>
            <form
              onSubmit={(e) => {
                searchSubmit(e);
              }}>
              <input onChange={(e) => handleChange(e)} type="text" name="orderRef" value={orderRef} placeholder={t('ordernumber')} />
              <input onChange={(e) => handleChange(e)} type="date" name="dateCreated" value={dateCreated} placeholder="Date" />
              <button type="submit">
                <SearchIcon />
              </button>
            </form>
          </div>
        </div>
        <div className="right-content-item-table">
          <div className="right-content-item-table-header">
            <div className="normal-start">{t('ordernumber')}</div>
            <div className="normal">{t('date')}</div>
            <div className="normal-half">{t('quantity')}</div>
            <div className="normal-start">{t('paymentoption')}</div>
            <div className="normal-half">{t('price')}</div>
            <div className="normal-start">{t('status')}</div>
            <div className="normal-half"></div>
          </div>
          {
            orderList.length ? (
              Object.keys(orderList).map((key, index) => {
                const { OrderRef, PaymentMode, TotalAmount, CreatedAt, OrderDetails, Status } = orderList[key]
                let Created = new Date(CreatedAt);
                let DateCreated = moment(Created).format("MM/DD/YYYY");
                return (
                  <div key={index} className="right-content-item-table-data">
                    <div className="normal-start">{OrderRef}</div>
                    <div className="normal">{DateCreated}</div>
                    <div className="normal-half">{OrderDetails.length}</div>
                    <div className="normal-start">{PaymentMode}</div>
                    <div className="normal-half"><CurrencyFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </div>
                    <div className="normal-start">
                      {
                        Status === 1 ? t('processing')
                          : Status === 2 ? t('confirmed')
                            : Status === 3 ? t('prepared')
                              : Status === 4 ? t('toship')
                                : Status === 5 ? t('delivered')
                                  : Status === 6 ? t('complete')
                                    : Status === 7 ? t('declined')
                                      : Status === 8 ? t('cancelled')
                                        : Status === 9 ? t('returned')
                                          : t('pending')
                      }
                    </div>
                    <div className="normal-half">
                      <button className="right-content-item-table-data-pay" onClick={() => {
                        newTabURL(`/ordersummary/${OrderRef}`)
                      }}>{t('view')}</button>
                    </div>
                  </div>
                )
              })
            ) : t('nodatafound')
          }


        </div>
      </div>
    </div>
  );
};

export default OrderHistoryTable;
