import Swal from "sweetalert2";

export const copyText = (value) => {
    navigator.clipboard.writeText(value);
    Swal.fire({
        toast: true,
        icon: 'success',
        title: `Copied`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
}