import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import tab from "./reducers/tab";
import token from "./reducers/token";
import CmsConfigPusher from "./reducers/CmsConfigPusher";
import Order from "./reducers/Order";
// remove logger on production

const reducers = combineReducers({
  token,
  tab,
  cmsConfigPusher: CmsConfigPusher,
  order: Order
});

export default createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
