import _ from 'lodash';
import PriceSummary from './PriceSummary';
import ProductDetails from './ProductDetails';
import ExtraOrder from "../ExtraOrder";

const OrderDetailTable = ({
  OrderDetails,
  getData,
  order,
  setOrder,
  setSubtotal,
  udpateOrder,
  user,
  editItem,
  openModalAddProduct,
  openAddFee,
  computeAddedFee,
  currentStatus,
  getOrderDetail,
  openApplyVoucher,
  removeVoucher
}) => {
  return (
    <div className="width-100 order-container-left-products">
      <div className="flex-row-end gap-10 margin-bottom-10">
        {
          currentStatus === 6 ? <></> : (
            <>
              <button className='btn-cancel' onClick={() => openModalAddProduct()}>ADD PRODUCT</button>
              <button className='btn-submit' onClick={() => openAddFee()}>ADD FEE</button>
              <button className='btn-submit' onClick={() => openApplyVoucher()}>APPLY VOUCHER</button>
              <button className='btn-cancel' onClick={(e) => {removeVoucher(e)}}>REMOVE VOUCHER</button>
            </>
          )
        }

      </div>
      <div className="order-container-left-products-title">
        <span className="product-title">PRODUCT</span>
        <div className="product-number">
          <span className="fix-width">PRICE</span>
          <span>QTY</span>
          <span>TOTAL</span>
          <span>ACTION</span>
        </div>
      </div>
      {_.isEmpty(OrderDetails) ? (
        <></>
      ) : (
        _.map(OrderDetails, (value, key) => {
          return <ProductDetails key={key} {...{
            value, getData, order, setOrder, setSubtotal, udpateOrder, editItem, user, computeAddedFee,
            currentStatus
          }} />;
        })
      )}
      <ExtraOrder order={order} refreshOrder={getOrderDetail} />
      <PriceSummary order={order}/>
    </div>
  );
};

export default OrderDetailTable;
