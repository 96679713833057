import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { UpdateShippingArea } from '../EcommerceHelper';

const ShippingAreaUpdate = ({ getAreaList, areaToUpdate, node }) => {
  const [shippingArea, setShippingArea] = useState({
    _id: "",
    Area: "",
    BranchId: "",
    MinOrderAmount: 0,
    TotalOrderAmount: 0,
    ShippingFee: 0,
    IsActive: Boolean(true),
    AreaNote: {
      EN: "",
      VN: "",
      TH: "",
      CN: ""
    }
  })
  const { _id, Area, MinOrderAmount, TotalOrderAmount, ShippingFee, AreaNote } = shippingArea;
  useEffect(() => {
    setShippingArea({
      _id: areaToUpdate._id,
      Area: areaToUpdate.Area,
      BranchId: areaToUpdate.BranchId,
      MinOrderAmount: areaToUpdate.MinOrderAmount,
      TotalOrderAmount: areaToUpdate.TotalOrderAmount,
      ShippingFee: areaToUpdate.ShippingFee,
      IsActive: Boolean(areaToUpdate.IsActive),
      AreaNote: {
        EN: areaToUpdate.AreaNote.EN,
        VN: areaToUpdate.AreaNote.VN,
        TH: areaToUpdate.AreaNote.TH,
        CN: areaToUpdate.AreaNote.CN
      }
    })
    return () => {

    }
  }, [areaToUpdate])

  const submitShippingArea = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    UpdateShippingArea(_id, shippingArea).then(res => {
      Swal.close()
      getAreaList(1, "", {})
      HideItem("#updateShippingArea")
    }).catch(err => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
      console.log(err.response)
    })

  }
  const onchangeText = (e) => {
    const { name, value } = e.target;
    setShippingArea({
      ...shippingArea,
      [name]: value
    })
  }
  const onNoteChange = (e) => {
    const { name, value } = e.target;
    setShippingArea({
      ...shippingArea,
      AreaNote: {
        ...shippingArea.AreaNote,
        [name]: value
      }
    })
  }
  return (
    <div className="add-shipping-area" id='updateShippingArea'>
      <div ref={node} className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            UPDATE: Shipping Area & Conditions
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#updateShippingArea")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => {
          submitShippingArea(e)
        }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="area">Area</label>
              <input onChange={(e) => onchangeText(e)} value={Area} type="text" name="Area" id="area" />
            </div>
            <div className="form-container column">
              <label htmlFor="minreq">Min. Total Order Amount Req.</label>
              <input onChange={(e) => onchangeText(e)} value={MinOrderAmount} type="number" name="MinOrderAmount" id="minreq" />
            </div>
            <div className="form-container column">
              <label htmlFor="totaltofreeship">Total Order Amount To Free Ship</label>
              <input onChange={(e) => onchangeText(e)} value={TotalOrderAmount} type="number" name="TotalOrderAmount" id="totaltofreeship" />
            </div>
            <div className="form-container column">
              <label htmlFor="shippingfee">Shipping Fee</label>
              <input onChange={(e) => onchangeText(e)} value={ShippingFee} type="number" name="ShippingFee" id="shippingfee" />
            </div>
            <div className="form-container column">
              <label htmlFor="note">Note</label>
              <input type="text" onChange={(e) => onNoteChange(e)} value={AreaNote.VN} name="VN" id="note" placeholder='VN' />
              <input type="text" onChange={(e) => onNoteChange(e)} value={AreaNote.EN} name="EN" placeholder='EN' />
              <input type="text" onChange={(e) => onNoteChange(e)} value={AreaNote.CN} name="CN" placeholder='CN' />
              <input type="text" onChange={(e) => onNoteChange(e)} value={AreaNote.TH} name="TH" placeholder='TH' />
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingAreaUpdate;
