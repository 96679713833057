import _ from "lodash";
// import moment from 'moment-timezone';
const axios = require( "axios" );

const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'https://db90-165-22-102-82.ap.ngrok.io'
const branchId = localStorage.getItem( 'branchId' )
const token = localStorage.getItem( 'cmsToken' )
const config = {

  method: undefined,
  url: undefined,
  responseType: undefined,
  headers: {
    'X-Auth-Token': token,
    'Content-Type': 'application/json',
    'cache-control': 'no-cache'
  },
  data: undefined,
  params: undefined

}

// ======================== GET Requests ======================== //

export const GetRateList = async ( page, searchValue ) => {

    const searchValues = _.isEmpty(searchValue) ? {
        Status: "",
    } : {
      ...searchValue
    }
    const { Status } = searchValues      
    const filters = `&status=${Status}` 
    config.method = "get"
    config.url = `${baseUrl}/cms/paymentRate/getPaymentRate?SelectedBranch=${branchId}&page=${page}${filters}`
  
    const res = await axios( config );
    return res.data; 
};

// ======================== PUT Requests ======================== //

export const UpdateRate = async ( data ) => { 
    config.method = "put"
    config.url = `${baseUrl}/cms/paymentRate/updatePaymentRate?SelectedBranch=${branchId}`
    config.data = data
  
    const res = await axios( config );
    return res.data; 
};

// ======================== POST Requests ======================== //

export const AddRate = async ( data ) => {
  
  config.method = 'post'
  config.url = `${baseUrl}/cms/paymentRate/addPaymentRate?SelectedBranch=${branchId}`
  config.data = data

  const res = await axios( config )
  return res.data 
}