import axios from "axios";
import _ from "lodash";

let token = localStorage.getItem("cmsToken");
const baseUrl = process.env.REACT_APP_BASE_URL;
const _axios = axios.create({
    baseURL: `${baseUrl}/cms/customer`,
    headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
    },
})
export const getListCustomer = async (filters = {}) => {
    const params = {
        page: filters?.page ?? 1,
        limit: filters?.limit ?? 10,
        Email: filters?.Email ?? "",
        ContactNum: filters?.ContactNum ?? "",
        IsActive: filters?.IsActive ?? true,
        sortBy: filters?.sortBy ?? "spend",
        sortDesc: filters?.sortDesc ?? 1
    }
    const res = _axios.get("/list", {params})

    return (await res).data
}

export const exportListCustomer = async (filters) => {
    const params = {
        Email: filters?.Email ?? "",
        ContactNum: filters?.ContactNum ?? "",
        IsActive: filters?.IsActive ?? true,
        sortBy: filters?.sortBy ?? "spend",
        sortDesc: filters?.sortDesc ?? 1
    }
    const res = _axios.get("/export", {params, responseType: "arraybuffer"})

    return (await res).data
}

export const GetCustomerOrderList = async (customerid, page, limit, status, orderRef, datemade) => { 
    let config = {
      method: "get",
      url: `${baseUrl}/cms/customer/order/list?CustomerId=${customerid}&page=${page}&limit=${limit}&Status=${status}&OrderRef=${orderRef}&CreatedAt=${datemade}`,
      headers: {
        "Content-Type": "application/json",
        'X-Auth-Token': token
      },
    };
    let res = await axios(config);
    return res.data;
}

export const GetCustomerOrderListother = async (customerid, page, limit, orderRef, datemade) => { 
    let config = {
      method: "get",
      url: `${baseUrl}/cms/customer/order/list?CustomerId=${customerid}&page=${page}&limit=${limit}&Status=1&Status=3&Status=2&Status=5&Status=6&Status=7&Status=8&Status=9&OrderRef=${orderRef}&CreatedAt=${datemade}`,
      headers: {
        "Content-Type": "application/json",
        'X-Auth-Token': token
      },
  
    };
    let res = await axios(config)
    return res.data;
};

export const newTabURL = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const GetSalesReport = async (page, customerid, searchValue) => {
    let searchvalue = _.isEmpty(searchValue) || searchValue === undefined || searchValue === null ? {
      DateFrom: "",
      DateTo: "",
    } : {
      DateFrom: searchValue?.DateFrom || "",
      DateTo: searchValue?.DateTo || ""
    }
    let Page = page || 1
    const limit = searchValue?.limit ?? 10
  
    const { DateFrom, DateTo } = searchvalue
    let token = localStorage.getItem("cmsToken");
    let selectedBranch = localStorage.getItem("branchId");
    let config = {
      method: "get",
      url: `${baseUrl}/cms/customer/order/sales-report`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      params: {
        CustomerId: customerid,
        page: Page,
        BranchId: selectedBranch,
        SelectedBranch: selectedBranch,
        DateFrom,
        DateTo,
        limit
      }
    };
    let res = await axios(config);
    return res.data;
  };

  export const changeEmployee = async (id, IsEmployee) => {
    let token = localStorage.getItem("cmsToken");
    var data = { IsEmployee: IsEmployee };
    let config = {
      method: 'patch',
      url: `${baseUrl}/cms/customer/${id}/change-employee`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: data
    };
  
    let res = await axios(config);
    return res.data;
  }