const ConfirmPaymentStatus = ({order}) => {
    const Paid = order?.Paid ?? false
    const PaymentReceipt = order?.PaymentReceipt ?? {}
    const reason = PaymentReceipt?.Reason ?? ""
    const PaymentMode = order?.PaymentMode ?? ""
    if (PaymentMode.toString().toLowerCase().trim() !== "banktransfer" && !Paid){
        return null
    }

    return (
        <div className="width-100 order-container-right-container">
            <div className="order-container-right-container-content">
                <span className={`time-label text-confirm-payment text-${Paid ? "success" : "warning"}`}>Payment Receipt Confirmed: { Paid ? "Yes" : "No"}</span>
            </div>
            {
                Paid ? null : (
                    <div className="text-confirm-payment-reason">
                        <label className="title">Reason: </label>
                        <p className="reason">{reason}</p>
                    </div>
                )
            }
        </div>
    )
}

export default ConfirmPaymentStatus