import _ from 'lodash';
import React, {useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import {calcSubTotal, computeAddedFee} from "../OderHelper";

const PriceSummary = ({order}) => {
    const {AddFee, OrderAmount, Discount, ShippingAmount, TotalAmount, OrderDetails} = order
    const Subtotal = calcSubTotal(OrderDetails)
    const {totalAddedFee} = computeAddedFee(AddFee, OrderAmount)

    // let showAddfee = false;
    const [showAddfee, setShowAddFee] = useState(false)

    const toggleAddFee = (event) => {
        event.preventDefault()
        setShowAddFee(!showAddfee)
    }
    return (
        <div className="order-container-left-products-total margin-top-20">
            <div className="order-container-left-products-total-number">
                <span>ORDER AMOUNT:</span>
                <span className="price">
                  <CurrencyFormat
                      value={OrderAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
                </span>
            </div>
            <div className="order-container-left-products-total-number">
                <span>DISCOUNT:</span>
                <span className="price">
                      <CurrencyFormat
                          value={Discount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₱ '}
                      />
                </span>
            </div>
            <div className="order-container-left-products-total-number">
                <span>SHIPPING FEE:</span>
                <span className="price">
                  <CurrencyFormat
                      value={ShippingAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
                </span>
            </div>
            <div className="order-container-left-products-total-number">
                <span className='addfee'>ADDED FEE: {!_.isEmpty(AddFee) ? (<small className='showmore-addfee' onClick={toggleAddFee}>{showAddfee ? 'Show less' : 'Show more'}</small>) : ""}</span>
                <span className="price">
                  <CurrencyFormat
                      value={totalAddedFee ?? 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
                </span>
            </div>

            {
                showAddfee ?
                    _.map(AddFee, (value, key) => {
                        const {FeeValue, Name, Type} = value
                        if(FeeValue === undefined || FeeValue === null) {
                            return null
                        }
                        
                        return (
                            <div key={key} className="order-container-left-products-total-number">
                                <small>{Name} :</small>
                                <small className="price">
                                    <CurrencyFormat
                                        value={FeeValue ?? 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={Type === "fixed" ? '₱ ' : ''}
                                        suffix={Type === "percent" ? '%' : ''}
                                    />
                                </small>
                            </div>
                        )
                    })
                    : (<></>)
            }
            <div className="order-container-left-products-total-number">
                <span>SUBTOTAL:</span>
                <span className="price">
                  <CurrencyFormat
                      value={Subtotal ?? 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
                </span>
            </div>
            <div className="order-container-left-products-total-number">
                <span className="total">TOTAL:</span>
                <span className="total price">
                  <CurrencyFormat
                      value={TotalAmount ?? 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
                </span>
            </div>

        </div>
    );
};

export default PriceSummary;

