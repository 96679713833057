import React, { useCallback, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt'; 
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';
import { lalamovestatuses, citystatuses } from '../../../../components/public/CheckOut/OrderSummary/SummaryUtils';
import { Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const SearchFilter = ({ shippingFilter, walletBalance }) => {
 
  let ItemFields = {
    search: "",
    Status: "",
    DateOrderFrom: "",
    DateOrderTo: "",
    City: ""
  }
  const [searchItem, setSearchItem] = useState(ItemFields) 
  const [filters, setFilters] = useState({}) 
  const [showContainer, setShowContainer] = useState(false)

  const fillFilter = (searchItem) => { 
    setFilters({})
    _.forEach(searchItem, (value, key) => { 
      if (!_.isEmpty(value)) {
        setFilters(filter => {
          return {
            ...filter,
            [key]: value
          }
        })
      }
    })
  }
  
  const removeFilter = (e, keyPass) => {
    e.preventDefault()
    let newItem = {
      ...searchItem,
      [keyPass]: ""
    }
    fillFilter(newItem)
    setSearchItem(newItem)
    shippingFilter(e, newItem) 
  }
  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])

  const clearItem = (e) => { 
    setSearchItem(ItemFields)
    fillFilter(ItemFields)
    shippingFilter(e, ItemFields)
  }

  return (
    <section className="content-main-search">
    <div className="flex-column-start width-100 gap-5 content-main-search-filter">
      <div className="filter-container border-top-dashed padding-top-10 " >
        <div className="flex-row-start-start flex-wrap">
          
          <div className="content-main-search-item content-width-meduim">
            <form 
              onSubmit={(e) => {
                e.preventDefault()
                fillFilter(searchItem)
                shippingFilter(e, searchItem)
              }} className="content-main-search-item content-width-small ">
              <label htmlFor="search">SEARCH ORDER ID</label>
              <input autoComplete='off' type="text" name="search" 
                value={searchItem.search || ""} onChange={(e) => { handleChange(e) }} 
              />
            </form>

            <button className="flex-row-center-center gap-5 " onClick={() => {
              setShowContainer(!showContainer)
              }}>
              <FilterAltIcon /> Filters
            </button>  
          </div>  

          <div className="content-main-search-item content-width-small wallet">
            <span className="wallet-title">Wallet Balance: </span> 
            <span className="wallet-value">₱{walletBalance.toLocaleString("en-US")}</span>
          </div>
        </div> 
      </div> 
      <Collapse sx={{ width: "100%" }} in={showContainer}>
        <div className="filter-container border-top-dashed padding-top-10 " >
          <div className='flex-row-start-start flex-wrap '>
            {/* this is where you put your filtersss */}
            <div className="content-main-search-item content-width-small">
              <label htmlFor="Status">Status</label>
              <select name="Status" id="Status" value={searchItem.Status} onChange={(e) => { handleChange(e) }}>
                <option value="">All</option>
                {
                  _.map(lalamovestatuses, (value, key) => {
                    return <option key={key} value={key}>{value}</option>
                  })
                }
              </select>
            </div> 
              
            <div className="content-main-search-item content-width-small">
              <label htmlFor="City">City</label>
              <select name="City" id="City" value={searchItem.City} onChange={(e) => { handleChange(e) }}>
                <option value="">All</option>
                {
                  _.map(citystatuses, (value, key) => {
                    return <option key={key} value={key}>{value}</option>
                  })
                }
              </select>
            </div>

            <div className="content-main-search-item content-width-small">
              <label htmlFor="DateOrderFrom">Date Ordered From</label>
              <input type="date" name="DateOrderFrom" id="DateOrderFrom" value={searchItem.DateOrderFrom} onChange={(e) => { handleChange(e) }} />
            </div>

            <div className="content-main-search-item content-width-small">
              <label htmlFor="DateOrderTo">Date Ordered To</label>
              <input type="date" name="DateOrderTo" id="DateOrderTo" value={searchItem.DateOrderTo} onChange={(e) => { handleChange(e) }} />
            </div> 
            {/*  */}


          </div>
          <div className="flex-row-end gap-10 margin-top-20">
            <Tooltip title="Hide or Cancel filter" placement="bottom-start" arrow>
              <button className="flex-row-center-center gap-5 skeleton-button"
                onClick={(e) => {
                  setShowContainer(false)
                }}
              >
                Hide / Cancel
              </button>
            </Tooltip>
            <Tooltip title="Apply Filter" placement="bottom-start" arrow>
              <button className="flex-row-center-center gap-5"
                onClick={(e) => {
                  fillFilter(searchItem)
                  shippingFilter(e, searchItem)
                }}
              >
                Apply Filters
              </button>
            </Tooltip>
          </div>

        </div>
      </Collapse>
      {
        _.isEmpty(filters) ? <></> : (
          <div className="filter-list">
            Active filters: {
              _.map(filters, (value, key) => {
                return (
                  //<div key={key}></div>
                  <span key={key}>{key === "search" ? value : key}
                    <button onClick={(e) => { removeFilter(e, key) }}>
                      <CloseIcon sx={{ fontSize: "16px", color: "#7e4e2b", "&:hover": { color: "red" } }} />
                    </button>
                  </span>
                )
              })
            }
            <button className="flex-row-center-center gap-5 skeleton-button"
              onClick={(e) => {
                clearItem(e)
              }}
            >
              clear all
            </button>
          </div>
        )
      }

    </div >

  </section >
  )
}

export default SearchFilter