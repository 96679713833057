import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material"; 

const AddCouponButton = ({ addCoupon, tooltip }) => {
  return (
    <>
      <Tooltip title={tooltip} placement="bottom-start" arrow>
        <div className="export-product">
          <div
            className="icon"
            aria-describedby="addPopup"
            onClick={(e) => {
              addCoupon(e)
            }}
          >
            <AddIcon sx={{ fontSize: "18px" }} />
          </div>
          
        </div>
      </Tooltip>
      </>
  );
};
AddCouponButton.defaultProps = {
  tooltip: "",
  addCoupon: () => {},
};
export default AddCouponButton;
