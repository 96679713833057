
import Swal from "sweetalert2";

let axios = require("axios");
const baseUrl = process.env.REACT_APP_BASE_URL;
export const GetPublicBranches = async () => {
  let config = {
    method: "get",
    url: `${baseUrl}/web/branch/active`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetCustomerDetails = async () => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "get",
    url: `${baseUrl}/web/customer`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
}

export const GetOrderDetail = async (ref) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "get",
    url: `${baseUrl}/web/order-details/${ref}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
}
export const GetBankDetail = async (bankId) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "get",
    url: `${baseUrl}/web/payment-methods/${bankId}/bank`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
}



export const GetCouponDetails = async (couponCode) => {
  let token = localStorage.getItem("publicToken")
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "get",
    url: `${baseUrl}/web/get-coupon-list-code/${couponCode}/${branchId}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
}

export const AddShippingAreaWithoutToken = async (data, token) => {
  let config = {
    method: "put",
    url: `${baseUrl}/web/customer/add-shipping`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
}
export const AddShippingArea = async (data) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "put",
    url: `${baseUrl}/web/customer/add-shipping`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
}
export const GetSpecificShippingAreaFee = async (area, service, totalamount) => {
  let token = localStorage.getItem("publicToken");
  let selectedBranch = localStorage.getItem("publicBranch");
  let config = {
    method: "get",
    url: `${baseUrl}/web/shipping-fee?BranchId=${selectedBranch}&Area=${area}&Service=${service}&OrderAmount=${totalamount}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetLalamoveShippingAddressFee = async (data) => {
  let token = localStorage.getItem("publicToken"); 
  let config = {
    method: "post",
    url: `${baseUrl}/web/getshippingfee`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

 
// export const SubmitVoucher = async (data) => {
  // let token = localStorage.getItem("publicToken")
  // let config = {
  //   method: "post",
  //   url: `${baseUrl}/web/fetch/coupon-discount`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     "X-Auth-Token": token
  //   },
  //   data: data
  // };
  // let res = await axios(config);
  // return res.data;
// };


export const GetShippingAreaOnly = async () => {
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "get",
    url: `${baseUrl}/web/shipping-area?BranchId=${branchId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
}

export const GetShippingAreaOnlyCMS = async () => {
  let branchId = localStorage.getItem("branchId")
  let config = {
    method: "get",
    url: `${baseUrl}/web/shipping-area?BranchId=${branchId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
}

export const GetBannerList = async (page, search, searchValue) => {
  // let searchValues = _.isEmpty(searchValue) || searchValue === undefined ? {
  //   Category: "",
  //   Locale: "",
  //   Type: "",
  //   Subcategory: "",
  //   Locale: "",
  // } : {
  //   ...searchValue
  // }
  const token = localStorage.getItem("cmsToken");

  const config = {
    method: "get",
    url: `${baseUrl}/cms/public-manager/banner/list?isActive=${searchValue}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const res = await axios(config);
  return res.data;

}

export const GetContactListWeb = async (searchValue) => {

  const token = localStorage.getItem("cmsToken");

  const config = {
    method: "get",
    url: `${baseUrl}/web/contact/list?isActive=${searchValue}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const res = await axios(config);
  return res.data;

}

export const GetBannerListWeb = async (page, search, searchValue) => {
  // let searchValues = _.isEmpty(searchValue) || searchValue === undefined ? {
  //   Category: "",
  //   Locale: "",
  //   Type: "",
  //   Subcategory: "",
  //   Locale: "",
  // } : {
  //   ...searchValue
  // }
  const token = localStorage.getItem("cmsToken");

  const config = {
    method: "get",
    url: `${baseUrl}/web/banner/list?isActive=${searchValue}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const res = await axios(config);
  return res.data;

}

export const DeleteBanner = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'delete',
    url: `${baseUrl}/cms/public-manager/banner/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };

  let res = await axios(config);
  return res;

}

export const GetContactList = async (page, search) => {

  const token = localStorage.getItem("cmsToken");

  const config = {
    method: "get",
    url: `${baseUrl}/cms/public-manager/contact/list?isActive=${search}&page=${page}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const res = await axios(config);
  return res.data;

}

export const SubmitCreateContact = async (contact) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'post',
    url: `${baseUrl}/cms/public-manager/contact/add`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: contact
  };

  let res = await axios(config);
  return res.data;
}

export const UpdateContact = async (id, data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'patch',
    url: `${baseUrl}/cms/public-manager/contact/update/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  try {
    let res = await axios(config);
    return res.data;
  } catch (error) { }
}

export const DeleteContact = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: 'delete',
    url: `${baseUrl}/cms/public-manager/contact/delete/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };

  let res = await axios(config);
  return res;

}

export const GetCustomerOrderList = async (page, limit, status, orderRef, datemade) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "get",
    url: `${baseUrl}/web/order/list?page=${page}&limit=${limit}&Status=${status}&OrderRef=${orderRef}&CreatedAt=${datemade}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
};
export const GetCustomerOrderListother = async (page, limit, orderRef, datemade) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "get",
    url: `${baseUrl}/web/order/list?page=${page}&limit=${limit}&Status=1&Status=3&Status=2&Status=5&Status=6&Status=7&Status=8&Status=9&OrderRef=${orderRef}&CreatedAt=${datemade}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },

  };
  let res = await axios(config)
  return res.data;
};

export const DeleteCart = async (id) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "delete",
    url: `${baseUrl}/web/cart/${id}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
  };
  let res = await axios(config);
  return res.data;
};


export const DeleteShippingAddress = async (id) => {
  let token = localStorage.getItem("publicToken")
  let data = {
    ShippingId: id
  }
  let config = {
    method: "delete",
    url: `${baseUrl}/web/customer/remove-shipping`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitForgotPassword = async (data) => {
  let email = {
    "Email": data
  }
  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/forgot-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: email
  };
  let res = await axios(config);
  return res.data;
};


export const SubmitResetPassword = async (data) => {
  const { NewPassword, t, id } = data
  let reset = {
    "id": id,
    "t": t,
    "NewPassword": NewPassword
  }
  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/reset-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: reset
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitRegister = async (data) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitCheckoutRegister = async (data) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/checkoutregister`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitVoucher = async (data) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "post",
    url: `${baseUrl}/web/fetch/coupon-discount`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitLogin = async (data) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitLoginGoogle = async (data) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/google`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const SubmitLoginFacebook = async (data) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/auth/facebook`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const GetPublicProductList = async (branchid, search, limit) => {

  let config = {
    method: "get",
    url: `${baseUrl}/web/products/category`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      branchId: branchid || "",
      sort: "Alphabet",
      sortValue: "ASC",
      search: search,
      limit: limit ?? "-1"
    }
  };
  let res = await axios(config);
  return res.data;
};
export const GetPublicProductListPerBranch = async (branchid, limit = "") => {

  let config = {
    method: "get",
    url: `${baseUrl}/web/products/todays-menu`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      branchId: branchid || "",
      sort: "Alphabet",
      sortValue: "ASC",
      limit: limit
    }
  };
  let res = await axios(config);
  return res.data;
};
export const GetPublicPaymentMethod = async () => {
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "get",
    url: `${baseUrl}/web/payment-methods?BranchId=${branchId || ""}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetSpecificPaymentMethod = async (code) => {
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "get",
    url: `${baseUrl}/web/payment-methods?BranchId=${branchId || ""}&Code=${code}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};


export const AddToCartSubmit = async (userId, cart) => {
  let branchId = localStorage.getItem("publicBranch")
  let data = {
    "UserId": userId,
    "CartDetails": cart
  };

  let config = {
    method: "post",
    url: `${baseUrl}/web/cart?BranchId=${branchId || ""}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const UpdateCart = async (cartId, cart, userId) => {
  let branchId = localStorage.getItem("publicBranch")
  let data = {
    "UserId": userId,
    "CartDetails": cart
  }

  let config = {
    method: "patch",
    url: `${baseUrl}/web/cart/${cartId}?BranchId=${branchId || ""}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};
export const UploadReceipt = async (paymentReceipt, orderId) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "patch",
    url: `${baseUrl}/web/order/payment-receipt/${orderId}`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: paymentReceipt
  };
  let res = await axios(config);
  return res.data;
};
export const UpdateShippingArea = async (shippingArea) => {
  let token = localStorage.getItem("publicToken")
  let data = shippingArea

  let config = {
    method: "patch",
    url: `${baseUrl}/web/customer/update-shipping`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const UpdateCustomer = async (userData) => {
  let token = localStorage.getItem("publicToken")
  let data = userData

  let config = {
    method: "put",
    url: `${baseUrl}/web/customer`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};
export const UpdatePassword = async (userData) => {
  let token = localStorage.getItem("publicToken")
  let data = userData

  let config = {
    method: "patch",
    url: `${baseUrl}/web/customer/change-password`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const GetCustomerCart = async (userId) => {
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "get",
    url: `${baseUrl}/web/user/cart/${userId}?BranchId=${branchId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};


export const GetProductDetailCart = async (CartId, BranchId, userId) => {

  let config = {
    method: "get",
    url: `${baseUrl}/web/user/cart-products?CartId=${CartId}&BranchId=${BranchId}&UserId=${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};
export const IshippeeGet = async (keyword) => {

  let config = {
    method: "get",
    url: `https://api.ishipee.com/api/address/search?keyword=${keyword}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  return res.data;
};


export const CheckOutCartNoAccount = async (data, token) => {

  let config = {
    method: "post",
    url: `${baseUrl}/web/order/checkout`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};
export const CheckOutCart = async (data) => {
  let token = localStorage.getItem("publicToken")
  let config = {
    method: "post",
    url: `${baseUrl}/web/order/checkout`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const CheckCartAvailability = async (cart) => {
  let token = localStorage.getItem("publicToken")
  let branchId = localStorage.getItem("publicBranch")
  let config = {
    method: "post",
    url: `${baseUrl}/web/product-stocks`,
    headers: {
      "Content-Type": "application/json",
      'X-Auth-Token': token
    },
    data: {
      BranchId: branchId,
      Products: cart
    }
  };
  let res = await axios(config);
  return res.data;
};


export const logout = (response, history) => {
  if (response?.status === 403 || response?.status === 401) {
    Swal.fire({
      title: response?.data?.mess,
      text: "Please login again to continue",
      icon: 'error',
      footer: "&#169; Pao Kitchen 2023",
      showCancelButton: false,
      confirmButtonColor: '#50311b',
      confirmButtonText: 'Okay',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("publicToken");
        window.location.reload();
      }
    })
  }
}

export const newTabURL = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const GetPublicProductTemp = async (branchid) => {

  let config = {
    method: "get",
    url: `${baseUrl}/web/getproductlogs`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      BranchId: branchid || ""
    }
  };
  let res = await axios(config);
  return res.data;
};