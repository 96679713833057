import _ from "lodash";
import moment from "moment-timezone";
let today = moment(new Date()).tz("Asia/Manila").add(30, "minutes").format("YYYY-MM-DD"); 
let time = moment(today).isAfter(today) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
// let InitialDelDate = moment(`${InitialDate} ${InitialTime}`).tz("Asia/Manila").format().toString()
let InitialDelDate = moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString()

export const placeOrderPayload = (voucher, discount, totalAmount, orderAmount, checkoutCart, customerInfo, shippingArea, IsMealAllowance) => {
  // console.log(customerInfo);
  const { OrderNote, PaymentMode, PreferredDeliveryDate, SendOption, PaymentReceipt, ContactDetails, DeliveryDetails } = customerInfo
  const { Email, ContactNum, FirstName, LastName } = ContactDetails
  const { Area, Address, PlaceId } = DeliveryDetails

  let branchId = localStorage.getItem("publicBranch")
  let payload;
  if (_.isEmpty(ContactNum)) {
    payload = ""
  } else {
    payload = "+63" + ContactNum
  }
  return {
    "BranchId": branchId,
    "AgentCode": "DEV",
    "CouponCode": voucher,
    "Courier": {
      "Code": "LALAMOVE",
      "TrackingId": null,
      "PickupAddress": {
        "Name": "Pao Kitchen",
        "Phone": "09602472525",
        "Address": "174 Yakal",
        "Place": "Makati, Metro Manila, Philippines",
        "PlaceId": "ChIJk6scXQrJlzMRhOaBazQzwzc"
      },
      "LalamoveDetails": shippingArea.LalamoveDetails
    },
    "CustomerId": "",
    "Locale": "EN", 
    "OrderDetails": checkoutCart,
    "OrderNote": OrderNote,
    "PaymentMode": PaymentMode,
    "PreferredDeliveryDate": PreferredDeliveryDate,
    "SendOption": SendOption,
    "ContactDetails": {
      "FullName": FirstName + " " + LastName,
      "Email": Email,
      "MobileNum": payload //ContactNum
    },
    "DeliveryDetails": {
      "Area": Area,
      "Address": Address,
      "PlaceId": PlaceId,
      "Place": Address
    },
    "PaymentReceipt": {
      "PaymentId": PaymentReceipt?.PaymentId === "" || PaymentReceipt?.PaymentId === undefined ? null : PaymentReceipt?.PaymentId
    },
    "Status": "0",
    "StatusNote": [],
    "ShippingAmount": shippingArea.ShippingFee,
    "Discount": discount,
    "OrderAmount": orderAmount,
    "TotalAmount": totalAmount,
    "IsMealAllowance": IsMealAllowance
  }
}

export const customerInfovalue = (checkoutCart) => {
  return {
    OrderDetails: checkoutCart,
    OrderNote: "",
    PaymentMode: "",
    PreferredDeliveryDate: InitialDelDate,
    SendOption: "DELIVERY",
    PaymentReceipt: {
      PaymentId: ""
    },
    ContactDetails: {
      Email: "",
      ContactNum: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: "",
      Language: ""
    },
    DeliveryDetails: {
      Area: "",
      Address: "",
      PlaceId: "",
      Place: ""
    }
  }
}
export const customerInformationValue = {
  ShippingAddress: [],
  OrderDetails: [],
  OrderNote: "",
  PaymentMode: "",
  PaymentReceipt: {
    PaymentId: ""
  },
  PreferredDeliveryDate: InitialDelDate,
  SendOption: "DELIVERY",
  ContactDetails: {
    Email: "",
    ContactNum: "",
    FullName: ""
  },
  DeliveryDetails: {
    Area: "",
    Address: "",
    PlaceId: "",
    Place: ""
  },
}
export const mergeProductStock = (list) => {
  const out = [];
  for (let entry of list) {
    const existingEntry = out.find(o => o.ProductId === entry.ProductId);
    if (existingEntry) {
      existingEntry.IsStock = entry.IsStock
    } else {
      out.push(entry);
    }
  }
  return out;
}
export const mapCartLoggedIn = (data, userSendOption, resp) => {
  return _.map(data, (value, key) => {
    const { SaleDateFrom, SaleDateTo, SalePrice, Product, Price } = value
    let price = getPrice(Price, userSendOption)
    let realPrice = showPrice(SaleDateFrom, SaleDateTo, SalePrice, price)
    return {
      "ProductId": Product._id,
      "ProductName": Product.Name,
      "ProdImage": Product.Images.Delivery,
      "SKU": Product.SKU,
      "Price": realPrice,
      "Quantity": resp.data.CartDetails[key].Quantity,
      "TotalPrice": realPrice * resp.data.CartDetails[key].Quantity,
      "OrderProdType": "REGULAR",
      "PreparationTime": Product.PreparationTime
    }
  })
}
const showPrice = (SaleDateFrom, SaleDateTo, SalePrice, Price) => {
  const isBetween = moment().tz( 'Asia/Manila' ).isBetween( SaleDateFrom, SaleDateTo )
  if (isBetween && SalePrice !== 0) {
    return SalePrice;
  } else {
    return Price;
  }
}
export const mapCartNotLoggedIn = (cart, userSendOption) => {
  return _.map(cart, (value, key) => {
    const { SaleDateFrom, SaleDateTo, SalePrice, Price, Quantity, ProductId, OrderProdType, SKU, ProdImage, ProductName, PreparationTime } = value
    let price = getPrice(Price, userSendOption)
    let realPrice = showPrice(SaleDateFrom, SaleDateTo, SalePrice, price)
    return {
      "ProductId": ProductId,
      "ProductName": ProductName,
      "ProdImage": ProdImage,
      "SKU": SKU,
      "Price": realPrice,
      "Quantity": Quantity,
      "TotalPrice": realPrice * Quantity,
      "OrderProdType": OrderProdType,
      "PreparationTime": PreparationTime
    }
  })
}
export const getPrice = (Price, priceData) => {
  let price = 0
  switch (priceData) {
    case "DELIVERY":
      price = Price.Delivery
      break;
    case "PICKUP":
      price = Price.Regular
      break;
    case "DINEIN":
      price = Price.DineIn
      break;
    case "KTV":
      price = Price.KTV
      break;
    default:
      break;
  }
  return price
}
export const placeOrderPayloadWithToken = (voucher, discount, totalAmount, orderAmount, userInfo, checkoutCart, customerInfo, shippingArea, isMealAllowance) => {

  const { OrderNote, PaymentMode, PreferredDeliveryDate, SendOption, PaymentReceipt, ContactDetails, DeliveryDetails } = customerInfo
  const { Email, ContactNum, FullName } = ContactDetails
  const { Area, Address, PlaceId } = DeliveryDetails
  let today = moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD")
  let time = moment(today).isAfter(new Date()) ? 0 : moment().tz("Asia/Manila").add(1, 'hour').format("HH")
  let branchId = localStorage.getItem("publicBranch")
  let payload;
  if (_.isEmpty(ContactNum)) {
    payload = ""
  } else {
    payload = "+63" + ContactNum
  }
  return {
    "BranchId": branchId,
    "AgentCode": "DEV",
    "CouponCode": voucher,
    "Courier": {
      "Code": "LALAMOVE",
      "TrackingId": null,
      "PickupAddress": {
        "Name": "Pao Kitchen",
        "Phone": "09602472525",
        "Address": "174 Yakal",
        "Place": "Makati, Metro Manila, Philippines",
        "PlaceId": "ChIJk6scXQrJlzMRhOaBazQzwzc"
      },
      "LalamoveDetails": shippingArea.LalamoveDetails
    },
    "CustomerId": userInfo._id,
    "Locale": "EN", 
    "OrderDetails": checkoutCart,
    "OrderNote": OrderNote,
    "PaymentMode": PaymentMode,
    "PreferredDeliveryDate": PreferredDeliveryDate || moment(`${today} ${time}:00`).tz("Asia/Manila").format().toString(),
    "SendOption": SendOption,
    "ContactDetails": {
      "FullName": FullName,
      "Email": Email,
      "MobileNum": payload //ContactNum
    },
    "DeliveryDetails": {
      "Area": Area,
      "Address": Address,
      "PlaceId": PlaceId,
      "Place": Address
    },
    "PaymentReceipt": {
      "PaymentId": PaymentReceipt?.PaymentId === "" ? null : PaymentReceipt?.PaymentId
    },
    "Status": "0",
    "StatusNote": [],
    "ShippingAmount": shippingArea.ShippingFee,
    "Discount": discount,
    "OrderAmount": orderAmount,
    "TotalAmount": totalAmount,
    "IsMealAllowance": isMealAllowance
  }
}


export const totalAmountComputer = (checkoutCart, shippingArea) => {
  let total = 0
  Object.keys(checkoutCart).forEach(key => {
    const { TotalPrice } = checkoutCart[key]
    total += TotalPrice
  })
  let totalAmount = Math.abs((total + shippingArea.ShippingFee))
  return {
    totalAmount: totalAmount,
    total: total
  }

}