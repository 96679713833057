import CircleIcon from "@mui/icons-material/Circle";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import { newTabURL } from "../../../components/public/PublicHelper";
import { countPage } from "../../ProductManager/productHelper";
import Slider from "../../ProductManager/Slider";
import { GetAllCoupons } from "../discountHelper";
import AddCouponButton from "./AddCouponButton";
import WebcouponFilter from "./Filter/WebcouponFilter";
import WebcouponTable from "./WebcouponTable";
import UpdateCouponModal from "./UpdateCouponModal";
import AddCouponModal from "./AddCouponModal";
import UpdateExpModal from "./UpdateExpModal";
import SelectCouponType from "./SelectCouponType";
import AddAutoCouponModal from "./AddAutoCouponModal"; 

const WebCoupons = () => {
  useTitle("Pao Kitchen | Web Coupons");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filter, setFilters] = useState({});
  const [couponList, setCouponList] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAutoCoupon, setOpenAutoCoupon] = useState(false);
  const [openUpdateExpModal, setOpenUpdateExpModal] = useState(false)
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [couponId, setCouponId] = useState("");
  useEffect(() => {
    getAllCoupon(1, filter);
    return () => {};
    //eslint-disable-next-line
  }, []);
  const handlePage = (e, p) => {
    e.preventDefault();
    setPage(p);
    getAllCoupon(p, filter);
  };

  const showUpdateModal = (e, id, isAuto) => {
    e.preventDefault();
    if (isAuto) {
      setOpenUpdateExpModal(true);
    } else {
      setOpenUpdateModal(true);
    }

    setCouponId(id);
  };

  const closeUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  const showAutoModal = (e) => {
    e.preventDefault();
    setOpenAutoCoupon(true);
  };

  const closeAutoModal = () => {
    setOpenAutoCoupon(false);
  };

  const showAddModal = (e) => {
    e.preventDefault();
    setOpenAddModal(true);
  };

  const closeAddModal = () => {
    setOpenAddModal(false);
  };

  const showSelectModal = (e) => {
    e.preventDefault();
    setOpenSelectModal(true);
  };

  const closeSelectModal = () => {
    setOpenSelectModal(false);
  };

  const showUpdateExpModal = (e) => {
    e.preventDefault();
    setOpenUpdateExpModal(true);
  };

  const closeUpdateExpModal = () => {
    setOpenUpdateExpModal(false);
  };

  const getAllCoupon = (page, searchValue) => {
    setCouponList([]);
    setPageCount(0);
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    GetAllCoupons(page, searchValue)
      .then((res) => {
        Swal.close();
        const { paginatedResults, metadata } = res?.data;
        const { total, limit } = metadata;
        setPageCount(countPage(total, limit));
        setCouponList(paginatedResults);
      })
      .catch((err) => {
        setPageCount(0);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Coupon(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          },
        });
        console.log(err.response);
      });
  };
  const productFilter = (e, searchValues) => {
    e.preventDefault();
    console.log(searchValues);
    getAllCoupon(1, searchValues);
    setFilters(searchValues);
  };
  const clearFilter = (e) => {
    e.preventDefault();
    getAllCoupon(1, {});
    setFilters({});
  };

  const limitations = (e) => {
    newTabURL(`/admin/cms/web-coupons/limitations`);
  };
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        discount manager / web coupons
      </section>
      <WebcouponFilter {...{ productFilter, clearFilter, limitations }} />
      <div className="flex-row-start-center margin-bottom-15">
        The symbol &nbsp;{" "}
        <CircleIcon sx={{ color: "#1bb76d", fontSize: "12px" }} />
        &nbsp; means the coupon status is ACTIVE. While the &nbsp;
        <CircleIcon sx={{ color: "gray", fontSize: "12px" }} />
        &nbsp; means INACTIVE.
      </div>

      <WebcouponTable
        tableContents={couponList}
        showUpdateModal={showUpdateModal}
        refreshTable={(e) => {
          clearFilter(e);
        }}
      />
      {pageCount === 0 ? (
        <></>
      ) : (
        <Pagination
          count={pageCount}
          onChange={handlePage}
          page={page}
          shape="rounded"
        />
      )}
      <Slider tableId={"discountTable"} />
      <AddCouponButton addCoupon={showSelectModal} tooltip={"Add Coupon"} />
      
      {openSelectModal ? (
        <SelectCouponType
          modalLimitation={openSelectModal}
          openAutoCoupon={(e) => showAutoModal(e)}
          openAddModal={(e) => showAddModal(e)}
          closeModalLimitation={() => closeSelectModal()}
        />
      ) : (
        <></>
      )}

      {openAutoCoupon ? (
        <AddAutoCouponModal
          modalLimitation={openAutoCoupon}
          closeModalLimitation={() => {
            closeAutoModal();
          }}
          refreshTable={(e) => {
            clearFilter(e);
          }}
          id={couponId}
        />
      ) : (
        <></>
      )}

      {openUpdateExpModal ? (
        <UpdateExpModal
          modalLimitation={openUpdateExpModal}
          closeModalLimitation={() => {
            closeUpdateExpModal();
          }}
          refreshTable={(e) => {
            clearFilter(e);
          }}
          id={couponId}
        />
      ) : (
        <></>
      )}

      {openAddModal ? (
        <AddCouponModal
          modalLimitation={openAddModal}
          closeModalLimitation={() => {
            closeAddModal();
          }}
          refreshTable={(e) => {
            clearFilter(e);
          }}
          id={couponId}
        />
      ) : (
        <></>
      )}

      {openUpdateModal ? (
        <UpdateCouponModal
          modalLimitation={openUpdateModal}
          closeModalLimitation={() => {
            closeUpdateModal();
          }}
          refreshTable={(e) => {
            clearFilter(e);
          }}
          id={couponId}
        />
      ) : (
        <></>
      )}
    </main>
  );
};

export default WebCoupons;
