import parse from "html-react-parser";
import moment from 'moment';
import React from 'react';
const LogsC = ({
  value, getData, getStatus
}) => {
  const { DateAdded, StatusUpdateBy, Note, StatusNum } = value
  return (
    <div className="order-container-right-container-content logs">
      <div className="logs-container">
        {
          StatusNum === null ? (<></>) : (
            <span className="status-log">
              <strong>{getData(StatusUpdateBy)}</strong>
              {" "}changed the order status to{" "}
              <strong>{getStatus(StatusNum)}</strong>.
            </span>
          )
        }
        <span className="status-date">
          {getData(moment(DateAdded).tz("Asia/Manila").format('YYYY-MM-DD hh:mm:ss a'))}
        </span>
        <span className="status-note">{parse(String(getData(Note)))}</span>
      </div >
    </div >
  );
};

export default LogsC;
