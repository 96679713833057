import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ScrollContainer from "react-indiana-drag-scroll";

const ContactTable = ({ contactList, showEditContactForm, deleteContact }) => {
  const [contact, setContact] = useState([]);
  useEffect(() => {
    setContact(contactList);
    console.log(contactList);
    return () => {};
  }, [contactList]);

  return (
    <ScrollContainer className="scroll-container content-product-table">
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start item-name">
          Link
        </div>
        <div className="content-product-table-head-item normal-start item-name">
          Type
        </div>
        <div className="content-product-table-head-item normal-start item-name">
          Status
        </div>
        <div className="content-product-table-head-item normal-start item-name">
          Actions
        </div>
      </div>
      {!_.isEmpty(contact) ? (
        Object.keys(contact).map((key, index) => {
          const { id, link, type, isActive } = contact[key];
          return (
            <div className="content-product-table-data " key={index}>
              <div className="content-product-table-data-item normal-start item-name">
                <a href={link} target="_blank" rel="noreferrer" style={{textDecoration:"none"}}> {link} </a>
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                {type}
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                {" "}
                {isActive ? "Active" : "Inactive"}{" "}
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                <EditIcon
                  data-tip
                  data-for="editGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showEditContactForm(e, contact[key]);
                  }}
                />

                <DeleteIcon
                  data-tip
                  data-for="deleteGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteContact(id, e);
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item center item-name">
            No Link(s) Found
          </div>
          <div className="content-product-table-data-item center item-name"></div>
          <div className="content-product-table-data-item center item-name"></div>
        </div>
      )}
    </ScrollContainer>
  );
};

export default ContactTable;
