import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState, useRef } from "react";
import Compressor from "compressorjs";
import _ from "lodash";
import Swal from "sweetalert2";
import { HideItem, GetBase64 } from "../../ProductManager/productHelper";

const AddBannerFormEdit = ({ bannerValue, updateBanner }) => {
  const closeAddBannerForm = () => {
    HideItem("#bannerAddFormEdit");
  };
  const { image, isActive, priority } = bannerValue;

  let selectedBranch = localStorage.getItem("branchId");
  const [banner, setBanner] = useState({
    locale: "EN",
    page: "Home",
    type: "Slide",
    image: "",
    isActive: true,
    branch: selectedBranch,
    viewOption: "Branch",
    priority: 1,
  });
  const updateBannerNode = useRef(null);

  useEffect(() => {
    setBanner(bannerValue)

    return () => {};
  }, [bannerValue]);

  

  const changeBannerImage = (e) => {
    const { name } = e.target;
    let file = document.querySelector("#banner-img-update").files ?? "";
    if (!_.isEmpty(file)) {
      new Compressor(file[0], {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          GetBase64(compressedResult).then((data) => {
            let imageArray = data.split(",");
            console.log(imageArray[1])
            setBanner({
              ...banner,
              image: imageArray[1],
            });
          });
        },
      });
    }
  };

  const changeStatus = (e) => {
    const { name, value } = e.target;

    const statusVal = value === "true" || value === true ? true : false;
    setBanner({ ...banner, [name]: statusVal });
  };

  return (
    <div className="modal-add-group" id="bannerAddFormEdit">
    <div ref={updateBannerNode}  className="content-main-update-banner">
      <div className="content-main-add-user-header">
        <div className="content-main-add-user-header-title">Update banner</div>
        <div className="content-main-add-user-header-close">
          <CloseIcon
            sx={{
              fontSize: "18px",
              "&:hover": {
                fontWeight: "bold",
                color: "#50311b",
                cursor: "pointer",
              },
            }}
            onClick={closeAddBannerForm}
          />
        </div>
      </div>
      <div>
        <form className="modal-add-group-form-main">
          <div className="modal-add-group-form-main-status">
          <label htmlFor="banner-img">Banner image* </label>
            <input
              type="file"
              id="banner-img-update"
              name="image"
              accept="image/png, image/jpeg, image/gif"
              onChange={(e) => changeBannerImage(e)}
            />
            <label htmlFor="categorystatus">status</label>
            <select name="isActive" id="categorystatus" value={banner.isActive} onChange={changeStatus}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </form>
      </div>
      <div className="content-main-add-user-footer">
        <button
          className="content-main-add-user-footer-cancel"
          onClick={closeAddBannerForm}
        >
          cancel
        </button>
        <button
          className="content-main-add-user-footer-create"
          onClick={(e) => {
            updateBanner(e, banner)
            setBanner({...banner, image: ""})
          }}
        >
          Update
        </button>
      </div>
    </div>
    </div>
  );
};

export default AddBannerFormEdit;
