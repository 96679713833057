import { useEffect, useRef } from "react";
import moment from "moment";  
import ScrollContainer from 'react-indiana-drag-scroll';
import '../currency.scss'
import EditIcon from '@mui/icons-material/Edit'; 

const RateTable = ({ rateList, showRateUpdate }) => {
    const container = useRef(null) 
    useEffect(() => {
        container.current.getElement().id = "rateTable"  
    }, []) 
    return (
        <ScrollContainer className="scroll-container" ref={container}>
            <div className="currency-table-head">
                <div className="item-size-medium item-start">Rate</div>
                <div className="item-size-medium">Status</div>
                <div className="item-size-medium">Created By</div>
                <div className="item-size-medium">Created At</div>
                <div className="item-size-medium">Last Modified</div>
                <div className="item-size-small">Action</div>
            </div>
            {rateList.length ? (
                Object.keys(rateList).map((key, index) => {
                    const { createdAt, rate, status, updatedAt, updatedBy, _id } = rateList[key]
                    // console.log("Status: ", status)
                    let StatValue = status ? 'Active' : 'Inactive'
                    return (
                        <div className="currency-table-data" key={index}>
                            <div className="item-size-medium item-start">{rate}</div>
                            <div className="item-size-medium">{StatValue}</div>
                            <div className="item-size-medium">{updatedBy}</div>
                            <div className="item-size-medium">
                                <div>{moment( createdAt ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )}<br></br>
                                <div className="font_10">{moment( createdAt ).tz( "Asia/manila" ).format( "HH:mm:ss" )}</div></div>
                            </div>
                            <div className="item-size-medium">
                                <div>{moment( updatedAt ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )}<br></br>
                                <div className="font_10">{moment( updatedAt ).tz( "Asia/manila" ).format( "HH:mm:ss" )}</div></div>
                            </div> 
                            <div className="item-size-small">
                                <button className='edit-btn' onClick={() => {
                                    showRateUpdate(key, _id)
                                }}><EditIcon sx={{
                                    fontSize: "20px"
                                }} /></button>   
                            </div>
                        </div>
                    )
                })
            ) : (
                <div className="currency-table-data">
                    <div className="item-size-medium"></div>
                    <div className="item-size-medium"></div>
                    <div className="item-size-big">No Rate List Found</div>
                    <div className="item-size-medium"></div>
                    <div className="item-size-medium"></div>
                </div>
            )}
        </ScrollContainer>
    )
}

export default RateTable;