const EXTRA_ORDER_STATUS = {
    pending: 0,
    doing: 1,
    done: 2,
    cancel: 3
}

const EXTRA_ORDER_PRINT_TYPES = {
    all: "All",
    onlyMain: "Only Main Order",
    onlyExtra: "Only Extra Order"
}

module.exports = {
    EXTRA_ORDER_STATUS,
    EXTRA_ORDER_PRINT_TYPES
}