import { useEffect, useState } from 'react';

const ItemDetail = ({
  itemDetail,
  orderE,
  closeModalEdit,
  submitNewItem
}) => {
  const [item, setItem] = useState(itemDetail)
  useEffect(() => {
    return () => {

    }
  }, [itemDetail])
  const handleChange = (e) => {
    const { name, value } = e.target
    setItem({
      ...item,
      [name]: name === "ProductName" ? value : Number(value),
      TotalPrice: name === "Quantity" ? item.Price * Number(value) : name === "Price" ? item.Quantity * Number(value) : item.TotalPrice
    })
  }
  const { Quantity, Price, ProductName } = item
  return (
    <div className='order-modal-container'>
      <h3>Product</h3>
      <div className='input'>
        <label htmlFor="productNameEdit">Product Name</label>
        <input onChange={(e) => handleChange(e)} name="ProductName" value={ProductName.EN || ProductName.VN || ProductName.CN || ProductName.TH} type="text" id="productNameEdit" disabled={true} />
      </div>
      <div className='flex-row-space-between gap-5'>
        <div className='input'>
          <label htmlFor="quantityEdit">Quantity</label>
          <input onChange={(e) => handleChange(e)} name="Quantity" value={Quantity} min={1} type="number" id="quantityEdit" />
        </div>
        <div className='input'>
          <label htmlFor="priceEdit">Price</label>
          <input onChange={(e) => handleChange(e)} name="Price" value={Price} min={0} type="number" id="priceEdit" />
        </div>
      </div>

      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitNewItem(e, item)
        }}>Save</button>
      </div>

    </div>
  )
}

export default ItemDetail