
import _ from "lodash";
const baseUrl = process.env.REACT_APP_BASE_URL;
let axios = require("axios");

export const GetSalesReport = async (page, searchValue) => {
  let searchvalue = _.isEmpty(searchValue) || searchValue === undefined || searchValue === null ? {
    DateFrom: "",
    DateTo: "",
  } : {
    DateFrom: searchValue?.DateFrom || "",
    DateTo: searchValue?.DateTo || ""
  }
  let Page = page || 1
  const limit = searchValue?.limit ?? 10

  const { DateFrom, DateTo } = searchvalue
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/sales-report`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
      page: Page,
      BranchId: selectedBranch,
      SelectedBranch: selectedBranch,
      DateFrom,
      DateTo,
      limit
    }
  };
  let res = await axios(config);
  return res.data;
};

export const GetProductsReport = async (page, searchValue) => {
  let searchvalue = _.isEmpty(searchValue) ? {
    DateFrom: "",
    DateTo: "",
  } : {
    DateFrom: searchValue?.DateFrom || "",
    DateTo: searchValue?.DateTo || ""
  }
  const { DateFrom, DateTo } = searchvalue
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let Page = page || 1
  const limit = searchValue?.limit ?? ""
  const sortBy = searchValue?.sortBy ?? "total"
  const sendOptions = searchValue?.sendOptions ?? []

  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/product-report`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
        page: Page,
        BranchId: selectedBranch,
        SelectedBranch: selectedBranch,
        DateFrom: DateFrom,
        DateTo: DateTo,
        limit,
        sortBy,
        sendOptions
    }
  };
  let res = await axios(config);
  return res.data;
};

export const GetProductsReportByCategory = async (page, searchValue) => {
  let searchvalue = _.isEmpty(searchValue) ? {
    DateFrom: "",
    DateTo: "",
  } : {
    DateFrom: searchValue?.DateFrom || "",
    DateTo: searchValue?.DateTo || ""
  }
  const { DateFrom, DateTo } = searchvalue
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let Page = page || 1
  const limit = searchValue?.limit ?? ""
  const sortBy = searchValue?.sortBy ?? "total"
  const sendOptions = searchValue?.sendOptions ?? []
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/category-report`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
      page: Page,
      BranchId: selectedBranch,
      SelectedBranch: selectedBranch,
      DateFrom: DateFrom,
      DateTo: DateTo,
      limit,
      sortBy,
      sendOptions
    }
  };
  let res = await axios(config);
  return res.data;
};

//////////// export excel files here

export const ExportSalesReportByType = async (dateFrom, dateTo, Type) => {
  //['NormalList','PaymentMethod']
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/sales-report/export?SelectedBranch=${selectedBranch}&DateFrom=${dateFrom}&DateTo=${dateTo}&BranchId=${selectedBranch}&Type=${Type}`,
    responseType: 'arraybuffer',
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};

export const ExportProductReport = async (dateFrom, dateTo, sortBy, sendOptions) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  sendOptions = sendOptions ?? []
  sortBy = sortBy ?? "total"
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/product-report/export`,
    responseType: 'arraybuffer',
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
      BranchId: selectedBranch,
      SelectedBranch: selectedBranch,
      DateFrom: dateFrom,
      DateTo: dateTo,
      sortBy,
      sendOptions,
      limit: -1
    }
  };
  let res = await axios(config);
  return res.data;
};

export const ExportProductReportByCategory = async (dateFrom, dateTo, sortBy, sendOptions) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  sendOptions = sendOptions ?? []
  sortBy = sortBy ?? "total"
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/category-report/export`,
    responseType: 'arraybuffer',
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
      BranchId: selectedBranch,
      SelectedBranch: selectedBranch,
      DateFrom: dateFrom,
      DateTo: dateTo,
      sortBy,
      sendOptions,
      limit: -1
    }
  };
  let res = await axios(config);
  return res.data;
};

// Employee
export const GetEmployeeMealReport = async (page, searchValue) => {
  let searchvalue = _.isEmpty(searchValue) || searchValue === undefined || searchValue === null ? {
    DateFrom: "",
    DateTo: "",
  } : {
    DateFrom: searchValue?.DateFrom || "",
    DateTo: searchValue?.DateTo || ""
  }
  let Page = page || 1
  const limit = searchValue?.limit ?? 10

  const { DateFrom, DateTo } = searchvalue
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "get",
    url: `${baseUrl}/cms/analytics/employee-meal-report`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    params: {
      page: Page,
      BranchId: selectedBranch,
      SelectedBranch: selectedBranch,
      DateFrom,
      DateTo,
      limit
    }
  };
  let res = await axios(config);
  return res.data;
};

