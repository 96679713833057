import React from "react";

const HorizontalDivider = () => {
  return (
    <div className="horizontal-divider">
      <div className="horizontal-divider-item"></div>
    </div>
  );
};

export default HorizontalDivider;
