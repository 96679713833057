import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
const LimitationFilter = ({ productFilter, clearFilter }) => {
  const [searchItem, setSearchItem] = useState({

    search: "",
    limit: 10,
  })
  const clearSearch = (e) => {
    e.preventDefault()
    setSearchItem({
      search: ""
    })
    clearFilter(e)
  }


  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])
  return (
    <section className="content-main-search">

      <div className="content-main-search-item">
        <label htmlFor="isActive">STATUS</label>
        <select name="search" id="search" value={searchItem.search} onChange={(e) => { handleChange(e) }}>
          <option value="">All</option>
          <option value="DAYS">By Days</option>

        </select>
      </div>
      <Tooltip title="Submit Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => {
            productFilter(e, searchItem)
          }}
        >
          <SearchIcon />
        </button>
      </Tooltip>
      <Tooltip title="Clear Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => { clearSearch(e) }}
        >
          <AutorenewIcon />
        </button>
      </Tooltip>

    </section>
  )
};

export default LimitationFilter;
