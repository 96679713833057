import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate, useParams } from 'react-router';
import { useTitle } from 'react-use';
import { GetBase64 } from '../../../../pages/ProductManager/productHelper';
import Logo from '../../../reusable/Logo';
import { GetBankDetail, GetOrderDetail, GetSpecificPaymentMethod, UploadReceipt } from '../../PublicHelper';
import placeholderImage from './../../../../assets/placeholderImage.jpg';
import { statuses } from './SummaryUtils';
import Swal from "sweetalert2";

const OrderSummary = () => {
  let history = useNavigate()
  let { orderref } = useParams();
  useTitle('Pao Kitchen | Order summary');
  const [orderDetails, setOrderDetails] = useState({
    ContactDetails: {},
    CustomerId: "",
    DatePaid: "",
    DeliveryDetails: {
    },
    OrderNote: "",
    OrderRef: "",
    PaymentMode: "",
    PaymentReceipt: {},
    PreferredDeliveryDate: "",
    Status: 0,
    OrderDetails: [],
    TotalAmount: 0,
    Discount: 0,
    OrderAmount: 0,
    ShippingAmount: 0,
    Subtotal: 0,
    _id: ""
  })
  const [bankDetails, setBankDetails] = useState({
    Account: "",
    BankName: "",
    IsActive: false,
    Number: "",
    UrlLogin: "",
    _id: ""
  })
  const [fileInput, setFileInput] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [uploadedImage, setUploadedImage] = useState("")
  useEffect(() => {
    getOrderDetail()
    return () => {
      getOrderDetail()
    }
    //eslint-disable-next-line
  }, [])
  const getOrderDetail = () => {
    GetOrderDetail(orderref).then(
      res => {
        const { ContactDetails, CustomerId, DatePaid, DeliveryDetails, OrderNote, OrderRef, PaymentMode, PaymentReceipt, PreferredDeliveryDate, Status, OrderDetails, TotalAmount, Discount, OrderAmount, ShippingAmount, _id } = res.data
        let Subtotal = OrderAmount + ShippingAmount

        setOrderDetails({
          ContactDetails,
          CustomerId,
          DatePaid,
          DeliveryDetails,
          OrderNote,
          OrderRef,
          PaymentMode,
          PaymentReceipt,
          PreferredDeliveryDate,
          Status,
          OrderDetails,
          TotalAmount,
          Discount,
          OrderAmount,
          ShippingAmount,
          Subtotal,
          _id
        })

        // setUploadedImage(PaymentReceipt?.Receipt ?? "")

        if (PaymentMode !== "COD") {
          getDetails(PaymentReceipt.PaymentId)
        }
        getPaymentDetail(PaymentMode)
      }
    ).catch(
      err => {
        console.log(err.response);
      }
    )
  }
  const getPaymentDetail = (code) => {
    GetSpecificPaymentMethod(code).then(
      res => {
        console.log("CODE", code);
        setPaymentMethod(res.data.paginatedResults[0].Name);
      }
    ).catch(err => {
      console.log(err);
    })
  }
  const getDetails = (bankId) => {
    GetBankDetail(bankId).then(
      res => {
        const { Account, BankName, IsActive, Number, UrlLogin, _id } = res.data
        setBankDetails({
          Account,
          BankName,
          IsActive,
          Number,
          UrlLogin,
          _id
        })
      }
    ).catch(err => {
      console.log(err.response);
    })
  }
  const gotoHomepage = (e) => {
    e.preventDefault()
    history("/")
  }
  const getData = (property) => {
    return _.isEmpty(property) ? "N/A" : `${property}`
  }

  const getStatus = (status) => {
    return statuses[status]
  }
  const handleChangeImage = (e) => {
    const { name } = e.target
    let file = document.querySelector('#inputTag').files ?? "";
    setFileInput(file[0].name ?? fileInput)
    if (!_.isEmpty(file)) {
      GetBase64(file[0]).then(
        (data) => {
          let imageArray = data.split(",");
          setOrderDetails({
            ...orderDetails,
            PaymentReceipt: {
              ...orderDetails.PaymentReceipt,
              [name]: imageArray[1]
            }
          })

          Swal.showLoading()

          const payload = {
            PaymentReceipt: {
              ...orderDetails.PaymentReceipt,
              [name]: imageArray[1]
            }
          }

          UploadReceipt(payload, orderDetails._id)
          .then(() => {
            setUploadedImage(data)
            Swal.close()
          })
          .catch(err => {
              alert("Something went wrong. Please try again!")
              Swal.close()
              console.log(err.response);
          })
        }
      )
    }
  }
  const {
    ContactDetails,
    // CustomerId,
    // DatePaid,
    DeliveryDetails,
    OrderNote,
    OrderRef,
    // PaymentMode,
    // PaymentReceipt,
    PreferredDeliveryDate,
    Status,
    OrderDetails, TotalAmount, Discount,
    OrderAmount,
    ShippingAmount,
    Subtotal,
  } = orderDetails
  const {
    Account,
    BankName,
    IsActive,
    Number,
  } = bankDetails
  const imgError = (e) => {
    e.preventDefault()
    e.target.src = placeholderImage
    return true;
  }
  return (

    <div className='checkout'>

      <div className='checkout-container'>
        <div className='checkout-container-order-left'>
          <div className='checkout-container-order-left-content'>

            <div className='checkout-logo'>
              <Logo />
            </div>
            <div className='width-70 receipt center'>
              <div className='receipt-container'>
                <div className='receipt-header'> <h2 className='receipt-header-heading'>Order Receipt</h2> <span>Status: <span className='receipt-header-status'>{getStatus(Status)}</span></span></div>
                <div className='receipt-number'>
                  <div className='flex-column-center'>
                    <h5>We’ve received your order. </h5>
                    <h5>Your order number: </h5>
                  </div>
                  <span>{_.isEmpty(OrderRef) ? "N/A" : `${OrderRef}`}</span>
                </div>
                <div className='receipt-delivery'>
                  <div className='flex-row-space-between'>
                    <span className='receipt-title'>Delivery Details</span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span >Customer name: </span>
                    <span className='flex-end'>{getData(ContactDetails.FullName)}</span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span >Phone number:</span>
                    <span className='flex-end'>{getData(ContactDetails.MobileNum)}</span>
                  </div >
                  <div className='flex-row-space-between'>
                    <span >Email address:</span>
                    <span className='flex-end text-italic'>{getData(ContactDetails.Email)}</span>
                  </div>

                </div>
                <div className='receipt-delivery'>
                  <div className='flex-row-space-between'>
                    <span >Address:</span>
                    <span className='flex-end small-case'>{getData(DeliveryDetails.Address)}</span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span >Shipping time:</span>
                    <span className='flex-end'>{getData(moment(PreferredDeliveryDate).format('h:mm:ss a - MMMM Do, YYYY '))}</span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span >Note:</span>
                    <span className='flex-end text-italic'>{getData(OrderNote)}</span>
                  </div>
                </div>
                <div className='receipt-delivery'>
                  <div className='flex-row-space-between'>
                    <span>Payment option:</span>
                    <span className='flex-end'>{getData(paymentMethod)}</span>
                  </div>
                </div>
                <div className='receipt-summary'>
                  <div className='flex-row-start'>
                    <span>Order Summary</span>
                  </div>
                  <div className='flex-row-end margin-bottom-10'>
                    <span>Price</span>
                  </div>
                  {
                    _.isEmpty(OrderDetails) ? "N/A" : (
                      _.map(OrderDetails, (value, key) => {
                        // console.log(value);
                        const { ProdImage, Quantity, Price, ProductName, TotalPrice } = value
                        return (
                          <div key={key} className='flex-row-space-between'>
                            <div className='flex-row-start gap-10'>
                              <img className='border-radius-10' src={ProdImage || placeholderImage} onError={(e) => imgError(e)} alt="product" height="50" width="50" />
                              <div className='flex-column-start gap-1'>
                                <span>{getData(ProductName.EN || ProductName.VN || ProductName.CN || ProductName.TH)}</span>
                                <span>{Quantity}x @ <CurrencyFormat value={Price} displayType={'text'} thousandSeparator={true} prefix={'₱ '} /></span>
                              </div>
                            </div>
                            <span><CurrencyFormat value={TotalPrice} displayType={'text'} thousandSeparator={true} prefix={'₱ '} /></span>
                          </div>
                        )
                      })
                    )
                  }
                </div>
                {/* start of receipt money document */}
                <div className='receipt-moneydocument'>
                  <div className='flex-row-space-between'>
                    <span>Discount : </span>
                    <span className='flex-end'>
                      <CurrencyFormat value={Discount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span>Order Amount : </span>
                    <span className='flex-end'>
                      <CurrencyFormat value={OrderAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span>Shipping Amount : </span>
                    <span className='flex-end'>
                      <CurrencyFormat value={ShippingAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span>Subtotal : </span>
                    <span className='flex-end'>
                      <CurrencyFormat value={Subtotal} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </span>
                  </div>
                  <div className='flex-row-space-between'>
                    <span className='font-bold'>Total Amount : </span>
                    <span className='flex-end total-amount'>
                      <CurrencyFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                    </span>
                  </div>
                </div>
                {/* end of receipt money document  */}
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-container-order-right">
          <div className='checkout-container-order-left-content'>
            <div className={`width-70 receipt start margin-top-100 ${uploadedImage ? "enable-scroll" : ""}`}>
              <div className='receipt-container-right'>
                <div><h2 className='receipt-header-heading'>Payment Channel</h2> </div>
                {
                  orderDetails.PaymentMode === "COD" || "" ? (<></>) : (
                    <div className={IsActive ? 'receipt-card' : 'receipt-card input-warning'}>
                      <div className='flex-row-start gap-5'> <span>Bank: </span><span>{getData(BankName)}</span></div>
                      <div className='flex-row-start gap-5'> <span>Account Name: </span><span>{getData(Account)}</span></div>
                      <div className='flex-row-start gap-5'> <span>Account Number: </span><span>{getData(Number)}</span></div>
                      <div className='flex-row-start gap-5'> <span>Branch: </span><span>N/A</span></div>
                    </div>
                  )

                }

                <ul type="disk" className='flex-column-start gap-5 text-grey margin-negative-left-20'>
                  <li>Customer service will contact you to confirm order details</li>
                  {
                    orderDetails.PaymentMode === "COD" ? (<></>) : (
                      <li>Please proceed to uploading the reciept to process order</li>)
                  }
                  {
                    (!IsActive && orderDetails.PaymentMode !== "COD") || orderDetails.PaymentMode === "" ? (<small className='error-message'> This payment channel is inactive. Please change your payment channel</small>) : (<></>)
                  }


                </ul>
                {
                  orderDetails.PaymentMode === "COD" || orderDetails.PaymentMode === "" ? (<></>) : (
                   <>
                     <div className='flex-row-start-center gap-5'>
                       <label htmlFor="inputTag" className='input-file-upload-label'>
                         Upload Receipt
                         <input className='input-file-upload' id="inputTag" type="file" accept="image/png, image/gif, image/jpeg" multiple={false} name="Receipt" onChange={(e) => { handleChangeImage(e) }} />
                         <UploadFileIcon />
                       </label>
                       <span id="imageName">{fileInput}</span>
                     </div>
                     {
                       // uploadedImage ? <img src={uploadedImage} className="upload-file-image" alt="file-uploaded" title="file-uploaded"/> : null
                     }
                     {
                         uploadedImage ?
                             <div className="mess-image-uploaded">
                               <CheckCircleOutlineIcon />
                               <span>Receipt uploaded</span>
                             </div>
                         :null
                     }
                   </>
                  )
                }


              </div>
            </div>
          </div>
        </div>


      </div>
      <div className='checkout-bottom'>
        <div className="homepage-button"><button onClick={(e) => {
          gotoHomepage(e)
        }}><ArrowBackIosIcon sx={{ fontSize: "18px" }} /> Homepage</button>
        </div>

      </div>
    </div>
  )
}

export default OrderSummary