import Proptypes from "prop-types";
import css from "./Cart.module.scss"
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import ProductCart from "./ProductCart";
import {getPrice} from "../../../helper";

const Cart = (props) => {
    const {t} = useTranslation()
    const {order, extraProducts, updateExtraProducts} = props
    const OrderDetails = order.OrderDetails ?? []
    const sendOption = order?.SendOption ?? "DINEIN"

    const updateQuantity = (productId, extraQuantity) => {
        const dataUpdated = extraProducts.map(extraProduct => {
            const product = extraProduct?.product?.Product ?? {}
            if (product._id === productId) {
                return {
                    ...extraProduct,
                    quantity: extraQuantity
                }
            }

            return extraProduct
        })

        const newValue = dataUpdated.filter(value => {
            return value.quantity > 0
        })

        updateExtraProducts(newValue)
    }

    const newOrder = (extraProduct) => {
        const OLD_QUANTITY = 0
        const product = extraProduct?.product?.Product ?? {}
        const price = getPrice(extraProduct?.product?.Price ?? {}, sendOption)
        return {
            IsScan: false,
            "ProductId": product._id,
            "ProductName": product.Name,
            "ProdImage": product.Images.DineIn,
            "SKU": product.SKU,
            "Price": price,
            "Quantity": OLD_QUANTITY,
            "ExtraQuantity": extraProduct.quantity,
            "TotalPrice": price * extraProduct.quantity,
            "OrderProdType": "REGULAR",
            "PreparationTime": product.PreparationTime
        }
    }

    const mergeOrders = () => {
        if (!extraProducts.length || !extraProducts) {
            return OrderDetails
        } else {
            const otherCartProducts = extraProducts.map((extraProduct) => {
                const {product} = extraProduct
                const ordered = OrderDetails.find((orderProduct) => {
                    return orderProduct.ProductId === product.Product._id
                })

                if (!ordered) {
                    return newOrder(extraProduct)
                }

                return {
                    ...ordered,
                    ExtraQuantity: extraProduct.quantity
                }
            })

            if (!OrderDetails.length) {
                return otherCartProducts
            }

            let output = [...OrderDetails]
            let lengthOtherCartProd = otherCartProducts.length

            while (lengthOtherCartProd) {
                const otherCartProduct = otherCartProducts[lengthOtherCartProd - 1]

                const indexOrderDetail = OrderDetails.findIndex(product => {
                    return product.ProductId === otherCartProduct.ProductId
                })

                if (indexOrderDetail > -1) {
                    output[indexOrderDetail] = otherCartProduct
                } else {
                    output.push(otherCartProduct)
                }

                lengthOtherCartProd = lengthOtherCartProd - 1
            }

            return output
        }
    }

    const handleNewProduct = () => {
        if (!extraProducts.length || !extraProducts) {
            return []
        } else {
            return extraProducts.map((extraProduct) => {
                return newOrder(extraProduct)
            })
        }
    }

    const listCart = useMemo(() => {
        const products = mergeOrders()
        return products.map(product => {
            return <ProductCart productOrderDetail={product} key={product.ProductId}
                                updateExtraQuantity={updateQuantity}/>
        })

        // eslint-disable-next-line
    }, [extraProducts, OrderDetails])

    const total = useMemo(() => {
        const products = mergeOrders()
        return products.reduce((totalCart, product) => {
            const price = product?.Price ?? 0
            const Quantity = product?.Quantity ?? 0
            const ExtraQuantity = product?.ExtraQuantity ?? 0

            return totalCart + ((Quantity + ExtraQuantity) * price)
        }, 0)

        // eslint-disable-next-line
    }, [extraProducts, OrderDetails])

    const onClickUpdate = () => {
        // props.updateOrder(order, mergeOrders())
        props.updateOrder(order, handleNewProduct())
    }

    return (
        <div className={css.cartContainer}>
            <p className={css.title}>{t("cartOrderDineIn")}</p>
            <div className={css.wrapper}>
                <div className={css.list}>
                    <div className={css.listContent}>
                        {listCart}
                    </div>
                </div>
            </div>
            <div className={css.totalCart}>
                <p className={css.total}>{t("totalpayment")} <span className={css.totalAmount}>₱{total}</span></p>
                <button className={css.btnUpdate} disabled={extraProducts.length === 0} onClick={onClickUpdate}>{t("update")}</button>
            </div>
        </div>
    )
}


Cart.propTypes = {
    extraProducts: Proptypes.array.isRequired,
    order: Proptypes.object.isRequired,
    updateExtraProducts: Proptypes.func.isRequired,
    updateOrder: Proptypes.func
}

export default Cart