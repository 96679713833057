import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
const GroupCategoryTable = ({ deleteGroupCategory, groupCategorylists, showEditGroup }) => {
  const [groupCategorylist, setGroupCategorylist] = useState();
  const container = useRef(null);

  useEffect(() => {
    container.current.getElement().id = "groupcategoryTable";
    setGroupCategorylist(groupCategorylists);
    return () => {

    };

  }, [groupCategorylists]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return <ScrollContainer className="scroll-container content-product-table" ref={container}>

    <div className="content-product-table-head" >
      <div className="content-product-table-head-item big-item item-name">Group Category</div>
      <div className="content-product-table-head-item item-name">Description</div>
      <div className="content-product-table-head-item normal">Status</div>
      <div className="content-product-table-head-item normal">Date Created</div>
      <div className="content-product-table-head-item normal">
        Actions
      </div>

    </div>

    {
      !_.isEmpty(groupCategorylist) ? (
        Object.keys(groupCategorylist).map((key, index) => {
          const { id, icon, isActive, categoryName, createdAt } = groupCategorylist[key];
          let dateCreated = moment(createdAt).format("YYYY-MM-DD HH:MM:SS");
          return (
            <div className="content-product-table-data group-category" key={index}
              onClick={(e) => {
                openInNewTab(`/admin/cms/${id}/product-categories`)
              }}
            >
              <div className="content-product-table-data-item big-item item-name">
                <div className="product-name">
                  <img
                    srcSet={icon}
                    alt={"icon"}
                    width="100px"
                    className="product-name-image"
                  />
                  <div className="product-name-title ">
                    <div>
                      <span>
                        <b>{categoryName.EN}</b>
                      </span>
                    </div>
                    <div>
                      <small>{categoryName.VN}</small>
                    </div>
                    <div>
                      <small>{categoryName.CN}</small>
                    </div>
                    <div>
                      <small>{categoryName.TH}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item item-name">
                <div className="product-name-title">
                  <div>
                    <span>
                      <b>-</b>
                    </span>
                  </div>
                  <div>
                    <small>-</small>
                  </div>
                  <div>
                    <small>-</small>
                  </div>
                  <div>
                    <small>-</small>
                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal">
                {isActive ? (
                  <span>
                    <CircleIcon sx={{ color: "green", fontSize: "12px" }} />{" "}
                    Active
                  </span>
                ) : (
                  <span>
                    <CircleIcon sx={{ color: "red", fontSize: "12px" }} />{" "}
                    Inactive
                  </span>
                )}
              </div>
              <div className="content-product-table-data-item normal">
                {dateCreated}
              </div>
              <div className="content-product-table-data-item normal">
                <Tooltip title="Edit This Group Category" placement="bottom-start" arrow>
                  <EditIcon data-event="hover" data-tip="tooltip" data-for="editGroupCategoryTip" sx={{
                    color: "#50311b", cursor: "pointer", "&:hover": {
                      color: "#50311c", fontSize: "22px"
                    }

                  }}
                    onClick={(e) => {
                      e.stopPropagation();
                      showEditGroup(e, groupCategorylist[key])
                    }
                    }
                  />
                </Tooltip>


              </div>
            </div>
          )
        })

      ) : (
        <div className="content-product-table-data group-category">
          <div className="content-product-table-data-item big-item item-name"></div>
          <div className="content-product-table-data-item center">No Data Found</div>
          <div className="content-product-table-data-item normal"></div>
          <div className="content-product-table-data-item normal"></div>
          <div className="content-product-table-data-item normal">

          </div>
        </div>
      )
    }

  </ScrollContainer>;
}
export default GroupCategoryTable;