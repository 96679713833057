import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { logout, UpdateCustomer } from '../PublicHelper';
const ChangeFullName = () => {
  const _ = require('lodash');
  const node = useRef(null);
  const history = useNavigate()
  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#ChangeFullNameModal").style.display = "none";
    };
    document.querySelector("#ChangeFullNameModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])
  const hideAddLocation = (e) => {
    e.preventDefault()
    document.querySelector("#ChangeFullNameModal").style.display = "none";
  }
  const [fullName, setFullName] = useState({
    FirstName: "",
    LastName: ""
  })
  const submitChange = (e) => {
    e.preventDefault()
    UpdateCustomer(fullName).then(
      res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Fullname successfully changed!',
          showConfirmButton: false,
          timer: 1000,
          footer: "&#169; Pao Kitchen 2023",
        })
        setTimeout(() => {
          window.location.reload();
        }, 1000);


      }
    )
      .catch(err => {
        if (err.response) {
          if (_.isEmpty(err.response.data.data)) {
            const { response } = err
            logout(response, history)
          } else {
            Swal.fire({
              icon: 'error',
              title: err.response.data.mess,
              text: err.response.data.data.ContactNum,
              footer: "&#169; Pao Kitchen 2023"
            })
          }
        }
      })
  }
  const handleChange = (e) => {
    const { value, name } = e.target
    setFullName({ ...fullName, [name]: value })
  }
  const { FirstName, LastName } = fullName
  return (
    <div className="modal-container" id="ChangeFullNameModal">
      <form action="" method="POST" onSubmit={(e) => {
        submitChange(e)
      }}>
        <div ref={node} className="modal-container-item">
          <div className='modal-container-item-usertitle' >{t('changefullname')}</div>

          <div className="input-update mb">
            <input type="text" name="FirstName" value={FirstName} id="firstname" onChange={(e) => { handleChange(e) }} />

            <label htmlFor="firstname">{t('firstname')}</label>
          </div>
          <div className="input-update mb">
            <input type="text" name="LastName" value={LastName} id="lastname" onChange={(e) => { handleChange(e) }} />
            <label htmlFor="lastname">{t('lastname')}</label>
          </div>


          <div className='modal-container-item-button'>
            <button type='submit' className='modal-container-item-button-change' >{t('change')}</button>
          </div>
          <button className='modal-container-item-close' onClick={(e) => { hideAddLocation(e) }}><ChevronLeftIcon /></button>
        </div>

      </form>
    </div>
  )

}

export default ChangeFullName;
