import i18next from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {openPublicCart} from "../../../pages/ProductManager/productHelper";
import { GetPublicProductList, GetPublicProductListPerBranch } from "../PublicHelper";
import Section from "./Section";


const ProductList = ({ addToCart, showProductDetails, search }) => {
  const { t } = useTranslation()
  const onHover = [
    {
      color: "#50311b",
    },
    {
      "&:hover": {
        color: "#eaa460",
      },
    },
  ];


  const [listData, setListData] = useState([])
  const [todaylist, setTodaylist] = useState([])
  let branchid = localStorage.getItem("publicBranch");
  useEffect(() => {

    GetPublicProductList(branchid, search)
      .then(res => {
        const { paginatedResults } = res.data
        //setCategoryLink(paginatedResults)
        console.log("product list", paginatedResults)

        setListData(_.map(paginatedResults, value => {
          const { BranchProducts, _id, Attribute } = value
          const { Name } = Attribute
          return [_id, _id, Name, BranchProducts]
        })
        )
      })
      .catch(err => {
        setListData([])
      })
    getTodaymenu(branchid)
    return () => {

    }
  }, [branchid, search])
  const addCart = (e, product) => {
    e.preventDefault()
    addToCart(e, product)
    openPublicCart()
    e.stopPropagation()
  }
  const showProductItem = (e, product) => {
    e.preventDefault()
    console.log("showProductItem 2: ", product)
    showProductDetails(e, product)
  }


  const getTodaymenu = (branchId) => {
    GetPublicProductListPerBranch(branchId).then((res) => {
      const { paginatedResults } = res.data
      //setCategoryLink(paginatedResults)
      setTodaylist(paginatedResults)
      // console.log("TESTING : ", res.data)
    }).catch(err => { console.log(err.response) })
  }

  return (
    <main className="main">
      <Section
        mainId="todaymenu"
        id="todayMenu"
        onHover={onHover}
        cardData={todaylist}
        title={t('todaysspecialdish')}
        addCart={addCart}
        showProductItem={showProductItem}
      />
      {listData.map((value, index) => {
        return (
          <Section
            key={index}
            mainId={value[0]}
            id={"scroll" + value[1]}
            onHover={onHover}
            cardData={value[3]}
            title={value[2][i18next.language] || value[2].EN || value[2].VN || value[2].CN || value[2].TH}
            addCart={addCart}
            showProductItem={showProductItem}
          />
        );
      })}
    </main>
  );
};

export default ProductList;
