import './styles.scss'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SellIcon from '@mui/icons-material/Sell';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const PlatformItem = (props) => {

  const {

    name,
    discountGiven,
    discountSub, 
    orders, 
    sales, 
    shipping
    
  } = props.data

  return <>
    <section className="report-section-dataset">

      {/* Platform Name */}
      <section className="report-section-name">
        <span>{name}</span>
      </section>

      {/* Data Card Set 1 */}
      <section className="report-section-cards">
        
        {/* Discount */}
        <div className="report-section-cards-item">
          
          <div className="report-section-cards-item-texts">
            <h1 className="report-section-cards-item-texts-title">{discountGiven}</h1>
            <p className="report-section-cards-item-texts-desc">discounts given</p>
            <p className="report-section-cards-item-texts-footer"><span className="negative">31%</span> higher than usual</p>
          </div>
          <VolunteerActivismIcon
            sx={{
              fontSize: 50,
              color: '#50311b',
              backgroundColor: 'transparent'
            }}
            className="report-section-cards-item-icon"
          />
          
        </div>
        
        {/* Subtotal */}
        <div className="report-section-cards-item">
          
          <div className="report-section-cards-item-texts">
            <h1 className="report-section-cards-item-texts-title">&#8369;{discountSub}</h1>
            <p className="report-section-cards-item-texts-desc">discount subtotal</p>
            <p className="report-section-cards-item-texts-footer"><span className="negative">5%</span> higher than usual</p>
          </div>
          <SellIcon
            sx={{
              fontSize: 50,
              color: '#50311b',
              backgroundColor: 'transparent'
            }}
            className="report-section-cards-item-icon"
          />
          
        </div>

      </section>

      {/* Data Card Set 2*/}
      <section className="report-section-cards">
        
        {/* Orders */}
        <div className="report-section-cards-item">
          
          <div className="report-section-cards-item-texts">
            <h1 className="report-section-cards-item-texts-title">{orders}</h1>
            <p className="report-section-cards-item-texts-desc">orders made</p>
            <p className="report-section-cards-item-texts-footer"><span className="negative">5%</span> lower than usual</p>
          </div>
          <ShoppingBasketIcon
            sx={{
              fontSize: 50,
              color: '#50311b',
              backgroundColor: 'transparent'
            }}
            className="report-section-cards-item-icon"
          />
          
        </div>
        
        {/* Sales */}
        <div className="report-section-cards-item">
          
          <div className="report-section-cards-item-texts">
            <h1 className="report-section-cards-item-texts-title">&#8369;{sales}</h1>
            <p className="report-section-cards-item-texts-desc">sales made</p>
            <p className="report-section-cards-item-texts-footer"><span className="positive">5%</span> higher than usual</p>
          </div>
          <AttachMoneyIcon
            sx={{
              fontSize: 50,
              color: '#50311b',
              backgroundColor: 'transparent'
            }}
            className="report-section-cards-item-icon"
          />
          
        </div>
        
        {/* Shipping */}
        <div className="report-section-cards-item">
          
          <div className="report-section-cards-item-texts">
            <h1 className="report-section-cards-item-texts-title">&#8369;{shipping}</h1>
            <p className="report-section-cards-item-texts-desc">shipping cost</p>
            <p className="report-section-cards-item-texts-footer"><span className="negative">2%</span> higher than usual</p>
          </div>
          <LocalShippingIcon
            sx={{
              fontSize: 50,
              color: '#50311b',
              backgroundColor: 'transparent'
            }}
            className="report-section-cards-item-icon"
          />
          
        </div>

      </section>

    </section>
  </>

}

export default PlatformItem