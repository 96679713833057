import _ from "lodash";

const OrderSummaryButton = ({ customerAccount, gotoOrderSummary, publicToken }) => {

  return (
    <div className="checkout-modal-container-button gap-10">
      <button className="cancel-button"
        onClick={(e) => {
          gotoOrderSummary(e)
        }}
      >Order Summary</button>
      <button
        onClick={(e) => {
          customerAccount(e);
        }}
        className="cancel-button" disabled={_.isEmpty(publicToken) ? true : false}>
        My Account
      </button>
    </div>
  );
};

export default OrderSummaryButton;
