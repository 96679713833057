import _ from "lodash";
import ScrollContainer from "react-indiana-drag-scroll";
import {copyText} from "../../../helpers/stringHelper";
import "./listTable.scss"
import { Tooltip } from '@mui/material'; 
import DetailsIcon from '@mui/icons-material/Details';
const CustomerListTable = ({ customers, newTabURL, changeStatus }) => {

  return (
    <ScrollContainer className="content-product-table">
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal normal-start  item-name">Phone #</div>
        <div className="content-product-table-head-item normal normal-start item-name">Email</div>
        <div className="content-product-table-head-item normal normal-start item-name">Name</div>
        <div className="content-product-table-head-item normal normal-start item-name">Spend</div>
        <div className="content-product-table-head-item normal normal-start item-name">VIP Level</div>
        <div className="content-product-table-head-item normal normal-start item-name">Status</div>
        <div className="content-product-table-head-item normal normal-start item-name">Employee</div>
        <div className="content-product-table-head-item center item-name">Details</div>
      </div>
      {
        !_.isEmpty(customers) ? (
            // customers.map((customer) => {
            Object.keys(customers).map((key, index) => {
              const {
                ContactNum,
                Email,
                FirstName,
                LastName,
                spend,
                vipLevel,
                IsActive,
                IsEmployee,
                _id
              } = customers[key];
 
            return (
              <div className="content-product-table-data" key={index}>
                <div className={`content-product-table-data-item normal normal-start item-name ${ContactNum ? "link-primary" : ""}`} onClick={() => {
                  if(ContactNum) {
                    copyText(ContactNum)
                  }
                }}
                >{ContactNum ?? "N/A"}</div>
                <div className={`content-product-table-data-item normal normal-start item-name ${Email ? "link-primary" : ""}`} onClick={() => {
                  if(Email) {
                    copyText(Email)
                  }
                }}
                >{Email ?? "N/A"}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{`${FirstName} ${LastName}`}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{spend ?? 0}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{vipLevel ?? 0}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{IsActive ? "Active" : "Inactive"}</div>
                <div className="content-product-table-data-item">
                  <div className="status">
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={IsEmployee}
                        checked={IsEmployee}
                        onChange={changeStatus(key, _id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="content-product-table-data-item center item-name">
                  <Tooltip title="Customer Details" placement="bottom-start" arrow>
                    <button className='edit-btn' onClick={() => {
                      newTabURL(`/admin/cms/customer-list/${_id}`)
                    }}><DetailsIcon sx={{
                      fontSize: "20px"
                    }} /></button>
                  </Tooltip>
                </div>
              </div>
            )
          })
        ) : (
          <div className="content-product-table-data">
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name">No Customer(s) Found!</div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item center item-name"></div>
          </div>
        )}
    </ScrollContainer >
  )
};

CustomerListTable.defaultProps = {
  tableContents: []
}

export default CustomerListTable;
