import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import KTVMENU from '../assets/KTVMENU.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const KTVMenu = () => {
  const [page, setpage] = useState([1, 2, 3, 4, 5, 6, 7, 8])
  // const [numPages, setNumpage] = useState(null)
  useEffect((numPages) => {
    setpage([1, 2, 3, 4, 5, 6, 7, 8])
    return () => {

    }
  }, [])
  const onDocumentLoadSuccess = ({ numPages }) => {

  }

  return (
    <div className='flex-column-start-center'>

      <Document
        file={KTVMENU}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {
          _.map(page, (value, key) => {
            return (
              <Page
                key={key}
                loading={"Loading pdf page.."}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={value}
              />
            )
          })
        }

      </Document>
    </div>
  )

};

export default KTVMenu;
