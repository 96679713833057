export const orderDetails = {
  "ContactDetails": {
    "FullName": "",
    "Email": "",
    "MobileNum": ""
  },
  "DeliveryDetails": {
    "Area": "",
    "Address": "",
    "PlaceId": "",
    "Place": ""
  },
  "Courier": {
    "PickupAddress": {
      "Name": "",
      "Phone": "",
      "Address": "",
      "PlaceId": "",
      "Place": ""
    },
    "LalamoveDetails": {
      "QuotationId": null,
      "PlaceOrderId": null,
      "Status": null, 
    },
    "Code": "",
    "TrackingId": null
  },
  "CustomerId": null,
  "OrderNote": "",
  "Status": 0,
  "CreatedAt": "",
  "UpdatedAt": "",
  "Paid": false,
  "DatePaid": null,
  "PaidAmount": 0,
  "AgentCode": "",
  "HasOrderPriority": false,
  "SendOption": "",
  "PreferredDeliveryDate": "",
  "BankReceipt": null,
  "_id": "",
  "OrderRef": "",
  "BranchId": "",
  "CouponCode": "",
  "Locale": "",
  "OrderDetails": [

  ],
  "PaymentMode": "",
  "StatusNote": [],
  "ShippingAmount": 0,
  "Discount": 0,
  "DiscountOrderAmount": 0,
  "OrderAmount": 0,
  "TotalAmount": 0,
  "AddFee": [],
  "CouponDetails": []
}
export const statuses = {
  0: "Pending",
  1: "Processing",
  2: "Confirmed",
  3: "Prepared",
  4: "Shipping",
  5: "Delivered",
  6: "Completed",
  7: "Declined",
  8: "Cancelled",
  9: "Returned",
}
export const iconStyle = {
  edit: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: "#50311b",
    "&:hover": {
      color: "#f08d19",
    },
  },
  delete: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: "#c40000",
    "&:hover": {
      color: "#f08d19",
    },
  }
}

export const shippingDet = {
  orderId: "",
  quotationId: "",
  priceBreakdown: {
    base: "0",
    extraMileage: "0",
    surcharge: "0",
    specialRequests: "0",
    totalExcludePriorityFee: "0",
    total: "0",
    currency: "",
  },
  driverId: {
    driverId: "",
    name: "",
    phone: "",
    plateNumber: "",
    photo: ""
  },
  shareLink: "",
  status: "",
  distance: {
    value: "",
    unit: ""
  },
  stops: [],
  metadata: {
    restaurantName: "",
    restaurantOrderId: ""
  }      
}

export const shipCodeDet = {
  Code: ""
}

export const courierNotes = {
  note_annam: "",
  note_courier: ""
}

export const quotationDet = {
  quotationId: "",
  scheduleAt: "",
  expiresAt: "",
  serviceType: "MOTORCYCLE",
  specialRequests: [
    "PURCHASE_SERVICE_4"
  ],
  language: "EN_PH",
  stops: [],
  isRouteOptimized: true,
  priceBreakdown: {
    base: "0",
    extraMileage: "0",
    specialRequests: "0",
    totalBeforeOptimization: "0",
    totalExcludePriorityFee: "0",
    total: "0",
    currency: ""
  },
  distance: {
    value: "",
    unit: ""
  }
}