import React from "react";

const Feature = ({ img, width, height, left, right }) => {
  return (
    <>
      <div className="feature">
        <div className="feature-left">
          <img src={img} width={width} height={height} alt="icon" />
          <p>{left}</p>
        </div>
        <div className="feature-right">
          <p>{right}</p>
        </div>
      </div>
    </>
  );
};

export default Feature;
