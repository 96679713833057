import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { UpdateShippingOption } from '../EcommerceHelper';

const ShippingOptionUpdate = ({ node, getShippingOptionList, shippingOptions }) => {
  const [shippingOption, setShippingOption] = useState({
    _id: "",
    ShippingName: "",
    ShipiCode: "",
    IsActive: Boolean(true),
    CmsStatus: Boolean(true)
  })
  useEffect(() => {
    setShippingOption(shippingOptions)

    return () => {

    }
  }, [shippingOptions])

  const { _id, ShippingName, ShipiCode, IsActive, CmsStatus } = shippingOption;
  // const onchangeText = (e) => { 
  //   const { name, value } = e.target;
  //   setShippingOption({
  //     ...shippingOption,
  //     [name]: value
  //   }) 
  // }
  // console.log("shippingOption: ", shippingOption)
  const submitShippingOption = (e) => {
    e.preventDefault()
    
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const IsActive = (shippingOption.IsActive == "true" || shippingOption.IsActive == true) ? true : false
    const CmsStatus = (shippingOption.CmsStatus == "true" || shippingOption.CmsStatus == true) ? true : false
    const shippingOptionBody = {
      CmsStatus: CmsStatus,
      CreatedAt: shippingOption.CreatedAt,
      IsActive: IsActive,
      ShipiCode: shippingOption.ShipiCode,
      ShippingName: shippingOption.ShippingName,
      UpdatedAt: shippingOption.UpdatedAt,
      WalletBalance: shippingOption.WalletBalance, 
      _id: shippingOption._id
    }
    // console.log("shippingOptionBody: ", shippingOptionBody)
    UpdateShippingOption(_id, shippingOptionBody)
      .then(res => {
        getShippingOptionList(1, "")
        console.log(res)
        Swal.close()
        HideItem("#updateShippingOption")
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "There was a problem occurred while doing your request! 😔",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      })
  }
  return (
    <div className="add-shipping-area" id="updateShippingOption">
      <div ref={node} className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            UPDATE: Shipping Option
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#updateShippingOption")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => { submitShippingOption(e) }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="shipingName">Shipping Name</label>
              <input type="text" 
              onChange={(e) => //onchangeText(e)
                setShippingOption({
                  ...shippingOption,
                  shipingName: e.target.value
                })              
              } 
              value={ShippingName} name="ShippingName" id="shipingName" />
            </div>
            <div className="form-container column">
              <label htmlFor="shipingCode">Shipping Code</label>
              <input type="text" 
              onChange={(e) => //onchangeText(e)
                setShippingOption({
                  ...shippingOption,
                  ShipiCode: e.target.value
                })              
              } 
              value={ShipiCode} name="ShipiCode" id="shipingCode" />
            </div>
            <div className="form-container column">
              <label htmlFor="showOnCheckout">Show On Checkout</label>
              <select name="IsActive" 
              onChange={(e) => //onchangeText(e)
                setShippingOption({
                  ...shippingOption,
                  IsActive: e.target.value
                })              
              } 
              value={IsActive} id="showOnCheckout">
                <option value={Boolean(true)}>Yes</option>
                <option value={Boolean(false)}>No</option>
              </select>
            </div>
            <div className="form-container column">
              <label htmlFor="showOnCMS">Show ON Cms Selection</label>
              <select name="CmsStatus" 
              onChange={(e) => //onchangeText(e)
                setShippingOption({
                  ...shippingOption,
                  CmsStatus: e.target.value
                })              
              } 
              value={CmsStatus} id="showOnCMS">
                <option value={Boolean(true)}>Yes</option>
                <option value={Boolean(false)}>No</option>
              </select>
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit" >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingOptionUpdate;

