import _ from "lodash";
import banner1 from "../../../assets/banner/anh_bia.jpg";
import { GetBannerListWeb } from "../PublicHelper";
import banner2 from "./../../../assets/banner/banner2.jpg";
import { useEffect, useState } from "react";
const Banner = () => {
  useEffect(() => {
    getBanner(1, "", true);

    return () => {};
  }, []);

  const [bannerList, setBannerList] = useState();

  const getBanner = (page, search, searchValue) => {
    setBannerList([]);

    GetBannerListWeb(page, search, searchValue)
      .then((res) => {
        const { paginatedResults, metadata } = res?.data;

        console.log(paginatedResults);

        let toPush = [];

        Object.keys(paginatedResults).forEach((key) => {
          const { _id, Image, Priority, IsActive } = paginatedResults[key];
          toPush.push({
            id: _id,
            image: Image,
            priority: Priority,
            isActive: IsActive,
          });
        });
        setBannerList(toPush);
      })
      .catch((error) => {
        setBannerList([]);
      });
  };
  const handleClick = (e) => {
    const buttons = document.getElementsByTagName("BUTTON");
    const images = document.getElementsByTagName("IMG");
    for (let button of buttons) {
      button.classList.remove("active");
    }
    for (let image of images) {
      image.classList.remove("banner-active");
    }
    let num = e.target.id;
    document.getElementById(`image-${num}`).classList.add("banner-active");

    e.target.classList.add("active");
  };

  return (
    <div className="jumbotron">
      {!_.isEmpty(bannerList) ? (
        Object.keys(bannerList).map((key, index) => {
          const { id, image, priority, isActive } = bannerList[key];

          return (
            <>
              <img
                src={image}
                className={`banner-image${index === 0 ? " banner-active" : ""}`}
                alt=""
                id={`image-${id}`}
              />
              {/* <img src={banner2} className="banner-image" id="image5678" alt="" /> */}
            </>
          );
        })
      ) : (
        <div></div>
      )}
      <div className="button-group">
        {!_.isEmpty(bannerList) ? (
          Object.keys(bannerList).map((key, index) => {
            const { id, image, priority, isActive } = bannerList[key];

            return (
              <>
                <button
                  onClick={handleClick}
                  id={id}
                  className={`${index === 0 ? "active" : ""}`}
                ></button>
                {/* <button on Click={handleClick} id="5678" ></button> */}
              </>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Banner;
