import annamLogo from "./../../assets/pao-kitchen-logo.svg";
const Logo = ({ swidth, sheight }) => {
  return (
    <div>
      <img
        src={annamLogo}
        className="img-responsive image-logo"

        alt="paokitchen Logo"
        width={swidth || "40.71"}
        height={sheight || "83.11"}
      />
    </div>
  );
};

export default Logo;
