
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
export const orders = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom"
    },
    title: {
      display: true,
      text: "Orders Per Payment Method"
    }
  }
};
const AnalyticsPie = ({ chartData }) => {


  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  const [dataOrders, setDataOrders] = useState({
    data: {
      labels: [],
      datasets: [
        {
          label: "Order Per Payment Method",
          data: [],
          borderColor: [],
          backgroundColor: [],
          borderWidth: 1,
        },
      ]
    }
  })
  useEffect(() => {
    let colors = _.map(chartData, (value, index) => {
      return dynamicColors()
    })
    setDataOrders(
      {
        data: {
          labels: _.map(chartData, (value, index) => {
            const { PaymentName } = value
            return PaymentName
          }),
          datasets: [
            {
              label: "Order Per Payment Method",
              data: _.map(chartData, (value, index) => {
                const { Total } = value
                return Total
              }),
              borderColor: colors,
              backgroundColor: colors,
              borderWidth: 1,
            },
          ]
        }
      }
    )

    return () => {

    }
  }, [chartData])

  return (
    <div className="analytics-charts">
      <div className="analytics-charts-pie">
        <Pie options={orders} data={dataOrders.data} />
      </div>
    </div>
  );
};
AnalyticsPie.defaultProps = {
  chartData: []
}
export default AnalyticsPie;


