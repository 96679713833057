import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import moment from 'moment-timezone';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
import {iconStyle} from '../OrderUtils';
import {calcSubTotal} from "../OderHelper";
const ProductDetails = ({
  value,
  getData,
  order,
  setSubtotal,
  udpateOrder,
  editItem,
  user,
  currentStatus
}) => {
  const deleteItem = (e, id) => {
    e.preventDefault()
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm deletion!'
    }).then((result) => {
      if (result.isConfirmed) {
        const { OrderDetails } = order
        let NewOrderDetails = _.filter(OrderDetails, (product) => {
          return product._id !== id
        });

        let subtotal = calcSubTotal(NewOrderDetails)
        setSubtotal(subtotal)

        let today = new Date();
        const { Status, _id, StatusNote } = order
        const { UserName } = user
        let itemName = _.find(OrderDetails, (product) => {
          return product._id === id
        }).ProductName.EN ?? ""

        let status = {
          StatusNum: null,
          Note: `<strong>${UserName}</strong> deleted an item (${itemName})`,
          DateAdded: moment(today).tz("Asia/Manila").format(),
          StatusUpdateBy: UserName
        }

        udpateOrder(_id, {
          ...order,
          OrderDetails: NewOrderDetails,
          Status: Status,
          StatusNote: [...StatusNote, status]
        })
      }
    })

  }
  const { SKU, ProductName, ProdImage, Quantity, Price, TotalPrice, _id } = value
  return (
    <div className="order-container-left-products-list">
      <div className="order-container-left-products-list-details fix-width">
        <img src={ProdImage} alt="product img" width={75} height={75} />
        <div className="product-details">
          <span className="product-details-main margin-bottom-6">
            {getData(ProductName.EN)}
          </span>
          <span className="product-details-sub">{getData(ProductName.VN)}</span>
          <span className="product-details-sub">{getData(ProductName.CN)}</span>
          <span className="product-details-sub margin-bottom-6">
            {getData(ProductName.TH)}
          </span>
          <span className="product-details-sub">{getData(SKU)}</span>
        </div>
      </div>
      <div className="order-container-left-products-list-number">
        <span>
          <CurrencyFormat
            value={Price}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        </span>
        <span>
          <CurrencyFormat
            value={Quantity}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
          />
        </span>
        <span>
          <CurrencyFormat
            value={TotalPrice}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        </span>
        <span>
          {
            currentStatus >= 2 ? <></>
              : <>
                <Tooltip title="Edit This Product" placement="bottom-start" arrow>
                  <EditIcon onClick={(e) => { editItem(e, _id) }} sx={iconStyle.edit} />
                </Tooltip>
                <Tooltip title="Delete This Product" placement="bottom-start" arrow>
                  <DeleteIcon sx={iconStyle.delete} onClick={(e) => {
                    deleteItem(e, _id)
                  }} />
                </Tooltip>
              </>
          }


        </span>
      </div>
    </div>
  );
};

export default ProductDetails;

