import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { GetCustomerDetails, logout } from '../PublicHelper';
import Location from './../../../assets/myaccount/location.svg';
import EditLocation from './EditLocation';
function ShippingAddress() {
  const { t } = useTranslation()
  const node = useRef(null);
  const history = useNavigate()
  const [specificShippingArea, setSpecificShippingArea] = useState({
    _id: "",
    FullName: "",
    Email: "",
    MobileNumber: "",
    Area: "",
    Address: ""
  })
  const showAddLocation = (e) => {
    e.preventDefault()
    document.querySelector("#addLocationModal").style.display = "flex";
  }
  const showEditLocation = (e, shippingArea) => {
    e.preventDefault()

    document.querySelector("#editLocationModal").style.display = "flex";
    setSpecificShippingArea({ ...shippingArea })

  }
  const [shippingAddress, setShippingAddress] = useState([])
  useEffect(() => {
    GetCustomerDetails()
      .then(res => {
        const { ShippingAddress } = res.data
        setShippingAddress(ShippingAddress)
      })
      .catch(err => {
        const { response } = err
        logout(response, history)
      })

    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#editLocationModal").style.display = "none";
    };
    document.querySelector("#editLocationModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    //eslint-disable-next-line
  }, [])
  return (
    <div className="right-content">
      <div className="right-content-item">
        <div className="right-content-item-title border-bottom">
          <img src={Location} width="16" height="16" alt="icon" />
          <span>{t('shippingaddresses')}</span>
          <button className="right-content-item-title-location" onClick={(e) => {
            showAddLocation(e)
          }}>{t('addlocation')}</button>
        </div>
        <div className="right-content-item-column">
          {
            !_.isEmpty(shippingAddress) ? (
              Object.keys(shippingAddress).map((key, index) => {
                const { FullName, MobileNumber, Address } = shippingAddress[key]
                return (
                  <div key={index} className="right-content-item-column-item">
                    <div className='right-content-item-column-item-left'>
                      <span>{t('name')} : <span className='shipping-address'>{FullName}</span></span>
                      <span>{t('mobilenumber')} : {MobileNumber}</span>
                      <span>{t('shippingaddress')} : <span className='shipping-address'>{Address}</span></span>
                    </div>
                    <button className='right-content-item-column-item-right' onClick={(e) => {
                      showEditLocation(e, shippingAddress[key])
                    }}>
                      {t('edit')}
                    </button>
                  </div>
                )
              })
            ) : (
              <div className='not-found'>
                <span> {t('noshippingaddressfound')} </span>
              </div>
            )
          }

        </div>
      </div>
      <EditLocation {...{ node, specificShippingArea }} />
    </div>
  )
}

export default ShippingAddress