import jwt from "jsonwebtoken";

export const getCurrentToken = () => {
    return localStorage.getItem("cmsToken")
}

export const getCurrentLang = () => {
    return localStorage.getItem("language") || "EN"
}

export const getCurrentUser = () => {
    const token = getCurrentToken()
    return jwt.decode(token, null);
}

export const getSelectedBranch = () => {
    return getCurrentUser()?.SelectedBranch ?? null
}

export const getCurrentBranch = () => {
    return localStorage.getItem("branchId")
}

export const getPrice = (price = {}, sendOption) => {
    let key = ""
    switch (sendOption) {
        case "REGULAR":
            key = "Regular"
            break;
        case "KTV":
            key = "KTV"
            break;
        default:
            key = "KTV"
            break;
    }

    return price[key] ?? ""
}