import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const BannerTable = ({ tableContents, showBannerEdit, deleteBanner }) => {
  const container = useRef(null);
  const [bannerList, setBannerList] = useState();
  useEffect(() => {
    container.current.getElement().id = "bannerTable";
    setBannerList(tableContents);
    return () => {};
  }, [tableContents]);
  return (
    <ScrollContainer className="content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal normal-start item-name">
          BANNER
        </div>
        {/* <div className="content-product-table-head-item normal normal-start item-name">
          PRIORITY
        </div> */}
        <div className="content-product-table-head-item normal normal-start item-name">
          STATUS
        </div>
        <div className="content-product-table-head-item normal normal-start item-name">
          ACTIONS
        </div>
      </div>
      {!_.isEmpty(bannerList) ? (
        Object.keys(bannerList).map((key, index) => {
          const { id, image, priority, isActive } = bannerList[key];

          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal normal-start item-name">
                <img
                  src={image}
                  alt="Pao Kitchen Banner"
                  width="100px"
                  className="product-name-image"
                />
              </div>
              {/* <div className="content-product-table-data-item normal normal-start item-name">
                <span> {priority} </span>
              </div> */}
              <div className="content-product-table-data-item normal normal-start item-name">
                <span> {isActive ? "Active" : "Inactive"} </span>
              </div>
              <div className="content-product-table-data-item normal normal-start item-name">
                <EditIcon
                  data-tip
                  data-for="editGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showBannerEdit(e, bannerList[key]);
                  }}
                />

                <DeleteIcon
                  data-tip
                  data-for="deleteGroupCategoryTip"
                  sx={{
                    color: "#50311b",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteBanner(id, e);
                  }}
                />
              </div>
              {/* <div className="content-product-table-data-item center item-name"></div>
                <div className="content-product-table-data-item center item-name"></div>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item normal normal-start item-name"></div>
                <div className="content-product-table-data-item center item-name"></div> */}
            </div>
          );
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item normal normal-start item-name"></div>
          <div className="content-product-table-data-item center item-name">
            No Banner(s) Found
          </div>
          <div className="content-product-table-data-item center item-name"></div>
          <div className="content-product-table-data-item center item-name"></div>
        </div>
      )}
    </ScrollContainer>
  );
};

BannerTable.defaultProps = {
  tableContents: [],
  showBannerEdit: () => { },
  deleteBanner: () => { },
};

export default BannerTable;
