import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch /*, useSelector */ } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import Logo from "../../reusable/Logo";
import { setToken } from "./../../../redux/actions/token";
import VerticlaDivider from "./../../reusable/VerticlaDivider";

const Login = () => {
  //const tokens = useSelector((state) => state.token);
  useTitle('Pao Kitchen | Admin');
  const dispatch = useDispatch();
  const history = useNavigate();

  const icon = {
    color: "#50311b",
    cursor: "pointer",
    width: "20px",
    height: "auto",
    zIndex: "999",
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const axios = require("axios");

  const [showIcon, setShowIcon] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const showPassword = () => {
    showIcon ? setShowIcon(false) : setShowIcon(true);
  };
  useEffect(() => {
    setErrorMessage("");
    setShowIcon(false);
    setError(false);
  }, []);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      const { username, password } = values;

      axios
        .post(
          baseUrl + "/cms/users/login",
          {
            UserName: username,
            Password: password,
          },
          {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        )
        .then(function (response) {
          localStorage.setItem("cmsToken", response.data.data);

          dispatch(setToken({ token: response.data.data }));
          history("/admin/cms");
        })
        .catch(function (error) {
          setErrorMessage("" + error?.response?.data?.mess);
        });
    },
  });

  return (
    <div className="cms-login">
      <div className="cms-login-left">
        <div className="cms-logo">
          <Logo />
        </div>
      </div>
      <VerticlaDivider />

      <div className="cms-login-right">
        <form className="cms-login-form" onSubmit={formik.handleSubmit}>
          <div className="cms-login-form-username">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              name="username"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
          </div>
          <div className="cms-login-form-password">
            <label htmlFor="password">Password</label>

            <input
              id="password"
              name="password"
              type={showIcon ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.password}
            />

            <button className="show-pass" type="button" onClick={showPassword}>
              {showIcon ? (
                <VisibilityIcon sx={icon} />
              ) : (
                <VisibilityOffIcon sx={icon} />
              )}
            </button>
          </div>
          <div className="submitButton">
            <button className="cms-login-form-button" type="submit">
              login
              <ArrowRightAltIcon
                sx={{
                  marginBottom: "2.7px",
                }}
              />
            </button>
            <span className="error-message">
              {error ? errorMessage : errorMessage}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
