import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { countPage, IdSelector, ShowItemFlex } from '../../ProductManager/productHelper';
import { GetPaymentMethodList } from "../EcommerceHelper";
import SearchFilterPaymentMethod from "./Filter/SearchFilterPaymentMethod";
import PaymentMethodForm from "./PaymentMethodForm";
import PaymentMethodTable from "./PaymentMethodTable";
const PaymentMethodList = () => {
  const history = useNavigate()
  const [paymentMethodList, setPaymentMethodList] = useState([])

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)


  useEffect(() => {
    GetPaymentList(page, "", {})

    return () => {

    }
  }, [page])
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const GetPaymentList = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setPaymentMethodList([])
    setPageCount(0)

    GetPaymentMethodList(page, search, searchValue)
      .then(res => {
        const { paginatedResults, metadata } = res?.data;
        const { total, limit } = metadata;
        setPageCount(countPage(total, limit))
        setPaymentMethodList(paginatedResults)
        Swal.close()
      })
      .catch(
        err => {
          console.log(err.response)
          setPageCount(0)
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No Payment method(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
        }
      )
  }
  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";
        IdSelector("paymentMethodTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";

        IdSelector("paymentMethodTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  const refreshTable = (e) => {
    e.preventDefault()
    GetPaymentList(1, "", {})
    setPage(1)
  }

  const paymentMethodUpdate = (id) => {
    history(`/admin/cms/payment-method/${id}`)
  }
  const clearFilter = (e) => {
    e.preventDefault()
    GetPaymentList(1, "", {})
    setPage(1)
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    GetPaymentList(1, search, searchValue)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / payment method
      </section>
      <SearchFilterPaymentMethod {...{ clearFilter, productFilter }} />
      <PaymentMethodTable {...{ paymentMethodList, paymentMethodUpdate }} />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}
      >
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>
      <div className="add-shipping-area">
        <div className="add-shipping-area-container"></div>
      </div>
      <div className='btn-open-modal'>
        <button onClick={(e) => {
          ShowItemFlex("#addPaymentMethod")
        }}><PlusOneIcon sx={{ fontSize: "16px" }} /></button>
      </div>
      <PaymentMethodForm {...{ refreshTable }} />
    </main>
  )
}

export default PaymentMethodList
