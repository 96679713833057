import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const BranchSelect = ({ user, branchSelect, locationIcon }) => (
  <div className="branch-selection">
    {Object.keys(user.Branches).map((key, index) => {
      return (
        <div
          className="content-branch-dialog-cards-card"
          onClick={() => {
            branchSelect(user.Branches[key].BranchId);
          }}
          key={index}
        >
          <div>
            <LocationOnIcon sx={locationIcon} />
          </div>
          <div className="branch-name">
            <span className="branch-name-city">{user.Branches[key].City}</span>
            <span className="branch-name-name">{user.Branches[key].Name}</span>
          </div>
        </div>
      );
    })}
  </div>
);

export default BranchSelect;
