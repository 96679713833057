import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const SearchFilter = ({ dataState, handleOnChange, getUser, resetFilter }) => (
  <section className="content-main-search">
    <div className="content-main-search-item">
      <label htmlFor="search">Username</label>
      <input
        type="text"
        name="search"
        value={dataState.search}
        onChange={handleOnChange}
      />
    </div>

    <div className="content-main-search-item">
      <label htmlFor="filterStatus">filter by status</label>
      <select
        id="filterStatus"
        name="isActive"
        value={dataState.isActive}
        onChange={handleOnChange}
      >
        <option value="">-All-</option>
        <option value="true">Active</option>
        <option value="false">Inactive</option>
      </select>
    </div>
    <button
      onClick={() => {
        getUser("0", dataState.search, dataState.isActive);
      }}
    >
      <SearchIcon />
    </button>
    <button onClick={resetFilter}>
      <AutorenewIcon />
    </button>
  </section>
);

export default SearchFilter;
