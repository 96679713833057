import React /*useEffect, useState */ from "react";
import { Outlet } from "react-router-dom";
import SubMenu from "../submenu/SubMenu";
import BranchDialog from "./BranchDialog";
import Navigation from "./Navigation";


//import io from "socket.io-client";
const Content = () => {
  //const [socket, setSocket] = useState(null);
  //   useEffect(() => {
  //     const getOrderSocket = async (params) => {
  //       const orderSocket = await io("http://devapi.annam.ph");
  //       setSocket(orderSocket);
  //       orderSocket.on("new_order", {});
  //       return () => orderSocket.close();
  //     };
  //     getOrderSocket();
  //     // eslint-disable-next-line
  //   }, [setSocket]);
  //
  //   socket ? console.log(socket) : console.log("disconnected");
  return (
    <div className="content">
      <BranchDialog />
      <Navigation />
      <SubMenu />
      <Outlet />

    </div>
  );
};

export default Content;
