import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const SearchBar = ({ submitSearch }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [isSearched, setIsSearched] = useState(false)
  const handleChange = (e) => {
    const { value } = e.target
    setSearch(value)
  }

  return (
    <form onSubmit={(e) => {
      setIsSearched(true)
      submitSearch(e, search)
    }} className='input search-bar'>
      <input type="text" onChange={(e) => { handleChange(e) }} value={search} placeholder={t('searchitem') + "..."} />
      <div className='search-icon'>
        {
          isSearched ?
            <Tooltip title="Remove Search" placement="bottom-start" arrow>
              <ClearIcon onClick={(e) => {
                setIsSearched(false)
                submitSearch(e, "")
                setSearch("")
              }} sx={{
                '&:hover': {
                  color: "#f08d19",
                  cursor: "pointer"
                }
              }} />
            </Tooltip>
            :
            <SearchIcon onClick={(e) => {
              setIsSearched(true)
              submitSearch(e, search)
            }} sx={{
              '&:hover': {
                color: "#f08d19",
                cursor: "pointer"
              }
            }} />
        }

      </div>
    </form>
  )
}

export default SearchBar