import moment from "moment";
import { useEffect, useRef } from "react"; 
import ScrollContainer from 'react-indiana-drag-scroll'; 
import Swal from 'sweetalert2';  
import _ from 'lodash';

const ShippingTransactionTable = ({ orderList }) => {

  const container = useRef(null) 
  useEffect(() => {
    container.current.getElement().id = "orderTable"

    return () => {

    }
  }, [])
  // console.log("orderList 2: ", orderList)

  const copyText = (e, value) => {
    e.preventDefault()

    navigator.clipboard.writeText(value);
    Swal.fire({
      toast: true,
      icon: 'success',
      title: `Order reference (${value}) copied`,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  } 
 
  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item center">Status</div>
        <div className="content-product-table-head-item normal-start">Placed Date <br></br>(Pick-up Time)</div>
        <div className="content-product-table-head-item superbig-center">Route</div>
        <div className="content-product-table-head-item normal-start center">Pick-Up Contact</div>
        <div className="content-product-table-head-item center">Driver / Servicer</div>
        <div className="content-product-table-head-item center">Service Type <br></br>& Special Request(s)</div>
        <div className="content-product-table-head-item small-item center">Order Amount</div> 

      </div>
      {orderList.length ? (
        Object.keys(orderList).map((key, index) => {
          const { order_display_id, order_status, order_time, address, contact_user, contact_tel, driver, vehicle_type_name, spec_req, order_total_price } = orderList[key]
          let bg = {}
          switch (order_status) {
            case "Assigning":
              bg = "#f7981c"
              break
            case "On Going":
              bg = "#008ff5"
              break
            case "Picked Up":
              bg = "#1e4cb8"
              break
            case "Completed":
              bg = "#059674"
              break
            default:
              bg = "#9e0606"
        }

        let styleObj = {
          fontSize: 14,
          color: "#fff",
          textAlign: "center",
          background: bg
        }
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item center">
                <div 
                  onClick={(e) => {
                  e.stopPropagation()
                  copyText(e, order_display_id)
                  }}
                >
                  <div className="llm-badge" style={styleObj}>{order_status}</div><br></br>
                  <label className="center pickuptime">{order_display_id}</label>
                </div>
              </div>

              <div className="content-product-table-data-item normal-start">
                <div>{moment( order_time ).tz( "Asia/manila" ).format( "YYYY-MM-DD" )}<br></br>
                <label className="pickuptime">{moment( order_time ).tz( "Asia/manila" ).format( "HH:mm" )}</label></div>
              </div>

              <div className="content-product-table-data-item superbig-item dPvIVd"> 
                <div className="fZHyZI">{address[0].name}</div> 
                <div className="fZHyZI">{address[1].name}</div>                
              </div>

              <div className="content-product-table-data-item normal-start">
                <div>{contact_user}<br></br>
                <label className="pickuptime">{contact_tel}</label></div>
              </div>

              <div className="content-product-table-data-item normal-start">
                <div>{driver.driver_name}<br></br>
                <label className="pickuptime">{driver.phone_no}</label></div>
              </div>

              <div className="content-product-table-data-item normal-start">
                <div>{vehicle_type_name}<br></br>
                {
                  _.map(spec_req, (value, key) => {
                    return ( 
                      <div key={key}><span  className="pickuptime">{value.name}</span><br></br></div>
                    )
                  })
                }
                </div>
              </div>

              <div className="content-product-table-data-item small-item center">
                <div>₱{(order_total_price / 100)}</div>
              </div>

            </div>            
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>

          <div className="content-product-table-data-item normal-start">
            No Order List Found
          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div> 
        </div>
      )}
    </ScrollContainer>
  )
};

export default ShippingTransactionTable;
