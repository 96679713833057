import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { HideItem, IdSelector, ShowItem, ShowItemFlex } from "./../productHelper";
const AddGroupCategory = () => {
  const showTextAdd = () => {
    IdSelector("addgroupcategory").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#addgroupcategory");
  };
  const hideTextAdd = () => {
    IdSelector("addgroupcategory").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#addgroupcategory");
    }, 500);
  };
  const showAddGroupCategory = () => {
    ShowItemFlex("#groupAddForm")
  }
  return (
    <div className="add-product-group-category" onClick={showAddGroupCategory}>
      <div id="addgroupcategory" >Add Group Category</div>
      <div className="icon" onMouseEnter={showTextAdd} onMouseLeave={hideTextAdd} // onClick={}
      >
        <AddIcon sx={{
          fontSize: "18px"
        }} />
      </div>
    </div>
  );
}

export default AddGroupCategory;