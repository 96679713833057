import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
const LimitationsTable = ({ tableContents, showUpdateModal }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "limitationTable"

    return () => {

    }
  }, [])
  return (
    <ScrollContainer className="content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal normal-start item-name">TYPE</div>
        <div className="content-product-table-head-item normal normal-start item-name">REDEEM LIMIT</div>
        <div className="content-product-table-head-item normal normal-start item-name">DAYS</div>
        <div className="content-product-table-head-item normal normal-start item-name">GROUP</div>
        <div className="content-product-table-head-item normal normal-start item-name">DESCRIPTION</div>
        <div className="content-product-table-head-item center item-name">STATUS</div>
        <div className="content-product-table-head-item center item-name">ACTIONS</div>
      </div>
      {
        !_.isEmpty(tableContents) ? (
          Object.keys(tableContents).map((key, index) => {
            const { Description, LoginType, NoDays, PeriodName, RedeemLimit, Status, _id } = tableContents[key]
            return (
              <div className="content-product-table-data" key={index}>
                <div className="content-product-table-data-item normal normal-start item-name">{PeriodName || "N/A"}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{RedeemLimit || "N/A"}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{NoDays || "N/A"}</div>
                <div className="content-product-table-data-item normal normal-start item-name">{LoginType}</div>
                <div className="content-product-table-data-item normal normal-start item-name">
                  <div className="product-name">

                    <div className="product-name-title">
                      <div>
                        <span>
                          <b>{Description.VN}</b>
                        </span>
                      </div>
                      <div>
                        <small>{Description.EN}</small>
                      </div>
                      <div>
                        <small>{Description.CN}</small>
                      </div>
                      <div>
                        <small>{Description.TH}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-product-table-data-item center item-name">
                  {
                    Status === "true" || Status === true ?
                      (
                        <div className="flex-row-start-center gap-5">
                          <CircleIcon sx={{ color: "#1bb76d", fontSize: "12px" }} /> Active
                        </div>
                      ) :
                      (
                        <div className="flex-row-start-center gap-5">
                          <CircleIcon sx={{ color: "gray", fontSize: "12px" }} /> Inactive
                        </div>
                      )
                  }
                </div>
                <div className="content-product-table-data-item center item-name">
                  <Tooltip title="Edit This Limitations" placement="bottom-start" arrow>
                    <button className='edit-btn' onClick={(e) => {
                      showUpdateModal(e, _id)
                    }}>
                      <EditIcon sx={{
                        fontSize: "20px"
                      }} />
                    </button>
                  </Tooltip>
                </div>
              </div>
            )
          })
        ) : (
          <div className="content-product-table-data">
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item normal normal-start item-name">No limitations Found!</div>
            <div className="content-product-table-data-item normal normal-start item-name"></div>
            <div className="content-product-table-data-item center item-name"></div>
            <div className="content-product-table-data-item center item-name"></div>
          </div>
        )}
    </ScrollContainer >
  )
};

LimitationsTable.defaultProps = {
  tableContents: [],
  showUpdateModal: () => { }
}

export default LimitationsTable;
