import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import SelectDateRange from "../../components/reusable/SelectDateRange";

const AnalyticsFilter = ({ productFilter, clearFilter, showSortby = false }) => {
  const [searchItem, setSearchItem] = useState({
    DateFrom: "",
    DateTo: "",
    sortBy: "total",
    datePickerType: "thisDay"
  })
  const clearSearch = (e) => {
    e.preventDefault()
    setSearchItem({
      DateFrom: "",
      DateTo: "",
      sortBy: "total"
    })
    clearFilter(e)
  }

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
      console.log(name, value)
    setSearchItem(oldValue => {
      return {
        ...oldValue,
        [name]: value
      }
    })
  }, [])

    const onChangeDate = ({startDate, endDate, type}) => {
        setSearchItem({
            ...searchItem,
            DateFrom: startDate.format("YYYY-MM-DD"),
            DateTo: endDate.format("YYYY-MM-DD"),
            datePickerType: type
        })
    }
  return (
    <div className="content-main-search scroll-container  analytics-filter">
        <SelectDateRange endDate={searchItem.DateTo} startDate={searchItem.DateFrom} onChange={onChangeDate} type={searchItem.datePickerType} />
        {
            showSortby ? <div className="content-main-search-item">
                <label htmlFor="sortBy">Sort by</label>
                <select onChange={(e) => handleChange(e)} name="sortBy" id="sortBy">
                    <option value="total">Total</option>
                    <option value="sold">Qty. Sold/Orders</option>
                </select>
            </div>
                : null
        }
      <Tooltip title="Submit Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => {
            productFilter(e, searchItem)
          }}
        >
          <SearchIcon />
        </button>
      </Tooltip>
      <Tooltip title="Clear Filter" placement="bottom-start" arrow>
        <button className="flex-row-center-center gap-5"
          onClick={(e) => { clearSearch(e) }}
        >
          <AutorenewIcon />
        </button>
      </Tooltip>
    </div>
  )
};

export default AnalyticsFilter;
