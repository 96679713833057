
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
const baseUrl = process.env.REACT_APP_BASE_URL;
if (baseUrl === 'https://devapi.annam.ph') {
  console.log = console.warn = console.error = () => { };
}

const root = document.getElementById("root");
render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  root
);
