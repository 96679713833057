import _ from "lodash";
import CurrencyFormat from "react-currency-format";
import ScrollContainer from "react-indiana-drag-scroll";

const AnalyticsTableCategories = ({ tableContents }) => (
  <ScrollContainer className="content-product-table" style={{minHeight: "auto"}}>
    <div className="content-product-table-head">
      <div className="content-product-table-head-item big-item normal-start item-name">CATEGORY</div>
      <div className="content-product-table-head-item normal center item-name">SOLD QTY.</div>
      <div className="content-product-table-head-item normal center item-name">TOTAL</div>
    </div>
    {
      !_.isEmpty(tableContents) ? (
        Object.keys(tableContents).map((key, index) => {
          const { Category, Sold, Total, } = tableContents[key]
          const Name = Category?.Name ?? {}
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item big-item normal-start  item-name">
                <div className="product-name width-100 gap-30">
                  <strong>{Name.EN || Name.VN || Name.CN || Name.TH}</strong>

                </div>
              </div>
              <div className="content-product-table-data-item normal center item-name">
                {Sold || "N/A"}
              </div>
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Total}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>


            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item big-item normal-start item-name">

          </div>
          <div className="content-product-table-data-item normal center item-name">
            No Available Reports Found!
          </div>
          <div className="content-product-table-data-item normal center item-name">
          </div>
        </div>
      )}
  </ScrollContainer >
);

AnalyticsTableCategories.defaultProps = {
  tableContents: []
}

export default AnalyticsTableCategories;
