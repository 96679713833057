import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { setToken } from "./../../../redux/actions/token";
const BranchDialog = () => {
  const jwtSecretKey = process.env.REACT_APP_JWT_SECRET_KEY;
  const locationIcon = { fontSize: "60px", color: "#50311b" };
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { token, branchId } = tokens.token;
  const user = jwt.decode(token);
  const dispatch = useDispatch();
  useEffect(() => {
    if (branchId) {
      document.querySelector(".content-branch-dialog").style.display = "none";
    } else {
      document.querySelector(".content-branch-dialog").style.display = "flex";
    }
  }, [branchId, user.Branches]);
  const branchSelect = (id) => {
    localStorage.setItem("branchId", id);
    document.querySelector(".content-branch-dialog").style.display = "none";
    dispatch(setToken({ branchId: id }));
    const newToken = jwt.sign({ ...user, SelectedBranch: id }, jwtSecretKey);
    localStorage.setItem("cmsToken", newToken);
    dispatch(setToken({ token: newToken }));
  };

  return (
    <div className="content-branch-dialog">
      <div className="content-branch-dialog-greeting">
        <h1>Hello {user.UserName},</h1>
        <span> You have to select a store branch for you to continue..</span>
      </div>
      <div className="content-branch-dialog-cards">
        {Object.keys(user.Branches).map((key, index) => {
          return (
            <div
              className="content-branch-dialog-cards-card"
              onClick={() => {
                branchSelect(user.Branches[key].BranchId);
              }}
              key={index}
            >
              <div>
                <LocationOnIcon sx={locationIcon} />
              </div>
              <div className="branch-name">
                <span className="branch-name-city">
                  {user.Branches[key].City}
                </span>
                <span className="branch-name-name">
                  {user.Branches[key].Name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BranchDialog;
