import { useTitle } from "react-use";
import { useState, useEffect } from 'react'
import PlatformFilter from './PlatformFilter'
import PlatformItem from "./PlatformItem";
import { GetOrdersByPlatformId, GetPlatformList } from "../../Ecommerce/EcommerceHelper";

const PlatformReport = () => {

  useTitle("Pao Kitchen | Platform Report")

  const dummyData = {
    name: "Grab Mart",
    discountGiven: 36,
    discountSub: 5125,
    orders: 210,
    sales: 25126,
    shipping: 929
  }

  const dummyData2 = {
    name: "Food Panda",
    discountGiven: 36,
    discountSub: 5125,
    orders: 210,
    sales: 25126,
    shipping: 929
  }

  const [platformItemsData, setPlatformItemsData] = useState( [] )
  const [orderData, setOrderData] = useState( [] )
  const [renderPlatformItems, setRenderPlatformItems] = useState( {} )
  const [platformOptions, setPlatformOptions] = useState( [] )
  
  // To switch re-render platform list
  const renderPlatfromItems = () => {

    setRenderPlatformItems(platformItemsData)

  }

  // Initialziign Platform Analytics
  const getPlatformList = async () => {

    const platforms = await GetPlatformList( true ) 

    const orderIds = platforms.map( x => GetOrdersByPlatformId( x._id ) )
    const orders = await Promise.all( orderIds )

    const options = platforms.map(x => {

      return <option key={x._id} id={x._id} value={x._id}> {x.PlatformName} </option>
    
    })
    
    setPlatformOptions( options )
    setOrderData( orders )

  }


  useEffect(() => {

    getPlatformList()
    renderPlatfromItems()

  }, [])

  useEffect(() => {
    
    console.log( orderData )
  
  }, [orderData])
  

  return <>
    <main className="content-product">
      
      {/* Breadcrumbs */}
      <section className="content-main-breadcrumbs">
        analytics / platform reports
      </section>

      {/* Filter Section & Overview Display*/}
      <PlatformFilter props={{ setPlatformItemsData, platformOptions }} />
      
      {/* Platform Report */}
      <div className="report-section">
      
        <PlatformItem data={dummyData} />
        <PlatformItem data={dummyData2} />
      
      </div>    
      
    </main>
  </>

}

export default PlatformReport;