import {useCallback} from "react";
import "./style.scss"
import ExtraOrderItem from "./components/ExtraOrderItem";
import Proptypes from "prop-types";
const ExtraOrder = ({order, refreshOrder}) => {
    const renderListExtraOrders = useCallback(() => {
        const ordersPending = order?.OrdersPending ?? []

        return ordersPending.map((orderPending, index) => {
            const key = `extra-order-item-${index}`
            return <ExtraOrderItem key={key} order={orderPending} mainOrder={order} index={index} refreshOrder={refreshOrder} />
        })

        // eslint-disable-next-line
    }, [order])

    return renderListExtraOrders()
}

ExtraOrder.propTypes = {
    order: Proptypes.object.isRequired,
    refreshOrder: Proptypes.func
}

export default ExtraOrder