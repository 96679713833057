import CloseIcon from "@mui/icons-material/Close";
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import React from "react";
import { useNavigate } from "react-router";
const TypeOfProduct = ({ selectType }) => {
  const history = useNavigate();

  return (
    <div className="modal-type">
      <div className="modal-type-form" >
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            Type of product Selection
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={() => history("/admin/cms/product-list")}
            />
          </div>
        </div>
        <form className="modal-type-form-main">

          <div className="modal-type-form-main-note">
            <span> Please select the type of product you want to create.
            </span>
            <span>Make sure you will choose the correct one because once the product is created, changing its type is not allowed.
            </span>
          </div>
          <div className="container-row">
            <div className="card-column" onClick={(e) => { selectType(1, e) }}> <RiceBowlIcon sx={{ fontSize: "50px" }} /> <span>Single</span></div>
            {/* <div className="card-column" onClick={(e) => { selectType(2, e) }}> <BrunchDiningIcon sx={{ fontSize: "50px" }} /> <span>Group</span></div> */}
          </div>
        </form>

      </div>
    </div>
  )
};

export default TypeOfProduct;
