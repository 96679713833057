import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import moment from "moment";
import React, { useEffect, useRef } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
const PaymentMethodTable = ({ paymentMethodList, paymentMethodUpdate }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "paymentMethodTable"

    return () => {

    }
  }, [])

  return (
    <ScrollContainer className="content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start">Code</div>
        <div className="content-product-table-head-item normal-start item-name">Name</div>
        <div className="content-product-table-head-item normal-start">Status</div>
        <div className="content-product-table-head-item center item-name">Date Created</div>
        <div className="content-product-table-head-item center">Action</div>
      </div>
      {paymentMethodList.length ? (
        Object.keys(paymentMethodList).map((key, index) => {
          const { _id, Code, Name, IsActive, CreatedAt } = paymentMethodList[key]
          let DateCreated = moment(CreatedAt).format("YYYY-MM-DD HH:MM:SS");
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal-start">
                {Code}
              </div>
              <div className="content-product-table-data-item normal-start item-name">
                {Name}
              </div>
              <div className="content-product-table-data-item normal-start">
                {IsActive ? (<span className="span-active">Active</span>) : (<span className="span-inactive">InActive</span>)}
              </div>

              <div className="content-product-table-data-item center item-name">
                {DateCreated}
              </div>
              <div className="content-product-table-data-item center">
                <Tooltip title="Edit This Payment method" placement="bottom-start" arrow>
                  <button className='edit-btn' onClick={() => {
                    paymentMethodUpdate(_id)

                  }}><EditIcon sx={{
                    fontSize: "20px"
                  }} /></button>
                </Tooltip>


              </div>



            </div>
          )
        })
      ) : (
        <div className="content-product-table-data center-data">
          <div className="content-product-table-data-item normal-start">
          </div>
          <div className="content-product-table-data-item normal-start item-name">

          </div>
          <div className="content-product-table-data-item normal-start">
            No payment method found
          </div>

          <div className="content-product-table-data-item center item-name">
          </div>
          <div className="content-product-table-data-item center">
          </div>
        </div>
      )}
    </ScrollContainer >
  )
};

export default PaymentMethodTable;
