import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import {Tooltip} from "@mui/material";
import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import ReactSelect from "react-select";
import SelectDateRange from "../../../../components/reusable/SelectDateRange";
import moment from "moment";

const FilterBar = forwardRef(({onSearch, onClear}, ref) => {
        const clearSearch = () => {
            const newValue = {
                DateFrom: moment().format("YYYY-MM-DD"),
                DateTo: moment().format("YYYY-MM-DD"),
                sortBy: "total",
                sendOptions: []
            }
            setSearchItem(newValue)
            onClear(newValue)
        }

        const [searchItem, setSearchItem] = useState({
            DateFrom: moment().format("YYYY-MM-DD"),
            DateTo: moment().format("YYYY-MM-DD"),
            sortBy: "total",
            sendOptions: [],
            datePickerType: "thisDay"
        })

        useImperativeHandle(ref, () => {
            return {
                clearSearch: () => clearSearch(),
                getPayload: () => searchItem
            }
        })

        const handleChange = useCallback((e) => {
            const {name, value} = e.target
            setSearchItem(oldValue => {
                return {
                    ...oldValue,
                    [name]: value
                }
            })
        }, [])

        const sendOptions = [
            {value: "DELIVERY", label: "Delivery"},
            {value: "PICKUP", label: "Pickup"},
            // {value: "DINEIN", label: "Dine In"},
            {value: "KTV", label: "KTV"},
        ]

        const onSelectSendOption = (options) => {
            if (options === null || typeof options === "undefined") {
                setSearchItem({
                    ...searchItem,
                    sendOptions: []
                })
            } else {
                const sendOptions = options.map(option => option.value)
                setSearchItem({
                    ...searchItem,
                    sendOptions
                })
            }
        }

        const customStyleSendOption = {
            menu: (styles) => {
                return {
                    ...styles,
                    zIndex: 100
                }
            },
            multiValue: (styles) => {
                return {
                    ...styles,
                    background: "transparent",
                    fontSize: "14px",
                    border: "1px solid #cfcece"
                }
            },
            control: (styles) => {
                return {
                    ...styles,
                    background: "#f7f7f7",
                    border: "none",
                    minWidth: "220px",
                    maxWidth: "320px",
                    outline: "none",
                    paddingTop: "3px",
                    paddingBottom: "2px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    borderRadius: "4px",
                    boxShadow: "0 0 5px #d9d9d9",
                }
            },
            placeholder: (styles) => {
                return {
                    ...styles,
                    fontSize: "13px",
                    color: "#262626",
                    fontFamily: "SF Pro Display Regular, sans-serif"
                }
            }
        }

        const onChangeDate = ({startDate, endDate, type}) => {
            setSearchItem({
                ...searchItem,
                DateFrom: startDate.format("YYYY-MM-DD"),
                DateTo: endDate.format("YYYY-MM-DD"),
                datePickerType: type
            })
        }

        return (
            <div className="content-main-search scroll-container  analytics-filter">
                <SelectDateRange type={searchItem.datePickerType} startDate={searchItem.DateFrom} endDate={searchItem.DateTo} onChange={onChangeDate} />
                <div className="content-main-search-item">
                    <label htmlFor="sortBy">Sort by</label>
                    <select onChange={(e) => handleChange(e)} name="sortBy" id="sortBy">
                        <option value="total">Total</option>
                        <option value="sold">Qty. Sold/Orders</option>
                    </select>
                </div>
                <div className="content-main-search-item">
                    <label htmlFor="sortBy">Send Option</label>
                    <ReactSelect options={sendOptions} onChange={onSelectSendOption} isClearable={true} isMulti={true} styles={customStyleSendOption}
                                 value={
                                     sendOptions.filter(sendOption => {
                                         const find = searchItem.sendOptions.find(option => {
                                             return option === sendOption.value
                                         })

                                         return typeof find !== "undefined" && find !== null
                                     })
                                 }
                    />
                </div>
                <Tooltip title="Submit Filter" placement="bottom-start" arrow>
                    <button className="flex-row-center-center gap-5"
                            onClick={() => {
                                onSearch(searchItem)
                            }}
                    >
                        <SearchIcon/>
                    </button>
                </Tooltip>
                <Tooltip title="Clear Filter" placement="bottom-start" arrow>
                    <button className="flex-row-center-center gap-5" onClick={clearSearch}>
                        <AutorenewIcon/>
                    </button>
                </Tooltip>
            </div>
        )
    }
)
export default FilterBar;
