import {orderDetails} from "../../../Order/OrderUtils";
import css from "../../OrderDineIn.module.scss"
// import moment from "moment-timezone";
import Proptypes from "prop-types";

const OrderInfo = ({order = orderDetails}) => {
    const { ContactDetails } = order ?? {}
    const { FullName, MobileNum } = ContactDetails ?? {}
    // const formatTime = (time) => {
    //     if(!time) {
    //         return ""
    //     }
    //
    //     return moment.utc(time).format('yyyy-MM-DD hh:mm:ss A')
    // }

    return (
        <div className={css.orderInfoRow}>
            <div className={css.orderInfo}>
                {/*<span><span className={css.bold}>Order Ref:</span> {OrderRef || "N/A"}</span>*/}
                {/*<span><span className={css.bold}>Order Time:</span> {formatTime(CreatedAt)}</span>*/}
                {/*<span><span className={css.bold}>PREF. DELIVERY TIME:</span> {formatTime(PreferredDeliveryDate)}</span>*/}
                <span className={css.fullname}>
                    <span className={`${css.bold} ${css.fullWidth}`}>Fullname: </span><span className={css.highlight}>{FullName || "N/A"}</span>
                </span>
            </div>
            <div className={css.customerInfo}>
                {/*<span className={css.fullname}>*/}
                {/*    <span className={css.bold}>Fullname: </span><span className={css.highlight}>{FullName || "N/A"}</span>*/}
                {/*</span>*/}
                {/*<span>*/}
                {/*    <span className={css.bold}>Email: </span>*/}
                {/*    <span className={css.email}>{Email || "N/A"}</span>*/}
                {/*</span>*/}
                <span>
                    <span className={`${css.bold} ${css.fullWidth}`}>Phone: </span>{MobileNum || "N/A"}
                </span>
            </div>
        </div>
    )
}

OrderInfo.propTypes = {
    order: Proptypes.any
}

export default OrderInfo