import React from 'react';
import Banner from './banner/Banner';
import ProductList from './product/ProductList';

const PublicContentRight = ({ addToCart, showProductItemDetails, search }) => {
  const showProductDetails = (e, product) => {
    e.preventDefault()
    console.log("showProductDetails 3: ", product)
    showProductItemDetails(e, product)
  }
  return (
    <>
      <Banner />
      <ProductList {...{ addToCart, showProductDetails, search }} />

    </>
  );
};

export default PublicContentRight;
