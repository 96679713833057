import { TOKEN } from "../types";
const initialState = {
  token: {
    token: localStorage.getItem("cmsToken"),
    branchId: localStorage.getItem("branchId"),
    publicBranch: localStorage.getItem("publicBranch"),
    publicToken: localStorage.getItem("publicToken"),
    lang: localStorage.getItem("language") === "" || !localStorage.getItem("language") ? "EN" : localStorage.getItem("language")
  },
};
const tokenReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOKEN:
      return {
        ...state,
        token: {
          ...state.token,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default tokenReducers;
