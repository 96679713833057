import css from "./Language.module.scss"
import cn from "../../../../../../assets/lang/cn.svg";
import en from "../../../../../../assets/lang/en.svg";
import th from "../../../../../../assets/lang/th.svg";
import vn from "../../../../../../assets/lang/vn.svg";
import {useEffect, useState} from "react";
import i18n from "i18next";
import {getCurrentLang} from "../../../helper";
import {setToken} from "../../../../../../redux/actions/token";
import {useDispatch} from "react-redux";

const Language = () => {
    const [language, setLanguage] = useState("");
    const [currentFlag, setCurrentFlag] = useState(en);
    const listLang = ["EN", "VN", "CN", "TH"]
    const dispatch = useDispatch()

    useEffect(() => {
        let lang = getCurrentLang()
        setLanguage(lang);
        i18n.changeLanguage(lang)
        .then(() => {
            setCurrentFlag(getSrcFlag(lang))
        })
    }, [])

    const getSrcFlag = (lang) => {
        let output = en
        switch (lang?.toLowerCase()) {
            case "vn":
                output = vn;
                break;
            case "cn":
                output = cn;
                break;
            case "th":
                output = th;
                break
            default:
               output = en;
               break;
        }

        return output
    }

    const onChangeLang = (lang) => {
        localStorage.setItem("language", lang)
        dispatch(setToken({ lang: lang }));
        i18n.changeLanguage(lang)
            .then(() => {
                setLanguage(lang);
                setCurrentFlag(getSrcFlag(lang))
            })
    }

    return (
        <div className={css.changeLangContainer}>
            <span className={css.currentLangWrapper}>
                <span className={css.langTitle}>{language}</span>
                <img src={currentFlag} className={css.flag} alt={language}/>
            </span>
            <ul className={css.listLang}>
                {
                    listLang.map((lang, index) => {
                        return (
                            <li className={css.itemLang} key={`item_lang_${index}`}>
                                <button className={css.lang} onClick={(e) => {
                                    e.preventDefault()
                                    onChangeLang(lang)
                                }}>
                                    <span className={css.langTitle}>{lang}</span>
                                    <img className={css.flag} src={getSrcFlag(lang)} alt={lang}/>
                                </button>
                            </li>
                        )
                    })
                }

            </ul>
        </div>
    )
}

export default Language