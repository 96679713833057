import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useTitle } from 'react-use';
import Swal from "sweetalert2";
import { HideItem, IdSelector, ShowItemFlex } from '../../ProductManager/productHelper';
import { DeleteBank, GetPaymentMethodDetail, UpdatePaymentMethod, UpdatePaymentMethodStatus, UpdateBankInfo } from "../EcommerceHelper";
import AddBankForm from './AddBankForm';
import BankTable from './BankTable';
import EditBankForm from "./EditBankForm";
const EditPaymentBankList = () => {
  useTitle('Pao Kitchen | Edit Payment method');
  let urlParameter = useParams();
  const [paymentMethodList, setPaymentMethodList] = useState({
    Code: "",
    IsActive: true,
    Name: "",
    Banks: [],
    _id: ""
  })
  const [bankList, setBanklist] = useState([])
  const [paymentMethodId, setPaymentMethodId] = useState()

  useEffect(() => {
    getPaymentMethodDetails()
    return () => {

    }
    // eslint-disable-next-line
  }, [])
  const getPaymentMethodDetails = () => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    GetPaymentMethodDetail(urlParameter.id)
      .then(
        res => {
          // console.log(res.data);
          const { Code, Name, IsActive, Banks, _id } = res.data
          setPaymentMethodList({
            Code: Code,
            Name: Name,
            IsActive: Boolean(IsActive),
            Banks: Banks,
            _id: _id
          })
          setBanklist([...Banks])
          Swal.close()
        }
      )
      .catch(err => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Bank(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      })
  }

  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";
        IdSelector("paymentMethodTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("paymentMethodTable").style.scrollBehavior = "smooth";

        IdSelector("paymentMethodTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  const refreshTable = (e) => {
    e.preventDefault()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setPaymentMethodList({ ...paymentMethodList, [name]: value })
  }
  const addBank = (e, bank) => {

    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setPaymentMethodList({
      ...paymentMethodList,
      Banks: [...paymentMethodList.Banks, bank]
    })
    UpdatePaymentMethod(paymentMethodList._id, {
      ...paymentMethodList,
      Banks: [...paymentMethodList.Banks, bank]
    })
      .then(res => {
        getPaymentMethodDetails()
        Swal.close()
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! ",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const submitPaymentEdit = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    UpdatePaymentMethod(paymentMethodList._id,
      {
        ...paymentMethodList,
        Banks: bankList
      })
      .then(res => {
        getPaymentMethodDetails()
        Swal.close()
      })
      .catch(
        err => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! :pensive:",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
        }
      )
  }
  const deleteBank = (e, id) => {
    e.preventDefault()
    let parentId = paymentMethodList._id
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      footer: "&#169; Pao Kitchen 2023",
      
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteBank(id, parentId)
        .then(res => {
          // console.log(res)
          getPaymentMethodDetails()

          Swal.fire({
            title: "Deleted",
            text: "Bank Info has been deleted!",
            icon: "success"
          })
        })
        .catch(
          err => {
            console.log(err.response);
          }
        )
      }
    })

  }

  const [ editBankValue, setEditBankValue ] = useState({
    BankId: "",
    BankName: "",
    Account: "",
    Number: "",
    UrlLogin: "",
    IsActive: true,
  })
  const editBank = (e, bankInfo) => {
    e.preventDefault()
    const { _id, BankId, BankName, Account, Number, UrlLogin, IsActive } = bankInfo

    // console.log(bankInfo)
    setEditBankValue ({
      _id,
      BankId, 
      BankName, 
      Account, 
      Number, 
      UrlLogin, 
      IsActive
    })

    ShowItemFlex("#editBankForm")
  }

  const updateBankInfo = (e, bankValue) => {
    e.preventDefault()
    // console.log(bankValue)

    const { 
      _id, 
      BankId, 
      BankName, 
      Account, 
      Number, 
      UrlLogin, 
      IsActive } = bankValue
    
    let data = {
      BankId: _id, 
      BankName: BankName, 
      Account: Account, 
      Number: Number, 
      UrlLogin: UrlLogin, 
      IsActive: IsActive
    }
    
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    UpdateBankInfo(paymentMethodList._id, data).then((res) => {
      
      Swal.close()
      Swal.fire({
        toast: true,
        icon: 'success',
        title: `${_id} Updated`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      setTimeout(() => {
        getPaymentMethodDetails()
        HideItem("#editBankForm")
      }, 1500);
      
      
    }).catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    })
  }

  const handleChangeBankStatus = (i, id) => {
    setBanklist(
      bankList.map((bankList) =>
        bankList._id === id
          ? { ...bankList, IsActive: Boolean(!bankList.IsActive) }
          : { ...bankList }
      )
    )


    UpdatePaymentMethodStatus(paymentMethodList._id, {
      BankId: id,
      IsActive: !bankList[i].IsActive
    })
      .then(
        res => {
          console.log(res)
        }
      )
      .catch(err => {
        console.log(err.response);
      })

  }
  const handleChangeStatus = (e) => {
    setPaymentMethodList({
      ...paymentMethodList,
      IsActive: Boolean(!paymentMethodList.IsActive)
    })
  }
  const { Code, Name, IsActive } = paymentMethodList
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / payment method / edit payment-method
      </section>
      {/* start of main Edit payment route */}

      <div className='form-container-edit'>
        <form onSubmit={(e) => { submitPaymentEdit(e) }}>
          <div className='form-column container-w'>
            <div className="input">
              <input type="text" name="Code" id="code" value={Code} disabled={true} />
              <label htmlFor="code">Code</label>
            </div>
            <div className="input">
              <input type="text" onChange={(e) => { handleChange(e) }} name="Name" id="name" value={Name} />
              <label htmlFor="name">Name</label>
            </div>
            <div className="status">
              <label className="switch">
                <input
                  type="checkbox"
                  name="IsActive"
                  value={IsActive}
                  checked={IsActive}
                  onChange={() => handleChangeStatus()}
                />
                <span className="slider round"></span>
              </label>
              {" "}
              {IsActive ? "Active" : "Inactive"}
            </div>
            <div className="row-right">
              <button className="btn-submit" type="submit">
                SAVE
              </button>
            </div>
          </div>
        </form>
        <div className='form-column container-100'>
          <div className='container-60'>
            <BankTable {...{ bankList, deleteBank, handleChangeBankStatus, editBank }} />
            <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
              <div className="table-slider-left-button">
                <ChevronLeftIcon />
              </div>
            </div>
            <div
              className="table-slider-right"
              onMouseEnter={tableScrollRight}
              onMouseLeave={stopScrolling}
            >
              <div className="table-slider-right-button">
                <ChevronRightIcon />
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* End of main Edit payment route */}
      <Tooltip title="Add Bank" placement="top-end" arrow>
        <div className='btn-open-modal'>
          <button onClick={(e) => {
            ShowItemFlex("#addBankForm")
            //add bank method form
          }}><PlusOneIcon sx={{ fontSize: "16px" }} /></button>
        </div>
      </Tooltip>
      <AddBankForm {...{ refreshTable, addBank }} />
      <EditBankForm {...{ editBankValue, updateBankInfo }} />
    </main>
  )
}

export default EditPaymentBankList
