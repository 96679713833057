import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { logout, UpdateCustomer } from '../PublicHelper';
const ChangeContactNumber = () => {
  const _ = require('lodash');
  const node = useRef(null);
  const history = useNavigate()
  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#ChangeContactNumberModal").style.display = "none";
    };
    document.querySelector("#ChangeContactNumberModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])
  const hideAddLocation = (e) => {
    e.preventDefault()
    document.querySelector("#ChangeContactNumberModal").style.display = "none";
  }
  const [ContactNumber, setFullName] = useState({
    ContactNum: "",
  })
  const [error, setError] = useState({
    ContactNumE: "",
  })
  const { ContactNum } = ContactNumber

  const submitChange = (e) => {
    e.preventDefault()
    let payload;
    if (_.isEmpty(ContactNumber.ContactNum)) {
      payload = ""
    } else {
      payload = "+63" + ContactNumber.ContactNum
    }
    UpdateCustomer({
      ContactNum: payload
    }).then(
      res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Contact Number successfully changed!',
          showConfirmButton: false,
          timer: 1000,
          footer: "&#169; Pao Kitchen 2023",
        })
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      }
    )
      .catch(err => {
        if (err.response) {
          if (_.isEmpty(err.response.data.data)) {
            const { response } = err
            logout(response, history)
          } else {

            setError({ ...error, ContactNumE: err.response.data.data.ContactNum })
          }
        }

      })
  }
  const handleChange = (e) => {
    const { value, name } = e.target
    const re = /^[0-9\b]+$/;
    if (_.isEmpty(value) || re.test(value)) {
      setFullName({ ...ContactNum, [name]: value })
    }

  }

  return (
    <div className="modal-container" id="ChangeContactNumberModal">
      <form action="" method="POST" onSubmit={(e) => {
        submitChange(e)
      }}>
        <div ref={node} className="modal-container-item">
          <div className='modal-container-item-usertitle' >{t('changephonenumber')}</div>

          <div className="input-update mb">
            <span>+63</span><input type="text"
              maxLength="10" name="ContactNum" id="contactnum" value={ContactNum} onChange={(e) => { handleChange(e) }} />
            <label htmlFor="contactnum">{t('contactnumber')}</label>
            <small>{error.ContactNumE}</small>
          </div>


          <div className='modal-container-item-button'>
            <button type='submit' className='modal-container-item-button-change' onClick={(e) => {
              submitChange(e)
            }}>{t('change')}</button>
          </div>
          <button className='modal-container-item-close' onClick={(e) => { hideAddLocation(e) }}><ChevronLeftIcon /></button>
        </div>

      </form >
    </div >
  )

}

export default ChangeContactNumber;
