import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { setToken } from "../../redux/actions/token";
import BranchSelectPublic from './BranchSelectPublic';
import Cart from './Cart';
import LeftMenu from "./MyAccount/LeftMenu";
import { GetCustomerCart, GetProductDetailCart, GetPublicBranches, UpdateCart } from "./PublicHelper";
import TopBar from "./topbar/TopBar";
const MyAccount = () => {
  useTitle('Pao Kitchen | My account');
  const history = useNavigate();
  const locationIcon = { fontSize: "30px", color: "#50311b" };
  const tokens = useSelector((state) => state.token);
  const { publicBranch, publicToken } = tokens.token;
  const [branches, setBranches] = useState();
  const dispatch = useDispatch();
  const jwt = require("jsonwebtoken");
  let user;
  const [cartList, setCartList] = useState([])
  const [cartCount, setCartCount] = useState(0)
  const [cartId, setCartId] = useState();
  useEffect(() => {
    //setCartCount(cartList.length)
    if (publicToken) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      user = jwt.decode(publicToken)
      console.log(user)
      const { _id } = user
      let cart = []
      GetCustomerCart(_id)
        .then(resp => {
          setCartId(resp.data._id)
          GetProductDetailCart(resp.data._id, publicBranch, _id)
            .then(res => {
              const { data } = res
              console.log(data)
              setCartCount(data.length)
              cart = _.map(data, (value, key) => {
                const { Product, Price } = data[key]
                return {
                  "ProductId": Product._id,
                  "ProductName": Product.Name,
                  "ProdImage": Product.Images.Delivery,
                  "SKU": Product.SKU,
                  "Price": Price,
                  "Quantity": resp.data.CartDetails[key].Quantity,
                  "TotalPrice": Price.Regular * resp.data.CartDetails[key].Quantity,
                  "OrderProdType": "REGULAR",
                  "PreparationTime": Product.PreparationTime
                }
              })
              setCartList(cart)
            })
            .catch(err => {

              console.log(err.response)
            })
        })
        .catch(err => {
          setCartList([])
          setCartCount(0)
        })
    } else {
      if (!_.isEmpty(localStorage.getItem("publicCart"))) {
        let cartArray = JSON.parse(localStorage.getItem("publicCart") || "[]")
        setCartList([...cartArray])
        setCartCount(cartArray.length)
      }

      //setCartList(localStorage.getItem("publicCart"))
    }
    GetPublicBranches()
      .then(res => {
        const { paginatedResults } = res.data;
        setBranches(paginatedResults)
        console.log(paginatedResults)
      })
      .catch(err => console.log(err.response))
    if (publicBranch) {
      document.querySelector(".branch-dialog-selection").style.display = "none";
    } else {
      document.querySelector(".branch-dialog-selection").style.display = "flex";
    }
    return () => {

    }
  }, [publicBranch, publicToken])
  // }, [publicBranch, publicToken])

  const UpdateCartCustomer = (toPass) => {
    if (publicToken) {
      user = jwt.decode(publicToken)
      UpdateCart(cartId, toPass, user._id).then(
        res => {
          setCartList(toPass)
          setCartCount(cartList.length)
        }
      ).catch(
        error => console.log(error.response)
      )
    }

  }
  const branchSelect = (e, id, Name, CityAddress) => {
    e.preventDefault()
    localStorage.setItem("publicBranch", id);
    document.querySelector(".branch-dialog-selection").style.display = "none";
    dispatch(setToken({ publicBranch: id }));
  };
  const checkOutCart = (e, productList, totalAmount) => {

    if (cartCount === 0) {
      Swal.fire({
        icon: 'error',
        title: t('stop'),
        text: t('nocartcontinue'),
        footer: "&#169; Pao Kitchen 2023"
      })
    } else {
      history("/checkout");
    }

  }
  const removeAllItemsOnCart = (e) => {
    e.preventDefault()
    setCartList([])
    setCartCount(0)
    localStorage.removeItem("publicCart")
  }
  const newList = (newListOfitem) => {
    setCartList(newListOfitem)
    setCartCount(cartList.length)
  }
  const gotoHomepage = (e) => {
    e.preventDefault()
    history("/")
  }
  return (
    <div className="body">
      <div className="body-right bg-f7">
        <TopBar searchbar={false} {...{ cartCount }} />
        <div className="homepage-button-myaccount"><button onClick={(e) => {
          gotoHomepage(e)
        }}><ArrowBackIosIcon sx={{ fontSize: "18px" }} /> {t('backhome')}</button>
        </div>
        <LeftMenu />
      </div>

      <BranchSelectPublic
        {...{
          branches,
          branchSelect,
          locationIcon,
        }} />
      <Cart {...{ cartList, publicToken, checkOutCart, UpdateCartCustomer, newList, cartId, removeAllItemsOnCart }} />

    </div>
  );
};

export default MyAccount;
