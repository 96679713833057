import Proptypes from "prop-types";
import css from "./Category.module.scss"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation } from "swiper";

import Product from "../Product";
import {getCurrentLang} from "../../../helper";
import {useState} from "react";
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
const ITEM_PER_COLUMN = 4

const Category = (props) => {
    const {category, onSelectProduct} = props
    const {Attribute, BranchProducts} = category ?? {}
    const {Name, _id} = Attribute ?? {}
    const lang = getCurrentLang()
    const BranchProductActive = BranchProducts.filter(product => product.IsAvailable === true)
    const [gridView, setGridView] = useState(true)

    const displayName = () => {
        let output = Name[lang] ?? Name["EN"]
        if(!output){
            for (const outputElement in Name) {
                if(Name[outputElement]){
                    return Name[outputElement]
                }
            }
        }
        return output
    }

    if(!BranchProductActive) {
        return null
    }

    const listProducts = () => {
        if(!gridView || BranchProductActive.length <= ITEM_PER_COLUMN) {
            return BranchProductActive.map((branchProduct, index) => {
                const Stocks = branchProduct?.Stocks ?? 0
                const IsAvailable = branchProduct?.IsAvailable ?? false
                if(!Stocks || !IsAvailable){
                    return null
                }
                const key = `branch_product_${branchProduct._id}${index}`

                return (<SwiperSlide key={key}>
                    <Product product={branchProduct} sendOption={props.SendOption} onSelect={onSelectProduct} hasOrderSelected={props.hasOrderSelected}/>
                </SwiperSlide>)
            })
        }
        else {
            const output = []
            let temp = []
            for (let index = 0; index < BranchProductActive.length; index++) {
                let branchProduct = BranchProductActive[index];
                const Stocks = branchProduct?.Stocks ?? 0
                const IsAvailable = branchProduct?.IsAvailable ?? false
                if(!Stocks || !IsAvailable){
                    return null
                }

                if(index % ITEM_PER_COLUMN === 0 && index > 0) {
                    const row = (
                        <div className={css.row} key={`row_cate_${branchProduct._id}${index}`}>
                            {temp}
                        </div>
                    )

                    output.push(row)
                    temp = []
                }

                const key = `branch_product_${branchProduct._id}${index}`
                const element = (<div className={css.product} key={`prod_item_${key}`}>
                                    <Product product={branchProduct} key={key} sendOption={props.SendOption} onSelect={onSelectProduct} hasOrderSelected={props.hasOrderSelected}/>
                                </div>)
                temp.push(element)
            }

            return output
        }
    }

    const classBtnListView = gridView ? `${css.btnListView} ${css.disabled}` : css.btnListView
    const classBtnGridView = !gridView ? `${css.btnGridView} ${css.disabled}` : css.btnGridView

    const renderListProducts = () => {
        if(gridView && BranchProductActive.length > ITEM_PER_COLUMN) {
            return listProducts()
        }
        else {
            return (
                <Swiper
                    slidesPerView={4}
                    spaceBetween={6}
                    navigation={true}
                    modules={[Navigation]}
                >
                    {listProducts()}
                </Swiper>
            )
        }
    }

    return (
        <div className={css.categoryContainer} id={`category_${_id}`}>
            <div className={css.infoCate}>
                <p className={css.cateName}>{displayName()}</p>
                <div className={css.btnActions}>
                    <button className={classBtnListView} onClick={() => {
                        setGridView(false)
                    }} disabled={!gridView}>
                        <ListIcon />
                    </button>
                    <button className={classBtnGridView} onClick={() => {
                        setGridView(true)
                    }} disabled={gridView}>
                        <GridViewIcon />
                    </button>
                </div>
            </div>
            {renderListProducts()}
        </div>
    )
}

Category.propTypes = {
    onSelectProduct: Proptypes.func.isRequired,
    category: Proptypes.object.isRequired,
    SendOption: Proptypes.oneOf(["DINEIN", "KTV", "REGULAR"]),
    hasOrderSelected: Proptypes.bool
}

export default Category