import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AccessContainer = ({
  backAccess,
  editAccess,
  isEditAccess,
  handleAllCheckedAccess,
  accessLists,
  addOrRemoveAccess,
  accessUpdate,
}) => (
  <div className="content-main-update-user-form-item-access">
    <button id="backAccessButton" onClick={backAccess}>
      <ArrowBackIosIcon sx={{ color: "#50311B", fontSize: "14px" }} /> Back
    </button>
    <span>
      <b>
        Actions [
        <button
          className="access-edit"
          id="editAccessButton"
          onClick={editAccess}
        >
          edit
        </button>
        ]
      </b>
    </span>
    <div className="content-main-update-user-form-item-access-item">
      {isEditAccess ? (
        <div className="div-edit-access">
          <div className="div-edit-access-select-all">
            <input
              type="checkbox"
              name=""
              id="checkAllAccess"
              value="checkAllAccess"
              onClick={handleAllCheckedAccess}
            />
            <label htmlFor="checkAllAccess">Select All</label>
          </div>
          <div className="div-edit-access-list">
            {Object.keys(accessLists).map((key, index) => {
              const { code, name, isCheck } = accessLists[key];
              return (
                <div className="div-edit-access-list-branches" key={index}>
                  <input
                    type="checkbox"
                    onChange={addOrRemoveAccess(key)}
                    name=""
                    id={code}
                    value={code}
                    checked={isCheck}
                  />
                  <label htmlFor={code}>{name}</label>
                </div>
              );
            })}
          </div>
        </div>
      ) : accessUpdate.length ? (
        Object.keys(accessUpdate).map((key, index) => {
          const { AccessName } = accessUpdate[key];
          return (
            <div key={index} className="div-access">
              {AccessName}
            </div>
          );
        })
      ) : (
        <span>No special access assigned to this user </span>
      )}
    </div>
  </div>
);

export default AccessContainer;
