import { Backdrop, Button, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";

const SelectCouponType = ({
  modalLimitation,
  closeModalLimitation,
  openAutoCoupon,
  openAddModal

}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal
      open={modalLimitation}
      onClose={closeModalLimitation}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998,
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalLimitation}>
        <Box sx={{ ...style, width: 400 }}>
          
            <div className="form-modal-container">
              <h3>Coupon: Choose creation type</h3>
              <hr />
              <Button onClick={(e) => openAutoCoupon(e)} variant="contained">Auto</Button>
              <Button onClick={(e) => openAddModal(e)} variant="contained">Manual</Button>
            </div>
            
        </Box>
      </Fade>
    </Modal>
  );
};
SelectCouponType.defaultProps = {
  modalLimitation: false,
  closeModalLimitation: () => {},
  openAddModal: () => {},
  openAutoCoupon: () => {},
};
export default SelectCouponType;
