import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { ClassSelector } from '../../pages/ProductManager/productHelper';
import { setToken } from "../../redux/actions/token";
import Member from './../../assets/member.svg';
import { SubmitLogin, SubmitLoginFacebook, SubmitLoginGoogle, SubmitRegister } from './PublicHelper';
import SocialLogin from './SocialLogin';
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const LoginSignUp = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [register, setRegister] = useState(
    {
      Email: "",
      ContactNum: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: ""
    }
  )
  const [registerE, setRegisterE] = useState(
    {
      Email: "",
      ContactNum: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: ""
    }
  )
  const [login, setLogin] = useState({
    Username: "",
    Password: ""
  })
  const [loginE, setLoginE] = useState({
    Username: "",
    Password: ""
  })

  const [loginEMess, setLoginEMess] = useState({
    Message: ""
  })

  const reset = () => {
    setRegister({
      Email: "",
      ContactNum: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: ""
    })
    setLogin({
      Username: "",
      Password: ""
    })
  }
  const node = useRef(null);
  useEffect(() => {

    document.querySelector("#register-public").classList.add("active-register")

    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      ClassSelector("login-dialog").style.display = "none";
    };
    ClassSelector("login-dialog").style.display = "none";
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])

  const changeActive = (e, active) => {
    let buttons = document.querySelectorAll(".login-dialog-container-header-buttons-button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("active")
    }
    e.target.classList.add('active')
    let container = document.querySelectorAll(".login-dialog-container-register");
    for (let i = 0; i < container.length; i++) {
      container[i].classList.remove("active-register")
    }
    if (active === 1) {
      document.querySelector("#register-public").classList.add("active-register")

    } else if (active === 2) {
      document.querySelector("#login-public").classList.add("active-register")

    }
  }
  const responseGoogle = (response) => {
    let data = {
      IdToken: response?.tokenId
    }
    SubmitLoginGoogle(data).then(res => {
      localStorage.setItem("publicToken", res?.data);
      dispatch(setToken({ publicToken: res?.data }));
      ClassSelector("login-dialog").style.display = "none";
      reset()
    }).catch(err => console.log(err?.response))
  };
  const responseFacebook = (fbRes) => {
    let data = {
      access_token: fbRes?.accessToken
    }
    SubmitLoginFacebook(data).then(res => {
      localStorage.setItem("publicToken", res?.data);
      dispatch(setToken({ publicToken: res?.data }));
      ClassSelector("login-dialog").style.display = "none";
      reset()
    }).catch(err => console.log(err?.response))
  }
  const submitRegister = (e) => {
    e.preventDefault()
    setRegisterE({})
    SubmitRegister(register).then(res => {
      localStorage.setItem("publicToken", res.data);
      dispatch(setToken({ publicToken: res.data }));
      ClassSelector("login-dialog").style.display = "none";
      reset()
    }).catch(err => {
      const { data } = err?.response?.data
      setRegisterE(data)
    })
  }
  const submitLogin = (e) => {

    e.preventDefault()
    setLoginE({})
    setLoginEMess({
      ...loginEMess,
      Message: ""
    })
    SubmitLogin(login).then(res => {
      localStorage.setItem("publicToken", res.data);
      dispatch(setToken({ publicToken: res.data }));
      ClassSelector("login-dialog").style.display = "none";
      reset()
    }).catch(err => {
      const { data, mess } = err?.response?.data 
      if(!_.isEmpty(data)){
        setLoginE(data)
      }else{
        setLoginEMess({
          ...loginEMess,
          Message: mess
        })
      } 
    })
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setRegister({
      ...register,
      [name]: value
    })
  }
  const handleInputChangeNumber = (e) => {
    const { name, value } = e.target
    setRegister({
      ...register,
      [name]: "+63" + value
    })
  }
  const handleInputLogin = (e) => {
    const { name, value } = e.target
    setLogin({
      ...login,
      [name]: value
    })
  }
  const {
    Email,
    ContactNum,
    Password,
    ConfirmPassword,
    FirstName,
    LastName
  } = registerE

  return (
    <div className="login-dialog">
      <div className="login-dialog-container" ref={node}>
        <div className="login-dialog-container-header">
          <div className="login-dialog-container-header-buttons">
            <button
              onClick={(e) => changeActive(e, 1)}
              className="login-dialog-container-header-buttons-button active">
              {t('newuser')}
            </button>
            <button
              onClick={(e) => changeActive(e, 2)}
              className="login-dialog-container-header-buttons-button">
              <img src={Member} width="16" height="16" alt="icon" /> {t('asmember')}
            </button>
          </div>
        </div>
        <form method="post" onSubmit={(e) => { submitRegister(e) }} id="register-public" className="login-dialog-container-register">
          <div className="login-dialog-container-register-item">
            <h3>{t('welcometoannam')}</h3>
          </div>
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputChange(e) }} type="text" name="FirstName" value={register.FirstName} placeholder={t('firstname')} />
          </div>
          {
            _.isEmpty(FirstName) ? (<></>) : (<small className='error-message'>{FirstName}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputChange(e) }} type="text" name="LastName" value={register.LastName} placeholder={t('lastname')} />

          </div>
          {
            _.isEmpty(LastName) ? (<></>) : (<small className='error-message'>{LastName}</small>)
          }
          <div className="login-dialog-container-register-item">
            <span>+63</span>
            <input onChange={(e) => { handleInputChangeNumber(e) }} type="number" name="ContactNum" placeholder={t('mobilenumber')} />

          </div>
          {
            _.isEmpty(ContactNum) ? (<></>) : (<small className='error-message'>{ContactNum}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputChange(e) }} type="email" name="Email" value={register.Email} placeholder={t('email')} autoComplete='off' />
          </div>
          {
            _.isEmpty(Email) ? (<></>) : (<small className='error-message'>{Email}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputChange(e) }} type="password" name="Password" value={register.Password} placeholder={t('password')} />

          </div>
          {
            _.isEmpty(Password) ? (<></>) : (<small className='error-message'>{Password}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputChange(e) }} type="password" name="ConfirmPassword" value={register.ConfirmPassword} placeholder={t('confirmpassword')} />

          </div>
          {
            _.isEmpty(ConfirmPassword) ? (<></>) : (<small className='error-message'>{ConfirmPassword}</small>)
          }
          <SocialLogin
            {...{
              GOOGLE_CLIENT_ID,
              responseGoogle,
              FACEBOOK_APP_ID,
              responseFacebook,
            }}
          />
          <div className="login-dialog-container-register-item">
            <button type='submit' onClick={(e) => { submitRegister(e) }}>{t('signup')}</button>
          </div>
        </form>
        <div id="login-public" className="login-dialog-container-register">
          <div className="login-dialog-container-register-item">
            <h3>{t('welcomeback')}</h3>
          </div>
          {
            _.isEmpty(loginEMess.Message) ? (<></>) : (<small className='error-message'>{loginEMess.Message}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputLogin(e) }} type="text" name="Username" value={login.Username} placeholder={t('mobilenumber') + ", " + t('email')} />
          </div>
          {
            _.isEmpty(loginE.Username) ? (<></>) : (<small className='error-message'>{loginE.Username}</small>)
          }
          <div className="login-dialog-container-register-item">
            <input onChange={(e) => { handleInputLogin(e) }} type="password" name="Password" value={login.Password} placeholder={t('password')} />
          </div>
          {
            _.isEmpty(loginE.Password) ? (<></>) : (<small className='error-message'>{loginE.Password}</small>)
          }
          <SocialLogin
            {...{
              GOOGLE_CLIENT_ID,
              responseGoogle,
              FACEBOOK_APP_ID,
              responseFacebook,
            }}
          />
          <div className="login-dialog-container-register-item">
            <button onClick={(e) => { submitLogin(e) }}>{t('signup')}</button>
          </div>
          <a className='forgot-password-link' href='/forgot-password' target="_blank"> {t('forgotpassword')}</a>
        </div>
      </div>
    </div>
  );
};

export default LoginSignUp;
