import BarChartIcon from '@mui/icons-material/BarChart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ChairIcon from '@mui/icons-material/Chair';
import LanguageIcon from '@mui/icons-material/Language';
import _ from 'lodash';
import CurrencyFormat from 'react-currency-format';
import ScrollContainer from 'react-indiana-drag-scroll';
import AnalyticsCard from "../AnalyticsCard";

const AnalyticsCardList = ({
  totalOrders,
  totalSales,
  totalSubtotal,
  totalShipping,
  totalDiscount,
    totalOnline,
    totalKTV,
    totalDineIn
}) => {
  return (
    <ScrollContainer className="analytics-card-list scroll-container">
      <div className="flex-row-space-between gap-20 width-100">
        <AnalyticsCard

          icon={[<ShoppingCartIcon />]}
          number={[
            <CurrencyFormat
              value={totalOrders}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
            />
          ]}
          text="Total Orders" />
        <AnalyticsCard
          icon={[<BarChartIcon />]}
          number={[
            <CurrencyFormat
              value={_.floor(totalSales, 2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₱ '}
            />
          ]}
          text="Total Sales" />
        <AnalyticsCard
          icon={[<BarChartIcon />]}
          number={[
            <CurrencyFormat
              value={_.floor(totalSubtotal, 2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₱ '}
            />
          ]}
          text="Subtotal" />
      </div>
      <div className="flex-row-space-between gap-20 width-100">
        <AnalyticsCard icon={[<LocalShippingIcon key="LocalShippingIcon" />]}
          number={[
            <CurrencyFormat
              value={_.floor(totalShipping, 2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₱ '}
            />
          ]} text="Shipping" />
        <AnalyticsCard icon={[<LocalOfferIcon key="LocalOfferIcon" />]} number={[
          <CurrencyFormat
            value={_.floor(totalDiscount, 2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        ]} text="Discounts" />
        <AnalyticsCard showCard={false} />
      </div>
      <div className="flex-row-space-between gap-20 width-100">
          <AnalyticsCard
              icon={[<LanguageIcon key="LanguageIcon" />]}
              number={[
                  <CurrencyFormat
                      value={_.floor(totalOnline, 2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
              ]}
              text="Online" />
          <AnalyticsCard
              icon={[<RestaurantIcon key="RestaurantIcon" />]}
              number={[
                  <CurrencyFormat
                      value={_.floor(totalDineIn, 2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
              ]}
              text="Dine in" />
          <AnalyticsCard
              icon={[<ChairIcon key="ChairIcon" />]}
              number={[
                  <CurrencyFormat
                      value={_.floor(totalKTV, 2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₱ '}
                  />
              ]}
              text="KTV" />
      </div>
    </ScrollContainer>
  )

}

export default AnalyticsCardList;


