import {getShipingFee} from "../EcommerceHelper";
import _ from "lodash";
import {orderDetails} from "./OrderUtils";

export const calcSubTotal = (listProduct = []) => {
    return listProduct.reduce((total, product) => {
        const price = product?.Price ?? 0
        const quantity = product?.Quantity ?? 0
        const TotalPrice = price * quantity

        return total + TotalPrice
    }, 0)
}

export const calcDiscount = (order = orderDetails, subtotal = 0) => {
    let coupon = order?.CouponDetails ?? null
    if (coupon !== null) {
        // const { Fix, Percentage } = coupon.Discount
        // if (coupon.Discount.DiscountType === "By Percentage") {
        //     return _.floor(subtotal * (Number(Percentage) / 100), 2)
        // } else {
        //     return _.floor(subtotal - Number(Fix), 2)
        // }
        return coupon.Payment
    }

    return order?.Discount
}

export const calcTotalAmount = (subtotal = 0, discount = 0, otherFee = 0, shipingFee = 0) => {
    return (subtotal - discount) + otherFee + shipingFee
}

export const computeAddedFee = (addedFee, OrderAmount) => {
    let totalFixed = 0
    let totalPercentage = 0
    for (let i = 0; i < addedFee?.length; i++) {
        const { FeeValue, Type } = addedFee[i]
        if (Type === 'fixed') {
            totalFixed += Number(FeeValue)
        } else {
            totalPercentage += Number(FeeValue)
        }
    }
    let totalAddedFee = Number(Number(totalFixed)) + Number((Number(totalPercentage) / 100) * Number(OrderAmount))
    return {
        totalAddedFee
    }
}

export const calcShippingFee = async (order = orderDetails) => {
    const orderDetails = order?.OrderDetails ?? []
    const itemPrice = calcSubTotal(orderDetails)

    let shippingFee = order?.ShippingAmount ?? 0
    const SendOption =  order?.SendOption ?? ""
    if(SendOption.toUpperCase() === "DELIVERY") {
        const Area = order?.DeliveryDetails?.Area
        const shippingFeeResult = await getShipingFee(order?.BranchId, Area, SendOption, itemPrice)
        const {data} = shippingFeeResult
        shippingFee = data?.ShippingFee ?? ""
        if (isNaN(shippingFee)) {
            return false
        }
    }

    return shippingFee
}

export const getObjectKey = (object, value) => {
    for (const objectKey in object) {
        if(object[objectKey] === value){
            return objectKey
        }
    }

    return null
}