
import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import Location from './../../../assets/myaccount/location.svg';
import OrderHistory from './../../../assets/myaccount/orderhistory.svg';
import Pending from './../../../assets/myaccount/pending.svg';
import Toship from './../../../assets/myaccount/toship.svg';
import User from './../../../assets/myaccount/user.svg';
import Wallet from './../../../assets/myaccount/wallet.svg';
import AddLocation from './AddLocation.js';
import ChangeContactNumber from './ChangeContactNumber.js';
import ChangeFullName from './ChangeFullName.js';
import ChangePassword from './ChangePassword';
function LeftMenu() {
  const node = useRef(null);
  const history = useNavigate();
  // const jwt = require("jsonwebtoken");
  let activeUrl = window.location.pathname
  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#addLocationModal").style.display = "none";
    };
    document.querySelector("#addLocationModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])

  const changeActive = React.useCallback(
    (url) => {
      return activeUrl === url ? 'left-menu-item-buttons-button active' : 'left-menu-item-buttons-button'
    },
    [activeUrl],
  )

  return (

    <div className='container-row-account'>
      <div className='left-menu'>
        <div className='left-menu-item'>
          <div className='left-menu-item-title'>{t('trackorder')}</div>
          <div className='left-menu-item-buttons'>
            <button className={changeActive("/myaccount/pending")} onClick={(e) => {
              e.preventDefault()
              // changeActive(e)
              history("/myaccount/pending");
            }}><img src={Pending} width="16" height="16" alt="icon" />{t('pending')}</button>
            <button className={changeActive("/myaccount/toship")}
              onClick={(e) => {
                e.preventDefault()
                //  changeActive(e)
                history("/myaccount/toship");
              }}
            ><img src={Toship} width="16" height="16" alt="icon" />{t('toship')}</button>
            <button className={changeActive("/myaccount/orderhistory")}
              onClick={(e) => {
                e.preventDefault()
                //changeActive(e)
                history("/myaccount/orderhistory", { replace: true });
              }}
            ><img src={OrderHistory} width="16" height="16" alt="icon" />{t('orderhistory')}</button>
          </div>
        </div>
        <div className='left-menu-item'>
          <div className='left-menu-item-title'>{t('userinformation')}</div>
          <div className='left-menu-item-buttons'>
            <button className={changeActive("/myaccount/shippingaddresses")} onClick={(e) => {
              e.preventDefault()
              //changeActive(e)
              history("/myaccount/shippingaddresses");
            }}
            ><img src={Location} width="16" height="16" alt="icon" />{t('shippingaddress')}</button>
            <button className={changeActive("/myaccount/accountsetting")}
              onClick={(e) => {
                e.preventDefault()
                // changeActive(e)
                history("/myaccount/accountsetting");
              }}
            ><img src={User} width="16" height="16" alt="icon" />{t('useraccount')}</button>
          </div>
        </div>
        <div className='left-menu-item'>
          <div className='left-menu-item-title'>{t('vmartwallet')}</div>
          <div className='left-menu-item-buttons'>
            <button className='left-menu-item-buttons-button'><img src={Wallet} width="16" height="16" alt="icon" />{t('personalwallet')}</button>
          </div>
        </div>
      </div>
      <AddLocation {...{ node }} />

      <ChangeFullName />
      <ChangeContactNumber />
      <ChangePassword />
      <Outlet />
    </div>

  )
}

export default LeftMenu