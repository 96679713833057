import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { GetCustomerDetails, logout } from '../PublicHelper';
import IdentificationPic from './../../../assets/myaccount/id.svg';
import lock from './../../../assets/myaccount/lock.svg';
import telphone from './../../../assets/myaccount/telphone.svg';
import User from './../../../assets/myaccount/user.svg';
function UserAccount() {
  const history = useNavigate()
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { publicToken } = tokens.token;
  let user = jwt.decode(publicToken)

  const [account, setAccount] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    id: ""
  })
  const { firstName, lastName, mobileNumber } = account
  useEffect(() => {
    GetCustomerDetails()
      .then(res => {
        const { FirstName, LastName, _id, ContactNum } = res.data
        setAccount({
          firstName: FirstName,
          lastName: LastName,
          mobileNumber: ContactNum,
          id: _id
        })
      })
      .catch(err => {
        const { response } = err
        logout(response, history)
      })

    return () => {

    }
    //eslint-disable-next-line
  }, [])
  const showChangeFullName = (e) => {
    e.preventDefault()
    document.querySelector("#ChangeFullNameModal").style.display = "flex";
  }
  const showChangeContactNumber = (e) => {
    e.preventDefault()
    document.querySelector("#ChangeContactNumberModal").style.display = "flex";
  }
  const showPassword = (e) => {
    e.preventDefault()
    document.querySelector("#ChangePasswordModal").style.display = "flex";
  }
  return (
    <div className="right-content">
      <div className="right-content-item">
        <div className="right-content-item-title border-bottom">
          <img src={User} width="16" height="16" alt="icon" />
          <span>{t('useraccount')}</span>
        </div>
        <div className="right-content-item-column-item">
          <div className='right-content-item-column-item-left'>
            <span className='right-content-item-column-item-left-row-center'> <img src={IdentificationPic} width="16" height="16" alt="icon" /> {t('userfullname')} : {firstName + " " + lastName}</span>
          </div>
          <button className='right-content-item-column-item-right' onClick={(e) => {
            showChangeFullName(e)
          }}>
            {t('change')}
          </button>
        </div>
        <div className="right-content-item-column-item">
          <div className='right-content-item-column-item-left'>
            <span className='right-content-item-column-item-left-row-center'> <img src={telphone} width="16" height="16" alt="icon" /> {t('phonenumber')} : {mobileNumber}</span>
          </div>
          <button className='right-content-item-column-item-right' onClick={(e) => {
            showChangeContactNumber(e)
          }}>
            {t('change')}
          </button>
        </div>
        <div className="right-content-item-column-item">
          <div className='right-content-item-column-item-left'>
            <span className='right-content-item-column-item-left-row-center'> <img src={lock} width="16" height="16" alt="icon" /> {t('password')} : ********</span>
          </div>
          <button className='right-content-item-column-item-right' onClick={(e) => {
            showPassword(e)
          }} disabled={user.Uid} >
            {t('change')}
          </button>
        </div>
      </div>
    </div >
  )
}

export default UserAccount