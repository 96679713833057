import { SET_TAB } from "../types";
const initialState = {
  tab: {
    currentTab: 0,
  },
};
const tabReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        tab: {
          ...state.tab,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default tabReducers;
