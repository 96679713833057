import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLink = ({ link, path }) => {
  return (
    <HashLink
      smooth
      to={"#" + path}
      scroll={(el) =>
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }
    >
      <span className="Link-Path">{link}</span>
    </HashLink>
  );
};

export default NavLink;
