import { Pagination } from "@mui/material"
import _ from "lodash"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { useTitle } from "react-use"
import Swal from "sweetalert2"
import { countPage } from "../../ProductManager/productHelper"
import AnalyticsFilter from "../AnalyticsFilter"
import { GetEmployeeMealReport } from '../analyticsHelper'
// import ExportButton from "../ExportButton"
// import ExportModalSales from "../Exports/ExportModalSales"
import CardList from './CardList.js'
import Charts from './Charts'
// import AnalyticsPie from "./AnalyticsPie"
import TableProduct from "./TableProduct"
// import SalesModalContent from './SalesModalContent'
// import SalesPerPaymentMethods from "./SalesPerPaymentMethod"
import { defaultEmployeeMeal } from './MealUtils'
const MealReport = () => {
  useTitle("Pao Kitchen | Sales Report")
  const [overView, setOverView] = useState({
    from: "",
    to: ""
  })
  const [salesReport, setSalesReport] = useState(defaultEmployeeMeal)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  // const [salesModal, setSalesModal] = useState(false)
  const [exportDates, setExportDates] = useState({
    DateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    DateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
  })
  const ITEM_PER_PAGE = 10

  useEffect(() => {
    getEmployeeMealReport(page, {})
    return () => {

    }
    //eslint-disable-next-line
  }, [page])

  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getEmployeeMealReport(p, exportDates)
  }

  const getEmployeeMealReport = (page, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setSalesReport(defaultEmployeeMeal)
    setPageCount(0)
    GetEmployeeMealReport(page, {...searchValue, limit: ITEM_PER_PAGE})
      .then(
        res => {
          Swal.close()
          const { paginatedResults, metadata } = res?.data
          const { total, limit } = metadata;
          setPageCount(countPage(total, limit))
          setChartControl(paginatedResults)
          setSalesReport(res?.data);
          console.log("EMPLOYEE SALES REPORT", res?.data);
        }
      )
      .catch(
        err => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No sales report(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
          setSalesReport(defaultEmployeeMeal)
        }
      )
  }

  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { DateFrom, DateTo } = searchValue
    if (_.isEmpty(DateFrom) || _.isEmpty(DateTo)) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please fill up all required inputs",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    } else {
      if (moment(DateFrom).tz("Asia/Manila").isAfter(DateTo)) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Date From field must be earlier than Date to field",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        })
      } else {
        setOverView({
          from: moment(DateFrom || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
          to: moment(DateTo || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
        })
        setExportDates(
          {
            DateFrom: moment(DateFrom || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD'),
            DateTo: moment(DateTo || (new Date())).tz("Asia/Manila").format('YYYY-MM-DD')
          }
        )
        getEmployeeMealReport(1, searchValue)
      }
    }
  }
  const [chartControl, setChartControl] = useState([])

  const clearFilter = (e) => {
    e.preventDefault()
    getEmployeeMealReport(1, {})
    setExportDates({
      DateFrom: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
      DateTo: moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD"),
    })
  }
  //eslint-disable-next-line
  const { Card, paginatedResults, EmployeeLists } = salesReport
  const sortEmployeebyTotal = _.orderBy(EmployeeLists, ['Total'],['desc']);
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        analytics / employees meal report
      </section>
      <section className="content-product-subtitle ">
        OVERVIEW : {_.isEmpty(overView.from) || _.isEmpty(overView.to) ? moment().tz("Asia/Manila").format("MMM DD, YYYY (dddd)") : `${overView.from} ~ ${overView.to}`}
      </section>
      <AnalyticsFilter {...{ productFilter, clearFilter }} />
      <div className="flex-column-start gap-30 width-100">
        <CardList {...Card} />
        {
          _.isEmpty(paginatedResults) ? <></> :
            <>
              <Charts chartData={chartControl} /> 
            </>
        }
        {
          _.isEmpty(EmployeeLists) ? <></> :
            <> 
              <TableProduct tableContents={sortEmployeebyTotal} />
              {
                pageCount === 0 ? <></> :
                  <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
              }

            </>
        } 
      </div>



    </main>
  )
}
export default MealReport