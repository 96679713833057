import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from 'react';
import { IdSelector } from './productHelper';
const Slider = ({ tableId }) => {
  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector(tableId).style.scrollBehavior = "smooth";
        IdSelector(tableId).scrollLeft -= 75;
      }, 100);
    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector(tableId).style.scrollBehavior = "smooth";
        IdSelector(tableId).scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  return (
    <>
      <div
        className="table-slider-left"
        onMouseEnter={tableScrollLeft}
        onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}>
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>
    </>
  );
};

export default Slider;

