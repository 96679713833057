import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { HideItem } from '../../ProductManager/productHelper';
import { AddRate } from "../CurrencyHelper";

const RateAdd = ({ node1, getRateList }) => {
    const [rate, setRate] = useState({
        _id: "",
        rate: "",
        status: Boolean(true)
    })

    const submitRate = (e) => {
        e.preventDefault()
        Swal.fire({
            title: "In progress!",
            text: "Please wait while fetching data",
            allowOutsideClick: false,
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.showLoading();
            }
        });
        if(rate.rate){
            let rateBody = {
                rate: parseFloat(rate.rate)
            }
    
            AddRate(rateBody)
            .then(res => {
                getRateList(1, {})
                Swal.close()
                HideItem("#addRate")
            })
            .catch(err => {
                console.log(err)
              Swal.fire({
                icon: "error",
                title: "Failed",
                text: "There was a problem occurred while doing your request! 😔",
                footer: "&#169; Pao Kitchen 2023",
                didOpen: () => {
                  Swal.hideLoading();
                }
              })
            })
        }else{ 
            Swal.fire({
                title: "Error",
                text: "Rate fields are empty",
                icon: "error",
                confirmButtonText: "Okay",
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        } 
    }

    return (
        <div className="add-shipping-area" id="addRate">
            <div ref={node1} className="add-shipping-area-container">
                <div className="add-shipping-area-container-header">
                    <h3 className="add-shipping-area-container-header-title">
                        Create New Rate
                    </h3>
                    <button className="close-shipping-modal" 
                        onClick={(e) => {
                            e.preventDefault()
                            HideItem("#addRate")
                        }}
                    >
                        <ClearIcon />
                    </button>
                </div>
                {/* -- Forms */}
                <form onSubmit={(e) => { submitRate(e) }}>
                    <div className="form">
                        <div className="form-container column">
                            <label htmlFor="rate">Rate</label>
                            <input type="number" 
                                onChange={(e) => //onchangeText(e)
                                    setRate({
                                        ...rate,
                                        rate: e.target.value
                                    })              
                                } 
                                value={rate.rate} name="rate" id="rate" />
                        </div>

                        <div className="form-container row-right">
                            <button className="btn-submit" type="submit" >
                                CREATE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RateAdd;