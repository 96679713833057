import CloseIcon from "@mui/icons-material/Close";
import Compressor from "compressorjs";
import _ from "lodash";
import React, { useState } from "react";
import { GetBase64, HideItem } from "../productHelper";

const AddGroupCategoryForm = ({ submitGroupCategoryForm }) => {
  const closeAddGroupCategoryForm = () => {
    HideItem("#groupAddForm")
  }
  const [groupCategory, setGroupCategory] = useState({
    categoryicon: Blob,
    categoryname: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    categorydescription: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    isActive: true
  });

  const [language, setLanguage] = useState({ Language: "VN", LangDesc: "Vietnam" });
  const { Language, LangDesc } = language;
  const { categoryname, categorydescription, isActive } = groupCategory;

  const changeLang = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    value === "EN" ? (setLanguage({ ...language, [name]: value, LangDesc: "English" }))
      : value === "CN" ? (setLanguage({ ...language, [name]: value, LangDesc: "Chinese" }))
        : value === "TH" ? (setLanguage({ ...language, [name]: value, LangDesc: "Thailand" }))
          : (setLanguage({ ...language, [name]: value, LangDesc: "Vietnam" }))

    let buttons = document.querySelectorAll(".langGC");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("lang-active")
    }
    e.target.classList.add('lang-active')
  }
  const changeCategoryName = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, categoryname: { ...categoryname, [name]: value } })
  }
  const changeCategoryDescription = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, categorydescription: { ...categorydescription, [name]: value } })
  }
  const changeIcon = (params) => {
    let file = document.querySelector('#icon').files[0];
    if (!_.isEmpty(file)) {
      new Compressor(file[0], {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server. 
          GetBase64(file).then(
            (data) => {
              let imageArray = data.split(",");
              setGroupCategory({ ...groupCategory, categoryicon: imageArray[1] })
            }
          )
        },
      });
    }
  }
  const changeStatus = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, [name]: value })
  }

  return (
    <div className="modal-add-group" id="groupAddForm" onClick={(e) => {
      HideItem("#groupAddForm")
    }}>
      <div className="modal-add-group-form" onClick={(e) => {
        e.stopPropagation()
      }}>
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            add new group category
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={() => closeAddGroupCategoryForm()}
            />
          </div>
        </div>
        <form className="modal-add-group-form-main">

          <div className="modal-add-group-form-main-language">

            <div className="language-changer">
              <label htmlFor="language">FIELDS FOR</label>
              <button onClick={(e) => changeLang(e)} className="langGC lang-active" name="Language" value="VN">VN</button>
              <button onClick={(e) => changeLang(e)} className="langGC " name="Language" value="EN">EN</button>
              <button onClick={(e) => changeLang(e)} className="langGC " name="Language" value="CN">CN</button>
              <button onClick={(e) => changeLang(e)} className="langGC " name="Language" value="TH">TH</button>
            </div>
          </div>
          <div className="modal-add-group-form-main-icon">
            <label htmlFor="icon">Group Category Icon </label>
            <input type="file"
              id="icon" name=""
              accept="image/png, image/jpeg, image/gif"
              onChange={changeIcon} />
          </div>
          <div className="modal-add-group-form-main-name">
            <label htmlFor="categoryname">Group Category Name ( {LangDesc} )</label>
            <input type="text" id="categoryname" name={Language}
              value={
                Language === "EN" ? categoryname.EN
                  : Language === "CN" ? categoryname.CN
                    : Language === "TH" ? categoryname.TH
                      : categoryname.VN
              }
              onChange={changeCategoryName}
            />
          </div>
          <div className="modal-add-group-form-main-description">
            <label htmlFor="categorydescription">Description ( {LangDesc} )</label>
            <textarea type="text" id="categorydescription" name={Language}
              value={
                Language === "EN" ? categorydescription.EN
                  : Language === "CN" ? categorydescription.CN
                    : Language === "TH" ? categorydescription.TH
                      : categorydescription.VN
              }
              onChange={changeCategoryDescription}
            />
          </div>
          <div className="modal-add-group-form-main-status">
            <label htmlFor="categorystatus">status</label>
            <select name="isActive" id="categorystatus" value={isActive} onChange={changeStatus}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </form>

        <div className="content-main-add-user-footer">
          <button
            className="content-main-add-user-footer-cancel"
            onClick={() => closeAddGroupCategoryForm()}
          >
            cancel
          </button>
          <button
            className="content-main-add-user-footer-create"
            onClick={(e) => submitGroupCategoryForm(groupCategory, e)}
          >
            create
          </button>
        </div>
      </div>
    </div>
  )
};

export default AddGroupCategoryForm;
