const {get} = require("./axiosHelper")

export const getListEventPusher =  async () => {
    const url = "/cms/pusher/list"
    try {
        const res = await get(url)
        return res?.data?.data ?? []
    }
    catch (e) {
        return []
    }
}

export const getListEventCMSPusher = async () => {
    const url = "/cms/pusher/list-cms"
    try {
        const res = await get(url)
        return res?.data?.data ?? []
    }
    catch (e) {
        return []
    }
}


export const getListTask = async () => {
    const url = "/cms/pusher/tasks-pending"
    try {
        const res = await get(url)
        return res?.data?.data ?? []
    }
    catch (e) {
        return []
    }
}

export const getSettingPusher = async () => {
    try {
        const res = await get("/cms/pusher/setting-pusher", {"Content-Type": "application/json"})
        return res?.data?.data ?? {}
    }
    catch (e) {
        console.error(e)
    }
}

export const getListEventPusher_Public =  async () => {
    const url = "/web/pusher/list"
    try {
        const res = await get(url)
        return res?.data?.data ?? []
    }
    catch (e) {
        return []
    }
}