import { useTitle } from "react-use";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  HideItem,
  ShowItemFlex,
  countPage,
} from "../../ProductManager/productHelper";
import {
  GetContactList,
  DeleteContact,
  UpdateContact,
  SubmitCreateContact,
} from "../../../components/public/PublicHelper";
import ContactTable from "./ContactTable";
import AddContactButton from "./AddContactButton";
import AddContactForm from "./AddContactForm";
import EditContactForm from "./EditContactForm";

const Contact = () => {
  useTitle("Pao Kitchen | Contact Info");

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [contactList, setContactList] = useState([]);
  const [contactValue, setContactValue] = useState({
    id: "",
    link: "",
    type: "Messenger",
    isActive: true
  })

  useEffect(() => {
    getContactList(page, "");

    return () => {};
  }, [page]);

  const getContactList = (page, search) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setContactList([]);
    setPageCount(0);

    GetContactList(page, search).then((res) => {
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;

      setPageCount(countPage(total, limit));
      let toPush = []

      Object.keys(paginatedResults).forEach(key => {
        const { _id, Link, Type, IsActive } = paginatedResults[key];
        toPush.push({
          id: _id,
          link: Link,
          isActive: IsActive,
          type: Type
        })
      })

      setContactList(toPush)
      Swal.close()

    }).catch((error) => {
        setContactList([])
        setPageCount(0)
        setPage(1)
        Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No link(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
    });
  };

  const showAddContactForm = () => {
    ShowItemFlex("#contactAddForm")
  };

  const showEditContactForm = (e, contactData) => {
    e.preventDefault()
    console.log("edit contact: ", contactData)
    const { id, link, type, isActive } = contactData

    setContactValue({
      id: id,
      Link: link,
      Type: type,
      IsActive: isActive,
    })

    ShowItemFlex("#contactEditForm")
  };

  const submitContactForm = async (contactData, e) => {
    console.log(contactData)
    e.preventDefault();

    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });

    SubmitCreateContact(contactData).then((res) => {
      getContactList(1, "")
      HideItem("#contactAddForm")
      Swal.close()

      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Contact info was added`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    })

  }

  const updateContactForm = async (contactData, e) => {
    console.log(contactData)
    e.preventDefault();

    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const { id } = contactData

    UpdateContact(id, contactData).then((res) => {
      getContactList(1, "")
      HideItem("#contactEditForm")
      Swal.close()

      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Contact info was updated`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    })

  }

  const deleteContact = (id, e) => {
    e.preventDefault()
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      footer: "&#169; Pao Kitchen 2023",
      
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteContact(id).then(res => {
          getContactList(1, "");
          Swal.fire({
            title: "Deleted",
            text: "Contact info has been deleted!",
            icon: "success"
          })
        })
      }
    })
  }

  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        public management / contact info list
      </section>
      <ContactTable {...{ contactList, showEditContactForm, deleteContact }} />
      <AddContactForm {...{ submitContactForm }} />
      <AddContactButton addContact={showAddContactForm} tooltip={"Add link"} />
      <EditContactForm {...{ contactValue, updateContactForm }} />
    </main>
  );
};

export default Contact;
