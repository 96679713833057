import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Alert, IconButton, Snackbar } from '@mui/material';
import i18next from 'i18next';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { HideItem, ShowItemFlex } from '../../../pages/ProductManager/productHelper';
import Logo from "../../reusable/Logo";
import EditLocation from '../MyAccount/EditLocation';
import { AddToCartSubmit, CheckCartAvailability, GetCustomerCart, GetProductDetailCart, GetLalamoveShippingAddressFee, logout, SubmitVoucher, UpdateCart, SubmitCheckoutRegister, AddShippingAreaWithoutToken, GetCustomerDetails } from '../PublicHelper';
import CheckoutModal from './CheckoutModal';
import { customerInformationValue, customerInfovalue, mapCartLoggedIn, mapCartNotLoggedIn, mergeProductStock } from './checkOutUtilities';
import LoggedIn from './LoggedIn';
import NotLoggedIn from './NotLoggedIn';
import PlaceOrderModal from './PlaceOrderModal';
import moment from "moment-timezone";
// import moment from 'moment-timezone';
const CheckOut = () => {
  const { t } = useTranslation()
  const node = useRef();
  const jwt = require("jsonwebtoken");
  let history = useNavigate()
  const tokens = useSelector((state) => state.token);
  const { publicToken, publicBranch } = tokens.token;
  useTitle('Pao Kitchen | Checkout');
  const [specificShippingArea, setSpecificShippingArea] = useState({
    _id: "",
    FullName: "",
    Email: "",
    MobileNumber: "",
    Area: "",
    Address: ""
  })
  const [checkoutCart, setCheckoutCart] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)
  const [shippingArea, setShippingArea] = useState({
    Area: "",
    AreaNote: { EN: '', VN: '', TH: '', CN: '' },
    BranchId: "",
    CreatedAt: "",
    IsActive: true,
    MinOrderAmount: 0,
    ShippingFee: 0,
    TotalOrderAmount: 0,
    LalamoveDetails: {
      QuotationId: "",
      Stops: []
    }
  })
  const [bankList, setBankList] = useState([])
  const [loginError, setLoginError] = useState({
    ConfirmPassword: "",
    FirstName: "",
    LastName: "",
    Password: "",
    Email: "",
    ContactNum: "",
    Address: ""
  })


  const [checkOutError, setCheckOutError] = useState({
    MobileNum: "",
    PreferredDeliveryDate: "",
    PaymentMode: "",
    PaymentId: "",
    ShippingFee: "",
    FullName: "",
    Email: ""
  })
  const [cartId, setCartId] = useState("")
  const { ShippingFee } = shippingArea
  const [customerInfo, setCustomerInfo] = useState(customerInfovalue(checkoutCart))
  const [customerInformation, setCustomerInformation] = useState(customerInformationValue)
  const [maxPreparationTime, setMaxPreparationTime] = useState("")
  const [isShippingEmpty, setIsShippingEmpty] = useState(false) 
  const [isMealAllowance, setIsMealAllowance] = useState(false)
  const [customer, setCustomer] = useState({
    IsEmployee: false
  })
  

  useEffect(() => {
    HideItem("#placeOrderModal")
    _.isEmpty(publicToken) ? userNotLoggedIn(customerInfo.SendOption) : userLoggedIn(customerInformation.SendOption)
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#editLocationModal").style.display = "none";
    };
    document.querySelector("#editLocationModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    //eslint-disable-next-line
  }, [publicToken, history, ShippingFee, publicBranch])
  

  const showEditLocation = (e) => {
    e.preventDefault()
    document.querySelector("#editLocationModal").style.display = "flex";
  }

  

  const checkCartAvailability = (cart, user, userSendOption) => {  
    CheckCartAvailability(cart)
      .then(
        res => {
          setCheckoutCart(mergeProductStock([...cart, ...res.data]))
          // setCustomerInformation({
          //   ...customerInformation,
          //   SendOption: userSendOption,
          //   ShippingAddress: user.ShippingAddress,
          //   OrderDetails: mergeProductStock([...cart, ...res.data]),
            
          // })
          const data = res.data
          getCustomerDetails(userSendOption, user, cart, data)
          _.isEmpty(user.ShippingAddress) ? setIsShippingEmpty(true) : setIsShippingEmpty(false)
          
        }
      ).catch(
        err => {
          const { response } = err
          logout(response, history);
        }
      )
  }

  const getCustomerDetails = (userSendOption, user, cart, data) => {
    GetCustomerDetails()
    .then((res) => {
      const { Email, ContactNum, FirstName, LastName, IsEmployee } = res.data;
      const DFullName = FirstName + " " + LastName;
      const SplitedContact = ContactNum.split("+63");
      const DefaultContact = SplitedContact[1];

      // console.log("Customer Details: ", res.data)
      setCustomer({IsEmployee: IsEmployee} );
       setCustomerInformation({
            ...customerInformation,
            SendOption: userSendOption,
            ShippingAddress: user.ShippingAddress,
            OrderDetails: mergeProductStock([...cart, ...data]),
            ContactDetails: {
              Email: Email,
              ContactNum: DefaultContact,
              FullName: DFullName
            },
        }) 
    })
    .catch((err) => {
      console.log(err.response);
      const { response } = err
      if(response?.data?.mess == "Session expired!"){
        logout(response, history);
      } 
    });
  }
  
  const loggedInProcess = (cart, user, userSendOption) => {
    checkCartAvailability(cart, user, userSendOption)
    let total = 0
    setMaxPreparationTime((_.orderBy(_.map(cart, (value, index) => {
      return {
        ...value,
        PreparationTime: Number(value.PreparationTime)
      }
    }), ['PreparationTime'], ['desc']))[0].PreparationTime);

    _.forEach(cart, (value, key) => {
      const { TotalPrice } = value
      total += TotalPrice
    })
    setTotalAmount(total)
    setFinalAmount(total + ShippingFee)
  }

  const userLoggedIn = (userSendOption) => {
    let user = jwt.decode(publicToken)
    let publicStorage = JSON.parse(localStorage.getItem("publicCart") || "[]")
    if (!_.isEmpty(publicStorage)) { 
      Swal.fire({
        title: 'Found items on guess cart, want do you want to do with these?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Merge item',
        denyButtonText: `Replace Item`,
        cancelButtonText: `Disregard guess cart`
      }).then((result) => { 
        GetCustomerCart(user._id)
          .then(resp => {
            setCartId(resp.data._id)
            //console.log(resp.data.CartDetails.length); //
            if (_.isEmpty(resp.data.CartDetails)) {
              history("/")
            } else {
              let cart = []
              GetProductDetailCart(resp.data._id, publicBranch, user._id)
                .then(res => {
                  const { data } = res
                  //data is about the product details

                  if (result.isConfirmed) {
                    //merge items
                    let cartToMerge = mapCartLoggedIn(data, userSendOption, resp)
                    UpdateCart(resp.data._id, mergeProductStock([...cartToMerge, ...publicStorage]), user._id).then(
                      res => {
                        cart = mergeProductStock([...cartToMerge, ...publicStorage])
                        localStorage.removeItem("publicCart");
                        Swal.fire('Items merged', '', 'success')
                        loggedInProcess(cart, user, userSendOption)
                      }
                    ).catch(
                      error => {
                        Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                      }
                    )
                  } else if (result.isDenied) {
                    //replace with local
                    UpdateCart(resp.data._id, publicStorage, user._id).then(
                      res => {
                        cart = mapCartNotLoggedIn(publicStorage, userSendOption)
                        localStorage.removeItem("publicCart");
                        loggedInProcess(cart, user, userSendOption)
                        Swal.fire('Items replaced by guess cart', '', 'success')
                      }
                    ).catch(
                      error => Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                    )
                  } else if (result.isDismissed) {
                    //local will be deleted
                    cart = mapCartLoggedIn(data, userSendOption, resp)
                    localStorage.removeItem("publicCart");
                    loggedInProcess(cart, user, userSendOption)
                    Swal.fire('Cart items remained', '', 'success')
                  }

                })
                .catch(err => {
                  const { response } = err
                  logout(response, history);
                })
            }
          })
          .catch(err => {
            let cart = []
            if (err?.response?.status === 404) {
              if (result.isConfirmed) {
                //merge items
                AddToCartSubmit(user._id, publicStorage)
                  .then(res => {
                    cart = mapCartNotLoggedIn(publicStorage, userSendOption)
                    localStorage.removeItem("publicCart");
                    checkCartAvailability(cart, user, userSendOption)
                    let total = 0
                    setMaxPreparationTime((_.orderBy(_.map(cart, (value, index) => {
                      return {
                        ...value,
                        PreparationTime: Number(value.PreparationTime)
                      }
                    }), ['PreparationTime'], ['desc']))[0].PreparationTime);

                    _.forEach(cart, (value, key) => {
                      const { TotalPrice } = value
                      total += TotalPrice
                    })
                    setTotalAmount(total)
                    setFinalAmount(total + ShippingFee)
                    Swal.fire('Items merged', '', 'success')
                  })
                  .catch(error => {
                    Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                  })

              } else if (result.isDenied) {
                //replace with local
                AddToCartSubmit(user._id, publicStorage)
                  .then(res => {
                    cart = mapCartNotLoggedIn(publicStorage, userSendOption)
                    localStorage.removeItem("publicCart");
                    checkCartAvailability(cart, user, userSendOption)
                    let total = 0
                    setMaxPreparationTime((_.orderBy(_.map(cart, (value, index) => {
                      return {
                        ...value,
                        PreparationTime: Number(value.PreparationTime)
                      }
                    }), ['PreparationTime'], ['desc']))[0].PreparationTime);

                    _.forEach(cart, (value, key) => {
                      const { TotalPrice } = value
                      total += TotalPrice
                    })
                    setTotalAmount(total)
                    setFinalAmount(total + ShippingFee)
                    Swal.fire('Items merged', '', 'success')
                  })
                  .catch(error => {
                    Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                  })
              } else if (result.isDismissed) {
                //local will be deleted
                Swal.fire('Cart items remained', '', 'success')
                setCheckoutCart([])
                history("/")
              }
            }


          })
      })


    } else { 
      GetCustomerCart(user._id)
        .then(resp => {
          setCartId(resp.data._id)
          //console.log(resp.data.CartDetails.length); //
          if (_.isEmpty(resp.data.CartDetails)) {
            history("/")
          } else {
            GetProductDetailCart(resp.data._id, publicBranch, user._id)
              .then(res => {
                const { data } = res
                //data is about the product details
                let cart = mapCartLoggedIn(data, userSendOption, resp)
                checkCartAvailability(cart, user, userSendOption)
                let total = 0
                setMaxPreparationTime((_.orderBy(_.map(cart, (value, index) => {
                  return {
                    ...value,
                    PreparationTime: Number(value.PreparationTime)
                  }
                }), ['PreparationTime'], ['desc']))[0].PreparationTime);

                _.forEach(cart, (value, key) => {
                  const { TotalPrice } = value
                  total += TotalPrice
                })
                setTotalAmount(total)
                setFinalAmount(total + ShippingFee)
              })
              .catch(err => {
                const { response } = err
                logout(response, history);
              })
          }
        })
        .catch(err => {
          setCheckoutCart([])
          history("/")
        })
    }
  }
 
  const userNotLoggedIn = (userSendOption) => {
    let cart = JSON.parse(localStorage.getItem("publicCart") || "[]")
    if (cart.length === 0) {
      history("/")
    } else {
      CheckCartAvailability(cart)
        .then(
          res => {
            cart = mapCartNotLoggedIn(cart, userSendOption)
            CheckCartAvailability(cart).then(
              res => {
                setCheckoutCart(mergeProductStock([...cart, ...res.data]))
                setCustomerInformation({
                  ...customerInformation,
                  OrderDetails: mergeProductStock([...cart, ...res.data])
                })
              }
            ).catch(
              err => console.log(err.response)
            )
            let total = 0
            setMaxPreparationTime((_.orderBy(_.map(cart, (value, index) => {
              return {
                ...value,
                PreparationTime: Number(value.PreparationTime)
              }
            }), ['PreparationTime'], ['desc']))[0].PreparationTime);

            _.forEach(cart, (value, key) => {
              const { TotalPrice } = value
              total += TotalPrice
            })
            
            setTotalAmount(total)
            setFinalAmount(total + ShippingFee)
          }
        ).catch(err => {
          console.log(err.response)
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: err + '. Please try again later!',
            confirmButtonText: 'Okay',
          }).then(res => {
            if (res?.isConfirmed) {
              history('/')
            }
          })
        })

    }
  }



  const changeActive = (e, active) => {
    let buttons = document.querySelectorAll(".login-dialog-container-header-buttons-button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("active")
    }
    e.target.classList.add('active')
    let container = document.querySelectorAll(".login-dialog-container-register");
    for (let i = 0; i < container.length; i++) {
      container[i].classList.remove("active-register")
    }
    if (active === 1) {
      document.querySelector("#register-public").classList.add("active-register")

    } else if (active === 2) {
      document.querySelector("#login-public").classList.add("active-register")

    }
  }

  const gotoHomepage = (e) => {
    e.preventDefault()
    history("/")
  }
  const [enableButton, setEnableButton] = useState(true)
  const getCustomerInformation = (informationInside) => {
    // console.log("informationInside: ",informationInside)
    setCustomerInfo(informationInside)
    if (informationInside.SendOption !== "DELIVERY") {
      setEnableButton(false)
    } else {
      if (_.isEmpty(informationInside.DeliveryDetails.Area)) {
        setEnableButton(true)
      }
    }
  }
  const [activeStep, setActiveStep] = useState(0)
  const [token, setToken] = useState("")
  const createAccountAndPlaceorder = (e) => {
    console.log(customerInfo);

    setActiveStep(2)
    ShowItemFlex("#placeOrderModal")
    placeOrderToChild.current()
    if (activeStep === 0) {
      let payload;
      if (_.isEmpty(customerInfo.ContactDetails.ContactNum)) {
        payload = ""
      } else {
        payload = "+63" + customerInfo.ContactDetails.ContactNum
      } 
      const myPass = payload.split("+63");
      const newPass = "Init@" + myPass[1]; //Init@9161234567
      console.log("ContactDetails: ", {
        ...customerInfo,
        ContactDetails: {
          ...customerInfo.ContactDetails,
          ContactNum: payload
        }
      });
      console.log("language: ", localStorage.getItem("language"))
      let Language = ""
      if(localStorage.getItem("language") === "VN"){
        Language = "VI";
      }else{
        Language = localStorage.getItem("language")
      }

      const { ContactDetails } = customerInfo
      setLoginError({
        ConfirmPassword: "",
        FirstName: "",
        LastName: "",
        Password: "",
        Email: "",
        ContactNum: "",
        Address: ""
      })
      SubmitCheckoutRegister({
        ...ContactDetails,
        ContactNum: payload,
        Password: newPass,
        ConfirmPassword: newPass,
        Language: Language
      })
        .then(
          res => {
            // console.log(res.data);
            setToken(res.data);
            let tokenInside = res.data
            // let tokenInside = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVaWQiOm51bGwsIkNvbnRhY3ROdW0iOiIrNjM5NjE0Nzc2MjExIiwiSXNBY3RpdmUiOnRydWUsIkNyZWF0ZWRBdCI6IjIwMjItMDItMTFUMDI6MzA6MTIuMDAwWiIsIlVwZGF0ZWRBdCI6IjIwMjItMDQtMThUMDY6MzE6NDEuMDAwWiIsIl9pZCI6IjYyMDVjZDA2ZDlmYTg3MjA2NDA3NzU3YiIsIkVtYWlsIjoiYXp1Y2VuYXZhZHJpYW4yMkBnb3RhYnJhbmQuY29tIiwiRmlyc3ROYW1lIjoiQWFyb24iLCJMYXN0TmFtZSI6IkJvcnJlIiwiU2hpcHBpbmdBZGRyZXNzIjpbeyJFbWFpbCI6InJvc2VhY2lsbGEwMjNAZ21haWwuY29tIiwiUGxhY2VJZCI6bnVsbCwiX2lkIjoiNjI1ZDA1Y2Q3YzkxOTgwYzA0ZGFhZDcwIiwiTW9iaWxlTnVtYmVyIjoiKzYzOTYxNDc3NjIxNSIsIkFyZWEiOiJtYW5pbGEiLCJBZGRyZXNzIjoiYjVsNSBkb3ZlIHN0LiwgIHZpbGxhIGJlbmVsZGEiLCJGdWxsTmFtZSI6ImFkaXphcyJ9XSwiX192IjowLCJpYXQiOjE2NTA5Mzc5MTUsImV4cCI6MTY1MDk4MTExNX0.DSia9GC9kvwl0nhWE-_nfHEXraNzhW5AyyZVQHHZn2k"
            const { ContactDetails, DeliveryDetails } = customerInfo
            const { Email, FirstName, LastName } = ContactDetails
            const { Area, Address, PlaceId } = DeliveryDetails
            console.log(Area);
            handleOpenLoginSuccess()
            if (customerInfo.SendOption === "DELIVERY") {
              AddShippingAreaWithoutToken(
                {
                  FullName: FirstName + " " + LastName,
                  Email: Email,
                  MobileNumber: payload,
                  Area: Area,
                  Address: Address,
                  PlaceId: PlaceId
                }
                , tokenInside)
                .then(
                  result => {
                    //success 
                    // addToCartCheckOut(jwt.decode(tokenInside)._id, checkoutCart)
                  }
                ).catch(
                  error => {
                    console.log(error.response);
                    HideItem("#placeOrderModal")
                    if (error.response.status === 401) {
                      const { mess } = error.response.data
                      Swal.fire({
                        icon: 'error',
                        title: "Failed!",
                        text: mess + " Your account has been saved, please refresh the page and sign in with your contact number/email and password",
                        footer: "&#169; Pao Kitchen 2023"
                      })
                    } else {
                      const { data } = error.response.data
                      console.log(data)

                      setLoginError({
                        ...loginError,
                        Address: data.Address
                      })
                    }

                  }
                )
            } else {
              // addToCartCheckOut(jwt.decode(tokenInside)._id, checkoutCart)
            }
          }
        )
        .catch(
          err => {
            console.log(err.response)
            if (!_.isEmpty(err.response)) {
              const { data } = err.response.data
              HideItem("#placeOrderModal")
              setLoginError({
                ConfirmPassword: data.ConfirmPassword,
                FirstName: data.FirstName,
                LastName: data.LastName,
                Password: data.Password,
                Email: data.Email,
                ContactNum: data.ContactNum
              })
            } else {
              const { mess } = err.response.data
              Swal.fire({
                icon: 'error',
                title: "Failed!",
                text: mess,
                footer: "&#169; Pao Kitchen 2023"
              })
            }
          }
        )
    } else {
      ShowItemFlex("#placeOrderModal")
    }
  }
  // window.ononline = (event) => {
  //   console.log("Back Online")
  // };

  // window.onoffline = (event) => {
  //   console.log("Connection Lost")
  // };
  const addToCartCheckOut = (x, y) => {
    AddToCartSubmit(x, y)
      .then(
        () => {
          setActiveStep(1);
        }
      ).catch(
        cartError => {
          console.log(cartError.response);
          HideItem("#placeOrderModal")
          if (cartError.response.status === 401) {
            const { mess } = cartError.response.data
            Swal.fire({
              icon: 'error',
              title: "Failed!",
              text: mess + " Your account has been saved, please refresh the page and sign in with your contact number/email and password",
              footer: "&#169; Pao Kitchen 2023"
            })
          } else {
            const { mess, data } = cartError.response.data
            if (!_.isEmpty(data)) {
              Swal.fire({
                icon: 'error',
                title: "Failed!",
                text: data["CartDetails[1]"],
                footer: "&#169; Pao Kitchen 2023"
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: "Failed!",
                text: mess,
                footer: "&#169; Pao Kitchen 2023"
              })
            }

          }
        }
      )
  }

  const [isVoucherApplied, setIsVoucherApplied] = useState({
    isApplied: false,
    payment: 0
  })
  const placeOrderToChild = React.useRef(null)

  const placeorder = (e) => {

    placeOrderToChild.current()
    // console.log(customerInformation);

    setActiveStep(2)
    ShowItemFlex("#placeOrderModal")
  }
  const getAreaShippingFee = (e, shippingAreaFromComponent, customerDetails, preferredDeliveryDate) => {
    e.preventDefault()
      console.log("shippingAreaFromComponent: ", shippingAreaFromComponent)
      console.log("customerDetails: ", customerDetails)
      console.log("customerInfo: ", customerInfo)
      console.log("customerInformation:", customerInformation)
      console.log("preferredDeliveryDate >>>", preferredDeliveryDate)
      
    if (_.isEmpty(shippingAreaFromComponent)) {
      setEnableButton(true)
    } else {
      setCheckOutError(oldvalue => {
        return {
          ...oldvalue,
          ShippingFee: ""
        }
      })
      // console.log("shippingAreaFromComponent: ", shippingAreaFromComponent)
      // console.log("SendOption: ", customerInfo.SendOption)
      // console.log("totalAmount: ", totalAmount)
      // console.log("customerDetails: ", customerInfo.ContactDetails)
      // console.log("PreferredDeliveryDate: ", customerInfo.PreferredDeliveryDate)

      

      //Default Courier Shipping Fee
      // GetSpecificShippingAreaFee(shippingAreaFromComponent, customerInfo.SendOption, totalAmount)
      //   .then(res => {  
      //     console.log(customerInformation)
      //     _.isEmpty(publicToken) ? setShippingArea({ ...customerInfo.DeliveryDetails, ShippingFee: res.data.ShippingFee }) : setShippingArea({
      //       ...customerDetails,
      //       ShippingFee: res.data.ShippingFee
      //     })
      //     setFinalAmount(totalAmount + res.data.ShippingFee)
      //     console.log("for area", res?.data)
      //     if (res.data?.MinOrderAmount > totalAmount || res?.data?.ShippingFee === "Invalid Shipping") {
      //       Swal.fire({
      //         icon: "warning",
      //         title: "Minimum Order Required!",
      //         text: "Minimum total amount does meet the required amount to place order for this area Please choose Grab - Lalamove - LBC - J and T",
      //         footer: "Pao Kitchen 2022",
      //       });
      //       setCheckOutError(oldvalue => {
      //         return {
      //           ...oldvalue,
      //           ShippingFee: res?.data?.ShippingFee
      //         }
      //       })
      //       setEnableButton(true)
      //     } else {

      //       setEnableButton(false)
      //     }
      //   }).catch(
      //     err => {
      //       console.log(err.response);
      //     }
      //   )
      Swal.fire({
        title: "In progress!",
        text: "Please wait while fetching Shipping Fee",
        allowOutsideClick: false,
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      
        //Lalamove Courier Shipping Fee
        let DelDate = new Date(customerInfo.PreferredDeliveryDate);
        
        // let DelDate = moment(`${preferredDeliveryDate.deliveryDate} ${preferredDeliveryDate.deliveryTime}`).tz("Asia/Manila").format().toString()
        console.log("dateVal: ", DelDate)
					  // dateVal.setHours(dateVal.getHours() - 8);
        // let TZDateTime = dateVal.toISOString(true)
        let InputData = {}
        _.isEmpty(publicToken) ? 
          InputData = {
            "BRANCHID": localStorage.getItem("publicBranch"), 
            "COURIER": "LALAMOVE",
            "pickup_address":{ 
                "address":"174 Yakal", 
                "place_id":"ChIJk6scXQrJlzMRhOaBazQzwzc",
                "place":"Makati, Metro Manila, Philippines"
            },
            "destination_address":{
                "phone": customerInfo.ContactDetails.ContactNum,
                "address":shippingAreaFromComponent.structured_formatting.main_text,
                "place":shippingAreaFromComponent.structured_formatting.secondary_text,
                "place_id":shippingAreaFromComponent.place_id
            }, 
            "PREFERREDDELIVERYDATE" : DelDate
          }  
        : InputData = {
            "BRANCHID": localStorage.getItem("publicBranch"), 
            "COURIER": "LALAMOVE",
            "pickup_address":{ 
                "address":"174 Yakal", 
                "place_id":"ChIJk6scXQrJlzMRhOaBazQzwzc",
                "place":"Makati, Metro Manila, Philippines"
            },
            "destination_address":{
                "phone": customerDetails.ContactNum,
                "address":shippingAreaFromComponent.Address,
                "place":shippingAreaFromComponent.Address,
                "place_id":shippingAreaFromComponent.PlaceId
            }, 
            "PREFERREDDELIVERYDATE" : DelDate
          }

        console.log("InputData: ", InputData)

        GetLalamoveShippingAddressFee(InputData)
        .then(res => {
          console.log("OutputData: ", res.data)

          let LalamoveDetails = {
            QuotationId: res.data.LalamoveResult.quotationId,
            Stops: res.data.LalamoveResult.stops
          }
          _.isEmpty(publicToken) ? 
          setShippingArea({ 
            ...customerInfo.DeliveryDetails, 
            ShippingFee: res.data.ShippingFee, 
            LalamoveDetails: LalamoveDetails
          }) : setShippingArea({
            ...customerInfo.DeliveryDetails, 
            ShippingFee: res.data.ShippingFee,
            LalamoveDetails: LalamoveDetails
          })
          setFinalAmount(totalAmount + res.data.ShippingFee)
          setEnableButton(false)
          Swal.close();
        }).catch(
          err => {  
            if(err.response.data.data.length > 0){
              if(err.response.data.data[0].id === "ERR_INVALID_FIELD"){
                Swal.fire("Date cannot be a past date or more than 30 days in advance.", '', 'error')
              }else if(err.response.data.data[0].id === "ERR_OUT_OF_SERVICE_AREA"){
                Swal.fire("Given latitude/longitude of address is out of service area.", '', 'error')
              }else{
                Swal.fire(err.response.data.data[0].message, '', 'error')
              } 
            }else{
              Swal.fire(err.response.data.mess, '', 'error')
            }
            setShippingArea({
              ...customerInfo.DeliveryDetails, 
              ShippingFee: 0,
              LalamoveDetails: null
            })
          }
        )
    }


  }

  const [voucherE, setVoucherE] = useState("")
  const [voucher, setVoucher] = useState("")
  const submitVoucher = (e) => {
    e.preventDefault()
    let orderDetail = _.map(checkoutCart, value => {
      return { ProductId: value.ProductId, Price: value.TotalPrice }
    });
    let Prices = 0;  
    _.forEach(checkoutCart, (value, key) => {
      Prices = Prices + value.TotalPrice
    })

    console.log("orderDetail: ", JSON.stringify(orderDetail));

    console.log("Price: ", Prices) 
    SubmitVoucher({
      "BranchId": localStorage.getItem("publicBranch"),
      "CouponCode": voucher,
      "CustomerId": jwt.decode(publicToken)?._id ?? "",
      "OrderDetails": orderDetail,
      "TotalPrice": Prices
    })
      .then(
        res => {
          const { IfReachedMax, Payment } = res?.data
          console.log("If coupon Success here >>> ")
          if (IfReachedMax) {
            console.log("IfReachedMax A: ", IfReachedMax)
            console.log("Payment A: ", Payment)
            //no voucher applied
            //setVoucherStatus("Voucher not applied, Reached max limit")
            setVoucherE("Voucher not applied, Reached max limit")
            setCustomerInformation({
              ...customerInformation,
              CouponCode: ""
            })
            setIsVoucherApplied({
              isApplied: false,
              payment: 0
            })
          } else {
            console.log("IfReachedMax B: ", IfReachedMax)
            console.log("Payment B: ", Payment)
            setCustomerInformation({
              ...customerInformation,
              CouponCode: voucher
            })
            setIsVoucherApplied({
              isApplied: true,
              payment: Payment
            })
            Swal.fire({
              toast: true,
              icon: 'success',
              title: 'Order updated',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            setVoucherE("")
            //

          }
        }
      )
      .catch(
        err => {
          console.log(err.response);
          console.log("If coupon error here >>> ")
          setVoucherE(!_.isEmpty(err.response) ? err.response.data.mess : "")
          setIsVoucherApplied({
            isApplied: false,
            payment: 0
          })
        }
      )
  }

  const voucherInputChange = (e) => {
    const { value } = e.target
    setVoucher(value)
  }
  const [openLoginSuccess, setOpenLoginSuccess] = React.useState(false);

  const handleOpenLoginSuccess = () => {
    setOpenLoginSuccess(true);
  };

  const handleCloseLoginSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLoginSuccess(false);
  };
  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseLoginSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const removeItemOnCart = (e, ProductId) => {
    e.preventDefault()
    if (_.isEmpty(publicToken)) {
      localStorage.setItem("publicCart", JSON.stringify(_.filter(checkoutCart, (cartProduct) => {
        return cartProduct.ProductId !== ProductId
      })));
      setCheckoutCart(_.filter(checkoutCart, (cartProduct) => {
        return cartProduct.ProductId !== ProductId
      }))
    } else {
      let cartDetails = _.filter(checkoutCart, (cartProduct) => {
        return cartProduct.ProductId !== ProductId
      })

      UpdateCart(cartId, cartDetails, jwt.decode(publicToken)._id).then(
        res => console.log(res)
      ).catch(
        error => console.log(error.response)
      )
      setCheckoutCart(cartDetails)
      setCustomerInformation({
        ...customerInformation,
        OrderDetails: cartDetails
      })
    }

  }
  const setBanktoBlank = () => {
    setCustomerInfo({
      ...customerInfo,
      PaymentReceipt: {
        PaymentId: ""
      }
    })
    let paymentCard = document.querySelectorAll(".payment-card")
    for (let i = 0; i < paymentCard.length; i++) {
      paymentCard[i].classList.remove("payment-active")
    }
  }
  const chosenPayment = (e, id) => {
    e.preventDefault()

    _.isEmpty(publicToken) ? setCustomerInfo({
      ...customerInfo,
      PaymentReceipt: {
        PaymentId: id
      }
    }) :
      setCustomerInformation({
        ...customerInformation,
        PaymentReceipt: {
          PaymentId: id
        }
      })
    let paymentCard = document.querySelectorAll(".payment-card")
    for (let i = 0; i < paymentCard.length; i++) {
      paymentCard[i].classList.remove("payment-active")
    }
    document.querySelector("#pc" + id).classList.add("payment-active")
  }

  const changeMealStatus = () => (event) => {  
    let isCheck = false
    if(isMealAllowance){
      isCheck = false
    }else{
      isCheck = true
    }
    // console.log("isCheck: ", isCheck) 
    setIsMealAllowance(isCheck) 
  };
 
  
  return (
    <div className='checkout'>
      <Snackbar
        open={openLoginSuccess}
        onClose={handleCloseLoginSuccess}
        action={action}
      >
        <Alert onClose={handleCloseLoginSuccess} severity="success" sx={{ width: '100%' }}>
          Account Successfully saved, please login when process in progress fails!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openLoginSuccess}
        onClose={handleCloseLoginSuccess}
        action={action}
      >
        <Alert onClose={handleCloseLoginSuccess} severity="success" sx={{ width: '100%' }}>
          Account Successfully saved, please login when process in progress fails!
        </Alert>
      </Snackbar>
      <div className='checkout-container'>
        {
          !_.isEmpty(publicToken) ? (
            <LoggedIn
              {...{
                customerInformation,
                setCustomerInformation,
                setBankList,
                setBanktoBlank,
                getAreaShippingFee,
                isShippingEmpty, maxPreparationTime, checkOutError,
                userLoggedIn,
                showEditLocation,
                setSpecificShippingArea,
                getCustomerInformation,
                customer,
                changeMealStatus,
                isMealAllowance
              }}
            />

          ) : (
            <NotLoggedIn
              {...{
                changeActive,
                getAreaShippingFee,
                getCustomerInformation,
                setShippingArea, checkOutError, setBankList, setBanktoBlank, maxPreparationTime,
                userNotLoggedIn
              }}
            />
          )
        }

        <div className='checkout-container-right'>
          <div className='checkout-container-right-content'>
            <div className='checkout-container-right-content-item center'>
              <div className='width-40'>
                <Logo />
              </div>
            </div>
            <div className='checkout-container-right-content-item-60'>
              <div className='checkout-container-right-content-item-60-span border-bot'>
                <span>{t('receiptdetails')}</span>
              </div>
              {
                !_.isEmpty(checkoutCart) ?
                  (
                    Object.keys(checkoutCart).map((key, index) => {
                      const { ProductName, Quantity, TotalPrice, IsStock, ProductId } = checkoutCart[key]
                      const { EN, VN, CN, TH } = ProductName
                      return (
                        <div key={index} className='checkout-container-right-content-item-60-list border-bot'>
                          <span className='checkout-container-right-content-item-60-list-product'>
                            <span className={IsStock ? "product-name-list" : "product-name-list out-stock"}>{Quantity}x {" "}{ProductName[i18next.language] || EN || VN || CN || TH} </span>
                            <small className='span-out-stock'>{IsStock ? "" : " out of stock"}</small>

                          </span>
                          <span className='checkout-container-right-content-item-60-list-price'>
                            <CurrencyFormat value={TotalPrice} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                          </span>
                          {IsStock ? (<></>) : (<button className='remove-item' onClick={(e) => {
                            removeItemOnCart(e, ProductId)
                          }}><RemoveCircleIcon /></button>)}

                        </div>
                      )
                    })
                  ) :
                  (
                    <div className='checkout-container-right-content-item-60-list border-bot'>
                      <span className='checkout-container-right-content-item-60-list-product'>{t('noproductfoundoncart')}</span>

                    </div>
                  )
              }
            </div>
            <div className='checkout-container-right-content-item-60-2 '>
              <form className='checkout-container-right-content-item-60-2-voucher '>
                <input type="text" value={voucher} onChange={e => { voucherInputChange(e) }} name="voucher" id="" />
                <button type='button' onClick={e => { submitVoucher(e) }}>{t('applyvoucher')}</button>
              </form>
              <small className='border-bot error-message'>{voucherE}</small>
              <div className='checkout-container-right-content-item-60-list'>
                <span className='checkout-container-right-content-item-60-list-product'>{t('subtotal')}: </span>
                <span className='checkout-container-right-content-item-60-list-productprice'>
                  <CurrencyFormat value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                </span>
              </div>
 
              {(isVoucherApplied.isApplied === true) ? (<>
                <div className='checkout-container-right-content-item-60-list'>
                  <span className='checkout-container-right-content-item-60-list-product'>Coupon (Discount):</span>
                  <span className='checkout-container-right-content-item-60-list-productprice-negative'>-
                    <CurrencyFormat value={isVoucherApplied.payment} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                  </span>
                </div>
                <div className='checkout-container-right-content-item-60-list'>
                  <span className='checkout-container-right-content-item-60-list-product'>Discounted Subtotal:</span>
                  <span className='checkout-container-right-content-item-60-list-productprice'>
                    <CurrencyFormat value={(totalAmount - isVoucherApplied.payment)} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                  </span>
                </div>
              </>) : (<></>)
              }

              <div className='checkout-container-right-content-item-60-list margin-bot'>
                <span className='checkout-container-right-content-item-60-list-product'>{t("shippingfee")}:</span>
                <span className='checkout-container-right-content-item-60-list-productprice'>
                  <CurrencyFormat value={ShippingFee} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                </span>
              </div>
              <div className='checkout-container-right-content-item-60-list border-bot padding-bottom-15'>
                <span className='checkout-container-right-content-item-60-list-product'>{t('totalpayment')}: </span>
                <span className='checkout-container-right-content-item-60-list-productsecondary'>
                  <CurrencyFormat value={(finalAmount - isVoucherApplied.payment)} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                </span>
              </div>
            </div>
            {
              !_.isEmpty(bankList) ? (
                <div className='checkout-container-right-content-item-60-2'>
                  <div className='checkout-container-right-content-item-60-list'>
                    <span className='checkout-container-right-content-item-60-list-product'>{t('choosepayment')} : </span>
                  </div>
                  {
                    _.isEmpty(checkOutError.PaymentId) ? (<></>) : (
                      <small className='error-message'>{checkOutError.PaymentId}</small>
                    )
                  }
                </div>
              ) : (<></>)
            }


            <div className='flex-wrap gap-5 payment-container'>
              {
                !_.isEmpty(bankList) ? (
                  _.map(bankList, (bank, index) => {
                    const { Account, BankName, IsActive, Number, _id } = bank
                    return IsActive ? (
                      <div onClick={(e) => { chosenPayment(e, _id) }} key={index} className='payment-card' id={'pc' + _id}>
                        <div className='flex-row-start gap-5'> <span>Bank: </span><span>{BankName}</span></div>
                        <div className='flex-row-start gap-5'> <span>Account Name: </span><span>{Account}</span></div>
                        <div className='flex-row-start gap-5'> <span>Account Number: </span><span>{Number}</span></div>
                        <div className='flex-row-start gap-5'> <span>Branch: </span><span>N/A</span></div>
                      </div>
                    ) : (
                      <></>
                    )
                  })
                ) : (
                  <></>
                )
              }

            </div>

          </div>

        </div>
      </div>

      <div className='checkout-bottom'>
        <div className="homepage-button">
          <button onClick={(e) => { gotoHomepage(e)}}>
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} /> {t('back')}
          </button>
        </div>
        {
          _.isEmpty(publicToken) ? (
            <button disabled={enableButton} className='button-secondary' onClick={(e) => { createAccountAndPlaceorder(e) }}>{t('createaccountandplaceorder')}</button>
          ) : (
            <button disabled={enableButton} className='button-secondary' onClick={(e) => { placeorder(e) }}>{t('placeorder')}</button>
          )
        }
      </div>
      <CheckoutModal />
      <PlaceOrderModal {...{ activeStep/** , token*/, checkoutCart, setActiveStep, customerInfo, shippingArea, setCheckOutError, setOpenLoginSuccess, publicToken, placeOrderToChild, isVoucherApplied, customerInformation, cartId, isMealAllowance }} />
      <EditLocation {...{ node, specificShippingArea }} />
    </div>
  )
}

export default CheckOut