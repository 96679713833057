import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { countPage } from '../../../ProductManager/productHelper';
import Slider from '../../../ProductManager/Slider';
import { GetAllLimitationsByType } from '../../discountHelper';
import AddCouponButton from './AddCouponButton';
import AddLimitationForm from './AddLimitationForm';
import LimitationFilter from "./Filter/LimitationFilter";
import LimitationsTable from "./LimitationsTable";
import UpdateLimitationForm from './UpdateLimitationForm';
const Limitations = () => {
  useTitle("Pao Kitchen | Limitations")
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [limitationList, setlimitationList] = useState([])
  const [filter, setFilters] = useState({})
  const [openAddLimitForm, setAddLimitForm] = useState(false)
  const [openUpdateLimitForm, setUpdateLimitForm] = useState(false)
  const [limitationId, setLimitationId] = useState("")
  useEffect(() => {
    getAllLimitations(1, filter)
    return () => {

    }
    //eslint-disable-next-line
  }, [])
  const getAllLimitations = (page, searchValue) => {
    setlimitationList([])
    setPageCount(0)
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    GetAllLimitationsByType(page, searchValue)
      .then(
        res => {

          Swal.close()
          const { paginatedResults, metadata } = res?.data;
          const { total, limit } = metadata;
          setPageCount(countPage(total, limit))
          setlimitationList(paginatedResults)
          console.log(paginatedResults);
        }
      ).catch(err => {
        setPageCount(0)
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Limitation(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
        console.log(err.response)
      })
  }
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getAllLimitations(p, filter)
  }
  const productFilter = (e, searchValues) => {
    console.log(searchValues);
    getAllLimitations(1, searchValues)
    setFilters(searchValues)
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getAllLimitations(1, {})
    setFilters({})
  }

  // form limitation
  // add 
  const openAddLimitationForm = () => {
    setAddLimitForm(true)
  }
  const closeAddLimitForm = () => {
    setAddLimitForm(false)
  }
  const showUpdateModal = (e, id) => {
    e.preventDefault()
    setUpdateLimitForm(true)
    setLimitationId(id)
  }
  const closeUpdateLimitForm = () => {
    setUpdateLimitForm(false)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        discount manager / web coupons / limitations
      </section>
      <LimitationFilter {...{ productFilter, clearFilter }} />
      <LimitationsTable tableContents={limitationList} showUpdateModal={showUpdateModal} />
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <Slider tableId={"limitationTable"} />
      <AddCouponButton addCoupon={() => {
        openAddLimitationForm()
      }} tooltip={"Add Limitations"} />
      {
        openAddLimitForm ?
          <AddLimitationForm refreshTable={(e) => { clearFilter(e) }} modalLimitation={openAddLimitForm} closeModalLimitation={() => { closeAddLimitForm() }} />
          : <></>
      }
      {
        openUpdateLimitForm ?
          <UpdateLimitationForm id={limitationId} modalLimitation={openUpdateLimitForm} refreshTable={(e) => { clearFilter(e) }} closeModalLimitation={() => closeUpdateLimitForm()} />
          : <></>
      }
    </main>
  )
}

export default Limitations