import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { HideItem, IdSelector, ShowItem, ShowItemFlex } from "../productHelper";
const AddCategory = () => {
  const showTextAdd = () => {
    IdSelector("addcategory").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#addcategory");
  };
  const hideTextAdd = () => {
    IdSelector("addcategory").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#addcategory");
    }, 500);
  };
  const showAddCategory = () => {
    ShowItemFlex("#categoryAddForm")
  }

  return (
    <div className="add-product-group-category" onClick={showAddCategory}>
      <div id="addcategory" >Add Category</div>
      <div className="icon" onMouseEnter={showTextAdd} onMouseLeave={hideTextAdd} // onClick={}
      >
        <AddIcon sx={{
          fontSize: "18px"
        }} />
      </div>
    </div>
  );
}

export default AddCategory;