import { SET_ORDER_TOTAL_PENDING } from "../types/Order";
const initialState = {
  totalPending: 0,
};
const Order = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ORDER_TOTAL_PENDING:
      return {
        ...state,
        totalPending: action.payload,
      };
    default:
      return state;
  }
};

export default Order;
