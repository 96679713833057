import _ from "lodash";
let axios = require("axios");
const baseUrl = process.env.REACT_APP_BASE_URL;
// GET
export const GetAllLimitationsByType = async (page, searchValue) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let searchValues = _.isEmpty(searchValue) ? {
    search: "",
    limit: 10
  } : {
    ...searchValue
  }
  const { search, limit } = searchValues
  let filters = `&search=${search}&limit=${limit}`
  let config = {
    method: "get",
    url: `${baseUrl}/cms/limitations/list?SelectedBranch=${selectedBranch}&page=${page}${filters}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetLimitationById = async (id) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: "get",
    url: `${baseUrl}/cms/limitations/get-limitation/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};


export const GetCouponById = async (id) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: "get",
    url: `${baseUrl}/cms/coupons/get-coupon/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetAllCoupons = async (page, searchValue) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let searchValues = _.isEmpty(searchValue) ? {
    search: "",
    status: ""
  } : {
    ...searchValue
  }
  const { search, status } = searchValues
  let filters = `&search=${search}&status=${status}`
  let config = {
    method: "get",
    url: `${baseUrl}/cms/coupons/list?SelectedBranch=${selectedBranch}&page=${page}${filters}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};

export const GetClaimHistory = async (id) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: "get",
    url: `${baseUrl}/cms/coupons/get-claim-history/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  let res = await axios(config);
  return res.data;
};


///POST

export const SubmitLimitations = async (data) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "post",
    url: `${baseUrl}/cms/limitations/add-limitation?SelectedBranch=${selectedBranch}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const SubmitAutoCreateCoupon = async (data) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "post",
    url: `${baseUrl}/cms/coupons/create-autocoupon?SelectedBranch=${selectedBranch}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const SubmitCreateCoupon = async (data) => {
  let token = localStorage.getItem("cmsToken");
  let selectedBranch = localStorage.getItem("branchId");
  let config = {
    method: "post",
    url: `${baseUrl}/cms/coupons/add-coupon?SelectedBranch=${selectedBranch}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};




//PATCH
export const UpdateLimitations = async (id, data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "patch",
    url: `${baseUrl}/cms/limitations/update-limitation/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};

export const UpdateCoupon = async (id, data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "patch",
    url: `${baseUrl}/cms/coupons/update-coupon/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


export const UpdateCouponExpiration = async (id, data) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "patch",
    url: `${baseUrl}/cms/coupons/update-expi-date/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };
  let res = await axios(config);
  return res.data;
};


// DELETE


export const DeleteCoupon = async (id) => {
  let token = localStorage.getItem("cmsToken");
  let config = {
    method: "delete",
    url: `${baseUrl}/cms/coupons/delete-coupon/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }
  };
  let res = await axios(config);
  return res.data;
};
