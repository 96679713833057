import CurrencyFormat from "react-currency-format";

const PaymentMethodsCard = ({ text, amount }) => {
  return (
    <div className="analytics-payment-card flex-column-center gap-5">
      <span className="analytics-payment-card-text">{text}</span>
      <span className="analytics-payment-card-amount">
        <CurrencyFormat
          value={amount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₱ '}
        />
      </span>
    </div>
  )
};
PaymentMethodsCard.defaultProps = {
  text: "",
  amount: 0,
}
export default PaymentMethodsCard;

