import css from "./QuantityInput.module.scss";
import React from "react";
import Proptypes from "prop-types";

const QuantityInput = (props) => {
    const {quantity, setQuantity} = props
    const add = () => {
        const newQuantity = quantity + 1
        setQuantity(newQuantity)
    }

    const sub = () =>  {
        const newQuantity = quantity - 1
        setQuantity(newQuantity >= props.min ? newQuantity : 1)
    }

    const onChangeQuantity = (e) => {
        setQuantity(e.target.value)
    }

    return (
        <div className={css.quantityContainer}>
            <button onClick={sub} className={css.buttonSub} >-</button>
            <input type="number" value={quantity} onChange={onChangeQuantity} min={props.min ?? 1}
                   className={css.inputQuantity}
            />
            <button onClick={add} className={css.buttonAdd}>+</button>
        </div>
    )
}

QuantityInput.propTypes = {
    quantity: Proptypes.number.isRequired,
    setQuantity: Proptypes.func.isRequired,
    min: Proptypes.number
}

export default QuantityInput