import { useEffect, useState } from 'react';
import "../listTable.scss"; 
import SearchIcon from '@mui/icons-material/Search';
import CurrencyFormat from 'react-currency-format';
import { t } from 'i18next';

const OrderHistory = ({ OrderHistory, orderHistoryList, moment, newTabURL, getOtherOrderList }) => {
    // useEffect(() => { 
    //     getOtherOrderList("", "", "", "")
    //     return () => { } 
    //   }, [])
    const [filter, setFilter] = useState({
        orderRef: "",
        dateCreated: ""
      })
    const { orderRef, dateCreated } = filter
    const searchSubmit = (e) => {
        e.preventDefault()
        getOtherOrderList("", "",  orderRef, dateCreated) 
    }
    const handleChange = (e) => {
        const { value, name } = e.target
        setFilter({ ...filter, [name]: value })
    }

    return (
       <div className="customer-container-right-content">
            <div className="customer-container-right-content-title">
                <img src={OrderHistory} width="16" height="16" alt="icon" />
                <span>Order History</span>
                <div>
                    <form onSubmit={(e) => { searchSubmit(e); }}>
                        <input onChange={(e) => handleChange(e)} type="text" name="orderRef" value={orderRef} placeholder="Order #" />
                        <input onChange={(e) => handleChange(e)} type="date" name="dateCreated" value={dateCreated} placeholder="Date" />
                        <button type="submit">
                            <SearchIcon />
                        </button>
                    </form>
                </div> 
            </div> 
            <div className="customer-container-right-content-table">
                <div className="customer-container-right-content-table-header">
                    <div className="normal-start">Order #</div>
                    <div className="normal">Date</div>
                    <div className="normal-half">Quantity</div>
                    <div className="normal-start">Payment Option</div>
                    <div className="normal-half">Price</div>
                    <div className="normal-start">Status</div>
                    <div className="normal-half"></div>
                </div>
                {
                    orderHistoryList.length ? (
                        Object.keys(orderHistoryList).map((key, index) => {
                            const { _id, OrderRef, PaymentMode, TotalAmount, CreatedAt, OrderDetails, Status } = orderHistoryList[key]
                            let Created = new Date(CreatedAt);
                            let DateCreated = moment(Created).format("MM/DD/YYYY");
                            return (
                                <div key={index} className="customer-container-right-content-table-data">
                                    <div className="normal-start">{OrderRef}</div>
                                    <div className="normal">{DateCreated}</div>
                                    <div className="normal-half">{OrderDetails.length}</div>
                                    <div className="normal-start">{PaymentMode}</div>
                                    <div className="normal-half"><CurrencyFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} /></div>
                                    <div className="normal-start">
                                    {
                                        Status === 1 ? t('processing')
                                        : Status === 2 ? t('confirmed')
                                        : Status === 3 ? t('prepared')
                                        : Status === 4 ? t('toship')
                                        : Status === 5 ? t('delivered')
                                        : Status === 6 ? t('complete')
                                        : Status === 7 ? t('declined')
                                        : Status === 8 ? t('cancelled')
                                        : Status === 9 ? t('returned')
                                        : t('pending')
                                    }
                                    </div>
                                    <div className="normal-half">
                                    <button className="customer-container-right-content-table-data-pay" onClick={() => { 
                                        newTabURL(`/admin/cms/order-list/${_id}`)
                                    }}>{t('pay')}</button>
                                    </div>
                                </div>
                            )
                        })
                    ) 
                    : "No Data Found"
                }
            </div>
        </div>
    )
};

export default OrderHistory;