import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { HideItem } from "../productHelper";

const AddGroupCategoryFormEdit = ({ submitGroupCategoryFormEdit, groupValue }) => {
  const closeAddGroupCategoryForm = () => {
    HideItem("#groupEdit")
  }
  const [groupCategory, setGroupCategory] = useState({
    id: "",
    Name: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    Description: {
      VN: "",
      EN: "",
      TH: "",
      CN: "",
    },
    IsActive: true
  });

  useEffect(() => {
    setGroupCategory(groupValue)
  }, [groupValue])


  const [language, setLanguage] = useState({ Language: "VN", LangDesc: "Vietnam" });
  const { Language, LangDesc } = language;
  const { Name, Description, IsActive } = groupCategory;

  const changeLang = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    value === "EN" ? (setLanguage({ ...language, [name]: value, LangDesc: "English" }))
      : value === "CN" ? (setLanguage({ ...language, [name]: value, LangDesc: "Chinese" }))
        : value === "TH" ? (setLanguage({ ...language, [name]: value, LangDesc: "Thailand" }))
          : (setLanguage({ ...language, [name]: value, LangDesc: "Vietnam" }))
    let buttons = document.querySelectorAll(".langeGc");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("lang-active")
    }
    e.target.classList.add('lang-active')
  }
  const changeCategoryName = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, Name: { ...Name, [name]: value } })
  }
  const changeCategoryDescription = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, Description: { ...Description, [name]: value } })
  }

  const changeStatus = (e) => {
    const { name, value } = e.target;
    setGroupCategory({ ...groupCategory, [name]: value === "true" ? true : false })
  }

  return (
    <div className="modal-add-group" id="groupEdit" onClick={(e) => {
      HideItem("#groupEdit")
    }}>
      <div className="modal-add-group-form" onClick={(e) => {
        e.stopPropagation()
      }}>
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            Edit Group Category
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={() => closeAddGroupCategoryForm()}
            />
          </div>
        </div>
        <form className="modal-add-group-form-main">

          <div className="modal-add-group-form-main-language">
            <div className="language-changer">
              <label htmlFor="language">FIELDS FOR</label>
              <button onClick={(e) => changeLang(e)} className="langeGc lang-active" name="Language" value="VN">VN</button>
              <button onClick={(e) => changeLang(e)} className="langeGc " name="Language" value="EN">EN</button>
              <button onClick={(e) => changeLang(e)} className="langeGc " name="Language" value="CN">CN</button>
              <button onClick={(e) => changeLang(e)} className="langeGc " name="Language" value="TH">TH</button>
            </div>
          </div>
          <div className="modal-add-group-form-main-name">
            <label htmlFor="categoryname">Group Category Name ( {LangDesc} )</label>
            <input type="text" id="categoryname" name={Language}
              value={
                Language === "EN" ? Name.EN
                  : Language === "CN" ? Name.CN
                    : Language === "TH" ? Name.TH
                      : Name.VN
              }
              onChange={(e) => { changeCategoryName(e) }}
            />
          </div>
          <div className="modal-add-group-form-main-description">
            <label htmlFor="categorydescription">Description ( {LangDesc} )</label>
            <textarea type="text" id="categorydescription" name={Language}
              value={
                Language === "EN" ? Description.EN
                  : Language === "CN" ? Description.CN
                    : Language === "TH" ? Description.TH
                      : Description.VN
              }
              onChange={(e) => { changeCategoryDescription(e) }}
            />
          </div>
          <div className="modal-add-group-form-main-status">
            <label htmlFor="categorystatus">status</label>
            <select name="isActive" id="categorystatus" value={IsActive} onChange={changeStatus}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </form>

        <div className="content-main-add-user-footer">
          <button
            className="content-main-add-user-footer-cancel"
            onClick={() => closeAddGroupCategoryForm()}
          >
            cancel
          </button>
          <button
            className="content-main-add-user-footer-create"
            onClick={(e) => submitGroupCategoryFormEdit(e, groupCategory)}
          >
            update
          </button>
        </div>
      </div>
    </div>
  )
};

export default AddGroupCategoryFormEdit;
