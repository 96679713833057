export const statuses = {
  0: "Pending",
  1: "Processing",
  2: "Confirmed",
  3: "Prepared",
  4: "Shipping",
  5: "Delivered",
  6: "Completed",
  7: "Declined",
  8: "Cancelled",
  9: "Returned",
}

export const lalamovestatuses = {
  "Assigning": "Assigning",
  "On Going": "On Going",
  "Picked Up": "Picked Up",
  "Completed": "Completed",
  "Expired": "Expired",
  "Cancelled": "Cancelled",
  "Rejected": "Rejected"
}

export const citystatuses = {
  "Manila": "Manila",
  "Cebu": "Cebu",
  "Pampanga": "Pampanga"
}