import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {useEffect, useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
import ConfirmPayment from "./ConfirmPayment";
import {post} from "../../../../helpers/axiosHelper";
import {Tooltip} from "@mui/material";

const PaymentMethodAndNote = ({ getData, paymentMethod, OrderNote, openModalPayment, openPaymentStatusModal, Paid, PaidAmount, currentStatus, order, getOrderDetail }) => {
  const [paymentStatus, setPaymentStatus] = useState(Paid)
  const PaymentReceipt = order?.PaymentReceipt ?? {}
  const DECLINED_RECEIPT = 2
  const paymentReceiptStatus = PaymentReceipt?.Status ?? 1
  const rejected = paymentReceiptStatus === DECLINED_RECEIPT
  const reason = PaymentReceipt?.Reason ?? ""

  const handlePayment = () => {
    setPaymentStatus(Paid)
  }

  useEffect(() => {
    setPaymentStatus(Paid)
    return () => {

    }
  }, [Paid])
  const openModalPaym = () => {
    if (Paid && (currentStatus === 6)) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Cannot remove payment when order status is COMPLETED!",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    } else {
      openPaymentStatusModal()
    }
  }

  const handleRejectReceipt = () => {
    if(rejected) {
      return false
    }

    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      text: "This receipt will be rejected! Please enter a reason if this receipt was declined.",
      input: "textarea",
      inputAutoTrim: true,
      showCancelButton: true,
      confirmButtonText: "Reject",
      inputValue: reason,
      inputPlaceholder: "Enter reason here....",
    })
    .then(result => {
        if(result.isConfirmed) {
          const value = result.value
          post("/cms/order/payment-receipt/reject", {orderId: order._id, reason: value})
              .then(() => {
                getOrderDetail(order._id)
              })
              .catch(() =>  {
                alert("Something went wrong. Please try again later!")
          })
        }
    })
    .catch(() =>  {
      alert("Something went wrong. Please try again later!")
    })
  }

  return (
    <div className="width-100 order-container-left-customer margin-bottom-50">
      <div className="order-container-left-customer-detail">
        <div className="order-container-left-customer-detail-title">
          <span>PAYMENT VIA</span>

          {
            currentStatus === 6 ?
                (<>
                  <Tooltip title="Unable to update completed order">
                    <button>Edit</button>
                  </Tooltip>
                </>) :
                (
                    <>
                      <button onClick={() => {openModalPayment()}}>Edit</button>
                    </>
                )
          }


        </div>
        <div className="order-container-left-customer-billing-content ">
          <span className="customer-method">
            <AccountBalanceIcon /> {getData(paymentMethod)}{' '}
          </span>
          <div>
            <label className="customer-method payment-checkbox" htmlFor="acceptPayment" onClick={() => openModalPaym() } >
                <input checked={paymentStatus} disabled={rejected} type="checkbox" name="" id="acceptPayment"
                       onClick={() => openPaymentStatusModal()}
                       onChange={() => handlePayment() }
                />
                {Paid ? "Remove Payment | " : "Accept Payment"}
                {Paid ? <span>
                          <CurrencyFormat
                              value={PaidAmount}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₱ '}
                          />
                        </span> : null
                }

              </label>

            {
              Paid ? null : <label className="customer-method payment-checkbox" htmlFor="rejectReceipt">
                <input onChange={() => { handleRejectReceipt() }} checked={rejected} type="checkbox" name="" id="rejectReceipt" />
                Reject Receipt
                <p>{reason}</p>
              </label>
            }
          </div>
        </div>
        <ConfirmPayment order={order} getOrderDetail={getOrderDetail}/>
      </div>
      <div className="order-container-left-customer-billing">
        <div className="order-container-left-customer-detail-title">
          <span>NOTE</span>
        </div>

        <div className="order-container-left-customer-billing-content ">
          <q>{getData(OrderNote)}</q>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodAndNote;
