import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {useEffect, useMemo, useRef, useState} from "react";
import {getListEventPusher, getListTask, getSettingPusher} from "../../../helpers/pusherHelper";
import Swal from "sweetalert2";
import Pusher from "pusher-js";
import {useSelector} from "react-redux";
import music from "../../../assets/order.wav";
import {post} from "../../../helpers/axiosHelper";

const TaskNotification = () => {
    const [listTasksPending, setListTasksPending] = useState([])
    const pusherEvents = useRef([])
    const [notifiIsOpen, setNotifiIsOpen] = useState(false)
    const alertAlwaysOpen = useRef(null)

    const audio = useMemo(() => {
        return new Audio(music);
    }, [])

    const handlePusherCallback = (pusherData) => {
        if (pusherData) {
            if(alertAlwaysOpen.current) {
                clearInterval(alertAlwaysOpen.current)
            }

            const pathName = window.location?.pathname ?? ""
            if(pusherData.linkTo === pathName) {
                return true
            }

            const notiType = pusherData?.type ?? 1
            let icon = ""
            switch (notiType) {
                case 2:
                    icon = "warning"
                    break
                case 3:
                    icon = "error"
                    break
                default:
                    icon = "info"
            }

            let openPopup = true
            setNotifiIsOpen(openPopup)

            alertAlwaysOpen.current = setInterval(() => {
                const hasOpen = document.querySelector(".swal2-container .swal2-cancel[aria-label=swal-notification-task-open]")

                const canMarkToRead = pusherData?.canMarkToRead ?? false
                const linkTo = pusherData?.linkTo ?? ""
                const showConfirmButton = linkTo || canMarkToRead

                let confirmButtonText = "Go"
                if(canMarkToRead && linkTo) {
                    confirmButtonText = "Go"
                }
                else if(canMarkToRead && !linkTo) {
                    confirmButtonText = "Mark as read"
                }

                if ((notifiIsOpen === true || openPopup) && !hasOpen) {
                    Swal.fire({
                        title: pusherData?.taskName ?? "",
                        text: pusherData?.contentMessage ?? "",
                        icon,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText,
                        showConfirmButton,
                        cancelButtonAriaLabel: "swal-notification-task-open",
                        willOpen() {
                            audio.loop = true
                            audio.play()
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (pusherData.linkTo) {
                                if (pusherData.canMarkToRead) {
                                    handleMarkToRead(pusherData._id, () => {
                                        window.location.href = pusherData.linkTo
                                    })
                                } else {
                                    window.location.href = pusherData.linkTo
                                }
                            }
                            else {
                                if (pusherData.canMarkToRead) {
                                    handleMarkToRead(pusherData._id)
                                }
                            }

                            setNotifiIsOpen(false)
                            openPopup = false
                            if (alertAlwaysOpen.current) {
                                clearInterval(alertAlwaysOpen.current)
                                alertAlwaysOpen.current = null
                            }
                        }

                        const isDismiss = result.isDismissed && result.dismiss // if trigger from method Swal.close() result.dismiss === null
                        if (result.isDenied || isDismiss) {
                            setNotifiIsOpen(false)
                            openPopup = false

                            if (alertAlwaysOpen.current) {
                                clearInterval(alertAlwaysOpen.current)
                                alertAlwaysOpen.current = null
                            }
                        }

                        audio.pause()
                    })
                }
            }, 100)
        }
    }

    const handleGetListTask = () => {
        if (notifiIsOpen === false) {
            getListTask().then((res) => {
                const firstItem = res[0] ?? null

                if (firstItem) {
                    handlePusherCallback(firstItem)
                }
                setListTasksPending(res)
            })
        }
    }

    const registerChanelPusher = async () => {
        const events = await getListEventPusher()
        const register = []

        events.forEach(event => {
            if (event?.appKey) {
                const pusher = new Pusher(event?.appKey, {
                    cluster: event?.cluster,
                    encrypted: true
                });

                const channel = pusher.subscribe(event?.channel);
                const eventNames = event.events
                eventNames.forEach(eventName => {
                    if (eventName === "PUSHER_EVENT_REFRESH_LIST_TASK") {
                        channel.bind(eventName, () => {
                            handleGetListTask()
                        });
                    } else {
                        channel.bind(eventName, data => {
                            handlePusherCallback(data)
                        });
                    }
                })

                register.push(pusher)
            }
        })

        return register
    }

    const intervalEvent = useRef(null)

    const intervalRefreshListTask = (repeatTime) => {
        const INTERVAL_TIME = repeatTime * 60 * 1000 //5 minute

        intervalEvent.current = setInterval(() => {
            handleGetListTask()
        }, INTERVAL_TIME)
    }
    const tokens = useSelector((state) => state.token);
    const {branchId} = tokens.token

    useEffect(() => {
        // document.addEventListener("")

        if (branchId) {
            registerChanelPusher()
                .then(events => {
                    pusherEvents.current = events
                })
                .catch(e => console.error(e))

            handleGetListTask()

            if (!intervalEvent.current) {
                getSettingPusher()
                    .then((pusherSetting) => {
                        const repeatTime = pusherSetting?.loopTime ?? 5
                        intervalRefreshListTask(repeatTime)
                    })
            }
        }

        return () => {
            pusherEvents.current.forEach(pusher => {
                if (pusher instanceof Pusher) {
                    pusher.unbind_all()
                    pusher.disconnect()
                }
            })

            if (intervalEvent.current) {
                clearInterval(intervalEvent.current)
            }

            if (alertAlwaysOpen.current) {
                clearInterval(alertAlwaysOpen.current)
            }
        }
        // eslint-disable-next-line
    }, [branchId])

    const icon = () => {
        return <div className="icon-container">
            <NotificationsNoneIcon className={`icon ${listTasksPending.length ? "active" : ""}`}/>
            <span className="badge-icon">{listTasksPending.length > 99 ? "99+" : listTasksPending.length}</span>
        </div>
    }

    const handleMarkToRead = (id, callback = () => {
    }) => {
        Swal.fire({
            icon: "question",
            titleText: "Mark as read",
            text: "Are you sure?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Close"
        })
            .then(result => {
                if (result.isConfirmed) {
                    try {
                        post("/cms/task/mark-as-read", {id}).then(() => {
                            callback()
                        })
                    } catch (e) {
                        alert("Something went wrong. Please try again later!")
                    }
                }
            })
    }

    const listTask = () => {
        const onClickTo = (e, task) => {
            e.preventDefault()
            if (task.canMarkToRead) {
                handleMarkToRead(task._id, () => {
                    if (task.linkTo) {
                        window.location.href = task.linkTo
                    }
                })
            } else {
                if (task.linkTo) {
                    window.location.href = task.linkTo
                }
            }
        }

        return <div className="task-list">
            {
                listTasksPending.map((task, index) => {
                    return <button className={`task-item ${task.linkTo ? "pointer" : ""}`} style={{backgroundColor: task.color}} key={`task-item${index}`} onClick={(e) => onClickTo(e, task)}>
                        <p className="task-title">{task.taskName}</p>
                        <p className="task-content">{task.contentMessage}</p>
                    </button>
                })
            }
        </div>
    }


    return <div className="task-notification-container">
        {icon()}
        {listTask()}
    </div>
}

export default TaskNotification