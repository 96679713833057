import _ from 'lodash';
import React, { useEffect, useState } from 'react';
// import { GetReceiptImage } from '../../EcommerceHelper';

const Acceptpayment = ({ closeModalEdit, order, submitCustomerPayment }) => {
  const [amount, setAmount] = useState("")
  const [amountE, setAmountE] = useState("")
  useEffect(() => {
    //PaidAmount={amount}, Paid={false}
    // if (order?.PaymentReceipt?.Receipt) {
    //
    //   let rawString = order?.PaymentReceipt?.Receipt
    //   let imageBlob = ""
    //   if (rawString.substring(0, 5) === "https") {
    //     imageBlob = rawString
    //   } else {
    //     let slicedString = rawString.slice(4);
    //     imageBlob = "https" + slicedString
    //   }
    //   GetReceiptImage(imageBlob)
    //     .then(res => {
    //       var arrayBufferView = new Uint8Array(res);
    //       var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
    //       var urlCreator = window.URL || window.webkitURL;
    //       var imageUrl = urlCreator.createObjectURL(blob);
    //       var img = document.getElementById(order.OrderRef);
    //       img.src = imageUrl;
    //     })
    //     .catch(
    //       err => {
    //       }
    //     )
    // }
    //eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    const { value } = e.target
    setAmount(value)
  }
  const submitPaymentStatus = (e) => {
    e.preventDefault()
    setAmountE("")
    const { TotalAmount } = order
    if (Number(amount) < TotalAmount) {
      //"Payment must not be lower than the total amount of order" 
      setAmountE("Payment amount must not be lower than the total amount of order")

    } else {
      //: "Amount proceed"
      submitCustomerPayment(e, amount)
    }
  }
  return (
    <div className='order-modal-container'>
      <h3>Accept Customer Payment</h3>
      <span className='text-grey'>Please enter the amount paid by the customer to change the payment status.</span>
      <span>Total amount: <span style={{color: "#f08d19"}}>₱ {order.TotalAmount}</span></span>
      <img src="" className='img-fluid' alt="" id={order.OrderRef} />
      <div className='input'>
        <label htmlFor="paymentAmountEdit">Amount Customer Payment</label>
        <input
          type="number"
          value={amount}
          id="paymentAmountEdit"
          placeholder='Enter Amount Customer Payment'
          onChange={(e) => {
            handleChange(e)
          }} />
      </div>
      {
        !_.isEmpty(amountE) ? <small className='error-message'>{amountE}</small> : <></>
      }
      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitPaymentStatus(e)
        }}>Save</button>
      </div>

    </div>
  )
}

export default Acceptpayment
