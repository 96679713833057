import _ from "lodash";
import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import PaymentMethodsCard from './PaymentMethodsCard';
const SalesPerPaymentMethods = ({ chartData }) => {
  const [paymentMethods, setPaymentMethods] = useState([])
  useEffect(() => {
    setPaymentMethods(chartData)

    return () => {

    }
  }, [chartData])

  return (
    <ScrollContainer className="analytics-card-list-row">
      {
        _.map(paymentMethods, (value, key) => {
          const { PaymentName, Total, PaymentMode } = value
          return (
            <PaymentMethodsCard key={PaymentMode} text={PaymentName} amount={Total} />
          )
        })
      }
    </ScrollContainer>
  );
};
SalesPerPaymentMethods.defaultProps = {
  chartData: []
}
export default SalesPerPaymentMethods;

