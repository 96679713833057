import _ from 'lodash';
import moment from 'moment-timezone'; 
import {useEffect, useMemo, useState} from 'react';
import css from "./ShippingOption.module.scss" 
import { GetQuotation, ReCreateQuotation, ApplyShipOrder } from '../../EcommerceHelper'
import Swal from 'sweetalert2';
import { courierNotes, quotationDet } from '../OrderUtils';
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback } from "reactstrap"
import { useSelector } from 'react-redux';

const ShippingPlaceOrders = ({ closeModalEdit, setOrder, order,  setCurrentPlaceOrderId, currentPlaceOrderId, getOrderDetail }) => {
  const tokens = useSelector((state) => state.token);
  const { token } = tokens.token
  const jwt = require("jsonwebtoken");
  let user = jwt.decode(token) 

  const [shippingOrder, setShippingOrder] = useState(courierNotes)
  const [otherErrorMessage, setOtherErrorMessage] = useState("")
  
  const [quotation, setQuotation] = useState(quotationDet)
  const PreferredDeliveryDate = order?.PreferredDeliveryDate

  useEffect(() => { 
    getQuotationDetails(order) 
  }, [order])

  const getQuotationDetails = (order) => {
    console.log(order) 
    const quotationid = order.Courier.LalamoveDetails.QuotationId
    GetQuotation(quotationid).then(res => {   
      setQuotation(res?.data)
    }).catch(err => {
      setQuotation(quotationDet)
      console.log(err.response);
    })
  }

  // const swalSuccess = data => {
  //   Swal.fire({
  //     title: "Success",
  //     text: data?.mess,
  //     icon: "success",
  //     confirmButtonText: "Okay",
  //   });
  // };

  // const swalError = data => {
  //   console.log("swalError: ", data?.data[0])
  //   Swal.fire({
  //     title: "Error",
  //     text: data?.data[0] || data?.mess,
  //     icon: "error",
  //     confirmButtonText: "Okay",
  //   });
  // };

  // ====================== Validate and Submit ======================
  let validationInitValue = {
    note_annam: (shippingOrder && shippingOrder.note_annam) || '',
    note_courier: (shippingOrder && shippingOrder.note_courier) || '',
  }

  let validationRule = {  
    note_annam: Yup.string().required("Note to Pao Kitchen is required"),
    note_courier: Yup.string().required("Note to shipper is required") 
  }
  console.log(order)
  // const SendReCreateQuotation = async (order) => {

  //   // Submit Recreate Quotation First >>>>
      
  //   const body = {
  //     "_id" : order?._id,
  //     "data":{
  //       "scheduleAt": order?.PreferredDeliveryDate,
  //       "serviceType": "MOTORCYCLE",
  //       "specialRequests": [
  //           "PURCHASE_SERVICE_4"
  //       ],
  //       "language": "en_PH",
  //       "stops": [
  //           {
  //               "coordinates": {
  //                   "lat": order?.Courier.LalamoveDetails.Stops[0].coordinates.lat,
  //                   "lng": order?.Courier.LalamoveDetails.Stops[0].coordinates.lng
  //               },
  //               "address": order?.Courier.LalamoveDetails.Stops[0].address
  //           },
  //           {        
  //             "coordinates": {
  //               "lat": order?.Courier.LalamoveDetails.Stops[1].coordinates.lat,
  //               "lng": order?.Courier.LalamoveDetails.Stops[1].coordinates.lng
  //           },
  //           "address": order?.Courier.LalamoveDetails.Stops[1].address
  //           }
  //       ],
  //       "isRouteOptimized": true
  //     }
  //   }

  //   const res = await ReCreateQuotation(body) 
  //   if (res.status == "success") {
  //     console.log("Submit Result: ", res) 

  //     try {  
  //       await SendPlaceOrder(res.data, order) 
  //     } catch (error) { 
  //       console.log("Error Res: ", error?.response.data.data[0].message)
  //       setOtherErrorMessage(error?.response.data.data[0].message)
  //     } 
  //   }
  // };
 
  const SendPlaceOrder = (data, order) => { 
    let NewSenderPhone = '+63' + order.Courier.PickupAddress.Phone.substring(1);  
    const body = {
      "_id" : order._id,
      "Status" : 4,
      "StatusNote": [
          {
              "StatusNum":4,
              "Note": order.note_annam,
              "StatusUpdateBy": order.UserName
          }
      ],
      "data": {
          "quotationId": data.quotationId,
          "sender":{
              "stopId": data.stops[0].stopId,
              "name": order.Courier.PickupAddress.Name,
              "phone": NewSenderPhone
          },
          "recipients":[
              {
                  "stopId": data.stops[1].stopId,
                  "name": order.ContactDetails.FullName,
                  "phone": order.ContactDetails.MobileNum,
                  "remarks": order.note_courier
              }
          ],
          "isPODEnabled": true, 
          "isRecipientSMSEnabled": true,
          "partner": "Lalamove Partner 1",
          "metadata": {
              "restaurantOrderId": order.OrderRef,
              "restaurantName": "Pao Kitchen"
          }
      }
    }
    // console.log("Order Body: ", order)
    // console.log("data Body: ", data)

    console.log("Apply Ship Order Body: ", body)
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023", 
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) { 
        ApplyShipOrder(body)
          .then(res => { 
            if (res.status == "success") { 
              Swal.fire('Saved!', '', 'success')
              console.log("ApplyShipOrder: Success")
              closeModalEdit()
              getOrderDetail()
            } 
          })
          .catch(err => { 
            Swal.close()
            // console.log("ApplyShipOrder: ", err?.response.data.data.errors[0].message)
            // setOtherErrorMessage(err?.response.data.data.errors[0].message)
            compressErrorMessage(err) 
          })

      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: validationInitValue,
    validationSchema: Yup.object(validationRule),
    onSubmit: async (values) => {  
      const { UserName } = user  
      const orderBody = {
        ...order,
        UserName,
        note_annam: values.note_annam,
        note_courier: values.note_courier
      }     
      let body = {
        "_id" : order?._id,
        "data":{
          "scheduleAt": order?.PreferredDeliveryDate,
          "serviceType": "MOTORCYCLE",
          "specialRequests": [
              "PURCHASE_SERVICE_4"
          ],
          "language": "en_PH",
          "stops": [
              {
                  "coordinates": {
                      "lat": order?.Courier.LalamoveDetails.Stops[0].coordinates.lat,
                      "lng": order?.Courier.LalamoveDetails.Stops[0].coordinates.lng
                  },
                  "address": order?.Courier.LalamoveDetails.Stops[0].address
              },
              {        
                "coordinates": {
                  "lat": order?.Courier.LalamoveDetails.Stops[1].coordinates.lat,
                  "lng": order?.Courier.LalamoveDetails.Stops[1].coordinates.lng
              },
              "address": order?.Courier.LalamoveDetails.Stops[1].address
              }
          ],
          "isRouteOptimized": true
        }
      }

      let PreferredDeliveryDates = moment(order?.PreferredDeliveryDate).format()
      console.log("orderpredate: ", PreferredDeliveryDates) 

      let today = new Date(); 
      let todayFormated = moment(today).format();
      console.log("todayFormated: ", todayFormated)
      
      if(PreferredDeliveryDates < todayFormated){
        delete body.data.scheduleAt;
      } 
  
      ReCreateQuotation(body)
      .then(res => {  
        console.log("Submit Result: ", res)
        SendPlaceOrder(res.data, orderBody) 
      })
      .catch(err => {  
        compressErrorMessage(err) 
      }) 
      // console.log("Submit Body: ", body)
      // closeModalEdit()
    }
  });

  const compressErrorMessage = err => { 
    if(err?.response.data.data[0].id == "ERR_INVALID_FIELD"){ 
      let errDetailVal = err?.response.data.data[0].detail.split("/") 
      if(errDetailVal[2] == "scheduleAt"){
        setOtherErrorMessage("Delivery Schedule: " + err?.response.data.data[0].message) 
      }else{
        setOtherErrorMessage(err?.response.data.data[0].message) 
      }
    }else{
      setOtherErrorMessage(validatorMessage(err?.response.data)) 
    }
  };

  const validatorMessage = data => { 
    let message = "" 
    let dataval = data.data; 
    var dataBullet = Object.entries(dataval).map(([key, value]) => (
      <li key={key}>{key}: {value}</li>
    ));
    message = <ul>{dataBullet}</ul>  
    return message;
  };
 

  // const submitForm = (e) => {
  //   e.preventDefault()
    
  //   Swal.fire({
  //     title: "Are you sure you want to send this request?",
  //     text: "Please wait while doing your request",
  //     allowOutsideClick: false,
  //     footer: "&#169; Pao Kitchen 2023", 
  //     showDenyButton: true, 
  //     confirmButtonText: 'Save',
  //     denyButtonText: `Don't save`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {  
  //       const Body = {
  //         ...order,
  //         note_annam: shippingOrder.note_annam,
  //         note_courier: shippingOrder.note_courier
          
  //       }

        
        
        
  //     } else if (result.isDenied) {
  //       Swal.fire('Changes are not saved', '', 'info')
  //     }
  //   }) 
  // }
 
  return ( 
    <div><h3>REQUEST ORDER SHIPPING</h3>
      <div className="order-container-right-container" > 
        <div className="order-container-right-container-content"><span>SERVICE: </span><b>{order.Courier.Code}</b></div> 
        <div className={css.content}>
          <div className={css.row}>

            <div className={css.column_50} >
              <div className={css.column_title}>PICK-UP ADDRESS:</div>
              <div className={css.column_indent} >
                <b>Vmart Store</b>
              </div>
              <div className={css.column_indent} >
                <span>{order.Courier.PickupAddress.Address}, {order.Courier.PickupAddress.Place}</span>
              </div>
            </div>

            <div className={css.column_50} >
              <div className={css.column_title}>DESTINATION ADDRESS: </div>
              <div className={css.column_indent} >
                <b>{order.DeliveryDetails.Address}</b>
              </div>
              <div className={css.column_indent} >
                <span>{order.DeliveryDetails.Place}</span>
              </div>
            </div> 

            <div className={css.column_50} >
              <div className={css.column_title}>RIDER DETAILS: </div>
              <div className={css.column_indent} >
                <b> - To be assigned - </b>
              </div> 
            </div> 

            <div className={css.column_50} >
              <div className={css.column_title}>DELIVERY SCHEDULE AT: </div>
              <div className={css.column_indent} >
                <b>                  
                {moment(PreferredDeliveryDate).tz("Asia/Manila").format('yyy-MM-DD hh:mm:ss A')}
                </b>
              </div> 
            </div>  
          </div>    
        </div>
      </div> 

      <div className={css.whitespace}></div>
      <div className="order-container-right-container"> 
        <div className="order-container-right-container-content"><span>QUOTATION DETAILS </span></div>
        <div className={css.content}>
          <div className={css.row}>

            <div className={css.column_50} > 
              <div className={css.column_indent}>Quotation ID: <b>{quotation.quotationId}</b></div>
              <div className={css.column_indent}>Price BreakDown: <b>{quotation.priceBreakdown.total}</b></div>
              <div className={css.column_indent}>Service Type: <b>{quotation.serviceType}</b></div>
            </div>  

          </div>
        </div> 
      </div>

      <div className={css.whitespace}></div>
      <form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false;}}> {/* Form */}
        <div className="order-container-right-container"> 
          <div className="order-container-right-container-content"><span>SHIPPING PLACE ORDER</span></div>
          <div className={css.content}>
            <div className={css.row}> 

              <div className={css.eoc_form__group} > 
                <div className={css.eoc_form__group_label}>Note to Pao Kitchen Order *</div> 
                <div className='input'>
                  <label htmlFor="fullNameEdit">Enter your message to Pao Kitchen</label>
                  <textarea 
                    name="note_annam"  
                    value={validation.values.note_annam || ""}
                    invalid={validation.errors.note_annam && validation.touched.note_annam ? 1 : 0}
                    className={validation.errors.note_annam ? css.error : ""}
                    onChange={(e) => { 
                      setShippingOrder({
                        ...shippingOrder,
                        note_annam: e.target.value
                      })
                     }}
                  ></textarea>
                  {validation.errors.note_annam ? (<FormFeedback type="invalid" className={css.feederror}>{validation.errors.note_annam}</FormFeedback>) : null}
                </div>
              </div> 

              <div className={css.eoc_form__group} > 
                <div className={css.eoc_form__group_label}>Note to courier *</div> 
                <div className='input'>
                  <label htmlFor="fullNameEdit">Enter your message to shipper</label>
                  <textarea 
                    name="note_courier" 
                    value={validation.values.note_courier || ""}
                    invalid={validation.errors.note_courier && validation.touched.note_courier ? 1 : 0}
                    className={validation.errors.note_courier ? css.error : ""}
                    onChange={(e) => { 
                      setShippingOrder({
                        ...shippingOrder,
                        note_courier: e.target.value
                      })
                    }}
                  ></textarea>
                  {validation.errors.note_courier ? (<FormFeedback type="invalid" className={css.feederror}>{validation.errors.note_courier}</FormFeedback>) : null}
                </div>
              </div> 

              <div className={css.column_100} > 
                <div className={css.column_indent}>
                  {otherErrorMessage ? (<p style={{color: "red"}}>{otherErrorMessage}</p>) : null}                  
                </div> 
              </div>   
            </div>
          </div>

          <div className='flex-end gap-10'>
                <button type="button" className='btn-cancel' onClick={() => {
                  closeModalEdit()
                }}>Cancel</button>
                <button className='btn-submit' type="submit">Save</button>
              </div> 
        </div> 
      </form> {/* End of Form */}
    </div>
  )
}

export default ShippingPlaceOrders