// import axios from "axios"

/**
 * A function for analyzing report sentiment
 * @param {Object}   filteredDate     the data object from filtering the desired date
 * @param {String}   comparatorOption the indicator whether for comparing the filter with comparator 
 * @param {Object}   comparatorDate   the data object from the date to compare to against the desired date
 * @return {Object}                   returns an object with the result after sentiment analysis
 */
const sentiment = ( filteredDate, comparatorDate, comparatorOption, platformData ) => {

  // Variables
  // -- Initialize return data structure
  const data = {

    name: 'Platform',
    discountGiven: {
      amount: 0, // amount of said data
      sentiment: 'neutral', // Can be 'positive', 'negative' or 'neutral'
      text: '' // text that is provided from 'texts object' below depending on sentiment and comparator option given
    },
    discountSubtotal: {
      amount: 0,
      sentiment: 'neutral',
      text: ''
    },
    ordersMade: {
      amount: 0,
      sentiment: 'neutral',
      text: ''
    },
    salesMade: {
      amount: 0,
      sentiment: 'neutral',
      text: ''
    },
    shippingCost: {
      amount: 0,
      sentiment: 'neutral',
      text: ''
    }

  }

  // -- All possible sentiment texts
  const texts = {
    day: {
      high: `${filteredDate.to} is higher than yesterday`,
      low: `${filteredDate.to} is lower than yesterday`
    },
    week: {
      high: `${filteredDate.from} to ${filteredDate.to} is higher than the the past week`,
      low: `${filteredDate.from} to ${filteredDate.to} is lower than the past week`
    },
    month: {
      high: `${filteredDate.from} to ${filteredDate.to} is higher than the past month`,
      low: `${filteredDate.from} to ${filteredDate.to} is lower than the past month`
    },
    year: {
      high: `${filteredDate.from} to ${filteredDate.to} is higher than the past year`,
      low: `${filteredDate.from} to ${filteredDate.to} is lower than the past year`
    },
    custom: {
      high: `${filteredDate.from} to ${filteredDate.to} is higher than ${comparatorDate.from} to ${comparatorDate.to}`,
      low: `${filteredDate.from} to ${filteredDate.to} is lower than ${comparatorDate.from} to ${comparatorDate.to}`
    }
  }

  

}

export default sentiment;