import Proptypes from "prop-types";
import {useEffect, useState} from "react";
import moment from "moment";
import css from "./SelectDateRange.module.scss"

const SELECT_TYPE = {
    thisDay: "thisDay",
    thisWeek: "thisWeek",
    thisMonth: "thisMonth",
    thisYear: "thisYear",
    option: "option"
}

const SelectDateRange = ({startDate, endDate, type, onChange}) => {
    const [selectValues, setSelectValues] = useState({
        startDate: moment(),
        endDate: moment(),
        type: SELECT_TYPE.thisDay
    })

    useEffect(() => {
        const _startDate = startDate ? moment.utc(startDate) : moment()
        const _endDate = endDate ? moment.utc(endDate) : moment()

        if(type === SELECT_TYPE.thisDay && (_startDate.isSame(moment(), "date") === false || _endDate.isSame(moment(), "date") === false)) {
            setSelectValues({
                startDate: moment(),
                endDate: moment(),
                type: SELECT_TYPE.thisDay
            })
        }

        if(type === SELECT_TYPE.thisWeek) {
            const diffDay = _endDate.diff(_startDate, "days")
            const isStartWeek = _startDate.isSame(moment().startOf("isoWeek"), "day")
            const isEndWeek = _endDate.isSame(moment().endOf("isoWeek"), "day")

            if(diffDay !== 7 || !isEndWeek || !isStartWeek) {
                setSelectValues({
                    startDate: moment().startOf("week"),
                    endDate: moment().endOf("week"),
                    type: SELECT_TYPE.thisWeek
                })
            }
        }

        if(type === SELECT_TYPE.thisMonth) {
            const diffDay = _endDate.diff(_startDate, "month", false)
            const firstOfMonth = moment().startOf("month")
            const endOfMonth = moment().endOf("month")
            const isStartMonth = _startDate.isSame(firstOfMonth, "day")
            const isEndMonth = _endDate.isSame(endOfMonth, "day")

            if(diffDay !== 1 || !isStartMonth || !isEndMonth) {
                setSelectValues({
                    startDate: firstOfMonth,
                    endDate: endOfMonth,
                    type: SELECT_TYPE.thisMonth
                })
            }
        }

        if(type === SELECT_TYPE.thisYear) {
            const diffDay = _endDate.diff(_startDate, "year", false)
            const firstOfYear = moment().startOf("year")
            const endOfYear = moment().endOf("year")
            const isStartYear = _startDate.isSame(firstOfYear, "day")
            const isEndYear = _endDate.isSame(endOfYear, "day")

            if(diffDay !== 1 || !isStartYear || !isEndYear) {
                setSelectValues({
                    startDate: firstOfYear,
                    endDate: endOfYear,
                    type: SELECT_TYPE.thisYear
                })
            }
        }

        if(type === SELECT_TYPE.option) {
            setSelectValues({
                startDate: _startDate,
                endDate: _endDate,
                type: SELECT_TYPE.option
            })
        }

    }, [startDate, endDate, type])

    const onChangeOption = (e) => {
        const type = e.target.value
        let newValue = {
            ...selectValues,
            type,
        }
        if(type === SELECT_TYPE.thisDay) {
            newValue["startDate"] = moment.utc()
            newValue["endDate"] = moment.utc()
        }

        if(type === SELECT_TYPE.thisWeek) {
            newValue["startDate"] = moment.utc().startOf("isoWeek")
            newValue["endDate"] = moment.utc().endOf("isoWeek")
        }

        if(type === SELECT_TYPE.thisMonth) {
            newValue["startDate"] = moment.utc().startOf("month")
            newValue["endDate"] = moment.utc().endOf("month")
        }

        if(type === SELECT_TYPE.thisYear) {
            newValue["startDate"] = moment.utc().startOf("year")
            newValue["endDate"] = moment.utc().endOf("year")
        }

        setSelectValues({...newValue})
        if(typeof onChange === "function") {
            console.log(newValue)
            onChange(newValue)
        }
    }

    const onChangeDate = (e) => {
        const targetName = e.target.name
        const value = e.target.value
        const newValue = {
            ...selectValues,
            [targetName]: moment(value)
        }
        setSelectValues(newValue)
        if(typeof onChange === "function") {
            onChange(newValue)
        }
    }

    const renderOptions = () => {
        return <div className={css.inputContainer}>
            <label htmlFor="search">Date</label>
            <select className={`${css.Input} ${css.InputSelect}`} value={selectValues.type} onChange={onChangeOption}>
                <option value={SELECT_TYPE.thisDay}>This Day</option>
                <option value={SELECT_TYPE.thisWeek}>This Week</option>
                <option value={SELECT_TYPE.thisMonth}>This Month</option>
                <option value={SELECT_TYPE.thisYear}>This Year</option>
                <option value={SELECT_TYPE.option}>Option</option>
            </select>
        </div>
    }

    const renderInputDate = () => {
        if(selectValues.type === SELECT_TYPE.option) {
            return <div className={css.groupInputSelectDate}>
                <div className={css.inputContainer}>
                    <label htmlFor="search">From</label>
                    <input
                        type="date"
                        name="startDate"
                        value={selectValues.startDate.format("YYYY-MM-DD")}
                        className={css.Input}
                        onChange={onChangeDate}
                    />
                </div>
                <div className={css.inputContainer}>
                    <label htmlFor="search">To</label>
                    <input
                        type="date"
                        name="endDate"
                        value={selectValues.endDate.format("YYYY-MM-DD")}
                        className={css.Input}
                        onChange={onChangeDate}
                    />
                </div>
            </div>
        }

        return null
    }

    return <div className={css.selectDateContainer}>
        {renderOptions()}
        {renderInputDate()}
    </div>
}

SelectDateRange.propTypes = {
    startDate: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
    endDate: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
    type: Proptypes.oneOf([SELECT_TYPE.option, SELECT_TYPE.thisDay, SELECT_TYPE.thisWeek, SELECT_TYPE.thisMonth, SELECT_TYPE.thisYear]),
    onChange: Proptypes.func
}

export default SelectDateRange