import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchIcon from "@mui/icons-material/Search";
import GetAppIcon from '@mui/icons-material/GetApp';
import {Tooltip} from "@mui/material";
import {useState} from "react";
import Proptypes from "prop-types";
import "./filter.scss"

const CustomerListFilter = ({onSearch, onExport}) => {
    const [searchItem, setSearchItem] = useState({
        page: 1,
        limit: 20,
        Email: "",
        ContactNum: "",
        IsActive: "",
        sortDesc: "1",
        sortBy: "spend"
    })

    const clearSearch = () => {
        const defaultSearch = {
            page: 1,
            limit: 20,
            Email: "",
            ContactNum: "",
            IsActive: "",
            sortDesc: "1",
            sortBy: "spend"
        }

        setSearchItem(defaultSearch)
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setSearchItem(oldValue => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }

    const onClickSearch = () => {
        onSearch(searchItem)
    }

    const onClickExport = () => {
        onExport(searchItem)
    }

    return (
        <section className="content-main-search">
            <div className="content-main-search-item auto-width">
                <label htmlFor="search">Email</label>
                <input
                    autoComplete="off"
                    type="text"
                    name="Email"
                    value={searchItem.Email}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                />
            </div>
            <div className="content-main-search-item auto-width">
                <label htmlFor="search">Phone</label>
                <input
                    autoComplete="off"
                    type="text"
                    name="ContactNum"
                    value={searchItem.ContactNum}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                />
            </div>
            <div className="content-main-search-item auto-width">
                <label>Sort by</label>
                <select name="sortBy"
                        value={searchItem.sortBy}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                >
                    <option value="UpdatedAt">Updated At</option>
                    <option value="spend">Spend</option>
                    <option value="CreatedAt">Created At</option>
                </select>
            </div>
            <div className="content-main-search-item auto-width">
                <label>Sort</label>
                <select name="sortDesc"
                        value={searchItem.sortDesc}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                >
                    <option value="1">DESC</option>
                    <option value="0">ASC</option>
                </select>
            </div>
            <Tooltip title="Submit Filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5" onClick={onClickSearch} >
                    <SearchIcon/>
                </button>
            </Tooltip>
            <Tooltip title="Clear Filter" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5" onClick={clearSearch}>
                    <AutorenewIcon/>
                </button>
            </Tooltip>
            <Tooltip title="Export result" placement="bottom-start" arrow>
                <button className="flex-row-center-center gap-5" onClick={onClickExport}>
                    <GetAppIcon/>
                </button>
            </Tooltip>
        </section>
    )
};

CustomerListFilter.propTypes = {
    onSearch: Proptypes.func
}

export default CustomerListFilter;
