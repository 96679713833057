 import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Pagination } from '@mui/material';
import React, {useEffect, useState} from "react";
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { countPage, IdSelector } from '../../ProductManager/productHelper'; 
import SearchFilterShippingTransaction from './Filter/SearchFilterShippingTransaction';
import ShippingTransactionTable from './ShippingTransactionTable';

import { GetShippingTransactionList, GetWalletBalance } from "../EcommerceHelper";
import ShippingTransactionExport from "./ShippingTransactionExport";

const ShippingTransactionList = () => {
  useTitle("Pao Kitchen | Shipping Transaction")

  const [orderList, setOrderList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [shippingSearch, setShippingSearch] = useState("")
  const [searchValues, setSearchValues] = useState({})
  const [walletBalance, setWalletBalance] = useState(0)

  useEffect(() => {
    getShippingTransactionList(1, "", {}) 
  }, []);

  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getShippingTransactionList(p, shippingSearch, searchValues)
  }

  const getShippingTransactionList = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2022",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setOrderList([])
    setPageCount(0)
    GetShippingTransactionList(page, search, searchValue)
      .then(res => {
        Swal.close()
        // console.log("order_base_info: ", res.data.order_base_info)
        const { order_base_info, page_size, record_total } = res?.data
        // const { total, limit } = metadata;
        setPageCount(countPage(record_total, page_size))
        setOrderList(order_base_info)
      })
      .catch( err => {
        // console.log("err: ", err)
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "No Transaction(s) found!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          });
          setPageCount(0)
        }
      )

      GetWalletBalance()
      .then(res => {
        const { avl_balance_fen } = res?.data
        let formatedBal = (avl_balance_fen / 100)
        setWalletBalance(formatedBal)
      })
      .catch( err => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Wallet Balance(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
        setWalletBalance(0)
      })
  } 
 

  const shippingFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    // console.log("searchValue: ", searchValue)
    getShippingTransactionList(1, search, searchValue)
    setShippingSearch(search)
    setSearchValues(searchValue)
  } 
  // console.log("searchValues: ", searchValues)

  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("orderTable").style.scrollBehavior = "smooth";
        IdSelector("orderTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("orderTable").style.scrollBehavior = "smooth";

        IdSelector("orderTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };

   
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / shipping transactions
      </section>
      <SearchFilterShippingTransaction {...{ shippingFilter, walletBalance }}/>
      <ShippingTransactionTable {...{ orderList }} />
      <ShippingTransactionExport {...{ searchValues }}/>
      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }

      <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}
      >
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>
    </main>
  )
}

export default ShippingTransactionList