import { Backdrop, CircularProgress, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';

const LoadingSpinner = ({ loaderOpen, loaderClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      open={loaderOpen}
      onClose={(e) => { loaderClose(e) }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998
      }}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={loaderOpen}>
        <Box sx={{ ...style, maxWidth: 400, width: "fit-content" }}>
          <div method="post" className="flex-column width-100 gap-10">
            <CircularProgress disableShrink />
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}

export default LoadingSpinner