// Components and Core
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import moment from 'moment/moment';
import TextField from '@mui/material/TextField';

// Styling and Icons
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/RestartAlt';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import RemoveIcon from '@mui/icons-material/Remove';
import './styles.scss'
import sentiment from './Functions/sentiment';


const PlatformFilter = ( props ) => {

  const {

    setPlatformItemsData,
    platformOptions

  } = props.props

  // Taking our year now and minus 1 (ex: 2022 - 1)
  const yearBefore = (parseInt(moment().format('YYYY')) - 1).toString()
  
  // Variables
  const [displayDate, setDisplayDate] = useState(moment().format('ll (dddd)'))
  const [comparatorOption, setComparatorOption] = useState('day')
  const [selectPlatform, setSelectPlatform] = useState('all')
  const [isMoreOptions, setIsMoreOptions] = useState(false)

  // -- Date Input Variables
  const [filteredDate, setFilteredDate] = useState({
    from: moment().format('YYYY-01-01'),
    to: moment().format('YYYY-MM-DD')
  })
  const [comparatorDate, setComparatorDate] = useState({
    from: moment().format(`${yearBefore}-01-01`),
    to: moment().format(`${yearBefore}-12-31`)
  })

  // Function
  // -- Filter/Search Button
  const filter = () => {

    // Variables
    // -- Format of every moment being made
    const format = 'YYYY-MM-DD'
    const today = moment().format(format)

    // -- Initialize return data structure
    const data = {
      filteredDate: {
        from: filteredDate.from,
        to: filteredDate.to
      },
      comparatorDate: {
        from: comparatorDate.from,
        to: comparatorDate.to
      }
    }
    
    if (!isMoreOptions) {

      data.filteredDate = {
        from: today,
        to: today,
      }

      switch (comparatorOption) {

        case "day":
          data.comparatorDate = {
            from: moment().subtract(1, 'day').format(format),
            to: moment().subtract(1, 'day').format(format),
          }
          break;
        
        case "week":
          data.comparatorDate = {
            from: moment().subtract(1, 'week').format(format),
            to: moment().subtract(1, 'day').format(format),
          }
          break;
        
        case "month":
          data.comparatorDate = {
            from: moment().subtract(1, 'month').format(format),
            to: moment().subtract(1, 'day').format(format),
          }
          break;
          
        
        case "year":
          data.comparatorDate = {
            from: moment().subtract(1, 'year').format(format),
            to: moment().subtract(1, 'day').format(format),
          }
          break;
        default: break;
      }

    } else {

      switch (comparatorOption) {

        case "day":
          data.comparatorDate = {
            from: moment(filteredDate.from).subtract(1, 'day').format(format),
            to: moment(filteredDate.from).subtract(1, 'day').format(format),
          }
          break;
        
        case "week":
          data.comparatorDate = {
            from: moment(filteredDate.from).subtract(1, 'week').format(format),
            to: moment(filteredDate.from).subtract(1, 'day').format(format),
          }
          break;
        
        case "month":
          data.comparatorDate = {
            from: moment(filteredDate.from).subtract(1, 'month').format(format),
            to: moment(filteredDate.from).subtract(1, 'day').format(format),
          }
          break;
        
        case "year":
          data.comparatorDate = {
            from: moment(filteredDate.from).subtract(1, 'year').format(format),
            to: moment(filteredDate.from).subtract(1, 'day').format(format),
          }
          break;
        default: break;
          
      }

    }


    let filteredFrom = moment( data.filteredDate.from ).format( 'YYYY' )
    let filteredTo = moment( data.filteredDate.to).format('YYYY')
    let comparatorFrom = moment( data.comparatorDate.from ).format( 'YYYY' )
    let comparatorTo = moment( data.comparatorDate.to ).format( 'YYYY' )

    // If the same year - FilteredDate
    if ( filteredFrom === filteredTo ) {
      
      filteredFrom =  moment( data.filteredDate.from ).format( 'MM' )
      filteredTo = moment( data.filteredDate.to ).format( 'MM' )
      
      // If the same month
      if ( filteredFrom === filteredTo ) {
        
        filteredFrom =  moment( data.filteredDate.from ).format( 'DD' )
        filteredTo = moment( data.filteredDate.to ).format( 'DD' )

        // If the same day
        if ( filteredFrom === filteredTo ) {
          
          filteredFrom =  moment( data.filteredDate.from ).format( 'MMMM DD YYYY' )
          filteredTo = ""

        } else {

          filteredFrom =  moment( data.filteredDate.from ).format( 'MMMM DD' )
          filteredTo = moment( data.filteredDate.to ).format( ' - DD YYYY' )

        }


      } else {

        filteredFrom =  moment( data.filteredDate.from ).format( 'MMMM DD' )
        filteredTo = moment( data.filteredDate.to ).format( ' - MMMM DD YYYY' )

      }

    } else {

      filteredFrom =  moment( data.filteredDate.from ).format( 'MMMM DD YYYY' )
      filteredTo = moment( data.filteredDate.to ).format( '- MMMM DD YYYY' )

    }

    // If the same year - ComparatorDate
    if ( comparatorFrom === comparatorTo ) {
      
      comparatorFrom =  moment( data.comparatorDate.from ).format( 'MM' )
      comparatorTo = moment( data.comparatorDate.to ).format( 'MM' )
      
      // If the same month
      if ( comparatorFrom === comparatorTo ) {
        
        comparatorFrom =  moment( data.comparatorDate.from ).format( 'DD' )
        comparatorTo = moment( data.comparatorDate.to ).format( 'DD' )

        // If the same day
        if ( comparatorFrom === comparatorTo ) {
          
          comparatorFrom =  moment( data.comparatorDate.from ).format( 'MMMM DD YYYY' )
          comparatorTo = ""

        } else {

          comparatorFrom =  moment( data.comparatorDate.from ).format( 'MMMM DD' )
          comparatorTo = moment( data.comparatorDate.to ).format( ' - DD YYYY' )

        }


      } else {

        comparatorFrom =  moment( data.comparatorDate.from ).format( 'MMMM DD' )
        comparatorTo = moment( data.comparatorDate.to ).format( ' - MMMM DD YYYY' )

      }

    } else {

      comparatorFrom =  moment( data.comparatorDate.from ).format( 'MMMM DD YYYY' )
      comparatorTo = moment( data.comparatorDate.to ).format( '- MMMM DD YYYY' )

    }
    
    if ( comparatorOption === 'custom' ) setDisplayDate( `(${comparatorOption})\xa0${comparatorFrom}${comparatorTo} \xa0\xa0\xa0\xa0\xa0\xa0\xa0 VS \xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${filteredFrom}${filteredTo}` )
    else setDisplayDate( `(past ${comparatorOption})\xa0${comparatorFrom}${comparatorTo} \xa0\xa0\xa0\xa0\xa0\xa0\xa0 VS \xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${filteredFrom}${filteredTo}` )

    return data

  }

  useEffect(() => {

    const data = filter()
    const sentimentData = sentiment( data.filteredDate, data.comparatorDate, comparatorOption )
    setPlatformItemsData( sentimentData )

  }, [])
  

  return <>
    <Row className='filter-container'>
      
      {/* Overview */}
      <Col xs={12}>
        <span className="overview">
          {displayDate}
        </span>
      </Col>

      {/* Filter Title */}
      <Col xs={12} className="overview-title-container">
        <span className='overview-title'>Filter</span>
        <span className='overview-title-space'></span>

        {/* Additional Options - isMoreOptions == true */}
        {isMoreOptions ?
          <>
            <span className='overview-title-date'>From</span>
            <span className='overview-title-date'>To</span>
          </>
          : <></>
        }

        {/* Additional Options - Custom Comparator */}
        {comparatorOption === 'custom' ?
          <>
            <span className='overview-title-date'>Compare start</span>
            <span className='overview-title-date'>Compare end</span>
          </>
          : <></>
        }
        
      </Col>

      {/* Filter */}
      <Col xs={12}>
        <section className="content-main-search">
          <form onSubmit={e => {

          }} className="content-main-search-item">
            
            {/* Date */}
            <select
              className='input-filter'
              name="status"
              id="status"
              value={comparatorOption}
              onChange={e => setComparatorOption(e.target.value)}
            >
              <option disabled> </option>
              <option value="disabled" disabled>Comparator</option>
              <option disabled> &#x2500;&#x2500;&#x2500;&#x2500;&#x2500; </option>

              <option value="day">Yesterday</option>
              <option value="week">Past Week</option>
              <option value="month">Past Month</option>
              <option value="year">Past Year</option>

              {/* Additional Options - isMoreOptions == true */}
              {isMoreOptions ? <option value="custom">Custom</option>:<option disabled>Custom</option>}

              <option disabled> </option>
            </select>

            {/* Platform */}
            <select
              className='input-filter'
              name="status"
              id="status"
              value={selectPlatform}
              onChange={e => setSelectPlatform(e.target.value)}
            >
              <option disabled> </option>

              <option value="disabled" disabled>Platform</option>
              <option disabled> &#x2500;&#x2500;&#x2500;&#x2500;&#x2500; </option>

              <option value="all">- All -</option>
              {platformOptions}

              <option disabled> </option>
            </select>

            {/* Additional Options - isMoreOptions == true */}
            {isMoreOptions ?
              <>
                <TextField
                  type="date"
                  value={filteredDate.from}
                  sx={{ padding: 0, width: 170 }}
                  onChange={e => setFilteredDate({
                    ...filteredDate,
                    from: e.target.value
                  })}
                />
                <TextField
                  type="date"
                  value={filteredDate.to}
                  sx={{ padding: 0, width: 170 }}
                  onChange={e => setFilteredDate({
                    ...filteredDate,
                    to: e.target.value
                  })}
                />
              </>
              : <></>
            }

            {/* Additional Options - Custom Comparator */}
            {comparatorOption === 'custom' ?
              <>
                <TextField
                  type="date"
                  value={comparatorDate.from}
                  sx={{ padding: 0, width: 170 }}
                  onChange={e => setComparatorDate({
                    ...comparatorDate,
                    from: e.target.value
                  })}
                />
                <TextField
                  type="date"
                  value={comparatorDate.to}
                  sx={{ padding: 0, width: 170 }}
                  onChange={e => setComparatorDate({
                    ...comparatorDate,
                    to: e.target.value
                  })}
                />
              </>
              : <></>
            }

            {/* More Options Icon */}
            <button className="gap-5" onClick={e => {
              e.preventDefault()
              setIsMoreOptions(!isMoreOptions)
              if (isMoreOptions) setComparatorOption('day')
            }}>
              {isMoreOptions ? <RemoveIcon/> : <SettingsSuggestIcon />}
            </button>

            {/* Search Icon */}
            <button className="gap-5" onClick={e => {
              e.preventDefault()
              const data = filter()
              const sentimentData = sentiment(data.filteredDate, data.comparatorDate, comparatorOption)
              setPlatformItemsData(sentimentData)
            }}>
              <SearchIcon />
            </button>
            
            {/* Reset Filter Icon */}
            <button className="gap-5" onClick={e => {
              e.preventDefault()
              if (comparatorOption !== 'custom')setComparatorOption('day')
              setSelectPlatform('all')
              setFilteredDate({
                from: moment().format('YYYY-01-01'),
                to: moment().format('YYYY-MM-DD'),
              })
              setComparatorDate({
                from: moment().format(`${yearBefore}-01-01`),
                to: moment().format(`${yearBefore}-12-31`),
              })
            }}>
              <ResetIcon />
            </button>
            
          </form>
        </section>
      </Col>

    </Row>
  </>

}

export default PlatformFilter