import { t } from 'i18next'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTitle } from 'react-use'
import Swal from 'sweetalert2'
import { SubmitForgotPassword } from './PublicHelper'

const ForgotPass = () => {
  useTitle("Pao Kitchen | Forgot password")
  const [email, setEmail] = useState("")
  const [emailE, setEmailE] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const submitForgotPassword = (e) => {
    e.preventDefault()
    setSubmitLoading(true)
    if (_.isEmpty(email)) {
      setEmailE("Email field must not be empty")
      setSubmitLoading(false)
    } else {
      setEmailE("")
      SubmitForgotPassword(email)
        .then(res => {
          setSubmitLoading(false)
          Swal.fire({
            icon: "success",
            title: "Successful!",
            text: res?.mess,
            footer: "&#169; Pao Kitchen 2023"
          })
        })
        .catch(
          err => {
            setSubmitLoading(false)
            const { data } = err?.response?.data
            setEmailE(data?.Email)
          }
        )
    }
  }
  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }
  return (
    <div className='forgot-password'>
      <div className='forgot-password-container'>
        <form onSubmit={(e) => {
          submitForgotPassword(e)
        }} method="post" aria-label='forgot password form'>
          <h1 aria-label='forgot password title'>{
            t('forgotpasswordtitle')
          }</h1>
          <div className='input'>
            <label htmlFor="emailAddress" aria-label='forgot password email address label'>
              {t('emailaddress')}
            </label>
            <input type="email" onChange={(e) => handleChange(e)} name="email" value={email} id="emailAddress" placeholder={t('pleaseenteremail')} aria-label='forgot password input for email' />
          </div>
          {
            _.isEmpty(emailE) ? (<></>) : (<small className='error-message'>{emailE}</small>)
          }
          <button disabled={submitLoading} type="submit" aria-label='forgot password button for submission'> {submitLoading ? (<div className='loader'></div>) : (<>{t('submit')}</>)}</button>
        </form>
        <small className='copyright'>&#169; Pao Kitchen 2023</small>
      </div>
    </div>
  )
}

export default ForgotPass