import Navigation from "./Navigation";
import OrderInfo from "./OrderInfo";
import SelectOrder from "./SelectOrder";
import Proptypes from "prop-types";
import {orderDetails} from "../../../Order/OrderUtils";
import css from "../../OrderDineIn.module.scss"

const Header = (props) => {
    const order = props.orderSelected?.order ?? orderDetails
    return (
        <>
            <Navigation/>
            <div className={css.groupSelectOrderInfo}>
                <SelectOrder onSelect={props.onChangeOrder} value={props.orderSelected} />
                <OrderInfo order={order} />
            </div>
        </>
    )
}

Header.propTypes = {
    onChangeOrder: Proptypes.func,
    orderSelected: Proptypes.any
}

export default Header