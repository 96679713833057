import Proptypes from "prop-types";
import css from "./Cart.module.scss"
import {useTranslation} from "react-i18next";
import QuantityInput from "../QuantityInput";

const ProductCart = (props) => {
    const {productOrderDetail} = props
    const {t} = useTranslation()
    // const currentLang = getCurrentLang()

    const {Price, Quantity, ProductName, ProductId} = productOrderDetail ?? {}
    const ExtraQuantity = productOrderDetail?.ExtraQuantity ?? 0

    // const imgError = (e) => {
    //     e.preventDefault()
    //     image.current.src = placeholderImage
    //     return true;
    // }

    const displayProductName = () => {
        //default EN
        // const name = ProductName[currentLang] ?? ""
        const nameEN = ProductName["EN"] ?? ""
        // if(!name && !nameEN){
        //     for (const outputElement in ProductName) {
        //         if(ProductName[outputElement]){
        //             return ProductName[outputElement]
        //         }
        //     }
        // }

        return nameEN
    }

    const displayProductVNName = () => {
        return ProductName["VN"] ?? ""
    }

    const quantityInput = () => {
        return <QuantityInput quantity={ExtraQuantity} setQuantity={(quantity) => {
            props.updateExtraQuantity(ProductId, quantity)
        }} min={0}/>
    }

    const renderQuantity = () => {
        if (Quantity <= 0 && ExtraQuantity) {
            return (
                <div className={css.quantity}>
                    <div className={css.quantityInputWrapper}>
                        <span className={css.newOrderTitle}>{t("newOrder")}: </span>
                        <div className={css.quantityInput}>
                            {quantityInput()}
                        </div>
                    </div>
                </div>
            )
        }

        if (Quantity > 0 && ExtraQuantity <= 0) {
            return (
                <div className={css.quantity}>
                    <span className={css.quantityOrdered}>{t("ordered")}: <span className={css.highlightOrdered}>{Quantity}</span></span>
                </div>
            )
        }

        if (Quantity > 0 && ExtraQuantity > 0) {
            return (
                <div className={css.quantity}>
                    <span className={css.quantityOrdered}>{t("ordered")}: <span className={css.highlightOrdered}>{Quantity}</span></span>
                    <div className={css.quantityInputWrapper}>
                        <span className={css.newOrderTitle}>{t("orderMore")}: </span>
                        <div className={css.quantityInput}>
                            {quantityInput()}
                        </div>
                    </div>
                </div>
            )
        }
    }

    const ProductCartContent = () => {
        if (ExtraQuantity <= 0 && Quantity <= 0) {
            return null
        }

        return (
            <div className={css.productContainer}>
                {/*<img*/}
                {/*    ref={image}*/}
                {/*    src={ProdImage || placeholderImage}*/}
                {/*    onError={(e) => imgError(e)}*/}
                {/*    className={css.image}*/}
                {/*    alt={displayProductName()}*/}
                {/*/>*/}
                <div className={css.infoWraper}>
                    <p className={css.productVNName}>{displayProductVNName()}</p>
                    <p className={css.productName}>{displayProductName()}</p>
                    <p className={css.productPrice}>₱{Price}</p>
                    {renderQuantity()}
                </div>
            </div>
        )
    }

    return ProductCartContent()
}

ProductCart.propTypes = {
    productOrderDetail: Proptypes.object,
    updateExtraQuantity: Proptypes.func
}

export default ProductCart