import React from 'react';
import SendOptionC from './SendOptionC';

const SendOptionContainer = ({ SendOption, handleChange, currentStatus }) => {
  return (
    <div className="order-container-right-container-content">
      <SendOptionC
        SendOption={SendOption}
        handleChange={handleChange}
        disabled={currentStatus>=6}
        id="delivery"
        value="DELIVERY"
        name="SendOption"
        text="Delivery"
      />
      <SendOptionC
        SendOption={SendOption}
        handleChange={handleChange}
        disabled={currentStatus>=6}
        id="pickup"
        value="PICKUP"
        name="SendOption"
        text="Pick Up"
      />
      {/* <SendOptionC
        SendOption={SendOption}
        handleChange={handleChange}
        disabled={currentStatus>=6}
        id="dinein"
        value="DINEIN"
        name="SendOption"
        text="Dine In"
      /> */}
      <SendOptionC
        SendOption={SendOption}
        handleChange={handleChange}
        disabled={currentStatus>=6}
        id="ktv"
        value="KTV"
        name="SendOption"
        text="KTV"
      />
    </div>
  );
};

export default SendOptionContainer;
