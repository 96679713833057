import React, {useEffect, useState, useRef} from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Pusher from "pusher-js";
import {useSelector} from "react-redux";
import _ from 'lodash';
import {getListEventPusher_Public} from "../../helpers/pusherHelper";
import { GetPublicProductTemp } from "./PublicHelper";

const ProductNotification = ({ search, showProductItemDetails }) => {
  const [productNotif, setProductNotif] = useState([{
    ProductName: "",
    Images: "",
    Price: ""
  }])
  const [index, setIndex] = useState(0);
  // const cmsConfigPusher = useSelector((state) => state.cmsConfigPusher.configs); 
  const myInterval = useRef(); 
  let branchid = localStorage.getItem("publicBranch");
  const [producttemp, setProductTemp] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // useEffect(() => {  
  //   if(!_.isEmpty(producttemp)){
  //     StartNotification();
  //   }    
  // }, []);
 
  useEffect( async () => {
    const pusherEventRegister = [] 
    const events = await getListEventPusher_Public()
    // console.log("cmsConfigPusher----: ", events)
    events.forEach(event => {
      // console.log("event----: ", event)
      if (event?.appKey) {
        const eventNames = event.events
        eventNames.forEach(eventName => {
          // console.log("eventNames----: ", eventNames)
          if (eventName === "PUSHER_EVENT_REFRESH_LIST_TASK") {
            const pusher = new Pusher(event?.appKey, {
              cluster: event?.cluster,
              encrypted: true
            });
            const channel = pusher.subscribe(event?.channel);

            channel.bind(eventName, async () => {
              setIndex(0);
              await getOrderProducts()   
              // console.log("channel: ", channel)
            });
            // console.log("channel: ", channel)
            // console.log("pusher: ", pusher)

            pusherEventRegister.push(pusher)
          }
        })
      }
    })

    return () => {
      pusherEventRegister.forEach(pusher => {
        if (pusher instanceof Pusher) {
          pusher.unbind_all()
          pusher.disconnect()
        }
      })
    } 
  }, [])

  useEffect( async () => {
    await getOrderProducts()
  }, [])

  const getOrderProducts = async() => { 
    const FetchProducts = await GetPublicProductTemp(branchid) 
    if(!_.isEmpty(FetchProducts)){
      setProductTemp(FetchProducts.data) 
      getOrderProductLogs(FetchProducts.data)
    } 
  }

  const getOrderProductLogs = (data) => {   
    
    console.log("dataxxxx: ", data)
    let ProductArray = []
    if(!_.isEmpty(data)){
      _.forEach(data, (value, key) => {
        let ProductName = (localStorage.getItem("language") === "VN") ? value.Product.Name.VN : value.Product.Name.EN;
        ProductArray.push({
          ProductName: ProductName,
          Images: value.Product.Images.Delivery,
          Price: value.Price.Regular
        }) 
      })
    }
    console.log("ProductArray: ", ProductArray)
    // return ProductArray; 
    StartNotification(ProductArray)
  }
 
  const StartNotification = (ProductArray = '') => { 
    if(myInterval.current) {
      clearInterval(myInterval.current)
    }
    myInterval.current = setInterval(() => {
        setIndex((counter) => counter + 1)  
      },6000
    );
    console.log("ProductArray: ", ProductArray)
    setProductNotif(ProductArray) 
    setIsOpen(true)
  }

  if(index === (producttemp.length)){ 
    clearInterval(myInterval.current); 
    myInterval.current = null;   
  }   

  const showProductDetails = (e, product) => {
    e.preventDefault() 
    showProductItemDetails(e, product)
    
  }

  const closeProduct = () => {      
    setIsOpen(false)
    myInterval.current = null;  
  }

  const titleProduct = () => {
    let Language = '';
    if(localStorage.getItem("language") === "VN"){
      Language = "Một khách hàng đã mua";
    }else{
      Language = "A Custmer Bought";
    }
    return Language;
  }

  return (
    <>
    {(myInterval.current === null) ? 
      (<></>) : (
        <div 
          className="note info slide-in" 
        >
            <div className="note-icon"
              onClick={(e) => { showProductDetails(e, producttemp[index % productNotif.length]) }}
            >
              <img src={productNotif[index % productNotif.length].Images} className="clip" width="100" height="74" />
            </div>
            <div onClick={(e) => { showProductDetails(e, producttemp[index % productNotif.length]) }}>
              <h4>{titleProduct()}</h4>
              <h5>{productNotif[index % productNotif.length].ProductName}</h5>
              <span>₱{productNotif[index % productNotif.length].Price}</span>
            </div>  
            <div className="close"
              onClick={(e) => { closeProduct() }}
            ><CancelIcon className="fa"></CancelIcon></div>
        </div>
      )
    } 
    </>
  );
};

export default ProductNotification;
