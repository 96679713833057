import _ from 'lodash';
import moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import React, { useCallback, useEffect, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useReactToPrint } from 'react-to-print';
import Logo from './../../../../components/reusable/Logo';
const PrintPOS = ({ closeModalEdit, order, paymentMethod, computeAddedFee }) => {
  const componentRef = useRef(null);

  const handleAfterPrint = useCallback(() => {
    closeModalEdit()
  }, [closeModalEdit]);

  const handleOnBeforeGetContent = useCallback(() => {


  }, []);
  const handleBeforePrint = useCallback(() => {

  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${order.OrderRef}`,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onBeforeGetContent: handleOnBeforeGetContent,
  });
  useEffect(() => {


    handlePrint()
    return () => {
      handlePrint()
    }
    //eslint-disable-next-line
  }, [handlePrint, computeAddedFee])
  const { ShippingAmount, OrderAmount, OrderDetails, CreatedAt, ContactDetails, OrderRef, DeliveryDetails, PreferredDeliveryDate, OrderNote, TotalAmount } = order

  return (
    <div className='forPrint' ref={componentRef}>
      <div className='flex-row-center-center'>
        <div className='logo'>
          <Logo swidth={120} sheight={120} />
        </div>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-30'>
        <span><b>Date:</b> {moment(CreatedAt).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
        <span><b>Order:</b> {OrderRef}</span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-30'>
        <span className='name'><b>Customer Name:</b> {ContactDetails?.FullName}</span>
        <span><b>Phone:</b> {ContactDetails?.MobileNum}</span>
        <span><b>Address:</b> {DeliveryDetails?.Address}</span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Shipping Time:</b> {moment(PreferredDeliveryDate).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
        <span><b>Payment Via:</b> {paymentMethod ?? ""}</span>
        <span><b>Note:</b> {OrderNote}</span>
      </div>
      <div className='table margin-bottom-15'>
        <div className="flex-row-start table-header margin-bottom-10">
          <div className='name'>Q.ty</div>
          <div className='name text-center'>Name</div>
          <div className='price'>Price</div>
        </div>
        <div>

          <Table className='striped bordered' >
            <tbody>



          {
            _.isEmpty(OrderDetails) ? (
              <div className="flex-row-center-center table-body">
                <span>No Products Found</span>
              </div>
            ) :
              (<>
                {
                  _.map(OrderDetails, (value, key) => {
                    const { Quantity, ProductName, TotalPrice } = value
                    return (

                            <tr key={key} >

                              <td >x{Quantity || 0}</td>
                              <td >{ProductName.VN || ProductName.EN } <br/>
                                <small >{ProductName.EN || ProductName.VN}</small>
                                <br/>
                              </td>
                              <td className='price' >
                                <CurrencyFormat
                                    value={TotalPrice ?? 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'₱ '}
                                />
                              </td>
                            </tr>




                    )
                  })
                }
              </>)
          }

            </tbody>
          </Table>


        </div>
      </div>
      <div className='table table-header margin-bottom-40'>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Received By:</b></span>
      </div>
      <div className='flex-column-start gap-5 margin-bottom-20'>
        <span><b>Issued By:</b></span>
        <div className='flex-column-start'>
          <span>
            <b>Added fee: </b>
            <CurrencyFormat
              value={computeAddedFee(order.AddFee, order.OrderAmount).totalAddedFee ?? 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₱ '}
            />
          </span>
          {
            !_.isEmpty(order.AddFee) ? _.map(order.AddFee, (value) => {
              const { FeeValue, Name, Type } = value
              return (<small className='tab-5'>
                <b>{Name}: </b>
                <CurrencyFormat
                  value={FeeValue ?? 0}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={Type === "fixed" ? '₱ ' : ''}
                  suffix={Type === "percent" ? '%' : ''}
                />
              </small>)
            }) : (<></>)
          }

        </div>
        <span><b>Shipping Fee : </b>
          <CurrencyFormat
            value={ShippingAmount ?? 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        </span>
        <span><b>Subtotal : </b>
          <CurrencyFormat
            value={OrderAmount ?? 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        </span>
        <span><b>Total:</b>
          <CurrencyFormat
            value={TotalAmount ?? 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₱ '}
          />
        </span>
      </div>
      <span>Thank you for shopping with us!</span>
    </div >
  )
}

export default PrintPOS