import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import { HideItem } from '../../ProductManager/productHelper';

const AddBankForm = ({ refreshTable, addBank }) => {

  const [bank, setBank] = useState({
    BankName: "",
    Account: "",
    Number: "",
    UrlLogin: "",
    IsActive: true
  })
  const [bankError, setBankError] = useState({
    BankName: "",
    Account: "",
    Number: "",
    UrlLogin: "",
    IsActive: true
  })
  const refreshAll = () => {
    setBank({
      BankName: "",
      Account: "",
      Number: "",
      UrlLogin: "",
      IsActive: true
    })
    setBankError({
      BankName: "",
      Account: "",
      Number: "",
      UrlLogin: "",
      IsActive: true
    })
  }
  const submitBank = (e) => {
    e.preventDefault()
    addBank(e, bank);
    HideItem("#addBankForm")
    refreshAll()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setBank({
      ...bank,
      [name]: value
    })
  }

  const { BankName, Account, Number, UrlLogin } = bank
  return (
    <div className="add-shipping-area" id='addBankForm'>
      <div className="add-shipping-area-container">
        <div className="add-shipping-area-container-header">
          <h3 className="add-shipping-area-container-header-title">
            {' '}
            Add Bank{' '}
          </h3>
          <button className="close-shipping-modal" onClick={(e) => {
            e.preventDefault()
            HideItem("#addBankForm")
          }}>
            <ClearIcon />
          </button>
        </div>
        <form onSubmit={(e) => { submitBank(e) }}>
          <div className="form">
            <div className="form-container column">
              <label htmlFor="Code">Bank Name</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="BankName" value={BankName} id="Bank Name" placeholder='VietComBank' />
              <small className='error-message'>{bankError.BankName}</small>
            </div>
            <div className="form-container column">
              <label htmlFor="Code">Account</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="Account" value={Account} id="Account" placeholder='Nguyen Van A' />
              <small className='error-message'>{bankError.Account}</small>
            </div>
            <div className="form-container column">
              <label htmlFor="Code">Account Number</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="Number" value={Number} id="Account Number" placeholder='67899999xxx' />
              <small className='error-message'>{bankError.Number}</small>
            </div>
            <div className="form-container column">
              <label htmlFor="Code">URL Login</label>
              <input onChange={(e) => { handleChange(e) }} type="text" name="UrlLogin" value={UrlLogin} id="URL Login" placeholder='https://vcbdigibank.vietcombank.com.vn/' />
              <small className='error-message'>{bankError.UrlLogin}</small>
            </div>

            <div className="form-container row-right">
              <button className="btn-submit" type="submit">
                ADD BANK
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBankForm;