import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import React, { useEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
const ShippingOptionTable = ({ shippingOptionList, showShippingOptionUpdate }) => {
  const container = useRef(null)
  useEffect(() => {
    container.current.getElement().id = "shippingOptionTable"
    return () => {
    }
  }, [])

  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start">Shipping option</div>
        <div className="content-product-table-head-item normal-start">Shipping code</div>
        <div className="content-product-table-head-item big-item">show on checkout page?</div>
        <div className="content-product-table-head-item big-item">show on cms selection</div>
        <div className="content-product-table-head-item center">Date Created</div>
        <div className="content-product-table-head-item center">Action</div>
      </div>
      {
        shippingOptionList.length ? (
          Object.keys(shippingOptionList).map((key, index) => {
            const { _id, CmsStatus, CreatedAt, IsActive, ShipiCode, ShippingName } = shippingOptionList[key]
            let DateCreated = moment(CreatedAt).format("YYYY-MM-DD HH:MM:SS");
            return (
              <div className="content-product-table-data" key={index}>
                <div className="content-product-table-data-item normal-start shipping-name">
                  {
                    ShippingName
                  }
                </div>
                <div className="content-product-table-data-item normal-start">
                  {
                    ShipiCode
                  }
                </div>
                <div className="content-product-table-data-item big-item">
                  {IsActive ? (
                    <span>
                      <CircleIcon sx={{ color: "green", fontSize: "12px" }} />{" "}
                      Active
                    </span>
                  ) : (
                    <span>
                      <CircleIcon sx={{ color: "red", fontSize: "12px" }} />{" "}
                      Inactive
                    </span>
                  )}
                </div>

                <div className="content-product-table-data-item big-item">
                  {CmsStatus ? (
                    <span>
                      <CircleIcon sx={{ color: "green", fontSize: "12px" }} />{" "}
                      Active
                    </span>
                  ) : (
                    <span>
                      <CircleIcon sx={{ color: "red", fontSize: "12px" }} />{" "}
                      Inactive
                    </span>
                  )}
                </div>
                <div className="content-product-table-data-item center">
                  {
                    DateCreated
                  }
                </div>
                <div className="content-product-table-data-item center">
                  <button className='edit-btn' onClick={() => {
                    showShippingOptionUpdate(key, _id)
                  }}><EditIcon sx={{
                    fontSize: "20px"
                  }} /></button>
                </div>


              </div>
            )
          })
        ) : (
          <div className="content-product-table-data">
            <div className="content-product-table-data-item normal-start">

            </div>
            <div className="content-product-table-data-item normal-start">

            </div>
            <div className="content-product-table-data-item big-item">

            </div>

            <div className="content-product-table-data-item big-item">
              No Shipping Option found
            </div>
            <div className="content-product-table-data-item center">

            </div>
            <div className="content-product-table-data-item center">

            </div>
          </div>
        )}
    </ScrollContainer>
  )
};

export default ShippingOptionTable;
