import { Stack, Step, StepLabel, Stepper } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { HideItem } from '../../../pages/ProductManager/productHelper';
import { CheckOutCartNoAccount, DeleteCart, GetCouponDetails, logout } from '../PublicHelper';
import { placeOrderPayload, placeOrderPayloadWithToken, totalAmountComputer } from './checkOutUtilities';
import OrderSummaryButton from './OrderSummaryButton';
const PlaceOrderModal = ({ activeStep,/** token,*/ cartId, checkoutCart, setActiveStep, customerInfo, shippingArea, setCheckOutError, setOpenLoginSuccess, publicToken, placeOrderToChild, isVoucherApplied, customerInformation, isMealAllowance }) => {
  let steps = _.isEmpty(publicToken) ? ['Checkout', 'Processing order', 'Placing order'] : ['Account login', 'Check voucher', 'Placing order']
  const jwt = require("jsonwebtoken");

  const [voucher, setVoucher] = useState("")
  const [voucherE, setVoucherE] = useState("")
  const [checkoutE, setCheckoutE] = useState("")
  const [voucherStatus, setVoucherStatus] = useState("")
  let history = useNavigate()
  useEffect(() => {
    placeOrderToChild.current = placeOrderSubmit
    // setUserInfo(!_.isEmpty(token) ? jwt.decode(token) : {})
    return () => {
    }
    // eslint-disable-next-line
  }, [activeStep, /** token,*/ customerInformation, customerInfo, isVoucherApplied])
  const [couponDetails, setCouponDetails] = useState({
    CouponStatus: "",
    Discount: {},
    ExpirationDate: {},
    MaxPayout: "",
    MinOrderReq: 0
  })
  const [isShowSuccessButton, setIsShowSuccessButton] = useState(false)
  const checkVoucher = (e) => {
    e.preventDefault()
    setVoucherE("")

    setCouponDetails({
      CouponStatus: "",
      Discount: {},
      ExpirationDate: {},
      MaxPayout: "",
      MinOrderReq: 0
    })
    //By Percentage
    //(totalAmount * ((Number(Discount.Percentage)) / 100))
    GetCouponDetails(voucher)
      .then(
        res => {
          console.log(res.data);
          setOpenLoginSuccess(false);
          let totalMax = 0
          const { CouponStatus, Discount, ExpirationDate, MaxPayout, MinOrderReq } = res.data
          if (Discount.DiscountType === "By Percentage") {
            if (MaxPayout > 0) {
              totalMax = MaxPayout - (MaxPayout - (MaxPayout * (Discount.Percentage / 100)))
            }
          }
          setCouponDetails({
            CouponStatus: CouponStatus,
            Discount: Discount,
            ExpirationDate: ExpirationDate,
            MaxPayout: totalMax,
            MinOrderReq: MinOrderReq
          })
        }
      )
      .catch(
        err => {
          console.log(err.response);
          setVoucherE(!_.isEmpty(err.response) ? err.response.data.mess : "")
        }
      )
  }
  const voucherInputChange = (e) => {
    const { value } = e.target
    setVoucher(value)
  }
  // const submitVoucher = (e) => {
  //   e.preventDefault()
  //   let orderDetail = _.map(checkoutCart, value => {
  //     return { ProductId: value.ProductId, Price: value.TotalPrice }
  //   });
  //   setVoucherE("")

  //   if (_.isEmpty(voucher)) {
  //     setActiveStep(2)
  //     let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
  //     placeOrderSubmit(placeOrderPayload(voucher, 0, totalAmount, total, userInfo, checkoutCart, customerInfo, shippingArea))
  //     //no voucher applied
  //     setVoucherStatus("No voucher applied")
  //   } else {

  //     SubmitVoucher({
  //       "BranchId": localStorage.getItem("publicBranch"),
  //       "CouponCode": voucher,
  //       "CustomerId": userInfo._id,
  //       "OrderDetails": orderDetail
  //     })
  //       .then(
  //         res => {
  //           console.log(res);
  //           const { IfReachedMax, Payment } = res.messages.data

  //           if (IfReachedMax) {
  //             setActiveStep(2)
  //             let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
  //             placeOrderSubmit(placeOrderPayload("", 0, _.floor(totalAmount, 2), _.floor(total, 2)))
  //             //no voucher applied
  //             setVoucherStatus("Voucher not applied, Reached max limit")
  //           } else {
  //             setActiveStep(2)
  //             let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
  //             //voucher will be applied
  //             setVoucherStatus("Voucher was applied")
  //             totalAmount = (total - Payment) + shippingArea.ShippingFee
  //             placeOrderSubmit(placeOrderPayload(voucher, _.floor(Payment, 2), _.floor(totalAmount, 2), _.floor(total, 2), userInfo, checkoutCart, customerInfo, shippingArea))
  //             //this is for account that has applied the 
  //           }
  //         }
  //       )
  //       .catch(
  //         err => {
  //           console.log(err.response);
  //           setActiveStep(2)
  //           let message = !_.isEmpty(err.response) ? err.response.data.mess : ""
  //           setVoucherStatus("Voucher not applied, " + message)
  //           setVoucherE(!_.isEmpty(err.response) ? err.response.data.mess : "")
  //           let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
  //           placeOrderSubmit(placeOrderPayload("", 0, _.floor(totalAmount, 2), _.floor(total, 2), userInfo, checkoutCart, customerInfo, shippingArea))
  //           ///no voucher applied
  //         }
  //       )
  //   }
  // }
  const timerRef = useRef(null);
  const intervalRef = useRef(null)
  const [countDown, setCountDown] = useState(2)
  const [showButton, setShowButton] = useState(true)
  const [orderRef, setOrderRef] = useState("")
  // const testingPayload = (params) => {
  //   alert(params)
  // }
  const placeOrderSubmit = (payload) => {
    payload = _.isEmpty(publicToken) ? checkOutPayLoadNoAccount() : checkOutPayLoad()
    let token = _.isEmpty(publicToken) ? "" : publicToken
    console.log("payload: ", payload);
    setShowButton(true)
    setCheckoutE("")
    setCheckOutError({
      PreferredDeliveryDate: "",
      PaymentMode: ""
    })
    setIsShowSuccessButton(false)
    let number = countDown;
    setCountDown(3)
    intervalRef.current = setInterval(
      () => {
        setCountDown(number--)
      }
      , 1000);

    timerRef.current = setTimeout(() => {

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setShowButton(false)


      CheckOutCartNoAccount(payload, token)
        .then(res => {
          console.log(res);
          setActiveStep(3)
          setIsShowSuccessButton(true)

          setOrderRef(res.data.OrderRef)
          _.isEmpty(token) ? localStorage.removeItem("publicCart") :
            DeleteCart(cartId).then(
              res => {
                console.log(res)
              }
            ).catch(err => {
              console.log(err.response);
            })
          //getreference number
          //run /devapi.annam.ph/web/order/payment-receipt/:OrderId

          console.log(res.data.OrderRef);
        })
        .catch(err => {
          console.log(err.response)

          if (!_.isEmpty(err.response)) {
            setCheckoutE(err.response.data.mess)
          }
          if (!_.isEmpty(err.response)) {
            const { data, status } = err?.response
            if (status === 403 || status === 401) {
              const { response } = err
              logout(response, history);
            } else {
              console.log("ERROR MESSAGE", data?.data['ContactDetails.MobileNum']);
              setCheckOutError({
                FullName: data?.data['ContactDetails.FullName'] ?? "",
                MobileNum: data?.data['ContactDetails.MobileNum'] ?? "",
                PreferredDeliveryDate: data?.data?.PreferredDeliveryDate,
                PaymentMode: data?.data?.PaymentMode,
                PaymentId: data?.data['PaymentReceipt.PaymentId'] ?? "",
                Email: data?.data["ContactDetails.Email"] ??""
              })
            }
          } else {
            const { mess } = err.response.data
            Swal.fire({
              icon: 'error',
              title: "Failed!",
              text: mess,
              footer: "&#169; Pao Kitchen 2023"
            })
          }
          _.isEmpty(publicToken) ?
            setActiveStep(1) : setActiveStep(2)
          HideItem("#placeOrderModal")

        })
      setCountDown(2)
    }, 3000);

  }
  const checkOutPayLoadNoAccount = () => {
    //asdasd
    let data = {}
    const { isApplied, payment } = isVoucherApplied
    let coupon = _.isEmpty(publicToken) ? voucher : customerInformation.CouponCode

    if (isApplied) { 
      let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea) 
      totalAmount = (total - payment) + shippingArea.ShippingFee
      data = placeOrderPayload(coupon, _.floor(payment, 2), _.floor(totalAmount, 2), _.floor(total, 2), checkoutCart, customerInfo, shippingArea, isMealAllowance)
    }else{
      
      setVoucherStatus("Voucher not applied : Login to get/apply discount")
      setVoucherE("Login to get/apply discount")
      let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
      data = placeOrderPayload("", 0, _.floor(totalAmount, 2), _.floor(total, 2), checkoutCart, customerInfo, shippingArea, isMealAllowance)
      console.log("checkOutPayLoadNoAccount: ", data)
    } 
    return data
  }
  const checkOutPayLoad = () => {
    const { isApplied, payment } = isVoucherApplied
    // console.log("isVoucherApplied: ", isVoucherApplied)
    let data = {}
    let coupon = _.isEmpty(publicToken) ? voucher : customerInformation.CouponCode

    if (isApplied) {
      let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
      //voucher will be applied
      totalAmount = (total - payment) + shippingArea.ShippingFee
      data = placeOrderPayloadWithToken(coupon, _.floor(payment, 2), _.floor(totalAmount, 2), _.floor(total, 2), jwt.decode(publicToken), checkoutCart, customerInformation, shippingArea, isMealAllowance)
    } else {
      let { totalAmount, total } = totalAmountComputer(checkoutCart, shippingArea)
      data = placeOrderPayloadWithToken("", 0, _.floor(totalAmount, 2), _.floor(total, 2), jwt.decode(publicToken), checkoutCart, customerInformation, shippingArea, isMealAllowance)
    }
    console.log("checkOutPayLoad: ", data)
    return data
  }
  // const loginCustomer = () => {
  //   localStorage.setItem("publicToken", token)
  //   dispatch(setToken({ publicToken: token }));
  // }
  const customerAccount = (e) => {
    e.preventDefault()
    // if (_.isEmpty(publicToken)) { loginCustomer() }
    history("/myaccount")
  }
  const gotoOrderSummary = (e) => {
    e.preventDefault()
    // if (_.isEmpty(publicToken)) { loginCustomer() }
    history(`/ordersummary/${orderRef}`)
  }
  const cancelCheckout = (e) => {
    e.preventDefault()
    setShowButton(true)
    // loginCustomer()

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

  }

  const copyText = (e, value) => {
    e.preventDefault()

    navigator.clipboard.writeText(value);
    Swal.fire({
      toast: true,
      icon: 'success',
      title: `Order reference (${value}) copied`,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }


  return (
    <div className="checkout-modal" id="placeOrderModal">
      <div className="checkout-modal-container ">
        <div className="checkout-modal-container-title m-bot">
          Creating Account and Placing order
        </div>
        <div className="checkout-modal-container-content-border">
          <Stack sx={{ width: '100%' }} spacing={0}>
            <Stepper alternativeLabel activeStep={activeStep} >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          {
            activeStep === 1 ? (
              <>
                <form className='checkout-container-right-content-item-60-2-voucher '>
                  <input type="text" value={voucher} onChange={e => { voucherInputChange(e) }} name="voucher" id="" />
                  <button type='button' onClick={e => { checkVoucher(e) }}>Check Voucher</button>
                  {/* <button type='button' onClick={e => { submitVoucher(e) }}>Continue</button> */}
                </form>
                <small className='note-grey'>NOTE: <span>voucher is not required</span> </small>

                {

                  !_.isEmpty(couponDetails.Discount) ? (
                    <>
                      <div className="couponcard">
                        <div className="maincouponcard">
                          <div className="co-img">
                            <div className='span'><small className='note-primary'><span>
                              {
                                couponDetails.Discount.DiscountType === "By Percentage" ? couponDetails.Discount.Percentage + " %" : "₱ " + couponDetails.Discount.Fix
                              }
                            </span> </small>{
                                <small className='note-primary'>{" "}Off{" "}<span>{
                                  couponDetails.MinOrderReq
                                }</span> </small>
                              }</div>
                          </div>
                          <div className="vertical"></div>
                          <div className="couponcardcontent">
                            <h2>Pao Kitchen</h2>
                            <h1>
                              {
                                couponDetails.Discount.DiscountType === "By Percentage" ? couponDetails.Discount.Percentage + " %" : "₱ " + couponDetails.Discount.Fix
                              }
                              <span> Voucher</span>
                            </h1>
                            <p>Valid till
                              {
                                moment(couponDetails.ExpirationDate.End).format("MMMM DD YYYY, h:mm:ss a")
                              }
                            </p>
                            <p>
                              {
                                couponDetails.Discount.DiscountType === "By Percentage" ? (
                                  <small className='note-grey nogap'>Discount cap:
                                    <span className="discount">
                                      <CurrencyFormat value={couponDetails.MaxPayout} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                                    </span>
                                  </small>
                                ) : (
                                  <></>
                                )
                              }
                            </p>

                          </div>
                        </div>
                      </div>
                      <small className='note-grey nogap'>Availability:
                        <span className={couponDetails.CouponStatus ? "available" : "not-available"}>{couponDetails.CouponStatus ? "Available" : "Not Available"}</span>
                      </small>
                    </>
                  ) :
                    (
                      <></>
                    )
                }
                {
                  !_.isEmpty(voucherE) ? (<small className='error-message'><span>{voucherE}</span> </small>)
                    :
                    (<></>)
                }
              </>
            ) : activeStep === 2 ? (
              <>
                {
                  _.isEmpty(publicToken) ? (<small className='note-grey'>Voucher Status:
                    <span className='discount'>{voucherStatus}</span>
                  </small>) : (<></>)
                }

                {
                  _.isEmpty(checkoutE) ? (
                    <></>
                  ) : (
                    <small className='note-grey'> Place order status:
                      <span className='error-message'>{" " + checkoutE}</span>
                    </small>
                  )
                }
                {
                  showButton ? (
                    <div className="checkout-modal-container-button">
                      <button className='cancel-button' onClick={(e) => {
                        cancelCheckout(e)
                      }}>({countDown}) cancel order
                      </button>
                    </div>
                  ) : (
                    <></>
                  )
                }
              </>


            ) : activeStep === 3 ? !_.isEmpty(checkoutE) ? (
              <small className='error-message'>
                <span>{checkoutE}</span>
              </small>
            ) :
              (<></>)
              : (
                <></>
              )
          }
          {
            activeStep === 3 ? isShowSuccessButton ? (
              <>
                <span className='cursor-pointer width-100 flex-row-center-center' onClick={(e) => copyText(e, orderRef)}> Order Reference :  <span className="available" > {orderRef}</span> </span>
                <OrderSummaryButton {...{ customerAccount, gotoOrderSummary, publicToken }} />
              </>
            ) : (<></>)
              : (<></>)
          }
        </div>
        {/* <div className="checkout-modal-container-button">
          <button onClick={(e) => {
            e.preventDefault()
            HideItem("#placeOrderModal")
            //setActiveStep(1)
          }}>Done</button>
          <button onClick={(e) => {
            e.preventDefault()
            //HideItem("#placeOrderModal")
            setActiveStep(0)
          }}>back to 0</button>
          <button onClick={(e) => {
            e.preventDefault()
            //HideItem("#placeOrderModal")
            setActiveStep(1)
          }}>back to 1</button>
          <button onClick={(e) => {
            e.preventDefault()
            //HideItem("#placeOrderModal")
            setActiveStep(2)
          }}>back to 2</button>
        </div> */}
      </div>
    </div>
  );
};

export default PlaceOrderModal;