import './styles.scss'
import EditIcon from '@mui/icons-material/Edit'; 
import Swal from "sweetalert2";
import { UpdatePlatform } from '../EcommerceHelper';

const PlatformList = (props) => {

  // const {
  //   id,
  //   name,
  //   percentage,
  //   created,
  //   status,
  //   refresher,
  //   refresh,
  //   rawPlatformData,
  //   setRawPlatformData
  // } = props.props

  const { platformdata = {}, prefresher, prefresh, prawPlatformData, psetRawPlatformData } = props
  let data = platformdata; 
  const id = data.id,
      created = data.created;

  
  const editPopup = async (event) => {

    event.preventDefault()
    const result = await Swal.fire( {
      title: '<strong>UPDATE</strong>',
      html: `
      <hr/>
      <div style="display:flex; flex-direction: column;">
        <label for="name">NAME:</label>
        <input type="text" id="name" class="swal2-input" placeholder="name" value="${data.name}">
        <label for="percentage" style="margin-top:1em;">PERCENTAGE:</label>
        <input type="number" id="percentage" class="swal2-input" placeholder="0" value="${data.percentage}">
        <label for="status" style="margin-top:1em;">STATUS:</label>
        <select value="${data.status}" id="status" class="swal2-input">
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      `,
      confirmButtonText: 'Update',
      confirmButtonColor: '#50311b',
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {

        const newName = Swal.getPopup().querySelector('#name').value
        const newPercentage = Swal.getPopup().querySelector('#percentage').value
        const newStatus = Swal.getPopup().querySelector('#status').value

        return {
          name: newName,
          percentage: newPercentage,
          status: newStatus
        }

      }
    } )

    if ( result.isConfirmed ) {

      try {
        
        const newName = result.value.name;
        const newStatus = ( result.value.status === 'active' ? true : false )
        const newPercentage = result.value.percentage
        UpdatePlatform( id, newName, newStatus, newPercentage )

         
        const newData = {
          id,
          ...result.value,
          created
        }
   
        const newArr = prawPlatformData
        const index = newArr.findIndex(x => x.id === id)
        newArr[index] = newData
        psetRawPlatformData( newArr )
        prefresher( !prefresh )

      } catch ( e ) {
        
        console.error( e )

        Swal.fire( {
          icon: "error",
          title: "Oops!",
          text: "Failed to update platform",
          footer: "&#169; Pao Kitchen 2023"
        })

      }

    }

  }

  return (
    <div className="platform-table-data">
      
      {/* name */}
      <div className="item-size-big item-start">
        {data.name}
      </div>

      {/* percentage */}
      <div className="item-size-big">
        {data.percentage}%
      </div>

      {/* created */}
      <div className="item-size-big">
        {data.created}
      </div>

      {/* active */}
      {data.status.toLowerCase() === 'active' ? 
        
        // active
        <div className="item-size-medium">
          <span className="active">
            &nbsp;{data.status}
          </span>
        </div> :

        // inactive
        <div className="item-size-medium">
          <span className="inactive">
            &nbsp;{data.status}
          </span>
        </div>

      }

      {/* edit */}
      <div className="item-size-small">
        <EditIcon className="edit-button" onClick={ e => editPopup(e)} />
      </div>

    </div>
  )

}

export default PlatformList