import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HorizontalDivider from "../../reusable/HorizontalDivider";
import Logo from "../../reusable/Logo";
import Check from "./../../../assets/feature/check.svg";
import Smiley from "./../../../assets/feature/smiley.svg";
import { showDialog, showMessenger } from './../MessengerPlugIn';
import { GetPublicProductList } from "./../PublicHelper";
import Feature from "./Feature";
import NavLinks from "./NavLinks";
import SocialLink from "./SocialLink";
const SideBar = ({ search }) => {
  const { t } = useTranslation()
  const feature = [
    [t('24/7customerservice'), Smiley, ">", 23.48, 23.97],
    [t('cashondelivery'), Check, "", 22, 22],
    [t('convenientpayment'), Check, "", 22, 22],
    [t('reliableandsecure'), Check, "", 22, 22],
  ];
  const [categoryLink, setCategoryLink] = useState();
  let branchid = localStorage.getItem("publicBranch");

  useEffect(() => {
    const UNLIMITED = -1
    GetPublicProductList(branchid, search, UNLIMITED)
      .then(res => {
        const { paginatedResults } = res.data
        setCategoryLink(paginatedResults)
        console.log(paginatedResults)
      })
      .catch(err => console.log(err.response))
    return () => {

    }
  }, [branchid, search])
  const showMessengerDialog = (e) => {
    e.preventDefault()
    showMessenger(true)
    showDialog();
  }
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <Logo />
      </div>
      <HorizontalDivider />
      <div className="navlinks">
        <div className="navlinks-item" >
          <NavLinks path="todaymenu" link={t('todaysspecialdish')} />
        </div>
        {
          categoryLink ? (
            Object.keys(categoryLink).map((key, index) => {
              const { Attribute } = categoryLink[key];
              const { Name, _id } = Attribute
              let link = Name[i18n.language] || Name.EN || Name.VN || Name.CN || Name.TH
              return (
                <div key={index} className="navlinks-item">
                  <NavLinks path={_id} link={link} />
                </div>
              )
            })
          )
            : ""
        }

      </div>
      <HorizontalDivider />
      <SocialLink />
      <HorizontalDivider />
      <div className="sidebar-footer">
        {feature.map((value, index) => {
          return index === 0 ? (
            <div key={index} onClick={(e) => { showMessengerDialog(e) }}>
              <Feature
                img={value[1]}
                width={value[3]}
                height={value[4]}
                left={value[0]}
                right={value[2]}
              />
              <HorizontalDivider />
            </div>
          ) : (
            <div key={index}>
              <Feature
                img={value[1]}
                width={value[3]}
                height={value[4]}
                left={value[0]}
                right={value[2]}
              />
            </div>
          );
        })}
      </div>
    </div >
  );
};

export default SideBar;
