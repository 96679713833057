import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetProductDetails, HideItem } from "../productHelper";

const UpdateStockForm = ({ node, idTopass, submitUpdateStockForm, showUpdate, setShowUpdate }) => {
  const [product, setProduct] = useState(
    {
      sku: "",
      image: "",
      name: {},
      id: ""
    }
  );
  const [stockNewValue, setStockNewValue] = useState("");
  const { sku, image, name } = product;
  useEffect(() => {
    getDetails(idTopass)
    return () => {

    };
    //eslint-disable-next-line
  }, [idTopass]);
  const [loading, setLoading] = useState(true)
  const getDetails = (idTopass) => {
    setLoading(true)
    if (showUpdate) {
      GetProductDetails(idTopass).then(res => {
        setLoading(false)
        setProduct({
          sku: res?.data[0]?.SKU,
          image: res?.data[0]?.Images?.DineIn,
          name: res?.data[0]?.Name,
          id: res?.data[0]?._id
        })
      }).catch((error) => {
        setLoading(false)
      })
    }

  }
  const closeUpdateStocKForm = () => {
    HideItem(".modal-add-group")
    setShowUpdate(false)
  }
  const changeValue = (e) => {
    const { value } = e.target;
    setStockNewValue(value);
  }

  return (
    <div className="modal-add-group">
      <div className="modal-add-group-form" ref={node}>
        <div className="content-main-add-user-header">
          <div className="content-main-add-user-header-title">
            Update stock
          </div>
          <div className="content-main-add-user-header-close">
            <CloseIcon
              sx={{
                fontSize: "18px",
                "&:hover": {
                  fontWeight: "bold",
                  color: "#50311b",
                  cursor: "pointer",
                },
              }}
              onClick={() => closeUpdateStocKForm()}
            />
          </div>
        </div>
        <form className="modal-add-group-form-main">
          {
            loading ? (
              <div className="flex-column-center-center width-100">
                <Skeleton animation="wave" variant="rectangular" width={100} height={100} />
                <Skeleton
                  animation="wave"
                  height={10}
                  width={100}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width={100}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width={100}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width={200}
                  style={{ marginBottom: 6 }}
                />
              </div>
            ) : (
              <>
                <div className="modal-add-group-form-main-stock-image">
                  <img src={image} alt={name.EN} width="100px" />
                </div>
                <div className="modal-add-group-form-main-stock-text">
                  <small>{sku}</small>
                </div>
                <div className="modal-add-group-form-main-stock-text">
                  <span className="text-en">{name.EN}</span>
                </div>
                <div className="modal-add-group-form-main-stock-text">
                  <span className="text-vn">{name.VN}</span>
                </div>
                <div className="modal-add-group-form-main-stock-input">
                  <input type="number" name="stockNewValue" value={stockNewValue} onChange={changeValue} placeholder="Enter new STOCK count" />
                </div>
              </>
            )
          }


        </form>
        {
          loading ? (
            <div className="content-main-add-user-footer gap-10">
              <Skeleton
                animation="wave"
                height={50}
                width={80}
              />
              <Skeleton
                animation="wave"
                height={50}
                width={80}
              />
            </div>
          ) : (
            <div className="content-main-add-user-footer">
              <button
                className="content-main-add-user-footer-cancel"
                onClick={() => closeUpdateStocKForm()}
              >
                cancel
              </button>
              <button
                className="content-main-add-user-footer-create"
                onClick={(e) => {
                  submitUpdateStockForm(stockNewValue, idTopass, e)
                  setStockNewValue("")
                }}
              >
                proceed
              </button>
            </div>)
        }

      </div>
    </div>
  )
};

export default UpdateStockForm;
