import _ from 'lodash';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';
import Swal from "sweetalert2";
import {closePublicCart, ShowItemFlex} from "../../pages/ProductManager/productHelper";
import { setToken } from "../../redux/actions/token";
import BranchSelectPublic from './BranchSelectPublic';
import Cart from './Cart';
import { mergeProductStock } from './CheckOut/checkOutUtilities';
import LoginSignUp from './LoginSignUp';
import { MessengerChat } from './MessengerPlugIn';
import Footer from './product/Footer';
import ProductModal from './ProductModal';
import ProductNotification from './ProductNotification';
import PublicContentRight from './PublicContentRight';
import { AddToCartSubmit, DeleteCart, GetCustomerCart, GetProductDetailCart, GetPublicBranches, logout, UpdateCart, GetContactListWeb } from "./PublicHelper";
import SideBar from "./sidebar/SideBar";
import TopBar from "./topbar/TopBar";
import TelegramIcon from '@mui/icons-material/Telegram';
import messenger from './../../assets/footer/messenger.svg';
import fb_messenger from './../../assets/footer/fb-messenger.svg';
import moment from 'moment';

const Public = () => {
  const { t } = useTranslation()
  useTitle('Pao Kitchen');
  const history = useNavigate()
  const locationIcon = { fontSize: "30px", color: "#50311b" };
  const tokens = useSelector((state) => state.token);
  const { publicBranch, publicToken } = tokens.token;
  const [branches, setBranches] = useState();
  const dispatch = useDispatch();
  const jwt = require("jsonwebtoken");
  let user;
  const [cartList, setCartList] = useState([])
  const [cartCount, setCartCount] = useState(0)
  const [cartId, setCartId] = useState();
  const [contactList, setContactList] = useState();

  

  useEffect(() => {
    //setCartCount(cartList.length)
    setCartList([])
    setCartCount(0)
    getContactList(true)
    if (!_.isEmpty(publicToken)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      user = jwt.decode(publicToken)
      let publicStorage = JSON.parse(localStorage.getItem("publicCart") || "[]")
      if (!_.isEmpty(publicStorage)) {

        Swal.fire({
          title: t('itemonguesscart'),
          showDenyButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: t('mergeitems'),
          denyButtonText: t('replaceitems'),
          cancelButtonText: t('disregarditem')
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          const { _id } = user
          let cart = []
          GetCustomerCart(_id)
            .then(resp => {
              setCartId(resp.data._id)
              GetProductDetailCart(resp.data._id, publicBranch, _id)
                .then(res => {
                  const { data } = res
                  cart = _.map(data, (value, key) => {
                    const { SaleDateFrom, SaleDateTo, SalePrice, Product, Price } = data[key]
                    return {
                      "ProductId": Product._id,
                      "ProductName": Product.Name,
                      "ProdImage": Product.Images.Delivery,
                      "SKU": Product.SKU,
                      "Price": Price,
                      "SaleDateFrom": SaleDateFrom,
                      "SaleDateTo": SaleDateTo,
                      "SalePrice": SalePrice,
                      "Quantity": resp.data.CartDetails[key].Quantity,
                      "TotalPrice": showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price.Regular) * resp.data.CartDetails[key].Quantity,
                      "OrderProdType": "REGULAR",
                      "PreparationTime": Product.PreparationTime
                    }
                  })
                  if (result.isConfirmed) {
                    //merge items
                    UpdateCart(resp.data._id, mergeProductStock([...cart, ...publicStorage]), user._id).then(
                      res => {
                        localStorage.removeItem("publicCart");
                        setCartList(mergeProductStock([...cart, ...publicStorage]))
                        setCartCount(mergeProductStock([...cart, ...publicStorage]).length)
                        Swal.fire('Items merged', '', 'success')
                      }
                    ).catch(
                      error => Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                    )
                  } else if (result.isDenied) {
                    //replace with local
                    UpdateCart(resp.data._id, publicStorage, user._id).then(
                      res => {
                        localStorage.removeItem("publicCart");
                        setCartList([...publicStorage])
                        setCartCount(publicStorage.length)
                        Swal.fire('Items replaced by guess cart', '', 'success')
                      }
                    ).catch(
                      error => Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                    )
                  } else if (result.isDismissed) {
                    //local will be deleted
                    localStorage.removeItem("publicCart");
                    setCartList(cart)
                    setCartCount(cart.length)
                    Swal.fire('Cart items remained', '', 'success')
                  }
                })
                .catch(err => {
                  console.log(err.response)
                  const { response } = err
                  logout(response, history);
                })
            })
            .catch(err => {
              if (err?.response?.status === 404) {
                if (result.isConfirmed) {
                  //merge items
                  AddToCartSubmit(user._id, publicStorage)
                    .then(res => {
                      localStorage.removeItem("publicCart");
                      setCartList(publicStorage)
                      setCartCount(publicStorage.length)
                      Swal.fire('Items merged', '', 'success')
                    })
                    .catch(error => {
                      Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                    })
                } else if (result.isDenied) {
                  //replace with local
                  AddToCartSubmit(user._id, publicStorage)
                    .then(res => {
                      localStorage.removeItem("publicCart");
                      setCartList(publicStorage)
                      setCartCount(publicStorage.length)
                      Swal.fire('Items replaced', '', 'success')
                    })
                    .catch(error => {
                      Swal.fire('Problem occured, please refresh the page and try again', '', 'error')
                    })
                } else if (result.isDismissed) {
                  //local will be deleted
                  removeAllItemsOnCart()
                  Swal.fire('Cart items remained', '', 'success')
                }
              }
              const { response } = err
              logout(response, history)
            })

          /// end of result modal
        })
      } else {
        const { _id } = user
        let cart = []
        
        GetCustomerCart(_id)
          .then(resp => {
            // console.log(resp)
            setCartId(resp.data._id)
            GetProductDetailCart(resp.data._id, publicBranch, _id)
              .then(res => {
                const { data } = res
                // console.log(data)
                setCartCount(data.length)
                cart = _.map(data, (value, key) => {
                  const { SaleDateFrom, SaleDateTo, SalePrice, Product, Price } = data[key]
                  return {
                    "ProductId": Product._id,
                    "ProductName": Product.Name,
                    "ProdImage": Product.Images.Delivery,
                    "SKU": Product.SKU,
                    "Price": Price,
                    "Quantity": resp.data.CartDetails[key].Quantity,
                    "SaleDateFrom": SaleDateFrom,
                    "SaleDateTo": SaleDateTo,
                    "SalePrice": SalePrice,
                    "TotalPrice": showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price.Regular) * resp.data.CartDetails[key].Quantity,
                    "OrderProdType": "REGULAR",
                    "PreparationTime": Product.PreparationTime
                  }
                  // showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price.Regular)
                })
                setCartList(cart)
              })
              .catch(err => {

                console.log(err.response)
              })
          })
          .catch(err => {
            localStorage.removeItem("publicCart");
            setCartList([])
            setCartCount(0)
            console.log("no cart found" + err.response)
          })
      }


    } else {
      if (!_.isEmpty(localStorage.getItem("publicCart"))) {
        let cartArray = JSON.parse(localStorage.getItem("publicCart") || "[]")
        setCartList([...cartArray])
        setCartCount(cartArray.length)
      } else {
        setCartList([])
        setCartCount(0)
      }
      //setCartList(localStorage.getItem("publicCart"))
    }
    GetPublicBranches()
      .then(res => {
        const { paginatedResults } = res.data;
        setBranches(paginatedResults)
      })
      .catch(err => console.log(err.response))
    if (publicBranch) {
      document.querySelector(".branch-dialog-selection").style.display = "none";
    } else {
      document.querySelector(".branch-dialog-selection").style.display = "flex";
    }
    return () => {

    }
  }, [publicBranch, publicToken])
  // }, [publicBranch, publicToken])
  const showPrice = (dateFrom, dateTo, salePrice, regularPrice) => {
    const isBetween = moment().tz( 'Asia/Manila' ).isBetween( dateFrom, dateTo )
    if (isBetween && salePrice !== 0) {
      return salePrice;
    } else {
      return regularPrice;
    }
  }
  const getContactList = (search) => {
    GetContactListWeb(search).then((res) => {
      const { paginatedResults } = res?.data;

      let toPush = [];

      Object.keys(paginatedResults).forEach((key) => {
        const { _id, Link, Type, IsActive } = paginatedResults[key];
        toPush.push({
          id: _id,
          link: Link,
          type: Type,
          isActive: IsActive,
        });
      });
      setContactList(toPush)
    }).catch((error) => {
      setContactList([])
    })
  }
  const addToCart = (e, product) => {
    const { Product, Price, SaleDateFrom, SaleDateTo, SalePrice } = product
    e.preventDefault()
    let isOkay = true;
    let cartDetail = {
      "ProductId": Product._id,
      "ProductName": Product.Name,
      "ProdImage": Product.Images.Delivery,
      "SKU": Product.SKU,
      "Price": Price,
      "Quantity": 1,
      "SaleDateFrom": SaleDateFrom,
      "SaleDateTo": SaleDateTo,
      "SalePrice": SalePrice,
      "TotalPrice": showPrice(SaleDateFrom, SaleDateTo, SalePrice, Price.Regular),
      "OrderProdType": "REGULAR",
      "PreparationTime": Product.PreparationTime
    }
    for (var i = 0; i < cartList.length; i++) {
      const { ProductId } = cartList[i]
      if (ProductId === Product._id) {
        isOkay = false;
        break;
      } else {
        isOkay = true;
      }
    }

    if (isOkay) {
      if (publicToken) {

        user = jwt.decode(publicToken)
        let cartToPass = {
          ProductId: Product._id,
          Quantity: 1
        }
        GetCustomerCart(user._id).then(
          res => {
            const { _id, CartDetails } = res.data
            if (CartDetails === null) {
              UpdateCart(_id, [cartToPass], user._id).then(
                res => console.log(res)
              ).catch(
                error => console.log(error.response)
              )
            } else {
              UpdateCart(_id, [...CartDetails, cartToPass], user._id).then(
                res => console.log(res)
              ).catch(
                error => console.log(error.response)
              )
            }
            setCartList([...cartList, cartDetail])
            setCartCount(cartList.length + 1)
          }
        ).catch(
          err => {
            if (!err.response.data.data.length) {
              AddToCartSubmit(user._id, [cartToPass]).then(res => {
                GetCustomerCart(user._id)
                  .then(resp => {
                    setCartId(resp.data._id)
                  })
                setCartList([...cartList, cartDetail])
                setCartCount(cartList.length + 1)
              }).catch(error => { console.log(error.response) })
            }
          }
        )

      } else {
        setCartList([...cartList, cartDetail])
        setCartCount(cartList.length + 1)
        localStorage.setItem("publicCart", JSON.stringify([...cartList, cartDetail]));
      }

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Product is already added in your cart!",
        footer: "&#169; Pao Kitchen 2023",
      });
    }

  }
  const DeleteOnCART = () => {
    // console.log("publicToken: ", publicToken)
    if (publicToken) {
      console.log("This IS FIRED ON DELETE ACCOUNT");
      DeleteCart(cartId).then(
        res => {
          setCartCount(0)
        }
      ).catch(err => {
        console.log(err.response);
      })
    } else {
      console.log("This IS FIRED ON DELETE NO ACCOUNT");
      localStorage.removeItem("publicCart")
      setCartCount(0)
    }

  }
  const UpdateCartCustomer = (toPass) => {
    if (publicToken) {
      user = jwt.decode(publicToken)
      UpdateCart(cartId, toPass, user._id).then(
        res => {
          setCartList(toPass)
          setCartCount(toPass.length)
        }
      ).catch(
        error => console.log(error.response)
      )
    } else {
      setCartList(toPass)
      localStorage.setItem("publicCart", JSON.stringify(toPass));
      setCartCount(toPass.length)
    }

  }
  const branchSelect = (e, id, Name, CityAddress) => {
    e.preventDefault()
    localStorage.setItem("publicBranch", id);
    document.querySelector(".branch-dialog-selection").style.display = "none";
    dispatch(setToken({ publicBranch: id }));
  };
  const checkOutCart = (e, productList, totalAmount) => {

    if (cartCount === 0) {
      Swal.fire({
        icon: 'error',
        title: t('stop'),
        text: t('nocartcontinue'),
        footer: "&#169; Pao Kitchen 2023"
      })
    } else {
      history("/checkout");
    }
  }
  const removeAllItemsOnCart = (e) => {
    e?.preventDefault()
    setCartList([])
    setCartCount(0)
    localStorage.removeItem("publicCart")
  }
  const newList = (newListOfitem) => {
    setCartList(newListOfitem)
    setCartCount(newListOfitem.length)
  }
  const [productDetails, setProductDetails] = useState({
    Branch: "",
    CreatedAt: "",
    IfFeatured: false,
    IsAvailable: true,
    LastUpdatedBy: "",
    OrderCount: 0,
    Price: { Regular: 0, DineIn: 0, KTV: 0, Delivery: 0, PriceCompare: 0 },
    Product: { _id: '', Name: {}, Description: {}, Images: {}, Tags: null, Ingredients: [] },
    SaleDateFrom: "",
    SaleDateTo: "",
    SalePrice: { $numberDecimal: '' },
    Stocks: 0,
    UpdatedAt: "",
    __v: 0,
    _id: "",
  })
  const showProductItemDetails = (e, product) => {
    e.preventDefault()
    setProductDetails(product)
    ShowItemFlex("#productDetailModal")
  }
  const [search, setSearch] = useState("")
  const submitSearchNav = (e, result) => {
    e.preventDefault()
    setSearch(result)
  }
  const closeCart = () => {
    closePublicCart()
  }
  const dontAffect = (e) => {
    e.stopPropagation();
  }

  const openInNewTab = (e, url) => {
    e.preventDefault()
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className="body" onClick={() => closeCart()}> 
      <MessengerChat
        pageId={107829387760557}
        language="en_US"
        themeColor={"#50311b"}
        bottomSpacing={10}
        version="v13.0"
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
        onMessengerMounted={() => {
          console.log("onMessengerMounted");
        }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad");
        }}

      />
      {/* <FacebookPlugin language={"en_US"} FB_APP_ID={3143017075953124} successCallback={sample} /> */}
      
      <SideBar {...{ search }} />
      <div className="body-right scroll-container">
        <TopBar {...{ cartCount, submitSearchNav, dontAffect }} />
        <PublicContentRight {...{ addToCart, showProductItemDetails, search }} />
        <Footer />
      </div>
      <BranchSelectPublic
        {...{
          branches,
          branchSelect,
          locationIcon,
        }}
      />
      <Cart {...{ cartList, publicToken, checkOutCart, UpdateCartCustomer, newList, cartId, removeAllItemsOnCart, DeleteOnCART, setCartCount }} />

      <LoginSignUp />

      <ProductModal {...{ productDetails, addToCart }} /> 
      <ProductNotification {...{ search, showProductItemDetails }} /> 
      <div className="telegram">
        {!_.isEmpty(contactList) ?(
           Object.keys(contactList).map((key, index) => {
            const { id, link, type, isActive } = contactList[key]
            if (type === "Telegram") {
              return (
                <img className='cursor-pointer' src={messenger} alt="messenger" height={50} onClick={(e) => openInNewTab(e, link)} />
              )
            } else if (type === "Messenger") {
              return (
                <img className='cursor-pointer' src={fb_messenger} alt="messenger" height={50} onClick={(e) => openInNewTab(e, link)} />
              )
            }
           })
          ) : (
            <div></div>
          )
        }
        {/* <img className='cursor-pointer' src={messenger} alt="messenger" height={50} onClick={(e) => openInNewTab(e, "https://t.me/pao_kitchen")} />
        <img className='cursor-pointer' src={fb_messenger} alt="messenger" height={50} onClick={(e) => openInNewTab(e, "https://www.facebook.com/paokitchenph")} /> */}
      </div>
    </div>
  );
};

export default Public;
