import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { HideItem, IdSelector, ShowItem, ShowItemFlex } from "../productHelper";
const AddSubCategory = () => {
  const showTextAdd = () => {
    IdSelector("addsubcategory").style.animation =
      "titleAnimeIn 1s ease-in forwards";
    ShowItem("#addsubcategory");
  };
  const hideTextAdd = () => {
    IdSelector("addsubcategory").style.animation =
      "titleAnimeOut 0.5s ease-in forwards";
    setTimeout(() => {
      HideItem("#addsubcategory");
    }, 500);
  };
  const showAddSubCategory = () => {
    ShowItemFlex("#subcategoryAdd")
  }

  return (
    <div className="add-product-group-category" onClick={showAddSubCategory}>
      <div id="addsubcategory" >Add Sub Category</div>
      <div className="icon" onMouseEnter={showTextAdd} onMouseLeave={hideTextAdd} // onClick={}
      >
        <AddIcon sx={{
          fontSize: "18px"
        }} />
      </div>
    </div>
  );
}

export default AddSubCategory;