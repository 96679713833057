import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import parse from 'html-react-parser';
import i18next from 'i18next';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { HideItem } from '../../pages/ProductManager/productHelper';
import placeholderImage from './../../assets/placeholderImage.jpg';
import moment from 'moment';
// import PriceVariation from './PriceVariation';
const ProductModal = ({ productDetails, addToCart }) => {
  const { t } = useTranslation()
  const nodeImageDelivery = useRef(null)
  const nodeImageDineIn = useRef(null)
  const nodeImageDineInMobile = useRef(null)
  const nodeImageDeliveryMobile = useRef(null)
  useEffect(() => {


    return () => {

    }
  }, [])
  const imgErrorDineIn = (e) => {
    e.preventDefault()
    nodeImageDineIn.current.src = placeholderImage
    return true;
  }
  const imgErrorDelivery = (e) => {
    e.preventDefault()
    nodeImageDelivery.current.src = placeholderImage
    return true;
  }
  const imgErrorDineInMobile = (e) => {
    e.preventDefault()
    nodeImageDineInMobile.current.src = placeholderImage
    return true;
  }
  const imgErrorDeliveryMobile = (e) => {
    e.preventDefault()
    nodeImageDeliveryMobile.current.src = placeholderImage
    return true;
  }
  const changeImage = (e, active) => {
    e.preventDefault()
    let container = document.querySelectorAll(".product-modal-container-mobile-container")
    _.forEach(container, (value, key) => {
      return container[key].classList.remove("mobile-active")
    })
    let buttons = document.querySelectorAll(".button-group-button")
    _.forEach(buttons, (value, key) => {
      return buttons[key].classList.remove("button-active")
    })
    e.target.classList.add("button-active")
    if (active === 1) {
      document.querySelector("#dineInMobile").classList.add("mobile-active")
    } else {
      document.querySelector("#deliveryMobile").classList.add("mobile-active")
    }

  }
  const showPrice = (dateFrom, dateTo, salePrice, regularPrice) => {
    const isBetween = moment().tz( 'Asia/Manila' ).isBetween( dateFrom, dateTo )
    if (isBetween && salePrice !== 0) {
      return salePrice;
    } else {
      return regularPrice;
    }
  }
  const { Product, Price, SaleDateFrom,SaleDateTo, SalePrice } = productDetails
  const { Images } = Product
  const { DineIn, Delivery } = Images
  return (
    <div className="product-modal" id="productDetailModal" onClick={() => {
      HideItem('#productDetailModal');
    }}>
      <div className="product-modal-container" onClick={(e) => {
        e.stopPropagation()
      }}>
        <div className="product-modal-container-images">
          {/* <div className="product-modal-container-images-container">
            <div className="clip-name">{t('dinein')}, KTV</div>
            <img
              src={DineIn || placeholderImage}
              alt="dinein ktv"
              ref={nodeImageDineIn}
              onError={(e) => imgErrorDineIn(e)}
            />
          </div> */}
          <div className="product-modal-container-images-container">
            <div className="clip-name">{t('delivery')}, {t('pickup')}</div>
            <img
              src={Delivery || placeholderImage}
              alt="delivery pickup"
              ref={nodeImageDelivery}
              onError={(e) => imgErrorDelivery(e)}
            />
          </div>
        </div>
        <div className="product-modal-container-mobile">
          <div className="product-modal-container-mobile-container mobile-active" id="dineInMobile">
            <img
              src={DineIn || placeholderImage}
              alt="dinein ktv"
              ref={nodeImageDineInMobile}
              onError={(e) => imgErrorDineInMobile(e)}
            />
          </div>
          <div className="product-modal-container-mobile-container" id="deliveryMobile">
            <img
              src={Delivery || placeholderImage}
              alt="delivery pickup"
              ref={nodeImageDeliveryMobile}
              onError={(e) => imgErrorDeliveryMobile(e)}
            />
          </div>
          <div className="button-group">
            <button className="button-group-button button-active cursor-pointer" onClick={(e) => {
              changeImage(e, 1)
            }}>{t('dinein')}</button>
            <button className="button-group-button cursor-pointer" onClick={(e) => {
              changeImage(e, 2)
            }}>{t('delivery')}</button>
          </div>
        </div>
        <div className="product-modal-container-title border-bot">
          <div className="product-modal-container-title-container">
            <span className="product-modal-container-title-container-name">
              {
                productDetails?.Product?.Name[i18next.language] || productDetails?.Product?.Name?.EN || productDetails?.Product?.Name?.VN || productDetails?.Product?.Name?.CN || productDetails?.Product?.Name?.TH
              }
            </span>
            <span className="product-modal-container-title-container-price margin-bottom-10">
              <CurrencyFormat
                value={showPrice(SaleDateFrom,SaleDateTo, SalePrice, Price.Regular)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₱ '}
              />
            </span>
            {/*<PriceVariation price={Price.DineIn} text={t('dinein') + " :"} />*/}
            {/*<PriceVariation price={Price.Delivery} text={t('delivery') + " :"} />*/}


          </div>
          <button
            onClick={(e) => {
              addToCart(e, productDetails);
            }}
            className="product-modal-container-title-container-button">
            {t('addtocart')}

          </button>
        </div>
        <div className="product-modal-container-description border-bot">
          <h1 className='description-title'>
            {t('description')}
          </h1>
          <div className="description-content">
            {parse(String(productDetails.Product.Description[i18next.language] || productDetails.Product.Description.EN || productDetails.Product.Description.VN || productDetails.Product.Description.CN || productDetails.Product.Description.TH || "No description"))}
          </div>
        </div>
        <div className="product-modal-container-description modal-ingredient">
          <h1 className='description-title'>
            {t('ingredients')}
          </h1>
          <ul>
            {
              _.isEmpty(Product?.Ingredients) ? (<span>No Ingredients set</span>) : (
                _.map(Product?.Ingredients, (value, key) => {
                  return (
                    <li key={key} className="ingredient">{value}</li>
                  )
                })
              )
            }
          </ul>
        </div>
        <button
          onClick={(e) => {
            addToCart(e, productDetails);
          }}
          className="product-modal-add-cart">
          {' '}
          {t('addtocart')}
        </button>
        <button
          className="close-btn"
          onClick={() => {
            HideItem('#productDetailModal');
          }}>
          <ArrowBackIosIcon sx={{ fontSize: '20px' }} />
        </button>
      </div>
    </div>
  );
};

export default ProductModal;
