import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { logout, UpdatePassword } from '../PublicHelper';
const ChangePassword = () => {
  const _ = require('lodash');
  const node = useRef(null);
  const history = useNavigate()
  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      document.querySelector("#ChangePasswordModal").style.display = "none";
    };
    document.querySelector("#ChangePasswordModal").style.display = "none";
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])
  const hideAddLocation = (e) => {
    e.preventDefault()
    document.querySelector("#ChangePasswordModal").style.display = "none";
  }
  const [password, setPassword] = useState({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  })
  const [error, setError] = useState({
    OldPasswordE: "",
    NewPasswordE: "",
    ConfirmPasswordE: "",
  })
  const submitChange = (e) => {
    e.preventDefault()
    UpdatePassword(password).then(
      res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Password successfully changed!',
          showConfirmButton: false,
          timer: 1000,
          footer: "&#169; Pao Kitchen 2023",
        })
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    )
      .catch(err => {
        console.log(err.response)
        if (_.isEmpty(err.response.data.data)) {
          const { response } = err
          logout(response, history)
        } else {
          setError({
            ...error,
            OldPasswordE: err.response.data.data.OldPassword,
            NewPasswordE: err.response.data.data.NewPassword,
            ConfirmPasswordE: err.response.data.data.ConfirmPassword,
          })

        }
      })
  }
  const handleChange = (e) => {
    const { value, name } = e.target
    setPassword({ ...password, [name]: value })
  }
  const { OldPassword, NewPassword, ConfirmPassword } = password
  const { OldPasswordE, NewPasswordE, ConfirmPasswordE } = error
  return (
    <div className="modal-container" id="ChangePasswordModal">
      <form action="" method="POST" onSubmit={(e) => {
        submitChange(e)
      }}>
        <div ref={node} className="modal-container-item">
          <div className='modal-container-item-usertitle' >{t('changepassword')}</div>

          <div className="input-update mb">
            <input type="text" name="OldPassword" value={OldPassword} id="OldPassword" onChange={(e) => { handleChange(e) }} />
            <small>{OldPasswordE}</small>
            <label htmlFor="OldPassword">{t('oldpassword')}</label>
          </div>
          <div className="input-update mb">
            <input type="text" name="NewPassword" value={NewPassword} id="NewPassword" onChange={(e) => { handleChange(e) }} />
            <label htmlFor="NewPassword">{t('newpassword')}</label>
            <small>{NewPasswordE}</small>
          </div>
          <div className="input-update mb">
            <input type="text" name="ConfirmPassword" value={ConfirmPassword} id="ConfirmPassword" onChange={(e) => { handleChange(e) }} />
            <label htmlFor="ConfirmPassword">{t('confirmpassword')}</label>
            <small>{ConfirmPasswordE}</small>
          </div>


          <div className='modal-container-item-button'>
            <button type='submit' className='modal-container-item-button-change' >{t('change')}</button>
          </div>
          <button className='modal-container-item-close' onClick={(e) => { hideAddLocation(e) }}><ChevronLeftIcon /></button>
        </div>

      </form>
    </div>
  )

}

export default ChangePassword;
