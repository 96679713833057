import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import AccessContainer from "./UpdateUserAccess/AccessContainer";
import BranchContainer from "./UpdateUserAccess/BranchContainer";
const UpdateUserAccess = ({ closeStyle, userUpdate, token, baseUrl }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const updateUserForm = useRef(null);

  //const { page, search, isActive } = dataState;
  const {
    usernameUpdate,
    roleUpdate,
    accessUpdate,
    branchesUpdate /*,passwordUpdate, _id, roleUpdate, isActiveUpdate */,
  } = userUpdate;
  const [isEditBranches, setIsEditBranch] = useState(false);
  const [isEditAccess, setIsEditAccess] = useState(false);

  const axios = require("axios");
  const [state, setState] = useState({
    branchLists: [{ city: "", name: "", id: "", isCheck: "" }],
  });
  const [access, setAccess] = useState({
    accessLists: [{ name: "", code: "", isCheck: "" }],
  });
  const { branchLists } = state;
  const { accessLists } = access;

  useEffect(() => {
    const handleClick = (e) => {
      if (updateUserForm.current.contains(e.target)) {
        return;
      }
      reactSelectClass("content-main-access-user").style.display = "none";
      branchReset();
    };

    reactSelectClass("content-main-access-user").style.display = "none";
    branchReset();
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);
  //Codes relater to BOTH type of Update
  const reactSelectId = (id) => {
    return document.querySelector("#" + id);
  };
  const reactSelectClass = (classValue) => {
    return document.querySelector("." + classValue);
  };
  const branchReset = () => {
    reactSelectId("editBranchButton").innerHTML = "edit";
    reactSelectId("backBranchButton").style.display = "none";
    reactSelectId("editAccessButton").innerHTML = "edit";
    reactSelectId("backAccessButton").style.display = "none";
    setIsEditBranch(false);
    setIsEditAccess(false);
  };
  const closeUpdateUserForm = () => {
    reactSelectClass("content-main-access-user").style.display = "none";
    reactSelectId("editBranchButton").innerHTML = "edit";
    reactSelectId("editAccessButton").innerHTML = "edit";
    setIsEditBranch(false);
    setIsEditAccess(false);
  };
  const addOrRemoveBranch = (i) => (event) => {
    setState((state) => {
      state.branchLists[i].isCheck = !state.branchLists[i].isCheck;
      return {
        branchLists: state.branchLists,
      };
    });
  };
  const handleAllChecked = (event) => {
    branchLists.forEach((branch) => (branch.isCheck = event.target.checked));
    setState({ branchLists: branchLists });
  };

  // Codes related to BRANCHES
  const backBranch = (e) => {
    e.preventDefault();
    reactSelectId("editBranchButton").innerHTML = "edit";
    reactSelectId("backBranchButton").style.display = "none";
    setIsEditBranch(!isEditBranches);
  };

  const editBranches = (e) => {
    e.preventDefault();
    if (isEditBranches) {
      reactSelectId(
        "editBranchButton"
      ).innerHTML = `<div class="loader"></div>`;

      let toSend = { Branches: [] };
      const empty = (branchesUpdate) => (branchesUpdate.length = 0);
      empty(branchesUpdate);
      Object.keys(branchLists).forEach((key, index) => {
        const { id, name, isCheck, city } = branchLists[key];
        if (isCheck) {
          toSend.Branches.push({
            Name: name,
            BranchId: id,
            City: city,
          });
          branchesUpdate.push({
            Name: name,
            BranchId: id,
            City: city,
          });
        }
      });

      submitUdpdateBranch(userUpdate._id, toSend);
    } else {
      reactSelectId(
        "editBranchButton"
      ).innerHTML = `<div class="loader"></div>`;

      getBranchList();
    }
  };
  const submitUdpdateBranch = (id, body) => {
    let config = {
      method: "patch",
      url: `${baseUrl}/cms/users/update/${id}/update-branches`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: body,
    };

    axios(config)
      .then(function (response) {
        Toast.fire({
          icon: "success",
          title: "Updated successfully",
        });
        reactSelectId("editBranchButton").innerHTML = "edit";
        reactSelectId("backBranchButton").style.display = "none";
        setIsEditBranch(!isEditBranches);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getBranchList = () => {
    let config = {
      method: "get",
      url: `${baseUrl}/cms/branch/list`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    axios(config)
      .then(function (response) {
        const { paginatedResults } = response.data.data;

        let List = [];
        let check = false;
        Object.keys(paginatedResults).forEach((key, index) => {
          const { _id, Name, CityAddress } = paginatedResults[key];

          let arrayIndex = Object.keys(branchesUpdate).filter((key, index) => {
            return branchesUpdate[key].BranchId === _id;
          });
          try {
            if (branchesUpdate[arrayIndex].BranchId === _id) {
              check = true;
            } else {
              check = false;
            }
            console.log(check);
          } catch (error) {
            check = false;
          }

          List.push({
            name: Name,
            id: _id,
            isCheck: check,
            city: CityAddress,
          });
        });
        setState({ branchLists: List });
        reactSelectId("editBranchButton").innerHTML = `save`;
        reactSelectId("backBranchButton").style.display = "flex";
        setIsEditBranch(!isEditBranches);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  // Code related to ACCESS

  const backAccess = (e) => {
    e.preventDefault();
    reactSelectId("editAccessButton").innerHTML = "edit";
    reactSelectId("backAccessButton").style.display = "none";
    setIsEditAccess(!isEditAccess);
  };
  const editAccess = (e) => {
    e.preventDefault();
    if (isEditAccess) {
      reactSelectId(
        "editAccessButton"
      ).innerHTML = `<div class="loader"></div>`;
      let toSend = { Access: [] };
      const empty = (accessUpdate) => (accessUpdate.length = 0);
      empty(accessUpdate);
      Object.keys(accessLists).forEach((key, index) => {
        const { code, name, isCheck } = accessLists[key];
        if (isCheck) {
          toSend.Access.push({
            Code: code,
            AccessName: name,
          });
          accessUpdate.push({
            Code: code,
            AccessName: name,
          });
        }
      });

      submitUpdateAccess(userUpdate._id, toSend);
    } else {
      reactSelectId(
        "editAccessButton"
      ).innerHTML = `<div class="loader"></div>`;

      getAccessList();
    }
  };

  const submitUpdateAccess = (id, body) => {
    let config = {
      method: "patch",
      url: `${baseUrl}/cms/users/update/${id}/update-access`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: body,
    };

    axios(config)
      .then(function (response) {
        Toast.fire({
          icon: "success",
          title: "Updated successfully",
        });
        reactSelectId("editAccessButton").innerHTML = "edit";
        reactSelectId("backAccessButton").style.display = "none";
        setIsEditAccess(!isEditAccess);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAccessList = () => {
    let config = {
      method: "get",
      url: `${baseUrl}/cms/users/access-list`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    axios(config)
      .then(function (response) {
        const { data } = response.data;
        let tempAccess = [];
        let check = false;
        Object.keys(data).forEach((key) => {
          const { Code, AccessName } = data[key];
          let arrayIndex = Object.keys(accessUpdate).filter((key, index) => {
            return accessUpdate[key].Code === Code;
          });
          try {
            if (accessUpdate[arrayIndex].Code === Code) {
              check = true;
            } else {
              check = false;
            }
          } catch (error) {
            check = false;
          }
          tempAccess.push({
            name: AccessName,
            code: Code,
            isCheck: check,
          });
        });
        reactSelectId("editAccessButton").innerHTML = "save";

        setAccess({ accessLists: tempAccess });
        setIsEditAccess(!isEditAccess);
        reactSelectId("backAccessButton").style.display = "flex";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addOrRemoveAccess = (i) => (event) => {
    setAccess((access) => {
      access.accessLists[i].isCheck = !access.accessLists[i].isCheck;
      return {
        accessLists: access.accessLists,
      };
    });
  };
  const handleAllCheckedAccess = (event) => {
    accessLists.forEach((access) => (access.isCheck = event.target.checked));
    setAccess({ accessLists: accessLists });
  };

  return (
    <div ref={updateUserForm} className="content-main-access-user">
      <div className="content-main-access-user-header">
        <div className="content-main-access-user-header-title">
          {usernameUpdate}'s access
        </div>
        <div className="content-main-access-user-header-close">
          <CloseIcon sx={closeStyle} onClick={closeUpdateUserForm} />
        </div>
      </div>
      <div>
        <form className="content-main-access-user-form">
          <div className="content-main-update-user-form-item">
            <div className="content-main-update-user-form-item-role">
              <span>
                <b>Role:</b>
              </span>{" "}
              <span>{roleUpdate}</span>
            </div>
          </div>
          <div className="content-main-update-user-form-item">
            <BranchContainer
              {...{
                backBranch,
                editBranches,
                isEditBranches,
                handleAllChecked,
                branchLists,
                addOrRemoveBranch,
                branchesUpdate,
              }}
            />
            <AccessContainer
              {...{
                backAccess,
                editAccess,
                isEditAccess,
                handleAllCheckedAccess,
                accessLists,
                addOrRemoveAccess,
                accessUpdate,
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateUserAccess;
