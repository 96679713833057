import _ from "lodash";
import CurrencyFormat from "react-currency-format";
import ScrollContainer from "react-indiana-drag-scroll";

const AnalyticsTableProducts = ({ tableContents }) => (
  <ScrollContainer className="content-product-table" style={{minHeight: "auto"}}>
    <div className="content-product-table-head">
      <div className="content-product-table-head-item big-item normal-start item-name">PRODUCTS</div>
      <div className="content-product-table-head-item normal center item-name">ORDER COUNT</div>
      <div className="content-product-table-head-item normal center item-name">QUANTITY</div>
      <div className="content-product-table-head-item normal center item-name">SALES</div>
    </div>
    {
      !_.isEmpty(tableContents) ? (
        Object.keys(tableContents).map((key, index) => {
          const { SKU, Sold, Total, Quantity } = tableContents[key]
            const Name = tableContents[key]?.Name ?? {}
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item big-item normal-start item-name">
                <div className="product-name width-100 gap-30">
                  <strong>{SKU}</strong>
                  <div className="product-name-title-100">
                    <div className="width-100">
                      <span>
                        <b>{Name.EN || Name.VN || Name.CN || Name.TH}</b>
                      </span>
                    </div>
                    <div>
                      <small>{Name.VN || Name.EN || Name.CN || Name.TH}</small>
                    </div>

                  </div>
                </div>
              </div>
              <div className="content-product-table-data-item normal center item-name">
                {Sold || "N/A"}
              </div>
            <div className="content-product-table-data-item normal center item-name">
                {Quantity || "N/A"}
            </div>
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Total}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>


            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item big-item normal-start item-name">

          </div>
          <div className="content-product-table-data-item normal center item-name">
            No Available Reports Found!
          </div>
          <div className="content-product-table-data-item normal center item-name">

          </div>



        </div>


      )}
  </ScrollContainer >
);

AnalyticsTableProducts.defaultProps = {
  tableContents: []
}

export default AnalyticsTableProducts;
