import { Backdrop, Fade, Modal, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { GetLimitationById, UpdateLimitations } from '../../discountHelper';
import { limitations } from '../../discountUtils';

const UpdateLimitationForm = ({
  modalLimitation,
  closeModalLimitation,
  refreshTable,
  id
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  useEffect(() => {
    getLimitationById(id)
    setIsLoading(false)
    return () => {
    }
    //eslint-disable-next-line
  }, [id])
  const [fetching, setIsFetching] = useState(false)
  const [limitation, setLimitation] = useState(limitations)
  const [errorList, setErrorList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const submitLimitations = (e) => {
    e.preventDefault()
    console.log("UPDATE LIMITATION", limitation);
    setIsLoading(true)
    setErrorList({})
    UpdateLimitations(id, limitation)
      .then(
        res => {
          console.log(res);
          setIsLoading(false)
          closeModalLimitation()
        }
      )
      .then(
        () => {
          refreshTable(e)
        }
      ).catch(
        err => {
          setIsLoading(false)
          console.log(err?.response);
        }
      )

  }
  const getLimitationById = (id) => {
    setIsFetching(true)
    GetLimitationById(id)
      .then(
        res => {
          console.log(res?.data);
          setLimitation(res?.data)
        }
      ).then(
        () => {
          setIsFetching(false)
        }
      )
      .catch(
        err => {

          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Error occured while doing your request!",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          }).then(
            () => {
              closeModalLimitation()
              setIsFetching(false)
            }
          );
        }
      )
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setLimitation(oldValue => {
      return {
        ...oldValue,
        [name]: name === "NoDays" || name === "RedeemLimit" ? Number(value) : value
      }
    })
  }
  const handleChangeName = (e) => {
    const { name, value } = e.target
    setLimitation(oldValue => {
      return {
        ...oldValue,
        Description: {
          ...oldValue.Description,
          [name]: value
        }
      }
    })
  }
  const { LoginType, NoDays, RedeemLimit, PeriodName, Description, Status } = limitation
  return (
    <Modal
      open={modalLimitation}
      onClose={closeModalLimitation}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998
      }}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={modalLimitation}>
        <Box sx={{ ...style, width: 400 }}>
          {
            fetching ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ margin: 1 }} width={"100%"}>
                  <h3>Update Limitation</h3>
                  <Skeleton
                    animation="wave"
                    height={70}
                    width={"100%"}
                  />
                  <Skeleton
                    animation="wave"
                    height={70}
                    width={"100%"}
                  />
                  <Skeleton
                    animation="wave"
                    height={70}
                    width={"100%"}
                  />
                </Box>
              </Box>
            ) :
              (
                <div className='form-modal-container'>
                  <h3>Update Limitation</h3>
                  <hr />
                  <div className='input'>
                    <label htmlFor="nameEN">Description: EN</label>
                    <input onChange={(e) => handleChangeName(e)} type="text" id="nameEN" name='EN' value={Description.EN} placeholder="Description of limitation (EN)" />
                  </div>
                  <div className='input'>
                    <label htmlFor="nameVN">Description: VN</label>
                    <input onChange={(e) => handleChangeName(e)} type="text" id="nameVN" name='VN' value={Description.VN} placeholder="Description of limitation (VN)" />
                  </div>
                  {
                    !_.isEmpty(errorList?.Description) ?
                      (<small className='error-message'>{errorList?.Description}</small>) : (<></>)
                  }
                  <div className='input'>
                    <label htmlFor="limitGroup">Group</label>
                    <select onChange={(e) => handleChange(e)} name="LoginType" id="limitGroup" value={LoginType}>
                      <option value="SHOWTOALL">Show to all</option>
                      <option value="LOGINONLY">Login Only</option>
                    </select>
                  </div>
                  <small className='text-grey'>
                    * Limitation will be shown according to the selected login type on coupon.
                  </small>
                  <div className='input'>
                    <label htmlFor="limitType">Type</label>
                    <select onChange={(e) => handleChange(e)} name="PeriodName" id="limitType" value={PeriodName}>
                      {(LoginType == "LOGINONLY") ? (<>
                          <option value="DAYS">By Days</option>
                          <option value="ONLYONCE">Only Once</option>
                          <option value="UNLIMITED">Unlimited</option>
                          <option value="CLAIMCOUNT">Claim Count</option>
                        </>
                        ) : (<>
                          <option value="UNLIMITED">Unlimited</option>
                          <option value="CLAIMCOUNT">Claim Count</option>
                        </> 
                        ) 
                      } 
                    </select>
                  </div>
                  <div className='input'>
                    <label htmlFor="numberOfDays">Number Of Days</label>
                    <input onChange={(e) => handleChange(e)} type="number" id="numberOfDays" name='NoDays' min={0} value={NoDays} placeholder="Number of days to reset" />
                  </div>
                  <small className='text-grey'>
                    If redeem limit is reached, coupon will be claimable again after the entered no. of days. E.g: 1 time in every 2 days.
                  </small>
                  <div className='input'>
                    <label htmlFor="redeemLimit">Redeem Limit</label>
                    <input onChange={(e) => handleChange(e)} type="number" id="redeemLimit" name='RedeemLimit' min={0} value={RedeemLimit} placeholder="Number of days to reset" />
                  </div>
                  <small className='text-grey'>
                    No. of times coupon can be claimed by the customer.
                  </small>
                  <div className='input'>
                    <label htmlFor="limitStatus">Status</label>
                    <select onChange={(e) => handleChange(e)} name="Status" id="limitStatus" value={Status}>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                </div>
              )
          }
          <div className='flex-row-end gap-10'>
            <button className='btn-cancel' onClick={closeModalLimitation}>Close</button>
            <button className='btn-submit' disabled={isLoading || fetching} onClick={(e) => submitLimitations(e)}>{isLoading ? (<div className='loader'></div>) : "Update"}</button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
UpdateLimitationForm.defaultProps = {
  modalLimitation: false,
  closeModalLimitation: () => { },
  refreshTable: () => { },
  id: ""
}
export default UpdateLimitationForm;
