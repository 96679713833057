import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from "chart.js";
import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
export const orders = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top"
      },
      title: {
        display: true,
        text: "# of Orders (Daily)"
      }
    }
};
export const amount = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top"
      },
      title: {
        display: true,
        text: "Meal Spent (Daily)"
      }
    }
};
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Charts = ({ chartData }) => {

    const [dataOrders, setDataOrders] = useState({
      data: {
        labels: [],
        datasets: [
          {
            label: "# of Orders (Daily)",
            data: [],
            borderColor: "#50311b",
            backgroundColor: "#50311b"
          },
        ]
      }
    })
    const [dataAmounts, setDataAmounts] = useState({
      data: {
        labels: [],
        datasets: [
          {
            label: "Meal Spent (Daily)",
            data: [],
            borderColor: "#f08d19",
            backgroundColor: "#f08d19"
          },
        ]
      }
    })
    useEffect(() => {
      setDataOrders(
        {
          data: {
            labels: _.map(chartData, (value, index) => {
              const { Date } = value
              return moment.utc(Date).format("YYYY-MM-DD")
            }),
            datasets: [
              {
                label: "# of Orders (Daily)",
                data: _.map(chartData, (value, index) => {
                  const { Orders } = value
                  return Orders
                }),
                borderColor: "#50311b",
                backgroundColor: "#50311b"
              },
            ]
          }
        }
      )
      setDataAmounts(
        {
          data: {
            labels: _.map(chartData, (value, index) => {
              const { Date } = value
              return moment.utc(Date).format("YYYY-MM-DD")
            }),
            datasets: [
              {
                label: "Meal Spent (Daily)",
                data: _.map(chartData, (value, index) => {
                  const { Total } = value
                  return Total
                }),
                borderColor: "#f08d19",
                backgroundColor: "#f08d19"
              },
            ]
          }
        }
      )
      return () => {
  
      }
    }, [chartData])
  
    return (
      <div className="customer-analytics-charts">
        <div className="customer-analytics-charts-graph">
          <Line options={orders} data={dataOrders.data} />
        </div>
        <div className="customer-analytics-charts-graph ">
          <Line options={amount} data={dataAmounts.data} />
        </div>
      </div>
    );
  };
  Charts.defaultProps = {
    chartData: []
  }
  export default Charts;