import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useEffect, useRef, useState } from "react";
import placeholderImage from './../../../assets/placeholderImage.jpg';
const ProductCard = ({ title, amount, image, id, addProductToCart, showProductDetails }) => {
  const [imageData, setImageData] = useState()
  const imageNode = useRef(null)
  const onHover = [
    {
      color: "#50311b",
    },
    {
      "&:hover": {
        color: "#eaa460",
      },
    },
  ];
  useEffect(() => {
    setImageData(image?.Delivery)
    return () => {

    }
  }, [image])
  const toDineIn = (e) => {
    e.preventDefault()
    setImageData(image?.DineIn)
  }
  const toDelivery = (e) => {
    e.preventDefault()
    setImageData(image?.Delivery)
  }
  const addToCart = (e, product) => {
    e.preventDefault()
    addProductToCart(e, product)
    // console.log(product);
  }
  const imgError = (e) => {
    e.preventDefault()
    imageNode.current.src = placeholderImage
    return true;
  }
  return (
    <div className="product-card" >
      <div className="image">
        <img
          ref={imageNode}
          src={imageData || placeholderImage}
          alt="food item"
          onError={(e) => imgError(e)}
          onClick={(e) => { showProductDetails(e, id) }}
        ></img>
        <button className="add-to-cart" onClick={(e) => addToCart(e, id)}>Add to cart</button>
      </div>

      <div className="buttons">
        {/* <button aria-label="previous">
          <NavigateBeforeIcon sx={onHover} onClick={(e) => toDineIn(e)} />
        </button> */}
        {/* <button aria-label="next">
          <NavigateNextIcon sx={onHover} onClick={(e) => toDelivery(e)} />
        </button> */}
      </div>

      <div className="card-description" onClick={(e) => { showProductDetails(e, id) }}>
        <h4>{title}</h4>
        <h3>₱{amount}</h3>
      </div>
    </div>
  );
};

export default ProductCard;
