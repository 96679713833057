import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { GetPaymentMethodList } from '../../EcommerceHelper'

const PaymentDetail = ({ PaymentMode, closeModalEdit, submitPaymentDetail }) => {
  const [paymentList, setPaymentList] = useState([])
  const [bankList, setBankList] = useState([])
  const [payment, setPayment] = useState({
    paymentMode: PaymentMode,
    paymentbankId: ""
  })
  useEffect(() => {
    getPaymentMethod()
    return () => {
    }
  }, [])
  const getPaymentMethod = () => {
    GetPaymentMethodList(1, "", {}).then(res => {
      const { paginatedResults } = res?.data
      setPaymentList(paginatedResults)
    }).catch(err => {
      setPaymentList([])
      console.log(err.response);
    })
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "paymentMode") {
      setPayment({
        ...payment,
        [name]: value,
        paymentbankId: ""
      })
      setBankList(_.find(paymentList, result => {
        return result.Code === value
      }).Banks || [])
    }
    clearClass()
  }

  const choosePayment = (e, _id) => {
    e.preventDefault()
    setPayment({
      ...payment,
      paymentbankId: _id
    })
    clearClass()
    document.querySelector("#paymentEdit" + _id).classList.add("payment-active")
  }
  const [paymentE, setPaymentE] = useState("")
  const submitPaymentEdit = (e) => {
    setPaymentE("")
    const { paymentMode, paymentbankId } = payment
    if (_.isEmpty(paymentMode)) {
      setPaymentE("Please choose payment menthod before saving!")
    } else {
      if (paymentMode !== "COD") {
        if (_.isEmpty(paymentbankId)) {
          setPaymentE("Please choose a bank before saving!")
        } else {
          submitPaymentDetail(e, payment);
        }
      } else {
        submitPaymentDetail(e, payment);
      }
    }

  }
  const clearClass = () => {
    let paymentCard = document.querySelectorAll(".payment-card")
    for (let i = 0; i < paymentCard.length; i++) {
      paymentCard[i].classList.remove("payment-active")
    }
  }
  return (
    <div className='order-modal-container'>
      <h3>Payment Details</h3>
      {
        _.isEmpty(paymentList) ? <>No payment method found</> : (
          <div className='input'>
            <label htmlFor="paymentModeEdit">Payment Method</label>
            <select type="text" id="paymentModeEdit" name="paymentMode" onChange={(e) => { handleChange(e) }} value={payment.paymentMode}>
              {
                _.map(paymentList, (value, key) => {
                  return <option key={key} value={value.Code}>{value.Name}</option>
                })
              }
            </select>
          </div>
        )
      }
      {
        _.isEmpty(bankList) ? <></> : (<>
          {
            _.map(bankList, (value, key) => {
              const { _id, BankName, Number, Account } = value
              return (
                <div key={key} onClick={(e) => choosePayment(e, _id)} className='payment-card' id={'paymentEdit' + _id}>
                  <div className='flex-row-start gap-5'> <span>Bank: </span><span>{BankName}</span></div>
                  <div className='flex-row-start gap-5'> <span>Account Name: </span><span>{Account}</span></div>
                  <div className='flex-row-start gap-5'> <span>Account Number: </span><span>{Number}</span></div>
                  <div className='flex-row-start gap-5'> <span>Branch: </span><span>N/A</span></div>
                </div>
              )
            })
          }
        </>
        )
      }
      {
        !_.isEmpty(paymentE) ? <small className='error-message'>{paymentE}</small> : <></>
      }
      <div className='flex-end gap-10'>
        <button className='btn-cancel' onClick={() => {
          closeModalEdit()
        }}>Cancel</button>
        <button className='btn-submit' onClick={(e) => {
          submitPaymentEdit(e)
        }}>Save</button>
      </div>
    </div>
  )
}

export default PaymentDetail