import { useTitle } from 'react-use';
import Slider from '../../ProductManager/Slider';
import AddBranchButton from './AddBranchButton';
import BranchesTable from './BranchesTable';
import BranchesFilter from './Filter/BranchesFilter';
const Branches = () => {
  useTitle("Pao Kitchen | Branches")
  const productFilter = (e, searchValues) => {
    console.log(searchValues);
  }
  const clearFilter = (e) => {
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        branch manager / branches
      </section>
      <BranchesFilter {...{ productFilter, clearFilter }} />
      <BranchesTable tableContents={[]} />
      <Slider tableId={"branchTable"} />
      <AddBranchButton tooltip={"Add Branch"} />
    </main>
  )
}

export default Branches