import { Backdrop, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Acceptpayment from './Acceptpayment';
import AddFee from './AddFee';
import AddproductModal from './AddproductModal';
import CustomerDetail from './CustomerDetail';
import ItemDetail from './ItemDetail';
import PaymentDetail from './PaymentDetail';
import PreparationTime from './PreparationTime';
import PrintPOS from './PrintPOS';
import PrintSmallPOS from './PrintSmallPOS';
import PrintPaoKitchenPOS from './PrintPaoKitchenPOS';
import PrintWOPrice from './PrintWOPrice';
import ShippingCouriers from './ShippingCouriers';
import ShippingPlaceOrder from './ShippingPlaceOrder';
import GenerateQRCode from './GenerateQRCode'
import ApplyVoucher from './ApplyVoucher'

const EditOrderModal = ({
  modalEdit,
  modalPreparationTime,
  closeModalEdit,
  PreferredDeliveryDate,
  submitNewPreparationTime,
  orderE,
  itemDetail,
  modalItemDetail,
  submitNewItem,
  modalPayment,
  PaymentMode,
  submitPaymentDetail,
  modalCustomer,
  setOrder,
  order,
  submitCustomerDetail,
  modalPaymentStatus,
  submitCustomerPayment,
  modalAddProduct,
  selectedAddProduct,
  setSelectedAddProduct,
  submitAddProduct,
  modalAddFee,
  submitFee,
  modalPrintPOS,
  paymentMethod,
  subtotal,
  computeAddedFee,
  modalPrintWOPrice,
  modalPrintSmallPOS,
  modalPrintPAO,
  modalShippingOption,
  setCurrentPlaceOrderId,
  currentPlaceOrderId,
  modalShippingPlaceOrder,
  getOrderDetail,
  modalGenerateQRCode,
  modalApplyVoucher,
  submitUpdateVoucher
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      open={modalEdit}
      onClose={closeModalEdit}
      aria-labelledby="order edit modal"
      aria-describedby="order edit modal"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      style={{
        zIndex: 998
      }}
      // BackdropProps={{
      //   timeout: 500,
      // }}
      >
      {/* <Fade in={modalEdit}> */}
        {/* <Box sx={{ ...style, width: modalAddProduct || modalAddFee ? 600 : modalPrintPOS || modalPrintWOPrice ? 794 : 400}}> */}
        
          {
            modalPreparationTime ?
              <Box sx={{ ...style, width: 400}}>
              <PreparationTime
                {...{
                  PreferredDeliveryDate,
                  closeModalEdit,
                  submitNewPreparationTime,
                  orderE,
                }}
              /></Box> :
              modalItemDetail ?
                <Box sx={{ ...style, width: 400}}>
                <ItemDetail {...{
                  itemDetail,
                  orderE,
                  closeModalEdit,
                  submitNewItem
                }} />
                </Box> :
                modalPayment ?
                  <Box sx={{ ...style, width: 400}}>
                  <PaymentDetail {...{
                    PaymentMode,
                    closeModalEdit,
                    submitPaymentDetail
                  }} />
                  </Box> : 
                    modalCustomer ? 
                    <Box sx={{ ...style, width: 400}}>
                      <CustomerDetail {...{
                      closeModalEdit,
                      order,
                      submitCustomerDetail
                    }} />
                    </Box> : modalPaymentStatus ? 
                     <Box sx={{ ...style, width: 400}}>
                      <Acceptpayment {...{ closeModalEdit, order, submitCustomerPayment }} />
                     </Box>
                        
                    : modalAddProduct ?
                      <Box sx={{ ...style, width: 600}}><AddproductModal {...{
                        closeModalEdit,
                        selectedAddProduct,
                        setSelectedAddProduct,
                        order,
                        submitAddProduct
                      }} /></Box>
                      : modalAddFee ? 
                      <Box sx={{ ...style, width: 600}}><AddFee {...{
                        closeModalEdit,
                        submitFee,
                        order
                      }} /></Box> : modalPrintPOS ? 
                      <Box sx={{ ...style, width: 400}}>
                      <PrintPOS {...{
                        closeModalEdit,
                        order,
                        paymentMethod,
                        subtotal,
                        computeAddedFee
                      }} /></Box> : modalPrintWOPrice ? 
                      <Box sx={{ ...style, width: 794}}>
                      <PrintWOPrice
                        {...{
                          closeModalEdit,
                          order,
                          paymentMethod,
                        }}
                      /></Box> : modalPrintSmallPOS ? 
                      <Box sx={{ ...style, width: 400}}><PrintSmallPOS {...{
                        closeModalEdit,
                        order,
                        paymentMethod,
                        subtotal,
                        computeAddedFee
                      }}
                      /></Box> : modalPrintPAO ? 
                        <Box sx={{ ...style, width: 400}}><PrintPaoKitchenPOS {...{
                          closeModalEdit,
                          order,
                          paymentMethod,
                          subtotal,
                          computeAddedFee
                      }} /></Box> : modalShippingOption ? 
                      <Box sx={{ ...style, width: 400}}><ShippingCouriers {...{
                        closeModalEdit,
                        setOrder,
                        order,
                        setCurrentPlaceOrderId,
                        currentPlaceOrderId
                      }} /></Box> : modalShippingPlaceOrder ? 
                      <Box sx={{ ...style, width: 794}}><ShippingPlaceOrder {...{
                        closeModalEdit,
                        setOrder,
                        order,
                        setCurrentPlaceOrderId,
                        currentPlaceOrderId,
                        getOrderDetail
                      }} /></Box> :
                       modalGenerateQRCode ?
                      <Box sx={{ ...style, width: 650}}><GenerateQRCode {...{ 
                        order
                      }} /></Box> :
                      modalApplyVoucher ?
                      <Box sx={{ ...style, width: 400}}><ApplyVoucher {...{
                        closeModalEdit, 
                        order, 
                        submitUpdateVoucher
                      }} /></Box> :
                        <></>
          }

        {/* </Box> */}
      {/* </Fade> */}
    </Modal>
  );
};

export default EditOrderModal;
