import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect } from 'react';

const CartItem = ({ ProductItem, quantitySubtract, quantityAdd, removeItemOnCart, index }) => {
  const { Quantity, ProductName, ProductId, Price, SaleDateFrom,SaleDateTo, SalePrice } = ProductItem
  useEffect(() => {


    return () => {

    }
  }, [ProductItem])

  const showPrice = () => {
    
    const isBetween = moment().tz( 'Asia/Manila' ).isBetween( SaleDateFrom, SaleDateTo )
    if (isBetween && SalePrice !== 0) {
      return SalePrice;
    } else {
      return Price?.Regular;
    }
  }

  return (
    <div id={"cart" + ProductId} className="public-cart-list-item">
      <div className="public-cart-list-item-left">
        <button onClick={(e) => removeItemOnCart(e, ProductId, index)}>
          <CloseIcon />
        </button>
      </div>
      <div className="public-cart-list-item-middle">
        <span className="name">{ProductName[i18next.language] || ProductName?.EN || ProductName?.VN || ProductName?.CN || ProductName?.TH}</span>
        <span className="price">₱{showPrice()}</span>
      </div>
      <div className="public-cart-list-item-right">
        <button onClick={(e) => quantitySubtract(e, ProductId)}>-</button>
        <span>{Quantity}</span>
        <button onClick={(e) => quantityAdd(e, ProductId)}>+</button>
      </div>
    </div>
  );
};

export default CartItem;

