import React from "react";

const UserManager = () => {
  return (
    <main className="content-main">
      <section className="content-main-breadcrumbs">
        product management / product barcode
      </section>

      <section className="content-main-table">
        <div className="content-main-table-head">
          <div className="content-main-table-head-item">Username</div>
          <div className="content-main-table-head-item">Role</div>
          <div className="content-main-table-head-item">Status</div>
          <div className="content-main-table-head-item">Date Created</div>
          <div className="content-main-table-head-item">Actions</div>
        </div>

        <div className="content-main-table-data">
          <div className="content-main-table-data-item-result">
            No Data Found
          </div>
        </div>
      </section>
    </main>
  );
};

export default UserManager;
