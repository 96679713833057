import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const BranchContainer = ({
  backBranch,
  editBranches,
  isEditBranches,
  handleAllChecked,
  branchLists,
  addOrRemoveBranch,
  branchesUpdate,
}) => (
  <div className="content-main-update-user-form-item-access">
    <button id="backBranchButton" onClick={backBranch}>
      <ArrowBackIosIcon sx={{ color: "#50311B", fontSize: "14px" }} /> Back
    </button>
    <span>
      <b>
        Branches [
        <button
          className="access-edit"
          id="editBranchButton"
          onClick={editBranches}
        >
          edit
        </button>
        ]
      </b>
    </span>
    <div className="content-main-update-user-form-item-access-item">
      {isEditBranches ? (
        <div className="div-edit-access">
          <div className="div-edit-access-select-all">
            <input
              type="checkbox"
              name=""
              id="checkAll"
              value="checkAll"
              onClick={handleAllChecked}
            />
            <label htmlFor="checkAll">Select All</label>
          </div>
          <div className="div-edit-access-list">
            {Object.keys(branchLists).map((key, index) => {
              const { id, name, isCheck } = branchLists[key];
              return (
                <div className="div-edit-access-list-branches" key={index}>
                  <input
                    type="checkbox"
                    onChange={addOrRemoveBranch(key)}
                    name=""
                    id={id}
                    value={id}
                    checked={isCheck}
                  />
                  <label htmlFor={id}>{name}</label>
                </div>
              );
            })}
          </div>
        </div>
      ) : branchesUpdate.length ? (
        Object.keys(branchesUpdate).map((key, index) => {
          const { Name } = branchesUpdate[key];
          return (
            <div key={index} className="div-access">
              {Name}
            </div>
          );
        })
      ) : (
        <span>No branch assigned to this user</span>
      )}
    </div>
  </div>
);

export default BranchContainer;
