import { Backdrop, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { ExportOrderHourlySale, ExportOrderNormalList, ExportSoldItems } from '../../EcommerceHelper';

const ExportModal = ({
  exportModalEdit,
  closeExportModalEdit
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };
  const jwt = require("jsonwebtoken");
  const tokens = useSelector((state) => state.token);
  const { branchId, token } = tokens.token;
  const user = jwt.decode(token);
  const [exportOrder, setExportOrder] = useState({
    exportFrom: "",
    exportTo: "",
    exportType: ""
  })

  const submitExportOrder = (e) => {
    e.preventDefault()
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }

    });


    const { exportType, exportFrom, exportTo } = exportOrder
    if (!_.isEmpty(exportFrom) && !_.isEmpty(exportTo)) {

      switch (exportType) {
        case "normallist":
          exportOrderNormalList(exportFrom, exportTo, "Normal Order", "", "")
          break;
        case "saleperhour":
          exportOrderHourlySale(exportFrom, exportTo)
          break;
        case "completedorders":
          exportOrderNormalList("", "", "Completed Order", exportFrom, exportTo)
          break;
        case "solditems":
          exportSoldItems(exportFrom, exportTo)
          break;
        default:
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: "Please select type of export before proceeding!",
            footer: "Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            },
          })
          break;
      }


    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "Please complete required fields before proceeding!",
        footer: "Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        },
      });
    }
  }

  const exportOrderNormalList = (exportFrom, exportTo, type, completedFrom, completedTo) => {
    ExportOrderNormalList(exportFrom, exportTo, completedFrom, completedTo)
      .then(
        res => successExport(res, type)
      )
      .catch(
        errorModal
      )
  }

  const exportOrderHourlySale = (exportFrom, exportTo) => {
    ExportOrderHourlySale(exportFrom, exportTo)
      .then(
        res => successExport(res, "Hourly Sales Report")
      )
      .catch(
        errorModal
      )
  }
  const exportSoldItems = (exportFrom, exportTo) => {
    ExportSoldItems(exportFrom, exportTo)
      .then(
        res => successExport(res, "Sold items Sales Report")
      )
      .catch(
        errorModal
      )
  }






  const successExport = (res, order) => {
    let FileSaver = require('file-saver');
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    let branchName = _.find(user.Branches, (value, key) => {
      return value.BranchId === branchId
    })?.Name ?? ""
    FileSaver.saveAs(blob, `Pao Kitchen (${branchName}) ${order} List`);
    Swal.close();
  }
  const errorModal = err => {
    Swal.close();
    const { status } = err?.response
    status === 404 ? Swal.fire({
      icon: "error",
      title: "Failed!",
      text: "No data found!",
      footer: "Pao Kitchen 2023",
      didOpen: () => {
        Swal.hideLoading();
      },
    }) : Swal.fire({
      icon: "error",
      title: "Failed!",
      text: "An error occured while doing your request",
      footer: "Pao Kitchen 2023",
      didOpen: () => {
        Swal.hideLoading();
      },
    });
    console.log(err.response);
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setExportOrder(exportorder => {
      return {
        ...exportorder,
        [name]: value
      }
    })
  }

  const { exportFrom, exportTo } = exportOrder
  return (
    <Modal
      open={exportModalEdit}
      onClose={closeExportModalEdit}
      aria-labelledby="order edit modal"
      aria-describedby="order edit modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998
      }}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={exportModalEdit}>
        <Box sx={{ ...style, width: 600 }}>
          <h3 className='margin-bottom-10'>Export Order</h3>
          <h4 className='margin-bottom-30 text-grey'>Please choose what export format you need:</h4>
          <div className='flex-row-space-between gap-7 margin-bottom-10'>
            <div className="content-main-search-item width-100">
              <label className='text-grey' htmlFor="exportFrom">From</label>
              <input type="date" name="exportFrom" id="exportFrom" onChange={e => { handleChange(e) }} value={exportFrom} />
            </div>
            <div className="content-main-search-item width-100">
              <label className='text-grey' htmlFor="exportTo">To</label>
              <input type="date" name="exportTo" id="exportTo" onChange={e => { handleChange(e) }} value={exportTo} />
            </div>
          </div>
          <div className="checkout-input-div-2 margin-bottom-10">
            <label className="covered-input">
              <input
                className="payment-option-input-radio"
                type="radio"
                value="normallist"
                name="exportType"
                onChange={e => { handleChange(e) }}
              />
              <div className='flex-column-start-start width-100 radio-span'>
                <span>Normal List</span>
                <span className='text-grey'>Required: Date Ordered</span>
              </div>
            </label>
            <label className="covered-input">
              <input
                className="payment-option-input-radio"
                type="radio"
                value="saleperhour"
                name="exportType"
                onChange={e => { handleChange(e) }}
              />
              <div className='flex-column-start-start width-100 radio-span'>
                <span>Sales Per Hour</span>
                <span className='text-grey'>Required: Date Completed</span>
              </div>
            </label>
            <label className="covered-input">
              <input
                className="payment-option-input-radio"
                type="radio"
                value="solditems"
                name="exportType"
                onChange={e => { handleChange(e) }}
              />
              <div className='flex-column-start-start width-100 radio-span'>
                <span>Sold Items</span>
                <span className='text-grey'>Required: Date Ordered</span>
              </div>
            </label>
            <label className="covered-input">
              <input
                className="payment-option-input-radio"
                type="radio"
                value="completedorders"
                name="exportType"
                onChange={e => { handleChange(e) }}
              />
              <div className='flex-column-start-start width-100 radio-span'>
                <span>Completed Orders</span>
                <span className='text-grey'>Required: Date Completed</span>
              </div>
            </label>

          </div>
          <div className='flex-end gap-10  '>
            <button className='btn-cancel' onClick={() => {
              closeExportModalEdit()
            }}>Cancel</button>
            <button className='btn-submit' onClick={(e) => {
              submitExportOrder(e)
            }}>Proceed</button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
