export const defaultSales = {
  Card: {
    totalDiscount: 0,
    totalOrders: 0,
    totalSales: 0,
    totalShipping: 0,
    totalSubtotal: 0,
  },
  SalesPerPaymentMethod: [],
  paginatedResults: []
}