import React from 'react';
import { useTranslation } from 'react-i18next';
import { HideItem } from '../../../pages/ProductManager/productHelper';

const CheckoutModal = () => {
  const { t } = useTranslation()
  return (
    <div className="checkout-modal" id="checkoutReminder">
      <div className="checkout-modal-container ">
        <div className="checkout-modal-container-title m-bot">
          {t('customerreminders')}
        </div>
        <div className="checkout-modal-container-content m-bot">
          <span>
            {t('shippingreminder')}{' '}
          </span>
          <span>
            {' '}
            {t('earlydeliveryreminder')}
          </span>
        </div>
        <div className="checkout-modal-container-button">
          <button onClick={(e) => {
            e.preventDefault()
            HideItem("#checkoutReminder")
          }}>{t('done')}</button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;