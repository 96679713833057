import moment from "moment-timezone";
let today = moment(new Date()).tz("Asia/Manila").format("YYYY-MM-DD HH:mm:ss")
let todayEnd = moment(new Date()).tz("Asia/Manila").add(12, 'hours').format("YYYY-MM-DD HH:mm:ss")
export const autoCoupon = {
  "ExpirationDate": {
    "Start": today,
    "End": todayEnd
  },
  "MinPayout": 0,
  "MaxPayout": 0,
  "CouponStatus": true,
  "MinOrderReq": 0,
  "CouponCode": "",
  "CouponName": {
    "VN": "-",
    "EN": "",
    "CN": "-",
    "TH": "-"
  },
  "Discount": {
    "DiscountType": "By Percentage",
    "Fix": 0,
    "Percentage": 0,
    "Rate":[]
  },
  "LoginType": "LOGINONLY",
  "Limitation": "",
  "IsAllowedFreebie": false,
  "IsTagCategory": false,
  "Category": [],
  "IsAutoGenerate": true,
  "Prefix": "",
  "GenerateCount": 0,
  "BranchId": [],
  "GeneratedCoupons": []
}

export const manualCoupon = {
  "ExpirationDate": {
    "Start": today,
    "End": todayEnd
  },
  "MinPayout": 0,
  "MaxPayout": 0,
  "CouponStatus": true,
  "MinOrderReq": 0,
  "CouponCode": "",
  "CouponName": {
    "VN": "-",
    "EN": "",
    "CN": "-",
    "TH": "-"
  },
  "Discount": {
    "DiscountType": "By Fix Amount",
    "Fix": 0,
    "Percentage": 0,
    "Rate":[]
  },
  "LoginType": "LOGINONLY",
  "Limitation": "",
  "IsAllowedFreebie": false,
  "IsTagCategory": false,
  "Category": [],
  "IsAutoGenerate": false,
  "Prefix": "",
  "BranchId": []
}

export const errorCoupon = {
  "CouponCode": "",
  "CouponName": "",
  "Limitation": "",
  "MinPayout": "",
  "MaxPayout": "",
  "MinOrderReq": "" 
}

export const errorAutoCoupon = {
  "Prefix": "",
  "CouponName": "",
  "Limitation": "",
  "MinPayout": "",
  "MaxPayout": "",
  "MinOrderReq": "" 
}

export const expirationDate = {
  "ExpirationDate": {
    "Start": today,
    "End": todayEnd
  }
}

export const limitations = {
  "LoginType": "LOGINONLY",
  "NoDays": 10,
  "RedeemLimit": 50,
  "PeriodName": "",
  "Description": {
    "VN": "Chỉ một lần",
    "EN": "Only Once",
    "CN": "",
    "TH": ""
  },
  "Status": true
}

export const rateFormula = {
  "Rate": [
    {
      "_id": String(Math.random()),
      "Condition": "<",
      "Amount": 0,
      "Rate": 0
    }
  ]
}
 