import moment from "moment-timezone";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Proptypes from "prop-types";
import {EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_STATUS} from "../constant";
import { Modal } from '@mui/material';
import {useReactToPrint} from "react-to-print";
import "../style.scss"
import Logo from "../../../../../components/reusable/Logo";
import {getObjectKey} from "../../OderHelper";
const PRINT_ALL_ONLY_EXTRA = -1

const KitchenPrintOrder = ({order, type, index = 0, isOpen, onCancel, onSuccess}) => {
    const OrderRef = order?.OrderRef ?? ""
    const OrderNote = order?.OrderNote ?? ""
    const [CreatedAt, setCreatedAt] = useState(moment.now())
    const [PrefDelivery, setPrefDelivery] = useState(moment.now())
    const CustomerInfo = order?.ContactDetails ?? {}
    const ordersPending = useMemo(() => {
        return order?.OrdersPending ?? []
    }, [order])
    useEffect(() => {
        if(type === "onlyExtra") {
            const _index = index === PRINT_ALL_ONLY_EXTRA ? 0 : index
            setCreatedAt(ordersPending[_index]["createdAt"] ?? null)
            setPrefDelivery(ordersPending[_index]?.PreferredDeliveryDate ?? null)
        }
        else {
            setCreatedAt(order?.CreatedAt ?? null)
            setPrefDelivery(order?.PreferredDeliveryDate ?? null)
        }

    }, [type, ordersPending, order?.CreatedAt, index, order?.PreferredDeliveryDate])

    const renderListProduct = useCallback(() => {
        const handleMergeOrderPendingToOrderDetail = (OrderDetails = []) => {
            let newOrderDetails = [...OrderDetails]

            ordersPending.forEach(orderPending =>  {
                const orderPendingDetails = orderPending?.detail ?? []
                const status = orderPending?.status ?? 0
                if(status === EXTRA_ORDER_STATUS.cancel || status === EXTRA_ORDER_STATUS.done) {
                    return
                }

                orderPendingDetails.forEach(orderPendingDetail => {
                    const productId = orderPendingDetail?.productId ?? null
                    const quantity = orderPendingDetail?.quantity ?? 0
                    const price = orderPendingDetail?.price ?? 0
                    const productInfo = orderPendingDetail?.Product ?? {}

                    const indexItemOrderDetail = newOrderDetails.findIndex(detail => {
                        const _productId = detail?.ProductId ?? ""
                        return productId.toString().trim() === _productId.toString().trim() && productId.toString().length > 0
                    })

                    if(indexItemOrderDetail === -1) { //no found
                        newOrderDetails.push({
                            IsScan: false,
                            "ProductId": productInfo?._id,
                            "ProductName": productInfo?.Name ?? {},
                            "ProdImage": productInfo?.Images?.DineIn ?? null,
                            "SKU": productInfo?.SKU,
                            "Price": price,
                            "Quantity": quantity,
                            "TotalPrice": price * quantity,
                            "OrderProdType": "REGULAR",
                            "PreparationTime": productInfo?.PreparationTime ?? 0
                        })
                    }
                    else {
                        let itemOrderDetail = Object.assign({}, newOrderDetails[indexItemOrderDetail])
                        const _quantity = itemOrderDetail?.Quantity ?? 0
                        const _price = itemOrderDetail?.Price ?? 0
                        itemOrderDetail.Quantity = _quantity + quantity
                        itemOrderDetail.TotalPrice = itemOrderDetail.Quantity * _price

                        newOrderDetails[indexItemOrderDetail] = itemOrderDetail
                    }
                })
            })

            return newOrderDetails
        }

        const getListProducts = () => {
            let listProducts
            switch (type) {
                case getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyMain):
                    listProducts = order?.OrderDetails ?? []
                    break
                case getObjectKey(EXTRA_ORDER_PRINT_TYPES, EXTRA_ORDER_PRINT_TYPES.onlyExtra):
                    if(index === PRINT_ALL_ONLY_EXTRA) {
                        listProducts = handleMergeOrderPendingToOrderDetail( [])
                    }
                    else {
                        listProducts = ordersPending[index]["detail"]
                    }
                    break
                default:
                    listProducts = handleMergeOrderPendingToOrderDetail(order?.OrderDetails ?? [])
            }

            return listProducts
        }

        const products = getListProducts()
        const getProductName = (product) => {
            if(product.hasOwnProperty("Name")) {
                if(typeof product["Name"] === "object" && product["Name"] !== null){
                    return product["Name"]
                }
            }
            else if(product.hasOwnProperty("ProductName")) {
                if(typeof product["ProductName"] === "object" && product["ProductName"] !== null){
                    return product["ProductName"]
                }
            }
            else if(product.hasOwnProperty("Product")) {
                const detailProduct = product["Product"]
                if(detailProduct.hasOwnProperty("Name")) {
                    if(typeof detailProduct["Name"] === "object" && detailProduct["Name"] !== null){
                        return detailProduct["Name"]
                    }
                }
            }

            return {}
        }

        const getProductQuantity = (product) => {
            if(product.hasOwnProperty("Quantity")) {
                return product["Quantity"]
            }
            else if(product.hasOwnProperty("quantity")) {
                return product["quantity"]
            }

            return ""
        }

        return products.map((product, index) => {
            const key = `print-order-item-${index}`
            const productName = getProductName(product)
            const quantity = getProductQuantity(product)

            return (
                <tr key={key}>
                    <td className="table-col-name">
                        <span className="product-name-vn">{productName?.VN ?? ""}</span>
                        <span className="product-name-en">{productName?.EN ?? ""}</span>
                    </td>
                    <td className="table-col-quantity">{quantity}</td>
                </tr>
            )
        })
    }, [type, order, index, ordersPending])

    const contentPrint = useRef(null)
    const getContent = useCallback(() => {
        return contentPrint.current
    }, [contentPrint])

    const handlePrint = useReactToPrint({
        content: getContent,
        documentTitle: `${order.OrderRef}`,
        onAfterPrint: onSuccess,
        removeAfterPrint: true,
        onBeforePrint: () => {},
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                console.log("Pending 0.5s...")
                setTimeout(() => {
                    resolve(true)
                }, 500)
            })
        },
        copyStyles: true,
        // fonts: [
        //     {family: "SF Pro Display Regular, sans-serif"}
        // ]
    });

    useEffect(() => {
        if(isOpen) {
            handlePrint()
        }
    }, [handlePrint, isOpen])

    return (
        <Modal open={isOpen} closeAfterTransition onClose={onCancel}>
            <div className="kitchen-print-order-container">
                <div className="kitchen-print-order" ref={contentPrint}>
                    <div className='logo'>
                        <Logo swidth="100px" sheight="100px" />
                    </div>
                    <div className='restaurant-info'>
                        <span>176 Yakal, AGS Plaza, Makati</span>
                        <span>0968 852 6209</span>
                        <span>http://paokitchen.ph</span>
                    </div>

                    <div className='margin-bottom-30 general-info'>
                        <span>Reference: <span className="general-info-highlight">#{OrderRef}</span></span>
                        <span>Date Ordered:  <span className="general-info-highlight">{CreatedAt ? moment.utc(CreatedAt).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A") : ""}</span></span>
                        <span>Pref. Delivery Time:  <span className="general-info-highlight">{PrefDelivery ? moment.utc(PrefDelivery).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A") : ""}</span></span>
                        <span>Customer name: <span className="general-info-highlight">{CustomerInfo?.FullName ?? ""}</span></span>
                        <span>Customer phone: <span className="general-info-highlight">{CustomerInfo?.MobileNum ?? ""}</span></span>
                        <div className="note">
                            <p>Order note: </p>
                            <p className='note-receipt'>{OrderNote}</p>
                        </div>
                    </div>
                    <table className="print-list-product" border={1}>
                        <thead>
                            <tr>
                                <th className="table-col-name">Product name</th>
                                <th className="table-col-quantity">Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                        {renderListProduct()}
                        </tbody>
                    </table>
                </div >
            </div>
        </Modal>
    )
}

KitchenPrintOrder.propTypes = {
    order: Proptypes.object.isRequired,
    type: Proptypes.oneOf(["all", "onlyMain", "onlyExtra"]).isRequired,
    index: Proptypes.number,
    isOpen: Proptypes.bool.isRequired,
    onSuccess: Proptypes.func.isRequired,
    onCancel: Proptypes.func.isRequired
}

export default KitchenPrintOrder