import { useTitle } from 'react-use';
import { useEffect, useRef, useState } from "react";
import Slider from '../../ProductManager/Slider';
import AddBannerButton from './AddBannerButton';
import BannerTable from './BannerTable';
import BannerFilter from './Filter/BannerFilter';
import AddBannerForm from "./AddBannerForm";
import Swal from "sweetalert2";
import axios from 'axios';
import { HideItem, ShowItemFlex, countPage } from '../../ProductManager/productHelper';
import { GetBannerList, DeleteBanner } from '../../../components/public/PublicHelper';
import AddBannerFormEdit from './AddBannerFormEdit';
import { Delete } from '@mui/icons-material';

const baseUrl = process.env.REACT_APP_BASE_URL;
const Banner = () => {
  useTitle("Pao Kitchen | Banners")
  const productFilter = (e, searchValues) => {
    console.log(searchValues);
  }
  const clearFilter = (e) => {
  }

  const node = useRef(null);

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [bannerList, setBannerList] = useState([])
  const [bannerValue, setBannerValue] = useState({
    id: "",
    image: "",
    isActive: true,
    priority: ""
  })

  useEffect(() => {
    getBanner(page, "", "");
    return () => {
    };

    // eslint-disable-next-line
  }, []);

  const showAddBannerForm = () => {
    // document.querySelector(".content-main-add-banner").style.display = "flex";
    ShowItemFlex("#bannerAddForm")
  };

  const getBanner = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setBannerList([])
    setPage(page)
    setPageCount(0)

    GetBannerList(page, search, searchValue).then((res) => {
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;

      setPageCount(countPage(total, limit))

      let toPush = []

      Object.keys(paginatedResults).forEach(key => {
        const { _id, Image, Priority, IsActive, BranchId, Locale, Page, Type, ViewOption} = paginatedResults[key];
        toPush.push({
          id: _id,
          image: Image,
          priority: Priority,
          isActive: IsActive,
          branch: BranchId,
          locale: Locale,
          page: Page,
          type: Type,
          viewOption: ViewOption,

        })
      })
      setBannerList(toPush)
      Swal.close()
    }).catch((error) => {
      setBannerList([]);
      setPageCount(0)
      setPage(1)
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "No Banner(s) found!",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      });
    })
  }
  const showBannerEdit = (e, bannerValue) => {
    e.preventDefault()

    const { id, locale, page, type, image, isActive, branch, viewOption, priority } = bannerValue;

    setBannerValue({
      id: id,
      locale: locale,
      page: page,
      type: type,
      image: image,
      isActive: isActive,
      branch: branch,
      viewOption: viewOption,
      priority: priority
    })


    // document.querySelector(".content-main-update-banner").style.display = "flex";

    ShowItemFlex("#bannerAddFormEdit")

  }

  const updateBanner = async (e, bannerValue) => {
    e.preventDefault();
    let token = localStorage.getItem("cmsToken");
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const { id, locale, page, type, image, isActive, branch, viewOption, priority } = bannerValue;
    var data = {
      "Locale": locale,
      "Page": page,
      "Type": type,
      "Image": image,
      "IsActive": isActive,
      "BranchId": branch,
      "ViewOption": viewOption,
      "Priority": priority,
    };
    let config = {
      method: 'patch',
      url: `${baseUrl}/cms/public-manager/banner/update/${id}`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: data
    };

    await axios(config).then(res => {
      getBanner(page, "", "");
      HideItem("#bannerAddFormEdit");
      Swal.close()
      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Banner was updated`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    });

  }

  const submitBannerForm = async (bannerData, e) => {
    // console.log(bannerData)
    e.preventDefault();
    let token = localStorage.getItem("cmsToken");
    Swal.fire({
      title: "In progress!",
      text: "Please wait while doing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const { locale, page, type, image, isActive, branch, viewOption, priority } = bannerData;

    var data = {
      "Locale": locale,
      "Page": page,
      "Type": type,
      "Image": image,
      "IsActive": isActive,
      "BranchId": branch,
      "ViewOption": viewOption,
      // "Priority": priority,
    };

    let config = {
      method: 'post',
      url: `${baseUrl}/cms/public-manager/banner/add`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      data: data
    };

    await axios(config).then(res => {
      getBanner(page, "", "");
      HideItem("#bannerAddFormEdit");
      Swal.close()

      Swal.fire({
        toast: true,
        icon: 'success',
        title: `Banner was added`,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          Swal.hideLoading();
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was a problem occurred while doing your request! 😔",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    });
  }

  const deleteBanner = (id, e) => {
    e.preventDefault()
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      footer: "&#169; Pao Kitchen 2023",
      
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteBanner(id).then(res => {
          getBanner(1, "", "");
          Swal.fire({
            title: "Deleted",
            text: "Banner has been deleted!",
            icon: "success"
          })
        })
      }
    })
  }

  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        public management / banner list
      </section>
      <BannerFilter {...{ productFilter, clearFilter }} />
      <BannerTable tableContents={bannerList} showBannerEdit={showBannerEdit} deleteBanner={deleteBanner} />
      <Slider tableId={"bannerTable"} />
      <AddBannerButton addBanner={showAddBannerForm} tooltip={"Add Banner"} />
      <AddBannerForm {...{ submitBannerForm }} />
      <AddBannerFormEdit {...{ bannerValue, updateBanner }} />
    </main>
  )
}

export default Banner