import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Pagination } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import Swal from 'sweetalert2';
import { countPage, HideItem, IdSelector, ShowItemFlex } from '../../ProductManager/productHelper';
import { GetShippingOptionList } from "../EcommerceHelper";
import SearchFilterShippingOption from './Filter/SearchFilterShippingOption';
import ShippingOptionForm from './ShippingOptionForm';
import ShippingOptionTable from './ShippingOptionTable';
import ShippingOptionUpdate from './ShippingOptionUpdate';
const ShippingOptionList = () => {
  useTitle("Pao Kitchen | Shipping Option")
  const node = useRef(null);
  const node1 = useRef(null);
  const [shippingOptionList, setShippingOptionList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    getShippingOptionList(1, "")
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      if (node1.current.contains(e.target)) {
        return;
      }
      HideItem("#addShippingOption")
      HideItem("#updateShippingOption")
    };
    HideItem("#addShippingOption")
    HideItem("#updateShippingOption")
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

  }, [page])
  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
  }
  const getShippingOptionList = (page, search) => {
    setPageCount(0)
    setShippingOptionList([])

    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    GetShippingOptionList(page, search)
      .then(res => {
        const { paginatedResults, metadata } = res?.data;
        const { total, limit } = metadata;
        setPageCount(countPage(total, limit))
        setShippingOptionList(paginatedResults)
        Swal.close()
      })
      .catch(
        err => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "There was a problem occurred while doing your request! 😔",
            footer: "&#169; Pao Kitchen 2023",
            didOpen: () => {
              Swal.hideLoading();
            }
          })
          setPageCount(0)
          console.log(err.response)
        })
  }
  const tableScrollLeft = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("shippingOptionTable").style.scrollBehavior = "smooth";
        IdSelector("shippingOptionTable").scrollLeft -= 75;
      }, 100);

    }
  };
  let scrollHandle = 0;
  const tableScrollRight = () => {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        IdSelector("shippingOptionTable").style.scrollBehavior = "smooth";

        IdSelector("shippingOptionTable").scrollLeft += 75;
      }, 100);
    }
  };
  const stopScrolling = () => {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  };
  const [shippingOptions, setShippingOptions] = useState({
    _id: "",
    ShippingName: "",
    ShipiCode: "",
    IsActive: Boolean(true),
    CmsStatus: Boolean(true)
  })
  const showShippingOptionUpdate = (key, id) => {
    ShowItemFlex("#updateShippingOption")
    setShippingOptions(shippingOptionList[key])
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getShippingOptionList(1, "")
  }

  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getShippingOptionList(1, search)
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        e-commerce / shipping options
      </section>
      <SearchFilterShippingOption {...{ clearFilter, productFilter }} />
      <ShippingOptionTable {...{ shippingOptionList, showShippingOptionUpdate }} />

      {
        pageCount === 0 ? <></> :
          <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
      <div className="table-slider-left" onMouseEnter={tableScrollLeft} onMouseLeave={stopScrolling}>
        <div className="table-slider-left-button">
          <ChevronLeftIcon />
        </div>
      </div>
      <div
        className="table-slider-right"
        onMouseEnter={tableScrollRight}
        onMouseLeave={stopScrolling}
      >
        <div className="table-slider-right-button">
          <ChevronRightIcon />
        </div>
      </div>
      <ShippingOptionForm {...{ node1, getShippingOptionList }} />
      <ShippingOptionUpdate {...{ node, getShippingOptionList, shippingOptions }} />
      <div className='btn-open-modal'>
        <button onClick={(e) => {
          ShowItemFlex("#addShippingOption")
        }}><AddLocationAltIcon sx={{ fontSize: "16px" }} /></button>
      </div>
    </main>
  )
}

export default ShippingOptionList