import _ from "lodash";
import moment from "moment-timezone";
import CurrencyFormat from "react-currency-format";
import ScrollContainer from "react-indiana-drag-scroll";

const AnalyticsTableProduct = ({ tableContents }) => (
  <ScrollContainer className="content-product-table">
    <div className="content-product-table-head">
      <div className="content-product-table-head-item normal center item-name">DATE</div>
      <div className="content-product-table-head-item normal center item-name">ORDERS</div>
      <div className="content-product-table-head-item normal center item-name">SUBTOTAL</div>
      <div className="content-product-table-head-item normal center item-name">SHIPPING</div>
      <div className="content-product-table-head-item normal center item-name">DISCOUNTS</div>
      <div className="content-product-table-head-item normal center item-name">TOTAL</div>
    </div>
    {
      !_.isEmpty(tableContents) ? (
        Object.keys(tableContents).map((key, index) => {
          const { Date, Discount, Orders, Shipping, Subtotal, Total, } = tableContents[key]
          let dateData = moment.utc(Date).format("MMM. DD YYYY")
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal center  item-name">
                {dateData || "N/A"}
              </div>
              <div className="content-product-table-data-item normal center item-name">
                {Orders || "N/A"}
              </div>
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Subtotal}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>

              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Shipping}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Discount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>
              <div className="content-product-table-data-item normal center item-name">
                <CurrencyFormat
                  value={Total}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal center item-name">

          </div>
          <div className="content-product-table-data-item normal center item-name">

          </div>
          <div className="content-product-table-data-item normal center item-name">
            No Available Reports Found!
          </div>

          <div className="content-product-table-data-item normal center item-name">
          </div>
          <div className="content-product-table-data-item normal center item-name">

          </div>
          <div className="content-product-table-data-item normal center item-name">

          </div>


        </div>
      )}
  </ScrollContainer>
);

AnalyticsTableProduct.defaultProps = {
  tableContents: []
}

export default AnalyticsTableProduct;
