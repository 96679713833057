import React from "react";

const VerticlaDivider = () => {
  return (
    <div className="vertical-divider">
      <div className="vertical-divider-item"></div>
    </div>
  );
};

export default VerticlaDivider;
