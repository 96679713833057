import { Backdrop, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';
const ExportModalSales = ({
  exportModalEdit,
  closeExportModalEdit,
  modalContent
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: "0px 3px 10px #2a0e4e21",
    borderRadius: "6px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal
      open={exportModalEdit}
      onClose={closeExportModalEdit}
      aria-labelledby="export modal"
      aria-describedby="export modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 998
      }}

      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={exportModalEdit}>
        <Box sx={{ ...style, width: 300 }} >
          {modalContent}
          <div className='flex-end gap-10 margin-top-20'>
            <button className='btn-modal-close' onClick={() => {
              closeExportModalEdit()
            }}>Close</button>

          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
ExportModalSales.defaultProps = {
  exportModalEdit: false,
  closeExportModalEdit: () => { },
  modalContent: null,

}
export default ExportModalSales;
