import paoKitchenLogo from "./../../assets/pao-kitchen-logo.svg";
const Logo = ({ swidth, sheight }) => {
  return (
    <div>
      <img
        src={paoKitchenLogo}
        className="img-responsive image-logo"

        alt="PAO Kitchen Logo"
        width={swidth || "140.71"}
        height={sheight || "83.11"}
      />
    </div>
  );
};

export default Logo;
