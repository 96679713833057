import { t } from 'i18next'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Swal from 'sweetalert2'
import { SubmitResetPassword } from './PublicHelper'

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [passwordReset, setPasswordReset] = useState({
    id: String,
    t: String,
    NewPassword: ""
  })
  const [emailE, setEmailE] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)

  useTitle("Pao Kitchen | Reset password")
  useEffect(() => {
    setPasswordReset({
      ...passwordReset,
      id: searchParams.get("id"),
      t: searchParams.get("t")
    })
    return () => {

    }
    //eslint-disable-next-line
  }, [searchParams])

  const submitForgotPassword = (e) => {
    e.preventDefault()
    setSubmitLoading(true)
    console.log(passwordReset);
    if (_.isEmpty(passwordReset)) {
      setEmailE("Password field must not be empty")
      setSubmitLoading(false)
    } else {
      setEmailE("")
      SubmitResetPassword(passwordReset)
        .then(res => {
          setSubmitLoading(false)
          Swal.fire({
            icon: "success",
            title: "Successful!",
            text: res?.mess,
            footer: "&#169; Pao Kitchen 2023"
          })
        })
        .catch(
          err => {
            console.log(err?.response)
            setSubmitLoading(false)
            const { data, mess, messCn, messVn, messTh } = err?.response?.data
            if (_.isEmpty(data)) {
              Swal.fire({
                icon: "error",
                title: "failed!",
                text: mess || messVn || messCn || messTh,
                footer: "&#169; Pao Kitchen 2023"
              })
            } else {
              setEmailE(data?.NewPassword)
            }

          }
        )
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setPasswordReset({
      ...passwordReset,
      [name]: value
    })
  }
  const { NewPassword } = passwordReset
  return (
    <div className='forgot-password'>
      <div className='forgot-password-container'>
        <form onSubmit={(e) => {
          submitForgotPassword(e)
        }} method="post">
          <h1 aria-label='forgot password title'>{t('resetpassword')}</h1>
          <div className='input flex-column'>
            <label htmlFor="emailAddress">
              {t('newpassword')}
            </label>
            <input autoComplete='off' required type="text" onChange={(e) => handleChange(e)} name="NewPassword" value={NewPassword} id="emailAddress" placeholder={t('pleaseenternewpassword')} aria-label='text input for reset password' />

          </div>
          {
            _.isEmpty(emailE) ? (<></>) : (<small className='error-message'>{emailE}</small>)
          }
          <button disabled={submitLoading} type="submit" className={_.isEmpty(emailE) ? '' : "margin-top-10"}> {submitLoading ? (<div className='loader'></div>) : (<>{t('submit')}</>)}</button>
        </form>
        <small className='copyright'>&#169; Pao Kitchen 2023</small>
      </div>
    </div>
  )
}

export default ResetPassword