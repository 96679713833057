let axios = require("axios");
const baseUrl = process.env.REACT_APP_BASE_URL;

export const ChangePassword = async (id, data) => {
  let token = localStorage.getItem("cmsToken");

  let config = {
    method: 'put',
    url: `${baseUrl}/cms/users/update/${id}`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    data: data
  };

  let res = await axios(config);
  return res.data;

}