import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import ScrollContainer from 'react-indiana-drag-scroll';
const ShippingAreaTable = ({ shippingAreaList, changeStatus, showUpdateForm }) => {

  const container = useRef(null)

  useEffect(() => {
    container.current.getElement().id = 'shippingTable'
    return () => {

    }
  }, [])

  return (
    <ScrollContainer className="scroll-container content-product-table" ref={container}>
      <div className="content-product-table-head">
        <div className="content-product-table-head-item normal-start item-name">Area</div>
        <div className="content-product-table-head-item normal-start">Min. total amoun req.</div>
        <div className="content-product-table-head-item big-item ml-3">Total amount to free ship</div>
        <div className="content-product-table-head-item center">shipping fee</div>
        <div className="content-product-table-head-item  big-center">Note</div>
        <div className="content-product-table-head-item center">Status</div>
        <div className="content-product-table-head-item center">Action</div>
      </div>
      {shippingAreaList.length ? (
        Object.keys(shippingAreaList).map((key, index) => {
          const { _id, Area, IsActive, AreaNote, MinOrderAmount, ShippingFee, TotalOrderAmount } = shippingAreaList[key]
          return (
            <div className="content-product-table-data" key={index}>
              <div className="content-product-table-data-item normal-start item-name">
                {
                  Area
                }
              </div>
              <div className="content-product-table-data-item normal-start">
                {
                  <CurrencyFormat value={MinOrderAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />

                }
              </div>
              <div className="content-product-table-data-item big-item ml-3">
                {
                  <CurrencyFormat value={TotalOrderAmount} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                }
              </div>

              <div className="content-product-table-data-item center">
                {
                  <CurrencyFormat value={ShippingFee} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
                }
              </div>
              <div className="content-product-table-data-item big-center">
                <div className="item-column-start">
                  <span>{AreaNote.EN}</span>
                  <span>{AreaNote.VN}</span>
                  <span>{AreaNote.CN}</span>
                  <span>{AreaNote.TH}</span>
                </div>

              </div>
              <div className="content-product-table-data-item center">
                <div className="status">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="IsActive"
                      value={IsActive}
                      checked={IsActive}
                      onChange={() => changeStatus(key, _id)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="content-product-table-data-item center">
                <button className='edit-btn' onClick={() => showUpdateForm(key)}><EditIcon sx={{
                  fontSize: "20px"
                }} /></button>
              </div>

            </div>
          )
        })
      ) : (
        <div className="content-product-table-data">
          <div className="content-product-table-data-item normal-start item-name">

          </div>
          <div className="content-product-table-data-item normal-start">

          </div>
          <div className="content-product-table-data-item big-item ml-3">

          </div>

          <div className="content-product-table-data-item center">
            No Shipping Area found
          </div>
          <div className="content-product-table-data-item big-center">

          </div>
          <div className="content-product-table-data-item center">

          </div>
          <div className="content-product-table-data-item center">

          </div>

        </div>
      )}
    </ScrollContainer>
  )
};

export default ShippingAreaTable;
