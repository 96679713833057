import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTitle } from "react-use";
import Swal from "sweetalert2";
import { ClassSelector, countPage, GetProductList, HideItem, ShowItemFlex, UpdateProductStock } from "./productHelper";
import SearchFilterStock from "./ProductStock/Filter/SearchFilterStock";
import ProductStockTable from "./ProductStock/ProductStockTable";
import UpdateStockForm from "./ProductStock/UpdateStockForm";
import Slider from './Slider';

const ProductStock = () => {
  useTitle("Pao Kitchen | Stocks")
  const [product, setProduct] = useState([]);
  const [idTopass, setIdTopass] = useState();
  const node = useRef(null);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [productSearch, setProductSearch] = useState("")
  const [searchValues, setSearchValues] = useState({})
  useEffect(() => {

    getProductList(1, "", {})
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      ClassSelector("modal-add-group").style.display = "none";
    };
    ClassSelector("modal-add-group").style.display = "none";
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const getProductList = (page, search, searchValue) => {
    Swal.fire({
      title: "In progress!",
      text: "Please wait while fetching data",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setProduct([])
    setPageCount(0)
    GetProductList(page, search, searchValue).then((res) => {
      const { paginatedResults, metadata } = res?.data;
      const { total, limit } = metadata;
      setPageCount(countPage(total, limit))
      setProduct(paginatedResults);
      Swal.close()
    }).catch(
      () => {
        setPageCount(0)
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "No Product(s) found!",
          footer: "&#169; Pao Kitchen 2023",
          didOpen: () => {
            Swal.hideLoading();
          }
        });
      }
    )
  }

  const handlePage = (e, p) => {
    e.preventDefault()
    setPage(p)
    getProductList(p, productSearch, searchValues)
  }
  const [showUpdate, setShowUpdate] = useState(false)
  const showUpdateStock = (id, e) => {
    e.preventDefault();
    ShowItemFlex(".modal-add-group")
    setIdTopass(id);
    setShowUpdate(true)
  }
  const submitUpdateStockForm = (stockNewValue, idToUpdate, e) => {
    e.preventDefault();
    HideItem(".modal-add-group");
    Swal.fire({
      title: "In progress!",
      text: "Please wait while processing your request",
      allowOutsideClick: false,
      footer: "&#169; Pao Kitchen 2023",
      didOpen: () => {
        Swal.showLoading();
      }
    });
    UpdateProductStock(idToUpdate, stockNewValue, "").then(res => {
      getProductList(page, "", {})

      Swal.close()
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Error occured while doing your request!",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      });
    }
    )
  }
  const clearFilter = (e) => {
    e.preventDefault()
    getProductList(1, "", {})
  }
  const productFilter = (e, searchValue) => {
    e.preventDefault()
    const { search } = searchValue
    getProductList(1, search, {})
    setProductSearch(search)
    setSearchValues({})
  }
  return (
    <main className="content-product">
      <section className="content-product-breadcrumbs">
        product management / product stock
      </section>
      <SearchFilterStock {...{ clearFilter, productFilter }} />
      <ProductStockTable {...{ product, showUpdateStock }} />
      <Slider tableId={"productStock"} />
      <UpdateStockForm {...{ node, idTopass, submitUpdateStockForm, showUpdate, setShowUpdate }} />
      {pageCount <= 0 ? <></> :
        <Pagination count={pageCount} onChange={handlePage} page={page} shape="rounded" />
      }
    </main>
  );
};

export default ProductStock;
