import React from "react";
import fb from "./../../../assets/social-link/fb.svg";
import instagram from "./../../../assets/social-link/instagram.svg";
import vmart from "./../../../assets/social-link/vmart.svg";
const SocialLink = () => {
  const openInNewTab = (e, url) => {
    e.preventDefault()
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
      <div></div>
    // <div className="social-links">
    //
    //   <img src={fb} onClick={(e) => openInNewTab(e, "https://www.facebook.com/Annam.KTV")} alt="fb-circle" width="25" height="24" />
    //   <img src={instagram} onClick={(e) => openInNewTab(e, "https://www.instagram.com/annam.mnl/")} alt="fb-circle" width="24.53" height="24.53" />
    //   <img src={vmart} onClick={(e) => openInNewTab(e, "https://vmart.ph/")} alt="fb-circle" width="" height="18" />
    // </div>
  );
};

export default SocialLink;
