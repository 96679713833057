import React from 'react';

const SendOptionC = ({ SendOption, id, value, name, text, handleChange, disabled }) => {
  return (
    <label className="sendoption-label" htmlFor={id}>
      <input
        type="radio"
        checked={SendOption === value}
        name={name}
        disabled={disabled}
        value={value}
        id={id}
        onChange={(e) => {
          handleChange(e)
        }}
      />{' '}
      {text}
    </label>
  );
};

export default SendOptionC;

