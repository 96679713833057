import CurrencyFormat from 'react-currency-format';

const ProductDetail = ({product, sendOption: orderType}) => {
    const { quantity, price, Product: ProductGeneral, SKU} = product
    const {Name: ProductName, Images} = ProductGeneral
    const getProductImage = () => {
        let src
        switch (orderType) {
            case "DELIVERY":
                src = Images["Delivery"]
                break
            default:
                src = Images["DineIn"]
        }

        return src
    }

    return (
        <div className="order-container-left-products-list">
            <div className="order-container-left-products-list-details fix-width">
                <img src={getProductImage()} alt="product img" width={75} height={75}/>
                <div className="product-details">
                    <span className="product-details-main margin-bottom-6">{ProductName.EN}</span>
                    <span className="product-details-sub">{ProductName.VN}</span>
                    <span className="product-details-sub">{ProductName.CN}</span>
                    <span className="product-details-sub margin-bottom-6">{ProductName.TH}</span>
                    <span className="product-details-sub">{SKU}</span>
                </div>
            </div>
            <div className="order-container-left-products-list-number">
              <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'₱ '} />
              <CurrencyFormat
                  value={quantity}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
              />
              <CurrencyFormat
                  value={price * quantity}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₱ '}
              />
            </div>
        </div>
    );
};

export default ProductDetail;