import _ from 'lodash';
import ReactQuill from 'react-quill';
import Swal from 'sweetalert2';
import EditorToolbar, { formats, modules } from '../EditorToolbar';
import { statuses } from '../OrderUtils';
import StatusContainer from './StatusContainer';
import GenerateQRCode  from './GenerateQRCode';
import {Tooltip} from "@mui/material";

// const baseUrl = process.env.REACT_APP_BASE_URL;
const OrderStatusContainer = ({ Status, updateOrderStatus,
  setStatusNote,
  statusNote, setOrder, order, currentStatus, currentPaymentStatus, openModalQRCode }) => {
  const statusChange = (e) => {
    setStatusNote(e)
  }
  const legibleUpdate = (e) => {
    //updateOrderStatus(e)
    // alert(statusNote)


    if (order?.Status > 6 && (_.isEmpty(statusNote) || statusNote === "<p><br></p>")) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Status note is required in (DECLINED, CANCELLED, RETURNED) statuses!",
        footer: "&#169; Pao Kitchen 2023",
        didOpen: () => {
          Swal.hideLoading();
        }
      })
    } else {
      updateOrderStatus(e)
    }




  }
  const notifyLegible = (e) => {
    const { ContactDetails, OrderRef } = order

    if (currentStatus === 1) {
      copyText(`${ContactDetails.FullName} 
      Đơn hàng ${OrderRef} đã được bếp confirm.`)
    } else {
      copyText(`${ContactDetails.FullName} 
      Chào bạn, Đơn hàng ${OrderRef} của bạn hiện tại đã chuyển sang trạng thái  ${statuses[currentStatus]} . Bạn có thể kiểm tra chi tiết tại đường dẫn sau.
${window.location.host}/ordersummary/${OrderRef}`)

    }

  }
  const copyText = (value) => {

    navigator.clipboard.writeText(value);
    Swal.fire({
      toast: true,
      icon: 'success',
      title: value,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }
  return (
    <div className="width-100 order-container-right-container">
      <div className="order-container-right-container-content">
        <span className="time-label">Order Status</span>
      </div>
      <StatusContainer {...{ Status, setOrder, order, currentStatus, currentPaymentStatus }} />
      <div className="order-container-right-container-content">
        <div className="width-100 bg-white">
          <EditorToolbar toolbarId={'t1'} />
          <ReactQuill
            theme="snow"
            value={statusNote}
            onChange={(e) => {
              statusChange(e)
            }}
            modules={modules('t1')}
            formats={formats}
          />
        </div>
      </div>
      <div className="flex-row-end margin-right-10 gap-5">
        <button onClick={(e) => openModalQRCode()
        } className="button-primary">Generate QR Code</button>

        <button onClick={(e) => notifyLegible(e)
        } className="button-skeleton order-edit-btn-notify">Notify</button>

        {
          currentStatus === 6 ?
              (<>
                <Tooltip title="Unable to update completed order">
                  <button className="button-primary disabled" >Update</button>
                </Tooltip>
              </>) :
              (
                <>
                  <button onClick={(e) => legibleUpdate(e)
                  } className="button-primary">Update</button>
                </>
              )
        }



      </div>
    </div>
  );
};

export default OrderStatusContainer;

