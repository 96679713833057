import Proptypes from "prop-types"
import ReactSelect from "react-select"
import {useState, useRef, useEffect} from "react";
import {ceil} from "lodash/math";
import {throttle} from "lodash/function";
import {getListOrderDineInAndKTV} from "../../../EcommerceHelper";
import css from "../../OrderDineIn.module.scss"
import {useTranslation} from "react-i18next";

const SelectOrder = (props) => {
    const [otherErrorMessage, setOtherErrorMessage] = useState()
    const LIMIT = 20
    const {onSelect, isClear} = props
    const isClearable = isClear ?? false
    const [listOrders, setListOrders] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const menuIsOpen = useRef(false)
    const PAGE = useRef(1);
    const [textSearch, changeTextSearch] = useState("")

    const getOrderDineInOrKTV = async (textSearch = "") => {
        if (!listOrders.isLoading && menuIsOpen.current) {
            setListOrders({
                ...listOrders,
                isLoading: true
            })

            const options = PAGE.current > 1 ? listOrders.options : []
            let totalItems = 0
            try {
                const res = await getListOrderDineInAndKTV(PAGE.current, LIMIT, textSearch)

                const status = res?.status ?? "fail"
                if(status === "fail") {
                    const language = localStorage.getItem("language") ?? "EN";

                    const { mess: messEN, messCn, messVn, messTh } = res ?? {}
                    let mess = messEN
                    switch (language){
                        case "VN":
                            mess = messVn;
                            break
                        case "TH":
                            mess = messTh;
                            break
                        case "CN":
                            mess = messCn;
                            break
                        default:
                            break
                    }

                    setOtherErrorMessage(mess ?? "Something went wrong!")
                }

                const data = res.data ?? {}

                const paginatedResults = data?.paginatedResults ?? []

                const metadata = data?.metadata ?? {}
                totalItems = metadata?.total ?? 0
                paginatedResults.forEach(order => {
                    const {_id: value, OrderRef, ContactDetails} = order
                    const phone = ContactDetails?.MobileNum || "N/A"
                    const label = `${OrderRef} / ${phone}`

                    options.push({value, label, order})
                })
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setListOrders({
                textSearch: listOrders.textSearch,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE.current = 1
        await getOrderDineInOrKTV(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!listOrders.isLoading) {
            const {totalItems} = listOrders
            const totalPage = ceil(totalItems / LIMIT, 0)
            const newPageNumber = (PAGE.current < totalPage) ? (PAGE.current + 1) : PAGE.current
            if (newPageNumber !== PAGE.current) {
                PAGE.current = newPageNumber
                await getOrderDineInOrKTV()
            }
        }
    }

    const filterOption = (option, inputValue) => {
        return option
    }

    const formatOptionLabel = (data, formatOptionLabelMeta) => {
        const {order} = data ?? {}

        const {ContactDetails, OrderRef} = order ?? {}
        const {MobileNum, FullName} = ContactDetails ?? {}
        const context = formatOptionLabelMeta.context
        if(context === "value") {
            return <div className={css.orderOptionContainer}>
                    <span className={css.optionOnlyRef}>{OrderRef || "N/A"}</span>
                </div>
        }
        return (
            <div className={css.orderOptionContainer}>
                <span className={css.optionRef}>{OrderRef || "N/A"}</span>
                <ul className={css.listInfo}>
                    <li>{FullName || "N/A"}</li>
                    <li>{MobileNum || "N/A"}</li>
                </ul>
            </div>
        )
    }

    const {t} = useTranslation()

    const placeholder = t("selectOrderPlaceholder")

    return (
        <div className={css.orderSelectContainer}>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={listOrders.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(option) => {
                    if(typeof onSelect === "function") {
                        onSelect(option)
                    }
                }}
                onMenuOpen={async () => {
                    menuIsOpen.current = true
                    await getOrderDineInOrKTV()
                }}
                options={listOrders.options}
                name="type"
                onBlur={(e) => {
                    menuIsOpen.current = false
                }}
                value={props.value}
                onMenuScrollToBottom={async (e) => {
                    await handleOnScrollToBottom()
                }}
                isClearable={isClearable}
                filterOption={filterOption}
                formatOptionLabel={formatOptionLabel}
                styles={{
                    option: (base) => ({
                        ...base,
                        borderBottom: "1px solid #a0a0a0"
                    }),
                }}
                placeholder={placeholder}
            />
            {otherErrorMessage ? (<p className={css.textDanger}>{otherErrorMessage}</p>) : null}
        </div>
    )

}

SelectOrder.propTypes = {
    onSelect: Proptypes.func.isRequired,
    isClear: Proptypes.bool,
    value: Proptypes.any
}

export default SelectOrder