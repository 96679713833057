import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../components/reusable/Logo';
const PrintSmallPOS = ({ closeModalEdit, order, paymentMethod, computeAddedFee }) => {
  const componentRef = useRef(null);
  // const [addFee, setAddFee] = useState(0)
  const handleAfterPrint = useCallback(() => {

    closeModalEdit()
  }, [closeModalEdit]);

  const handleOnBeforeGetContent = useCallback(() => {


  }, []);
  const handleBeforePrint = useCallback(() => {

  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${order.OrderRef}`,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onBeforeGetContent: handleOnBeforeGetContent,
  });
  useEffect(() => {

    handlePrint()
    // //console.log(totalAddedFee);
    // return () => {
    //   handlePrint()
    // }
    //eslint-disable-next-line
  }, [handlePrint, computeAddedFee, order])
  const { ShippingAmount, OrderAmount, OrderDetails, CreatedAt, ContactDetails, OrderRef, DeliveryDetails, PreferredDeliveryDate, TotalAmount, OrderNote, IsMealAllowance } = order

  return (
    <div className='forPrintSmall' ref={componentRef}>
      <div className='flex-column-center-center gap-5 width-100 margin-bottom-40'>
        <div className='logo'>
          <Logo swidth={100} sheight={100} />
        </div>
        <div className='flex-column-center-center gap-5 width-100'>
          <span>176 Yakal, AGS Plaza, Makati</span>
          <span>0968 852 6209</span>
          <span>http://paokitchen.ph</span>
        </div>

      </div>
      <div className='flex-column-start gap-4 margin-bottom-30'>
        <div className='table note-receipt'>
          <div className='note-receipt'><b> {IsMealAllowance ? "Meal Allowance" : ""} </b> </div>
          <div className='note-receipt'>{OrderNote} </div>
        </div>
        <div className='flex-column-start gap-4 margin-bottom-20'></div>
        <span>Date Ordered:  {moment(CreatedAt).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
        <span>Reference #: {OrderRef}</span>
        <span>Payment Via: {paymentMethod ?? "N/A"}</span>
        <span>Shipping Time: {moment(PreferredDeliveryDate).tz("Asia/Manila").format("MM/DD/YYYY HH:mm A")}</span>
      </div>

      <div className='table margin-bottom-15 width-100'>
        <div className='flex-column-start width-100'>
          {
            _.isEmpty(OrderDetails) ? (
              <div className="flex-row-center-center width-100 table-body">
                <span>No Products Found</span>
              </div>
            ) :
              (<>
                {
                  _.map(OrderDetails, (value, key) => {
                    const { Quantity, ProductName, TotalPrice } = value
                    return (
                      <div key={key} className="flex-row-start gap-5 width-100 table-body">
                        <div className='quantity'>x {Quantity}</div>
                        <div className='flex-column-start name-t'>
                          <div className='flex-row-start name-t'>
                            <span className='name-t-desc'>{(ProductName.VN || ProductName.EN || ProductName.CN || ProductName.TH)}</span>
                          </div>
                          <small className='name-t-desc font-bold' >{(ProductName.EN || ProductName.CN || ProductName.TH || ProductName.VN)}</small>
                        </div>
                        <div className='flex-row-end price'>
                          <CurrencyFormat
                            value={TotalPrice ?? 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={''}
                          /></div>
                      </div>
                    )
                  })
                }
              </>)
          }
        </div>
      </div>

      <div className='flex-column-start gap-5 margin-bottom-20 width-100 pricing'>
        <span className='flex-row-space-between width-100'>Subtotal :
          <CurrencyFormat
              value={OrderAmount ?? 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
          />
        </span>
        {/*<span className='flex-row-space-between width-100'>Added fee :*/}
        {/*  <CurrencyFormat*/}
        {/*    value={computeAddedFee(order.AddFee, order.OrderAmount).totalAddedFee}*/}
        {/*    displayType={'text'}*/}
        {/*    thousandSeparator={true}*/}
        {/*    prefix={''}*/}
        {/*  />*/}
        {/*</span>*/}
        {
          !_.isEmpty(order.AddFee) ? _.map(order.AddFee, (value, key) => {
            const { FeeValue, Name, Type } = value
            return (
                <span className='flex-row-space-between width-100' key={`added_fee_${key}`}>{Name} :
                  <CurrencyFormat
                      value={FeeValue ?? 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={Type === "fixed" ? '₱ ' : ''}
                      suffix={Type === "percent" ? '%' : ''}
                  />
                </span>
            )
          }) : (<></>)
        }
        <span className='flex-row-space-between width-100'>Shipping Fee :
          <CurrencyFormat
            value={ShippingAmount ?? 0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
          />
        </span>
        <span className='flex-row-space-between width-100'><b>Total</b>
          <b>
            <CurrencyFormat
                value={TotalAmount ?? 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={''}
            />
          </b>

        </span>
      </div>
      <div className='flex-column-start gap-4 margin-bottom-30'>
        <span>Name:  {ContactDetails?.FullName}</span>
        <span>Address: {DeliveryDetails?.Address}</span>
        <span>City: {DeliveryDetails?.Area}</span>
        <span>Phone: {ContactDetails?.MobileNum}</span>
      </div>
      <div className='flex-column-center-center end-of-receipt width-100'>
        <span className='margin-bottom-30 width-100'>THIS RECEIPT SHALL BE VAILD FOR FIVE(5) YEARS FROM THE DATE OF PERMIT TO USE.</span>
        <span className='margin-bottom-30 width-100'>Thank you for shopping with us!</span>
        <span className='margin-bottom-30 width-100'>Please contact us with any questions or problems you have.</span>
        <span >** CUSTOMER RECEIPT COPY **</span>
      </div>
    </div >
  )
}

export default PrintSmallPOS