const Card = ({ showCard, number, text, icon }) => {
    const renderIcon = () => {
        if(!icon) {
          return null
        }
    
        return (
            <div className='customer-analytics-card-icon'>
              {icon}
            </div>
        )
    }
    
    const renderContentCard = () => {
        if(!showCard) {
          return null
        }
        return (
            <div className='width-100 flex-row-space-between-center'>
              <div className='customer-analytics-card-data'>
                <h2>{number}</h2>
                <span className="text-span">{text}</span>
              </div>
              {renderIcon()}
            </div>
        )
    }

    return (
        <div className={`flex-row-center-center ${showCard ? "customer-analytics-card" : "customer-analytics-card-none"}`} >
          {renderContentCard()}
        </div>
    )
}

  
export default Card