import {useEffect, useState} from "react";
import {post} from "../../../helpers/axiosHelper"
import "./style.scss"
import Swal from "sweetalert2";
import {getSettingPusher} from "../../../helpers/pusherHelper";

const PusherNotification = () => {
    const [pusherSetting, setPusherSetting] = useState({
        enable: false,
        loopTime: 5
    })

    const getSetting = async () => {
        try {
            const res = await getSettingPusher()
            if(res) {
                setPusherSetting({
                    ...pusherSetting,
                    ...res
                })
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        getSetting().catch((e) => console.error(e))
        // eslint-disable-next-line
    }, [])

    const handleChange = (e) => {
        let value = e.target.value
        if(e.target.name === "enable") {
            value = e.target.value === "enable"
        }

        setPusherSetting({
            ...pusherSetting,
            [e.target.name]: value
        })
    }

    const handleSaveConfig = async () => {
        Swal.showLoading()

        const res = await post("/cms/pusher/setting-pusher", pusherSetting)
        if(res.data?.data !== "success") {
            alert("Something went wrong")
        }

        Swal.close()
    }

    return (
        <main className="content-product">
            <section className="content-product-breadcrumbs">
                Setting site / pusher
            </section>
            <div className="pusher-setting-container">
                <div className="pusher-setting-form">
                    <div className="block-50">
                        <label>Enable</label>
                        <select onChange={handleChange} name="enable" value={pusherSetting.enable === true ? "enable" : "disabled"}>
                            <option value="enable">Enable</option>
                            <option value="disabled">Disabled</option>
                        </select>
                    </div>
                    <div className="block-50">
                        <label>Repeat after(minute)</label>
                        <input type="number" name="loopTime" onChange={handleChange} value={pusherSetting.loopTime} placeholder="Enter number minute for loop time. Default 5" />
                    </div>
                </div>
                <div className="pusher-setting-footer">
                    <button className="pusher-setting-btn-save" onClick={handleSaveConfig}>Save</button>
                </div>
            </div>
        </main>
    );
};

export default PusherNotification;
